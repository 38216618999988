import { MODAL_ROUTE_URLS } from 'router/modalRoutes'
import { ROUTE_URLS } from 'router/routes'

interface IMenuSubItems {
  image?: string
  title: string
  link: string
  modalLink?: string
  isAuthReq?: boolean
  isHot?: boolean
}

export interface IMenuItem {
  name: string
  items: IMenuSubItems[]
}

export const headerItems: IMenuSubItems[] = [
  {
    image: '',
    title: 'home',
    link: ROUTE_URLS.MAIN(),
  },
  {
    image: '',
    title: 'promotions',
    link: ROUTE_URLS.PROMOTIONS(),
  },
]

export const firstItemsGroup: IMenuItem = {
  name: 'home',
  items: [
    {
      image: '',
      title: 'home',
      link: ROUTE_URLS.MAIN(),
    },
  ],
}

export const thirdItemsGroup: IMenuItem = {
  name: 'casino',
  items: [
    {
      image: '',
      title: 'casino',
      link: ROUTE_URLS.SLOTS_CASINO(),
    },
    {
      image: '',
      title: 'liveCasino',
      link: ROUTE_URLS.LIVECASINO(),
    },
  ],
}

export const tvGamesItemsGroup: IMenuItem = {
  name: 'tvGames',
  items: [
    {
      image: '',
      title: 'TV Games',
      link: ROUTE_URLS.SLOTS_GAMETYPE('tv-games'),
    },
  ],
}

export const virtualItemsGroup: IMenuItem = {
  name: 'virtualSport',
  items: [
    {
      image: '',
      title: 'virtuals',
      link: ROUTE_URLS.SLOTS_GAMETYPE('virtual'),
      // isHot: true,
    },
  ],
}

export const singleItems: IMenuSubItems[] = [
  {
    image: '',
    title: 'live',
    link: ROUTE_URLS.LIVECASINO(),
  },
]

export const fourthItemsGroup: IMenuItem = {
  name: 'betOnGames',
  items: [
    {
      image: '',
      title: 'Keno',
      link: ROUTE_URLS.SLOTS_PROVIDER('softwiss', 'infin:Keno', 'casino'),
      modalLink: MODAL_ROUTE_URLS.REPLACING_SLOT_FRAME('softwiss', 'infin:Keno'),
      isAuthReq: true,
    },
    {
      image: '',
      title: 'SicBo',
      link: ROUTE_URLS.SLOTS_PROVIDER('softwiss', 'belatra:SicBo', 'casino'),
      modalLink: MODAL_ROUTE_URLS.REPLACING_SLOT_FRAME('softwiss', 'belatra:SicBo'),
      isAuthReq: true,
    },
    {
      image: '',
      title: 'Penalty',
      link: ROUTE_URLS.SLOTS_PROVIDER('softwiss', 'evoplay:PenaltySeries', 'casino'),
      modalLink: MODAL_ROUTE_URLS.REPLACING_SLOT_FRAME('softwiss', 'evoplay:PenaltySeries'),
      isAuthReq: true,
    },
  ],
}
