import React, { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import block from 'bem-cn'

import { selectLocaleDict } from 'features/locale/selectors'
import { getSpinHistory } from 'features/wheelGame/actions'
import { selectSpinHistory } from 'features/wheelGame/selectors'

import './WheelGameHistory.scss'

const b = block('wheel-game-history')

const WheelGameHistory = () => {
  const dispatch = useDispatch()

  const locale = useSelector(selectLocaleDict, shallowEqual).wheelGame

  const spinHistory = useSelector(selectSpinHistory, shallowEqual)

  useEffect(() => {
    dispatch(getSpinHistory())
  }, [])

  const history = useMemo(
    () =>
      spinHistory.map(item => (
        <div key={item.id} className={b('row')}>
          <div className={b('col', { isDate: true })}>
            <div className={b('col_time')}>{item.date}</div>
          </div>
          <div className={b('col', { isType: true })}>{item.type}</div>
          <div className={b('col', { isAmount: true })}>
            +{item.winAmount}
            {/* <img src={dtIcon} className={b('coin-icon', { isCol: true })} /> */}
          </div>
        </div>
      )),
    [spinHistory],
  )

  return (
    <div className={b('modal')}>
      {/* <div className={b('top-info')}>
        <div className={b('top-amount')}>
          <div className={b('top-amount__title')}>Amount unlocable</div>
          <div className={b('top-amount__value')}>
            <img src={dtIcon} className={b('coin-icon', { isTopAmount: true })} />
            10.00
          </div>
        </div>
        <div className={b('img-block')}>
          <img src={amountBgImg} className={b('img-block__bg')} />
          <div className={b('img-block__bubble')} />
        </div>
        <div className={b('unlocked-block')}>
          Unlocked:
          <img src={dtIcon} className={b('coin-icon', { isUnlocked: true })} />
          <div className={b('unlocked-block__value')}>0.00</div>
        </div>
        <button className={b('btn-claim')}>
          <div className={b('btn-claim__inner')}>Claim</div>
        </button>
      </div>
      <div className={b('history-container')}>
        <div className={b('heading')}>
          <div className={b('heading__title')}>BCD Record</div>
          <div className={b('heading__claimed-amount')}>
            Total claimed
            <img src={dtIcon} className={b('coin-icon', { isHeading: true })} />
            <span>10.00</span>
          </div>
        </div> */}
      <div className={b('history')}>
        <div className={b('table')}>
          <div className={b('row', { isHeading: true })}>
            <div className={b('col-head', { isDate: true })}>{locale.date}</div>
            <div className={b('col-head', { isType: true })}>{locale.type}</div>
            <div className={b('col-head', { isAmount: true })}>{locale.amount}</div>
          </div>
          <div className={b('table_list')}>{history}</div>
        </div>
        <div className={b('pagination')} />
      </div>
    </div>
    // </div>
  )
}

export default WheelGameHistory
