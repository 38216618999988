import React from 'react'
import block from 'bem-cn'

import './Checkbox.scss'

interface ICheckboxProps {
  checked: boolean
  onChange?: (name: string, value: boolean) => void
  name: string
  disabled?: boolean
}

const b = block('checkbox')

export const Checkbox: React.FC<ICheckboxProps> = ({ checked = false, onChange, name, disabled, ...props }) => {
  const onClickCheckbox = () => {
    if (onChange) {
      onChange(name, !checked)
    }
  }

  return (
    <div className={b('wrapper')} onClick={onClickCheckbox}>
      <input type="checkbox" className={b()} name={name} checked={checked} disabled={disabled} readOnly />
      <label />
    </div>
  )
}
