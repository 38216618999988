import { AvailableCryptoCurrenciesList } from 'shared/models/Crypto'
import {
  CountriesWhiteList,
  IDefaultDepositConfig,
  IDepositCountry,
  OrderCurrenciesToList,
  OrderProvidersList,
  PaymentMethodsList,
} from 'shared/models/Deposit'

export const deafultCreatePaymentCurrency: AvailableCryptoCurrenciesList = 'usdttrc20'

export const defaultDepositConfig: IDefaultDepositConfig = {
  currency: OrderCurrenciesToList.USDTRX,
  provider_code: OrderProvidersList.MOONPAY,
  payment_method: PaymentMethodsList.CARD,
}

export const getListOfCountriesForSelect = (countries: IDepositCountry[]) =>
  countries.filter(country => CountriesWhiteList[country.name.toUpperCase() as keyof typeof CountriesWhiteList])
