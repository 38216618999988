import { createReducer } from '@reduxjs/toolkit'

import * as actions from '../actions'
import { IFrameManagerState } from '../types'

const initialState: IFrameManagerState['data'] = {
  openedFrames: [],
  countFrames: 0,
}

export default createReducer(initialState, builder => {
  builder
    .addCase(actions.addFrame, (state, action) => {
      state.openedFrames = [...state.openedFrames.filter(frame => frame.id !== action.payload.id), action.payload]
    })
    .addCase(actions.addFrameSession, (state, action) => {
      state.openedFrames.map(frame => {
        if (frame.id === action.payload.id) {
          frame.sessionUrl = action.payload.session
        }
        return frame
      })
    })
    .addCase(actions.removeFrame, (state, action) => {
      state.openedFrames = state.openedFrames.filter(frame => frame.id !== action.payload)
    })
    .addCase(actions.removeAllFrames, state => {
      state.openedFrames = []
    })
    .addCase(actions.changeIsFullscreenFrame, (state, action) => {
      state.openedFrames = state.openedFrames.map(frame => {
        if (frame.id === action.payload.id) {
          return {
            ...frame,
            isFullscreen: action.payload.val,
          }
        }
        return frame
      })
    })
    .addCase(actions.changeCountFrames, (state, action) => {
      state.countFrames = action.payload
    })
})
