import React, { useCallback, useContext } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import block from 'bem-cn'

import { MODAL_ROUTE_URLS } from 'router/modalRoutes'

import logoImg from 'shared/img/logo.png'

import { selectLocaleDict } from 'features/locale/selectors'

import { ModalContext } from 'components/HOC/withModalQuery/ModalContext'

import Button from 'components/UIKit/Button/Button'

import {
  StyledAuthBody,
  StyledAuthForm,
  StyledAuthLogo,
  StyledAuthModal,
  StyledButtonCointaner,
} from '../styles/AuthStyles'

import './EmailSent.scss'

const b = block('email-sent')

export const EmailSent = () => {
  const { closeModal } = useContext(ModalContext)
  const locale = useSelector(selectLocaleDict, shallowEqual).auth
  const localeCommon = useSelector(selectLocaleDict, shallowEqual).common

  const onCloseModal = useCallback(() => {
    closeModal(MODAL_ROUTE_URLS.EMAIL_SENT())()
  }, [closeModal])

  return (
    <StyledAuthModal width="540px">
      <div className={b('header')}>{localeCommon.great}</div>

      <StyledAuthForm>
        <StyledAuthBody>
          <h3 className={b('text-h')}>{locale.checkEmail}</h3>
          <p className={b('text-p')}>{locale.youShouldReceiveEmail}</p>
        </StyledAuthBody>
        <StyledButtonCointaner>
          <Button callback={onCloseModal} colorType="primary" text="OKAY" />
        </StyledButtonCointaner>
      </StyledAuthForm>
    </StyledAuthModal>
  )
}
