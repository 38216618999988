import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import block from 'bem-cn'
// import loaderImg from 'shared/img/cart.png'
import { AnimatePresence, motion } from 'framer-motion'
import { Loader } from 'rsuite'

import { selectLocaleDict } from 'features/locale/selectors'

import './Spinner.scss'

interface ISpinnerProps {
  isLoading: boolean
  text?: string
  size?: string
}

export const Spinner: React.FC<ISpinnerProps> = ({ isLoading, text, size = 'small' }) => {
  const b = block('spinner')

  const locale = useSelector(selectLocaleDict, shallowEqual).common

  return (
    <AnimatePresence exitBeforeEnter>
      {isLoading ? (
        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.25 }}
          className={b({ size })}>
          {/* <img src={loaderImg} className={b('icon')} alt="spinner" / */}
          <Loader size="lg" content={text || locale.loading} />
          {/* <div className={b('text')}></div> */}
        </motion.div>
      ) : null}
      ;
    </AnimatePresence>
  )
}
