import DePNG from './img/de.png'
import EnPNG from './img/en.png'
import RuPNG from './img/ru.png'

interface LangOptions {
  lang: string
  icon: string
  text: string
  isArabic?: boolean
}

export type languagesType = 'en-US' | 'ru-RU' | 'de-de'

export const languages: { [key: string]: languagesType } = {
  EN: 'en-US',
  RU: 'ru-RU',
  DE: 'de-de',
}

export const languagesWithIcons: { [key: string]: LangOptions } = {
  'en-US': { lang: languages.EN, icon: EnPNG, text: 'English' },
  'ru-RU': { lang: languages.RU, icon: RuPNG, text: 'Русский' },
  'de-de': { lang: languages.DE, icon: DePNG, text: 'Deutsch' },
}

export const shortLanguages: { [key: string]: string } = {
  'en-US': 'en',
  'ru-RU': 'ru',
  'de-de': 'de',
}

export const transLang: { [key: keyof typeof shortLanguages]: string } = {
  'en-US': 'en-US',
  'ru-RU': 'ru-RU',
  'de-de': 'de-DE',
}
