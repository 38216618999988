import block from 'bem-cn'
import React, { useCallback, useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import { Search } from '@mui/icons-material'
import { IoMdClose, IoMdHome } from 'react-icons/io'
import { ROUTE_URLS } from 'router/routes'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { changeOpenedLeftSidePanel } from 'features/sidePanel/actions'
import { selectOpenedLeftSidePanel } from 'features/sidePanel/selectors'
import BottomTabBar from 'components/BottomTabBar/BottomTabBar'
import SidebarLinks from './SidebarLinks/SidebarLinks'
import logo from '../../shared/img/sidebar/logo.svg'
import promotion from '../../shared/img/sidebar/promotions.svg'
import './Sidebar.scss'
import { forYouLinks, gamesLinks } from './data'

const Sidebar = () => {
  const b = block('sidebar')
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [activeTab, setActiveTab] = useState<number | null>(null)
  const isLeftPanelOpened = useSelector(selectOpenedLeftSidePanel, shallowEqual)
  // const isActive = useRouteMatch(ROUTE_URLS.MAIN())?.isExact;

  const handleOpenLeftPanel = useCallback(() => {
    dispatch(changeOpenedLeftSidePanel(!isLeftPanelOpened))
  }, [dispatch, isLeftPanelOpened])

  const handleTabClick = (tabId: number) => {
    setActiveTab(tabId)
  }
  return (
    <aside className={b()}>
      <section className={b('sidebar-container')}>
        <div className={b('logo-container')}>
          <Link className={b('logo')} to={ROUTE_URLS.MAIN()}>
            <span>
              <img src={logo} alt="logo" />
              <span>spineo.io</span>
            </span>
          </Link>

          <div className={b('close-button')} onClick={handleOpenLeftPanel}>
            <IoMdClose />
          </div>
        </div>

        <div className={b('searchInput')}>
          <OutlinedInput
            id="outlined-adornment-password"
            type="text"
            placeholder="Search"
            // inputRef={inputRef}
            aria-label="Search"
            sx={{ backgroundColor: '#271937', color: 'white', width: '100%' }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" edge="end">
                  <Search color="primary" sx={{ color: 'white', height: 25, width: 20 }} />
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
        <div className={b('sidebar-links-container')}>
          <span className={b('links-label')}>For you</span>
          {forYouLinks.map((navItem, id) => (
            <SidebarLinks key={id} {...navItem} />
          ))}
        </div>
        <div className={b('sidebar-links-container2')}>
          <span className={b('links-label')}>Games</span>
          {gamesLinks.map((navItem, id) => (
            <SidebarLinks key={id} {...navItem} />
          ))}
        </div>
        
      <div className={b('bottom-tab-bar')}>
        <BottomTabBar/>
      </div>

        
      </section>
    </aside>
  )
}

export default Sidebar
