import { projectDomainName } from 'shared/constants'

export const bonuses: Record<string, string> = {
  bonusWheel: 'Bonus wheel',
  receive: 'Receive',
  bonuses: 'Bonuses',
  moreAboutStock: 'More about the stock',
  ALL: 'All',
  Cashback: 'Cashback',
  SportDiscount: 'Sport discount',
  FirstDeposit: 'First deposit',
  DetractedFirstDepositBonusByWithdrawal: 'Detracted first deposit bonus by withdrawal',
  IncreasedBySO: 'Increased by SO',
  IncreasedByOwner: 'Personal bonus',
  TransferBonusesToBalance: 'Transfer bonuses to balance',
  LuckySpin: 'Lucky spin',
  balanceConvertedSuccess: 'Balance added successfully',
  exchange: 'Exchange',
  bonusBalanceOnYourAccount: 'Your current bonus balance',
  exchangeRate: 'Exchange rate',
  balanceMustBeLess: 'Balance must be less than',
  toCompleteExchange: 'to complete the exchange',
  cashbackHistory: 'Cashback history',
  wageringText: 'Wagering only works in "Pragmaticplay" games',
  from: 'From',
  collectWinnings: 'Collect winnings',
  to: 'To',
  show: 'Show',
  wonBonus: 'Won bonus',
  date: 'Date',
  filters: 'Filters',
  type: 'Type',
  amount: 'Amount',
  cashbackNotify: 'You have received cashback in the amount of',
  thankYou: 'Thank you',
  firstDepositNotify: 'You have received first deposit in the amount of',
  gettingToCashback: 'Getting to cashback',
  youWonJackpot: 'You have won the jackpot',
  pickUp: 'Pick up',
  currentBonusBalance: 'Your current bonus balance',

  activate: 'Activate',
  bonus: 'Bonus',
  freeSpins: 'Free spins',
  status: 'Status',
  expirationDate: 'Expiration date',
  wager: 'Need to win back',
  wagerComplete: 'Won back',
  AVAILABLE: 'Available',
  AVAILABLE_COMPLETED: 'Available',
  ACTIVE: 'Active',
  EXPIRED: 'Expired',
  COMPLETED: 'In wagering',
  PAID: 'Paid',
  WAIT: 'Wait',
  successActivate: 'Successfully activated',
  cancelBonus: 'You canceled the bonus',
  noBonuses: "You don't have bonuses",
  cancel: 'Cancel',
  freeSpinsGames: 'Free spins games',
  winAfterWagering: 'Win after wagering',
  first_deposit_bonus: 'Bonus',
  first_deposit_freespins: 'Free spin bonus',
  every_deposit_bonus: 'Bonus',
  wagerCoef: 'Wager',
  validityTerm: 'Validity term',
  freeSpinBonus: 'Free spin bonus',
  clickForPlayFreeSpins: 'Click for play free spins!',
  day: 'D',
  days: 'Days',
  takeYourBonus: 'Take your bonus',
  playFor: 'Play for',
  toGet: 'to get',
  openGames: 'Open games',
  moneySuccessfully: 'Money added successfully',
  register: 'Register',
  needToWinBack: 'Need to win back',

  changeActiveBonusText: `When a new bonus is activated, the bonus will be suspended.
  The stopped bonus can be activated again.`,
  bonusBackCardInfo:
    `In ${projectDomainName} A 100% First Deposit Bonus is available
   for all new Players on a permanent basis. The first deposit
    bonus applies only to the Player's first deposit, it is equal to 100% of the amount of the first deposit.\n` +
    `Bonus money – rewards equivalent to real money that the user receives from the
   casino on a free and gratuitous basis. When requesting a withdrawal
    of funds, the non-wagered bonus on the first deposit is canceled.`,
  freeSpinBackCardInfo: `In our casino, each new player can get a certain number
 of free spins when registering. Free spin is a spin in slots when no money is deducted 
 from the player's balance. When you register, you get {freespinCount} free FREE SPINS! The specified 
 bonus is given only once – directly for registration to new players who do not have other accounts
  in the casino ${projectDomainName}`,
}
