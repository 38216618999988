import { BonusPromotionStatus } from 'shared/models/Promotion'

export const bonusPromotionStatusWeights = {
  [BonusPromotionStatus.wait]: 99,
  [BonusPromotionStatus.completed]: 51,
  [BonusPromotionStatus.active]: 50,
  [BonusPromotionStatus.available]: 10,
  [BonusPromotionStatus.availableCompleted]: 10,
  [BonusPromotionStatus.expired]: 0,
  [BonusPromotionStatus.paid]: 0,
}

export const takeYourBonusStatuses = [
  BonusPromotionStatus.wait,
  BonusPromotionStatus.available,
  BonusPromotionStatus.availableCompleted,
]

export const activePromotionStatuses = [BonusPromotionStatus.completed, BonusPromotionStatus.active]
