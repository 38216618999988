import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { BonusPromotionStatus } from 'shared/models/Promotion'

import { selectBonusPromotions } from 'features/bonusSystem/selectors'

export const usePromotionsCount = () => {
  const promotions = useSelector(selectBonusPromotions, shallowEqual)

  const count = useMemo(
    () => promotions.filter(promo => promo.status === BonusPromotionStatus.available).length,
    [promotions],
  )

  return { count }
}
