import React, { useCallback, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import block from 'bem-cn'

import { MODAL_ROUTE_URLS } from 'router/modalRoutes'

import { resetPassword } from 'features/auth/actions'
import { selectAuthCommunication, selectIsUserAuthenticated } from 'features/auth/selectors'
import { selectLocaleDict } from 'features/locale/selectors'

import { useModalContext } from 'components/HOC/withModalQuery/ModalContext'

import { protectedModal } from 'components/protectedModal/protectedModal'
import Button from 'components/UIKit/Button/Button'
import { Input } from 'components/UIKit/Input/Input'

import { StyledAuthForm, StyledAuthHeader, StyledAuthModal } from '../styles/AuthStyles'

import './ForgotPassword.scss'

const b = block('forgot-password')

const ForgotPassword: React.FC = () => {
  const dispatch = useDispatch()
  const { closeModal } = useModalContext()

  const { isLoading } = useSelector(selectAuthCommunication, shallowEqual).resetPassword
  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)

  const [email, setEmail] = useState('')

  const onSubmit = useCallback(
    e => {
      e.preventDefault()

      dispatch(resetPassword({ email, callback: closeModal(MODAL_ROUTE_URLS.FORGOT_PASSWORD()) }))
    },
    [dispatch, email, closeModal],
  )
  const onChangeListener = useCallback(e => setEmail(e.currentTarget.value), [email])
  const locale = useSelector(selectLocaleDict, shallowEqual).auth

  return (
    <StyledAuthModal>
      <div className={b()}>
        <div className={b('header')}>
          <h2>{locale.forgotPassword.toUpperCase()}</h2>
        </div>
        <StyledAuthForm onSubmit={onSubmit} className={b('wrapper')}>
          {/* <StyledAuthHeader>
            <span className={b('header-locale')}>{locale.forgotPassword}</span>
          </StyledAuthHeader> */}
          <div className={b('description')}>{locale.forgotPasswordText}</div>
          <div className={b('input-wrapper')}>
            <Input
              type="email"
              name="email"
              placeholder={`${locale.email} *`}
              value={email}
              onChange={onChangeListener}
              isRequired
              height="40px"
            />
          </div>
          <div className={b('button-container')}>
            <Button type="submit" colorType="primary" text={locale.sendCode} disabled={isLoading} loading={isLoading} />
          </div>
        </StyledAuthForm>
      </div>
    </StyledAuthModal>
  )
}

export default protectedModal(ForgotPassword)
