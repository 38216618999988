import { QueryTypes } from 'services/api/data'

import { ROUTE_URLS } from 'router/routes'

import SoftwissConverter from './providersData/Softswiss/SoftwissConverter'
import { ISlotProvidersConfiguration } from './types/configuration'
import { Providers } from './types/slot'

type ConvertorsTypes = SoftwissConverter

const SoftwissConvterClass = new SoftwissConverter()

export const slotProvidersConfiguration: ISlotProvidersConfiguration<ConvertorsTypes> = {
  [Providers.softwiss]: {
    gameListUrl: 'api/integrations/softswiss/v2/games/',
    converter: SoftwissConvterClass,
    sessionUrlData: {
      url: 'api/integrations/softswiss/createsession/',
      demoUrl: 'api/integrations/softswiss/demostart/',
      method: QueryTypes.POST,
      getBody: params => ({
        game: params.gameId,
        return_url: `${location.origin}${ROUTE_URLS.SLOTS_CASINO()}`,
        client_type: params.isDesktop ? 'desktop' : 'mobile',
      }),
      converter: SoftwissConvterClass.convertGameUrl,
    },
  },
}
