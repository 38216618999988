import block from 'bem-cn'
import React, { useState } from 'react'
import { bottomTab } from './data'
import './BottomTabBar.scss'

const BottomTabBar = () => {
  const b = block('bottom-tabbar-footer')
  const [activeTab, setActiveTab] = useState<number | null>(null)

  const handleTabClick = (tabId: number) => {
    setActiveTab(tabId === activeTab ? null : tabId);
  }
  return (
   <div className={b('bottom-tabbar-footer')}>
     <div className={b('tabbar')}>
      <div className={b('bottom-tabbar')}>
        {bottomTab.map((item, id) => (
          <div key={id} className={b('bottom-icon')} onClick={() => handleTabClick(id)}>
            <div className={`${b('icon-background')} ${activeTab === id ? 'active' : ''}`}>
              {/* {item.svg && React.createElement(item.svg, { className: 'sidebar-icon' })} */}
              {item.svg && React.createElement(item.svg, {
                 className: 'sidebar-icon',
                 fill: activeTab === id ? 'white' : '#524267', 
                })}
            </div>
            <span>{item.text}</span>
          </div>
        ))}
      </div>
    </div>
   </div>
  )
}

export default BottomTabBar
