import React from 'react'
import block from 'bem-cn'

interface IClock {
  timerHours: string
  timerMinutes: string
  timerSeconds: string
}

export const Clock: React.FC<IClock> = ({ timerHours, timerMinutes, timerSeconds }) => {
  const b = block('clock')
  return (
    <>
      <div className={b()}>
        <div className={b('label')}>Next free spin</div>
        {/* <span className={b('time')}>{`${timerDays} дня ${timerHours}:${timerMinutes}:${timerSeconds}`}</span> */}
        <span className={b('time')}>{`${timerHours}:${timerMinutes}:${timerSeconds}`}</span>
      </div>
    </>
  )
}
