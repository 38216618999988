/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import block from 'bem-cn'
import { motion } from 'framer-motion'
import useSound from 'use-sound'

import LoadableImage from 'components/LoadableImage'
import Loader from 'components/Loader/Loader'

import wheelImg from '../img/wheel.png'
import wheelDiamondsImg from '../img/wheel-diamonds.png'
import wheelLightImg from '../img/wheel-light.png'
import spinWheelSound from '../sounds/wheel-spin.mp3'

import './Roulette.scss'

const b = block('roulette-game')

const RouletteWheel = ({ variants, isSpining, data, setWheelLayoutReady }) => {
  const [playWheelSpinningSound] = useSound(spinWheelSound)
  const [imageReadyState, setImageReadyState] = useState({
    wheelImage: false,
    wheelLight: false,
    diamonds: false,
  })

  const isAllImagesReady = useMemo(
    () => Object.values(imageReadyState).findIndex(t => t === false) === -1,
    [imageReadyState],
  )

  const setReadyImage = useCallback(
    name => {
      setImageReadyState(p => ({
        ...p,
        [name]: true,
      }))
    },
    [setImageReadyState],
  )

  const dataOnWheel = useMemo(
    () =>
      Object.values(data).map((item, index) => (
        <div className={b('data-on-wheel__item', { 'is-present': item.isPresent })} key={index}>
          <div className={b('data-on-wheel__icon')}>{item.icon}</div>
          <div className={b('data-on-wheel__value')}>{item.isPresent ? item.name : item.amount}</div>
        </div>
      )),
    [data],
  )

  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    if (isSpining) {
      playWheelSpinningSound()
    }
  }, [isSpining])

  useEffect(() => {
    if (isAllImagesReady) {
      setWheelLayoutReady(true)
    }
  }, [isAllImagesReady])

  return (
    <>
      {!isAllImagesReady && <Loader colorType="light" />}
      <motion.div className={b('wheel')} variants={variants} animate={isSpining ? 'rotate' : 'stop'}>
        <motion.div
          animate={isAllImagesReady ? { scale: 1, rotate: 0 } : false}
          transition={{ duration: 0.5 }}
          initial={{ scale: 0.8, rotate: -100 }}
          onAnimationComplete={() => setIsReady(true)}
          className={b('wheel-wrapper')}>
          <LoadableImage
            onLoad={() => setReadyImage('wheelImage')}
            src={wheelImg}
            className={b('img', { wheel: true })}
            alt="wheel"
          />
          {isAllImagesReady && (
            <motion.div
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              initial={{ opacity: 0 }}
              className={b('data-on-wheel')}>
              <div className={b('data-on-wheel__container')}>{dataOnWheel}</div>
            </motion.div>
          )}
        </motion.div>
        <LoadableImage
          onLoad={() => setReadyImage('wheelLight')}
          src={wheelLightImg}
          hidden={!isReady}
          classNameContainer={b('img', { light: true })}
          alt="wheel-light"
        />
        <LoadableImage
          onLoad={() => setReadyImage('diamonds')}
          src={wheelDiamondsImg}
          hidden={!isReady}
          classNameContainer={b('img', { diamonds: true })}
          alt="diamonds"
        />
      </motion.div>
    </>
  )
}

export default RouletteWheel
