// AcceptedCurrencies.tsx
import React from "react";
import block from "bem-cn";
import { footerItems } from "./data";
import "./AcceptedCurrencies.scss";

const AcceptedCurrencies = () => {
  const b = block("accepted-currencies");

  return (
    <section className={b()}>
      <div>
        <h3 className={b("accepted-heading")}>Accepted Currencies</h3>
        <div className={b("icon-row")}>
          {Object.keys(footerItems).map((key, index) => (
            <div
              key={key}
              className={b("icon")}
              style={{ backgroundColor: footerItems[key].backgroundColor }}
            >
              <img
                src={footerItems[key].imageUrl}
                alt=""
                className={index != 4 ? b("icon-img") : b("icon-img2")}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AcceptedCurrencies;
