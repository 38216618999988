import dayjs from 'dayjs'

import {
  IConvertedTransactions,
  ITransactionFilters,
  ITransactionFiltersPayload,
} from 'services/api/ProfileApi/types/types'

import { IUserInfoPayload } from 'shared/models/Profile'
import { makeCommunicationActionCreator } from 'shared/utils/communication/actions/makeCommunicationActionCreator'

import { addNotify } from 'components/Notify'

import { ICashierPayload, IDefaultChangePasswordState, IPromocodePayload } from './types'

export const getProfileInfo = makeCommunicationActionCreator({
  loading: '@profile/GET_PROFILE_INFO_LOADING',
  success: '@profile/GET_PROFILE_INFO_SUCCESS',
  error: '@profile/GET_PROFILE_INFO_ERROR',
  reset: '@profile/GET_PROFILE_INFO_RESET',
})(
  async ({
    deps: {
      extra: { api },
    },
  }) => {
    const response = await api.profile.getProfileInfo()
    return response.data
  },
)

export const getUserInfo = makeCommunicationActionCreator({
  loading: '@profile/GET_USER_INFO_LOADING',
  success: '@profile/GET_USER_INFO_SUCCESS',
  error: '@profile/GET_USER_INFO_ERROR',
  reset: '@profile/GET_USER_INFO_RESET',
})(
  async ({
    deps: {
      extra: { api },
    },
  }) => {
    const response = await api.profile.getUserInfo()
    return response.data
  },
)

export const updateUserInfo = makeCommunicationActionCreator({
  loading: '@profile/UPDATE_USER_INFO_LOADING',
  success: '@profile/UPDATE_USER_INFO_SUCCESS',
  error: '@profile/UPDATE_USER_INFO_ERROR',
  reset: '@profile/UPDATE_USER_INFO_RESET',
})<IUserInfoPayload, boolean, { callback?: () => void }>(
  async ({
    deps: {
      extra: { api },
    },
    payload,
    args,
  }) => {
    const response = await api.profile.updateUserInfo(payload)

    if (response.success && args?.callback) {
      args.callback()
    }

    return response.data
  },
)

export const changePassword = makeCommunicationActionCreator({
  loading: '@profile/CHANGE_PASSWORD_LOADING',
  success: '@profile/CHANGE_PASSWORD_SUCCESS',
  error: '@profile/CHANGE_PASSWORD_ERROR',
  reset: '@profile/CHANGE_PASSWORD_RESET',
})<IDefaultChangePasswordState, any>(
  async ({
    deps: {
      extra: { api },
      getState,
    },
    payload,
  }) => {
    const { data, callback } = payload
    const { oldPassword, newPassword, repeatNewPassword } = data

    const { profile }: any = getState().locale.data
    if (newPassword === repeatNewPassword) {
      const response = await api.profile.changePassword({ old_password: oldPassword, new_password: newPassword })
      if (response.success) {
        addNotify(profile.changedPassword, 'success')
        callback()
      }
    } else {
      addNotify(profile.changedPasswordDoNotMatch, 'error')
    }
  },
)

// export const getPayHistory = makeCommunicationActionCreator({
//   loading: '@profile/GET_PAY_HISTORY_LOADING',
//   success: '@profile/GET_PAY_HISTORY_SUCCESS',
//   error: '@profile/GET_PAY_HISTORY_ERROR',
//   reset: '@profile/GET_PAY_HISTORY_RESET',
// })<ITransactionFilters, IConvertedTransactions>(
//   async ({
//     deps: {
//       extra: { api },
//     },
//     payload,
//   }) => {
//     const response = await api.profile.loadTransfersList(payload)
//     return response.data
//   },
// )

export const getPayHistory = makeCommunicationActionCreator({
  loading: '@profile/GET_PAY_HISTORY_LOADING',
  success: '@profile/GET_PAY_HISTORY_SUCCESS',
  error: '@profile/GET_PAY_HISTORY_ERROR',
  reset: '@profile/GET_PAY_HISTORY_RESET',
})<ITransactionFiltersPayload, IConvertedTransactions>(
  async ({
    deps: {
      extra: { api },
      getState,
    },
    payload,
  }) => {
    const { user } = getState().auth.data

    const newFilters: ITransactionFilters = {
      page: payload.page,
      page_size: payload.pageSize,
      created_from: payload.createdFrom ? dayjs(payload.createdFrom).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ') : '',
      created_to: payload.createdTo
        ? dayjs(payload.createdTo).add(1, 'day').startOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
        : '',
      status: payload.status,
      sender: payload.type === 'withdrawal' ? String(user?.id) : undefined,
      recipient: payload.type === 'deposit' ? String(user?.id) : undefined,
    }

    const response = await api.profile.loadTransfersList(newFilters)
    return response.data
  },
)

export const activatePromocode = makeCommunicationActionCreator({
  loading: '@profile/ACTIVATE_PROMOCODE_LOADING',
  success: '@profile/ACTIVATE_PROMOCODE_SUCCESS',
  error: '@profile/ACTIVATE_PROMOCODE_ERROR',
  reset: '@profile/ACTIVATE_PROMOCODE_RESET',
})<IPromocodePayload, any>(
  async ({
    deps: {
      extra: { api },
      getState,
    },
    payload,
  }) => {
    const locale = getState().locale.data.payment
    const response = await api.promocode.activatePromocode(payload.promocode)
    if (response.success) {
      addNotify(locale.promocodeSuccess, 'success')
      payload.callback()
    }
    return response.data
  },
)

export const withdrawCashier = makeCommunicationActionCreator({
  loading: '@profile/WITHDRAW_CASHIER_LOADING',
  success: '@profile/WITHDRAW_CASHIER_SUCCESS',
  error: '@profile/WITHDRAW_CASHIER_ERROR',
  reset: '@profile/WITHDRAW_CASHIER_RESET',
})<ICashierPayload, any>(
  async ({
    deps: {
      extra: { api },
      getState,
    },
    payload,
  }) => {
    const locale = getState().locale.data.payment
    const response = await api.profile.withdrawCashier(payload.withdraw, payload.currencies, payload.parent)
    if (response.success) {
      addNotify(locale.withdrawalSuccess, 'success')
    }
    return response.data
  },
)

export const sendKYCPicture = makeCommunicationActionCreator({
  loading: '@profile/SEND_KYC_PICTURE_LOADING',
  success: '@profile/SEND_KYC_PICTURE_SUCCESS',
  error: '@profile/SEND_KYC_PICTURE_ERROR',
  reset: '@profile/SEND_KYC_PICTURE_RESET',
})<FormData, void, { callback: () => void }>(
  async ({
    deps: {
      extra: { api },
      getState,
    },
    payload,
    args,
  }) => {
    const locale = getState().locale.data.profile
    const response = await api.profile.sendKYCpicture(payload)
    if (response.success) {
      addNotify(locale.kycSendedSuccess, 'success')
    }
    if (args?.callback) {
      args.callback()
    }
  },
)
