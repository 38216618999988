import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaTelegram,
  FaBitcoin,
} from "react-icons/fa";
import { IoGameControllerOutline } from "react-icons/io5";
import block from "bem-cn";
import { ROUTE_URLS } from "router/routes";
import BottomTabBar from "components/BottomTabBar/BottomTabBar";
import logo from "../../shared/img/footer/logo.svg";
import coin from "../../shared/img/footer/coin.svg";

import CopyRightSection from "./copyRight/copyRightSection";
import "./NewFooter.scss";
import { CasinoItem, GameItem, LegalItem, SupportItem } from "./data";
import { IconContext } from "react-icons";
import { FaDiscord, FaX } from "react-icons/fa6";

const NewFooter = () => {
  const b = block("new-footer");
  const [activeTab, setActiveTab] = useState<number | null>(null);

  const handleTabClick = (tabId: number) => {
    setActiveTab(tabId);
  };
  return (
    <footer>
      <div className={b()}>
        <div className={b("footer-col")}>
          <div className={b("logo-container")}>
            <Link className={b("logo")} to={ROUTE_URLS.MAIN()}>
              <img src={logo} alt="logo" />
              <span>spineo.io</span>
            </Link>
          </div>
          <div className={b("logo-value")}>
            <img src={coin} alt="coin" />
            <span>1 BTC = $ 67349.76</span>
          </div>
          <div className={b("social-container")}>
            <span className={b("icon-container")}>
              <IconContext.Provider value={{ color: "white", size: "18px" }}>
                <FaInstagram
                  style={{
                    borderRadius: "50% important",
                    borderColor: "red !important",
                  }}
                />
              </IconContext.Provider>
            </span>
            <span className={b("icon-container")}>
              <IconContext.Provider value={{ color: "white", size: "18px" }}>
                <FaX
                  style={{
                    borderRadius: "50% important",
                    borderColor: "red !important",
                  }}
                />
              </IconContext.Provider>
            </span>
            <span className={b("icon-container")}>
              <IconContext.Provider value={{ color: "white", size: "18px" }}>
                <FaDiscord
                  style={{
                    borderRadius: "50% important",
                    borderColor: "red !important",
                  }}
                />
              </IconContext.Provider>
            </span>
            <span className={b("icon-container")}>
              <IconContext.Provider value={{ color: "white", size: "18px" }}>
                <FaBitcoin
                  style={{
                    borderRadius: "50% important",
                    borderColor: "red !important",
                  }}
                />
              </IconContext.Provider>
            </span>
            <span className={b("icon-container")}>
              <IconContext.Provider value={{ color: "white", size: "18px" }}>
                <FaYoutube
                  style={{
                    borderRadius: "50% important",
                    borderColor: "red !important",
                  }}
                />
              </IconContext.Provider>
            </span>
            <span className={b("icon-container")}>
              <IconContext.Provider value={{ color: "white", size: "18px", className: "icon-container" }}>
                <FaTelegram
                  style={{
                    borderRadius: "50% important",
                    borderColor: "red !important",
                  }}
                />
              </IconContext.Provider>
            </span>
          </div>
        </div>
        <div className={b("footer-col")}>
          <h3>Games</h3>
          <div>
            {GameItem.map((item, index) => (
              <Link to={item.route}>
                <p key={index}>{item.text}</p>
              </Link>
            ))}
          </div>
        </div>
        <div className={b("footer-col")}>
          <h3>Casino</h3>
          <div>
            {CasinoItem.map((item, index) => (
              <Link to={item.route}>
                <p key={index}>{item.text}</p>
              </Link>
            ))}
          </div>
        </div>
        <div className={b("footer-col")}>
          <h3>Legal</h3>
          <div>
            {LegalItem.map((item, index) => (
              <Link to={item.route}>
                <p key={index}>{item.text}</p>
              </Link>
            ))}
          </div>
        </div>
        <div className={b("footer-col")}>
          <h3>Support</h3>
          <div>
            {SupportItem.map((item, index) => (
              <Link to={item.route}>
                <p key={index}>{item.text}</p>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <CopyRightSection />

      <div className={b("bottom-tab-bar")}>
        <BottomTabBar />
      </div>
    </footer>
  );
};

export default NewFooter;
