import React from 'react';
import { ROUTE_URLS } from 'router/routes';
import { ReactComponent as UnionIcon } from '../../../shared/img/sidebar/Union.svg';
import { ReactComponent as DepositIcon } from '../../../shared/img/sidebar/deposit.svg';
import { ReactComponent as SupportIcon } from '../../../shared/img/sidebar/support.svg';
import { ReactComponent as HomeIcon } from '../../../shared/img/sidebar/home.svg';


export interface INavigationItem {
    id: string;
    text: string
    route: string
    svg?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  
  }


export const bottomTab: INavigationItem[] =[
    {
     id: 'union',
      svg: UnionIcon,
      text: 'Menu',
      route: ROUTE_URLS.MENU()
   
    },
    {
        id: 'home',
      svg: HomeIcon,
      text: 'Home',
      route: ROUTE_URLS.HOME()
    },
    {
        id: 'deposit',
      svg: DepositIcon,
      text: 'Deposit',
      route: ROUTE_URLS.DEPOSIT()
    },
    {id: 'suppport',
      svg: SupportIcon,
      text: 'Support',
      route: ROUTE_URLS.SUPPORT()
    },
  ]