import React, { useCallback, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import block from 'bem-cn'
import { AnimatePresence, motion } from 'framer-motion'

import useInfiniteVerticalScroll from 'shared/hooks/useInfiniteVerticalScroll'

import { ISlotViewItem } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'

import { selectLocaleDict } from 'features/locale/selectors'

import SlotsIcon from 'components/slots/SlotsIcon/SlotsIcon'

import './SlotsByParameter.scss'

const b = block('slots-by-parameter')
const slotsPerRender = 10

interface ISlotsByParameter {
  parameter: string
  slots: ISlotViewItem[]
}

const SlotsByParameter: React.FC<ISlotsByParameter> = ({ parameter, slots }) => {
  const locale = useSelector(selectLocaleDict, shallowEqual).slots
  const [currentSlice, setCurrentSlice] = useState<number>(11)

  const { current, ref }: { current: ISlotViewItem[]; ref: any } = useInfiniteVerticalScroll({
    data: slots,
    loadingCount: slotsPerRender,
  })

  const handleSeeMoreClick = useCallback(() => {
    setCurrentSlice(c => c + 10)
  }, [])

  const allSlots = useMemo(
    () => (
      <ul className={b('slots')}>
        {current && current.slice(0, currentSlice).map(slot => <SlotsIcon key={slot.gameId} slot={slot} />)}
        {slots.length > currentSlice && (
          <div className={b('see-more')} onClick={handleSeeMoreClick}>
            <span>{locale.seeMore}</span>
            <span>+{slots.length - currentSlice}</span>
          </div>
        )}
      </ul>
    ),
    [current, currentSlice, locale, handleSeeMoreClick, slots],
  )

  const loading = useMemo(
    () => (
      <>
        {current.length !== slots.length && slots.length && slots.length > slotsPerRender && (
          <div ref={ref} className={b('loading')}>
            <span>{locale.loading}</span>
          </div>
        )}
      </>
    ),
    [current, locale, ref, slots],
  )

  return (
    <div className={b()}>
      <div className={b('header')}>{parameter}</div>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1 }}>
          {allSlots}
          {loading}
        </motion.div>
      </AnimatePresence>
    </div>
  )
}

export default SlotsByParameter
