import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import block from 'bem-cn'

import { MODAL_ROUTE_URLS } from 'router/modalRoutes'

import { getCurrencySymbol } from 'shared/constants/currencies/getCurrencySymbol'

import { selectIsUserAuthenticated, selectUserData } from 'features/auth/selectors'
import { clearSpinInfo, getPrizesList, getPublicSettings } from 'features/wheelGame/actions'
import { selectSpinWheelInfo, selectWheelGamePrizesList } from 'features/wheelGame/selectors'

import { ModalContext } from 'components/HOC/withModalQuery/ModalContext'

import { PROCCESS_ROTATE_DURATION, wheelPrizesIconsByName } from './data'
import dtIconIMG from './img/dt-icon.png'
import Roulette from './Roulette/Roulette'
import RouletteWin from './Roulette/RouletteWin'

import './WheelGame.scss'

const b = block('wheel-game')

export const WheelGame: React.FC = () => {
  const dispatch = useDispatch()
  const prizesList = useSelector(selectWheelGamePrizesList, shallowEqual)
  const currency = useSelector(selectUserData, shallowEqual)?.currency
  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)
  const spinInfo = useSelector(selectSpinWheelInfo, shallowEqual)
  const { replaceLastModal } = useContext(ModalContext)

  const [spinState, setSpinState] = useState({
    winNumber: 0,
    spinCount: 0,
  })

  const currencySymbol = useCallback((itemCurrency: string) => {
    const symbol = getCurrencySymbol(itemCurrency)

    return <span className={b('icon')}>{symbol}</span>
  }, [])

  const [isSpining, setIsSpining] = useState(false)
  const [isFinished, setIsFinished] = useState(false)
  const [isSpinFinished, setIsSpinFinshed] = useState(false)

  const changeIsFinished = useCallback(val => setIsFinished(val), [])

  const prizes = useMemo(
    () =>
      prizesList.map(item => ({
        ...item,
        icon: item.isPresent ? (
          <img className={b('present-icon')} src={wheelPrizesIconsByName[item.name]} alt="item.name" />
        ) : (
          currencySymbol(item.currency)
          // currencySymbol
        ),
      })),
    [prizesList, currencySymbol],
  )

  useEffect(() => {
    dispatch(getPrizesList())
    // dispatch(spinActions.getPrivateSettings());

    if (isAuth) {
      dispatch(getPublicSettings())
    }
  }, [isAuth])

  useEffect(() => {
    if (!prizesList.length || spinInfo === null) return

    const winNumber = prizesList.findIndex(item => item.name === spinInfo.name)
    setSpinState(prev => ({
      ...prev,
      winNumber,
      spinCount: prev.spinCount + 1,
    }))
    setIsSpining(true)

    setTimeout(() => {
      setIsSpinFinshed(true)
    }, PROCCESS_ROTATE_DURATION * 1000)

    setTimeout(() => {
      changeIsFinished(true)
      dispatch(clearSpinInfo())
      // eslint-disable-next-line no-mixed-operators
    }, PROCCESS_ROTATE_DURATION * 1000 + 1000)
  }, [prizesList, spinInfo])

  const winnerData = useMemo(() => prizes[spinState.winNumber], [spinState.winNumber, prizes])

  return (
    <>
      <div className={b()}>
        <div className={b('roulette-wrapper', { hidden: isFinished })}>
          <Roulette
            prizes={prizes}
            spinState={spinState}
            currency={currency}
            isSpining={isSpining}
            isSpinFinished={isSpinFinished}
            isAuth={isAuth}
          />
        </div>
        <div className={b('winner-wrapper', { hidden: !isFinished })}>
          <RouletteWin spinInfo={winnerData} />
        </div>
      </div>
      {/* <div className={b('modal', { isHidden: !isFinished, isWinnerBlock: true })}>
        <button className={b('close')} onClick={onCloseWinnerBlock}>
          <SVGInline svg={closeSVG} />
        </button>
        <RouletteWin modal={modal} spinInfo={winnerData} />
      </div> */}
    </>
  )
}
