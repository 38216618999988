import React, { useCallback, useContext, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import block from 'bem-cn'
import { Formik } from 'formik'
import { Persist as FormikPersist } from 'formik-persist'

import { MODAL_ROUTE_URLS } from 'router/modalRoutes'

import { projectStyledName } from 'shared/constants'

import { resendEmail } from 'features/auth/actions'
import { selectAuthCommunication } from 'features/auth/selectors'
import { selectLocaleDict } from 'features/locale/selectors'

import { ModalContext } from 'components/HOC/withModalQuery/ModalContext'

import Button from 'components/UIKit/Button/Button'
import { Input } from 'components/UIKit/Input/Input'

import {
  StyledAuthForm,
  StyledAuthHeader,
  StyledAuthHeaderLocale,
  StyledAuthInputWrapper,
  StyledAuthModal,
  StyledAuthPaginator,
  StyledAuthPaginatorItem,
  StyledAuthPaginatorLink,
  StyledButtonCointaner,
} from '../styles/AuthStyles'

export const ResetEmail = () => {
  const dispatch = useDispatch()
  const { closeModal } = useContext(ModalContext)
  const locale = useSelector(selectLocaleDict, shallowEqual).auth
  const [state] = useState({
    email: '',
  })

  const { isLoading } = useSelector(selectAuthCommunication, shallowEqual).resendEmail

  const onSubmit = useCallback((values: typeof state) => {
    dispatch(resendEmail(values, { callback: closeModal(MODAL_ROUTE_URLS.RESEND_EMAIL()) }))
  }, [])

  return (
    <StyledAuthModal>
      <Formik
        initialValues={{
          email: state.email,
        }}
        onSubmit={onSubmit}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <StyledAuthForm onSubmit={handleSubmit}>
            <StyledAuthHeader>
              {projectStyledName}
              <StyledAuthHeaderLocale>{locale.resendEmail}</StyledAuthHeaderLocale>
            </StyledAuthHeader>
            <StyledAuthInputWrapper>
              <Input
                type="email"
                name="email"
                placeholder={`${locale.email} *`}
                value={values.email}
                onChange={handleChange}
                isRequired
                onBlur={handleBlur}
              />
            </StyledAuthInputWrapper>
            <StyledButtonCointaner>
              <Button type="submit" colorType="main" text={locale.send} disabled={isLoading} loading={isLoading} />
            </StyledButtonCointaner>
            <FormikPersist name="reset-email" />
          </StyledAuthForm>
        )}
      </Formik>
      <StyledAuthPaginator>
        <StyledAuthPaginatorItem>{locale.pleaseCheckSpam}</StyledAuthPaginatorItem>
      </StyledAuthPaginator>
    </StyledAuthModal>
  )
}
