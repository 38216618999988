import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import block from 'bem-cn'
import { debounce } from 'lodash'
import { Toggle } from 'rsuite'

import { ReactComponent as ArrowSVG } from 'shared/img/arrow-down-triangle.svg'

import { IFiltersSlots } from 'modules/SlotsRoot/types/filters'
import { ISlotsGameType, ISlotViewItem } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'

import { selectLocaleDict } from 'features/locale/selectors'

import { AllSlotsList } from 'components/slots/AllSlotsList/AllSlotsList'
import Button from 'components/UIKit/Button/Button'
import { Input } from 'components/UIKit/Input/Input'

import { themeItems } from './data'
import SimpleListItem from './SimpleListItem/SimpleListItem'

import './FilterParameters.scss'

const b = block('mobile-filter')

interface IFilterParametersProps {
  filters: IFiltersSlots
  goToResults: () => void
  gameType: ISlotsGameType
  slots: ISlotViewItem[]
  providers: string[]
  categories: string[]
}

const amountOfItemsToSlice = 6

const FilterParameters: React.FC<IFilterParametersProps> = ({
  goToResults,
  filters,
  slots,
  gameType,
  providers,
  categories,
}) => {
  const locale = useSelector(selectLocaleDict, shallowEqual).slots

  const {
    selectedProviders,
    setSelectedProviders,
    setSelectedCategories,
    selectedCategories,
    searchString,
    setSearchString,
  } = filters

  const debounceInputChangeHandler = React.useMemo(
    () => debounce(value => setSearchString(value), 400),
    [setSearchString],
  )

  const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(true)
  const [_searchValue, _setSearchValue] = useState(searchString)
  const [isOpenLists, setIsOpenLists] = useState({
    isOpenProviders: false,
    isOpenCategories: false,
    isOpenThemes: false,
  })

  const themesArray = useMemo(() => themeItems.filter(item => item.gameType === gameType), [gameType])

  const changeSearchValue = (text: string) => {
    _setSearchValue(text)
    debounceInputChangeHandler(text)
  }

  const handleAdvancedSearchCheckbox = useCallback(() => {
    setIsAdvancedSearch(!isAdvancedSearch)
  }, [setIsAdvancedSearch, isAdvancedSearch])

  useEffect(() => {
    setSelectedCategories(['all'])
  }, [])

  useEffect(() => {
    if (selectedCategories.length > 1 && selectedCategories.includes('all')) {
      setSelectedCategories(selectedCategories.filter(item => item !== 'all'))
    }
  }, [selectedCategories, setSelectedCategories])

  const handleCategoryClick = useCallback(
    (category: string) => {
      if (category === 'all') {
        setSelectedCategories(['all'])
        return
      }
      if (selectedCategories.includes(category)) {
        setSelectedCategories(selectedCategories.filter(item => item !== category))
        return
      }
      setSelectedCategories([category, ...selectedCategories])
    },
    [selectedCategories, setSelectedCategories],
  )

  const handleProviderClick = useCallback(
    (provider: string) => {
      if (provider === 'all') {
        setSelectedProviders([])
        return
      }
      if (selectedProviders.includes(provider)) {
        setSelectedProviders(selectedProviders.filter(item => item !== provider))
        return
      }
      setSelectedProviders([provider, ...selectedProviders])
    },
    [setSelectedProviders, selectedProviders],
  )

  const categoriesList = useMemo(() => {
    let listWithoutThemes: string[] = []
    if (themesArray.length > 0) {
      listWithoutThemes = ['all', ...categories].filter(item => !themesArray[0].themes.includes(item))
    } else {
      listWithoutThemes = ['all', ...categories].slice()
    }
    const list = isOpenLists.isOpenCategories ? listWithoutThemes : listWithoutThemes.slice(0, amountOfItemsToSlice)

    return list.map((item, index) => {
      const isActive =
        item.toLowerCase() === 'all'
          ? selectedCategories.length === 0 || selectedCategories[0] === 'all'
          : selectedCategories.includes(item)
      return (
        <SimpleListItem key={index} content={item} isActive={isActive} handleClick={() => handleCategoryClick(item)} />
      )
    })
  }, [categories, selectedCategories, handleCategoryClick, themesArray, isOpenLists])

  const providersList = useMemo(() => {
    const list = isOpenLists.isOpenProviders ? providers : providers.slice(0, amountOfItemsToSlice)

    return list.map((item, index) => {
      const isActive = item === 'all' ? selectedProviders.length === 0 : selectedProviders.includes(item)
      return (
        <SimpleListItem key={index} content={item} isActive={isActive} handleClick={() => handleProviderClick(item)} />
      )
    })
  }, [providers, isOpenLists, selectedProviders, handleProviderClick])

  const themesList = useMemo(() => {
    if (themesArray.length > 0) {
      const list = isOpenLists.isOpenThemes
        ? themesArray[0].themes
        : themesArray[0].themes.slice(0, amountOfItemsToSlice)
      return list.map((item, index) => {
        const isActive = selectedCategories.includes(item)
        return (
          <SimpleListItem
            key={index}
            content={item}
            isActive={isActive}
            handleClick={() => handleCategoryClick(item)}
          />
        )
      })
    }

    return null
  }, [handleCategoryClick, selectedCategories, themesArray, isOpenLists])

  const handleResetButtonClick = useCallback(() => {
    setSelectedCategories(['all'])
    setSelectedProviders([])
  }, [setSelectedCategories, setSelectedProviders])

  return (
    <div className={b()}>
      <div className={b('header-block')}>
        <span className={b('title')}>{isAdvancedSearch ? locale.gameFilter : locale.search}</span>
        <div className={b('advanced-search')}>
          <span className={b('label')}>{locale.advancedSearch}</span>
          <Toggle onChange={handleAdvancedSearchCheckbox} checked={isAdvancedSearch} size="md" color="violet" />
        </div>
      </div>
      <div className={b('search-container')}>
        <Input
          placeholder={locale.searchGames}
          value={_searchValue}
          onChange={e => changeSearchValue(e.currentTarget.value)}
          height="100%"
          isAllowedToDelete={_searchValue !== ''}
          deleteInputCallback={() => changeSearchValue('')}
        />
      </div>
      {!isAdvancedSearch && _searchValue && (
        <div className={b('slots-container')}>
          <AllSlotsList filters={filters} slotsList={slots} />
        </div>
      )}
      {isAdvancedSearch && (
        <>
          <div className={b('list-wrapper')}>
            <span className={b('list-title')}>{locale.categories}</span>
            <div className={b('list')}>
              {categoriesList}
              {categories.length > amountOfItemsToSlice + 1 && (
                <ArrowSVG
                  className={b('arrow', { isOpen: isOpenLists.isOpenCategories })}
                  onClick={() => setIsOpenLists({ ...isOpenLists, isOpenCategories: !isOpenLists.isOpenCategories })}
                />
              )}
            </div>
          </div>
          <div className={b('list-wrapper')}>
            <span className={b('list-title')}>{locale.providers}</span>
            <div className={b('list')}>
              {providersList}
              {providers.length > amountOfItemsToSlice && (
                <ArrowSVG
                  className={b('arrow', { isOpen: isOpenLists.isOpenProviders })}
                  onClick={() => setIsOpenLists({ ...isOpenLists, isOpenProviders: !isOpenLists.isOpenProviders })}
                />
              )}
            </div>
          </div>
          {themesArray.length > 0 && (
            <div className={b('list-wrapper')}>
              <span className={b('list-title')}>{locale.themes}</span>
              <div className={b('list')}>
                {themesList}
                {themesArray[0].themes.length > amountOfItemsToSlice && (
                  <ArrowSVG
                    className={b('arrow', { isOpen: isOpenLists.isOpenThemes })}
                    onClick={() => setIsOpenLists({ ...isOpenLists, isOpenThemes: !isOpenLists.isOpenThemes })}
                  />
                )}
              </div>
            </div>
          )}
          <div className={b('control-panel')}>
            <div className={b('button-container')}>
              <Button colorType="tertiary" text={locale.reset.toUpperCase()} callback={handleResetButtonClick} />
            </div>
            <div className={b('button-container')}>
              <Button colorType="main" text={`${locale.show.toUpperCase()} (${slots.length})`} callback={goToResults} />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default FilterParameters
