/* eslint-disable camelcase */

export interface IProfile {
  id: number
  username: string
  dateJoined: string
  balanceCurrency: Date
  balance: string
  isBanned: boolean
}

export interface IServerProfile {
  id: number
  username: string
  date_joined: string
  balance_currency: Date
  balance: string
  is_banned: boolean
}

export interface IUserInfo {
  firstName: string
  lastName: string
  gender: string
  email?: string
  dateOfBirth: string
  country: string
  city: string
  mobileNumber?: string
}

export interface IUserInfoPayload {
  first_name: string
  last_name: string
  gender: string
  date_of_birth: string
  country: string
  city: string
}

export interface IServerUserInfo {
  first_name: string
  last_name: string
  gender: string
  email: string
  date_of_birth: string
  country: string
  city: string
  mobile_number?: string
}

export const ProfileTabs = {
  PROFILE: 'profile',
  BET_HISTORY: 'betHistory',
  BONUSES: 'bonuses',
  TRANSACTIONS: 'transactions',
  DEPOSIT: 'deposit',
  WITHDRAWAL: 'withdrawal',
  BONUS_PROMOTIONS: 'bonusPromotions',
} as const

export type ProfileTabsType = ReverseMap<typeof ProfileTabs>

export const SideMenuTabs = {
  ...ProfileTabs,
  CHANGE_PASSWORD: 'changePassword',
} as const

export type SideMenuTabsType = ReverseMap<typeof SideMenuTabs>
