import React, { useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { usePoll } from 'shared/hooks/usePoll'

import { selectIsUserAuthenticated } from 'features/auth/selectors'
import { loadSettings } from 'features/settings/actions'

const LimitsPolling = () => {
  const dispatch = useDispatch()

  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)

  const handleLoadSettings = useCallback(async () => {
    if (isAuth) {
      dispatch(loadSettings())
    }
  }, [dispatch, isAuth])

  usePoll(handleLoadSettings, { delay: 10 * 60 }, isAuth)

  return null
}

export default LimitsPolling
