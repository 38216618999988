/* eslint-disable quotes */
export const errorStatusMessage: { [key: string]: string } = {
  invalidAmount: 'Invalid amount',
  defaultMessage: 'Something went wrong',
  tryAgain: 'Try Again',
  already_exists: 'User with provided username already exists',
  permission_denied: 'You have no permissions',
  not_authenticated: 'You are not authorized',
  transaction_limit: 'Transfer is out of limits',
  transaction_limit_does_not_exist: 'Limits for transactions does not exist',
  400: 'Bad request',

  invalidUserNameOrPassword: 'Incorrect username or password',
  referral_code_not_found: 'Referral code not found',
  referral_code_already_activated: 'Referral code already activated',

  referral_site_name_blank: 'This field may not be blank',
  referral_site_link_blank: 'This field may not be blank',
  referral_site_link_invalid: 'Enter a valid URL',
  not_enough_money: 'Not enough money',
  email_not_verified: 'Please confirm your email',
  documents_not_approved: 'Please confirm your documents',
  balance_not_low_enough: 'Real balance too high for exchanging',
  insufficient_funds_on_bonus_balance: 'There are not enough funds on the bonus balance',

  coefs_changed: 'Coefs were changed.',
  error_block_bet_data: 'Some rate is blocked now',
  error_exist_bet: 'Some rate does not exist',
  user_is_banned: 'This account is banned',
  payment_system_limits: 'The amount does not meet the limits',
}
