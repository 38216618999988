import React, { useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { usePoll } from 'shared/hooks/usePoll'

import { getUserData } from 'features/auth/actions'
import { selectIsUserAuthenticated } from 'features/auth/selectors'

const BalancePolling = () => {
  const dispatch = useDispatch()

  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)

  const handleGetUserData = useCallback(async () => {
    if (isAuth) {
      dispatch(getUserData())
    }
  }, [dispatch, isAuth])

  usePoll(handleGetUserData, { delay: 5 }, isAuth)

  return null
}

export default BalancePolling
