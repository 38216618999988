import { SlotsApi } from 'modules/SlotsRoot/api'

import AuthApi from './AuthApi'
import BonusHistoryApi from './BonusHistoryApi/BonusHistoryApi'
import BonusSystemApi from './BonusSystem/BonusSystem'
import PaymentApi from './PaymentApi/PaymentApi'
import ProfileApi from './ProfileApi/ProfileApi'
import PromocodeApi from './PromocodeApi/PromocodeApi'
import SettingsApi from './SettingsApi/SettingsApi'
import WheelGameApi from './WheelGameApi/WheelGameApi'

class Api {
  public auth: AuthApi

  public settings: SettingsApi

  public profile: ProfileApi

  public slots: SlotsApi

  public payment: PaymentApi

  public promocode: PromocodeApi

  public bonusHistory: BonusHistoryApi

  public wheelGame: WheelGameApi

  public bonusSystem: BonusSystemApi

  constructor(baseUrl = '') {
    this.auth = new AuthApi(baseUrl)
    this.settings = new SettingsApi(baseUrl)
    this.profile = new ProfileApi(baseUrl)
    this.slots = new SlotsApi(baseUrl)
    this.payment = new PaymentApi(baseUrl)
    this.promocode = new PromocodeApi(baseUrl)
    this.bonusHistory = new BonusHistoryApi(baseUrl)
    this.wheelGame = new WheelGameApi(baseUrl)
    this.bonusSystem = new BonusSystemApi(baseUrl)
  }
}

export default Api
