import React, { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { ROUTE_URLS } from 'router/routes'

import { selectBackUrl } from 'features/slots/selectors'

const RouteReplacingSlots = () => {
  const history = useHistory<any>()
  const backUrl = useSelector(selectBackUrl, shallowEqual)

  useEffect(() => {
    history.replace(history.location.state?.prevPath || backUrl || ROUTE_URLS.SLOTS_CASINO())
  }, [])

  return null
}

export default RouteReplacingSlots
