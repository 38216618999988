import { useInView } from 'react-intersection-observer'

function useOnScreen() {
  const { ref: containerRef, inView: isVisible } = useInView({
    threshold: 0,
  })

  return { containerRef, isVisible }
}

export default useOnScreen
