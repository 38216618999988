import React from 'react'
import { Route, Switch } from 'react-router-dom'

// import { lazyImportComponent } from 'shared/helpers/lazyImportComponent'
import ProtectedRoute from 'components/helpers/ProtectedRoute/ProtectedRoute'

import { ROUTE_PATHS } from '../../router/routes'

const ProfilePage = React.lazy(() => import('pages/ProfilePage/ProfilePage'))

export class ProfilePageModule {
  getRoutes() {
    return (
      <Route key={ROUTE_PATHS.PROFILE} path={ROUTE_PATHS.PROFILE}>
        <Switch>
          <ProtectedRoute key={ROUTE_PATHS.PROFILE} path={ROUTE_PATHS.PROFILE} component={ProfilePage} />
        </Switch>
      </Route>
    )
  }
}
