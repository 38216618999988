import { combineReducers } from 'redux'

import { makeCommunicationByIdReducer } from 'shared/utils/communication/reducer/makeCommunicationByIdReducer'
import { makeCommunicationReducer } from 'shared/utils/communication/reducer/makeCommunicationReducer'

import * as actions from '../actions'
import { IPaymentsState } from '../types'

export default combineReducers<IPaymentsState['communications']>({
  getSystemList: makeCommunicationReducer(actions.getDepositSystemList),
  createPayment: makeCommunicationByIdReducer(actions.createDeposit),
  createPayout: makeCommunicationReducer(actions.createPayout),
  getDepositList: makeCommunicationReducer(actions.getDepositList),
  getPayoutList: makeCommunicationReducer(actions.getPayoutList),
  getOnlinePaymentHistory: makeCommunicationReducer(actions.getOnlinePaymentHistory),
  getCryptoCurrencies: makeCommunicationReducer(actions.getCryptoCurrenciesList),
  createCryptoPayment: makeCommunicationByIdReducer(actions.createCryptoPayment),
  getCryptoMinAmount: makeCommunicationByIdReducer(actions.getCryptoMinAmount),
  cancelOnlinePayout: makeCommunicationByIdReducer(actions.cancelOnlinePayout),
  createCryptoTransferOrder: makeCommunicationReducer(actions.createCryptoTransferOrder),
  getCountriesForCryptoTransfer: makeCommunicationReducer(actions.getCountriesForCryptoTransferOrder),
})
