import { combineReducers } from 'redux'

import { makeCommunicationByIdReducer } from 'shared/utils/communication/reducer/makeCommunicationByIdReducer'

import * as actions from '../actions'
import { IFrameManagerState } from '../types'

export default combineReducers<IFrameManagerState['communications']>({
  getFrameSession: makeCommunicationByIdReducer(actions.getFrameSession),
})
