/* eslint-disable camelcase */
export enum CountriesWhiteList {
  // LATVIA = 'LV',
  // GERMANY = 'DE',
  SWITZERLAND = 'CH',
}

export type CountriesWhiteListType = ReverseMap<CountriesWhiteList>

export interface IDepositCountry {
  name: string
  code: string
}

export enum OrderCurrenciesToList {
  USDT20 = 'USDT20',
  USDTRX = 'USDTRX',
}

export type OrderCurrenciesToListType = ReverseMap<OrderCurrenciesToList>

export enum OrderProvidersList {
  MOONPAY = 'moonpay',
  BANXA = 'banxa',
}

export type OrderProvidersListType = ReverseMap<OrderProvidersList>

export enum PaymentMethodsList {
  CARD = 'card',
}

export type PaymentMethodsListType = ReverseMap<PaymentMethodsList>

export interface IDepositPayload {
  amount: number
  country: CountriesWhiteList
}

export interface IDefaultDepositConfig {
  currency: OrderCurrenciesToList
  provider_code: OrderProvidersListType
  payment_method: PaymentMethodsListType
}

export interface IDepositPayloadDTO extends IDefaultDepositConfig {
  amount: string
  country: CountriesWhiteListType
  wallet_address: string
  order_id: string
}

export interface IDepositResponse {
  redirectUrl: string
}
