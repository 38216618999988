/* eslint-disable camelcase */

export enum BonusPromotionStatus {
  available = 'AVAILABLE',
  availableCompleted = 'AVAILABLE_COMPLETED',
  active = 'ACTIVE',
  expired = 'EXPIRED',
  completed = 'COMPLETED',
  paid = 'PAID',
  wait = 'WAIT',
}

export enum BonusName {
  firstDepositFreeSpins = 'first_deposit_freespins',
  firstDepositBonus = 'first_deposit_bonus',
  everyDepositBonus = 'every_deposit_bonus',
  register = 'register',
}

export interface IServerPromotion {
  id: number
  name: BonusName
  freespins_grant_amount: number
  wager_coefficient: number
  wager_minutes_period: number
  active: boolean
  deposit_coefficient: number
  maximum_wager_currency: string
  maximum_wager: string
}

export interface IServerUserPromotion {
  id: number
  promotion: IServerPromotion
  freespins_count: {
    count: number
  }
  name: string
  status: BonusPromotionStatus
  wager_currency: string
  wager: string
  expiration_time: string
  promotion_grant_amount_currency: string
  promotion_grant_amount: string
  wager_completion_currency: string
  wager_completion: string
  user: number
  transaction: number
}

export interface IUserPromotion {
  id: number
  promotionId: number
  active: boolean
  status: BonusPromotionStatus
  expirationDate: string
  depositCoefficient: number
  wagerCoefficient: number
  wager: number
  wagerCurrency: string
  wagerComplete: number
  name: BonusName
  wagerCompleteCurrency: string
  freeSpinsCount: number
  freeSpinsGrantCount: number
  promotionGrandAmount: number
  promotionGrandAmountCurrency: string
  isFreeSpins: boolean
}
