import { IApplicationState } from 'setup/store'

export const selectBonusPromotions = (state: IApplicationState) => state.bonusSystem.data.promotions

export const selectBonusSystemComm = (state: IApplicationState) => state.bonusSystem.communications

export const selectBonusPromotionsComm = (state: IApplicationState) =>
  state.bonusSystem.communications.getUserPromotions

export const selectChangeActiveIdComm = (state: IApplicationState) =>
  state.bonusSystem.communications.changeActiveUserPromotion

export const selectGetMoneyFromPromotionIdComm = (state: IApplicationState) =>
  state.bonusSystem.communications.getMoneyFromPromotion
