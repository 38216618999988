import React, { useCallback, useEffect, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import block from 'bem-cn'

import { MODAL_ROUTE_PATH } from 'router/modalRoutes'

import { getRouteParamsByStrings } from 'shared/utils/getRouteParamsByStrings'

import { selectLocaleDict } from 'features/locale/selectors'

import Button from 'components/UIKit/Button/Button'

import './PaymentRedirection.scss'

interface ParamsMatch {
  url: string
}

interface IPaymentRedirectionProps {
  path: string
}

const b = block('payment-redirection')

const PaymentRedirection: React.FC<IPaymentRedirectionProps> = ({ path }) => {
  const params = getRouteParamsByStrings<ParamsMatch>(MODAL_ROUTE_PATH.PAYMENT_REDIRECTION, path)

  const locale = useSelector(selectLocaleDict, shallowEqual).payment

  const decodedRedirectUrl = useMemo(() => atob(params?.url.replace('%2F', '/') || ''), [params])

  const handleManualRedirect = useCallback(() => {
    window.open(decodedRedirectUrl, '_blank', 'noreferrer')
  }, [decodedRedirectUrl])

  useEffect(() => {
    if (decodedRedirectUrl !== '') {
      handleManualRedirect()
    }
  }, [decodedRedirectUrl])

  return (
    <div className={b()}>
      <div className={b('header')}>{locale.redirecting}</div>

      <div className={b('body')}>
        <div className={b('content')}>
          <h2 className={b('content-title')}>
            {locale.weAreProcessingRedirectTo.replace('$REDIRECT_URL', decodedRedirectUrl)}
          </h2>
          <p className={b('content-text')}>{locale.ifRedirectDidntHappen}</p>
        </div>
        <div className={b('button')}>
          <Button text={locale.redirect} callback={handleManualRedirect} />
        </div>
      </div>
    </div>
  )
}

export default PaymentRedirection
