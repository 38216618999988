import { ISlotViewItem } from './interfaces/Slots/Slots'

export interface IGetGameUrl<D, E> {
  data: D
  extraData: E
  gameId: string | number
  gameList: ISlotViewItem[]
  lang: string
}

export const Providers = {
  softwiss: 'softwiss',
} as const

export type ProvidersType = ReverseMap<typeof Providers>
