import { ITabs } from '../types/interfaces/Slots/Slots'

export const tabsLive: ITabs = {
  'Drops & Wins': [
    'pragmaticexternal:SpeedBaccarat5',
    'pragmaticexternal:SpeedBaccarat6',
    'pragmaticexternal:SpeedBaccarat7',
    'pragmaticexternal:SpeedBaccarat8',
    'pragmaticexternal:SpeedBaccarat9',
    'pragmaticexternal:SpeedBaccarat10',
    'pragmaticexternal:SpeedBaccarat11',
    'pragmaticexternal:SpeedBaccarat12',
    'pragmaticexternal:SpeedBaccarat13',
    'pragmaticexternal:SpeedBaccarat14',
    'pragmaticexternal:SpeedBaccarat15',
    'pragmaticexternal:Roulette11Dutch',
    'pragmaticexternal:Roulette14Spanish',
    'pragmaticexternal:PowerUPRoulette',
    'pragmaticexternal:Roulette10Ruby',
    'pragmaticexternal:Roulette14Spanish',
    'pragmaticexternal:Roulette6Turkish',
    'pragmaticexternal:Roulette7Italian',
    'pragmaticexternal:Roulette9TheClub',
    'pragmaticexternal:RouletteAzure',
    'pragmaticexternal:RouletteGermany',
    'pragmaticexternal:RouletteMacao',
    'pragmaticexternal:RouletteRussia',
    'pragmaticexternal:SpeedAutoRoulette1',
    'pragmaticexternal:SpeedRoulette',
    'pragmaticexternal:BlackJack16Azure',
    'pragmaticexternal:BlackJack17Azure',
    'pragmaticexternal:BlackJack18Azure',
    'pragmaticexternal:BlackJack19Azure',
    'pragmaticexternal:BlackJack20Azure',
    'pragmaticexternal:BlackJack22Azure',
    'pragmaticexternal:BlackJack22Azure',
    'pragmaticexternal:BlackJack23Azure',
    'pragmaticexternal:BlackJack24Azure',
    'pragmaticexternal:BlackJack25Azure',
    'pragmaticexternal:BlackJack26Azure',
    'pragmaticexternal:BlackJack27Azure',
    'pragmaticexternal:BlackJack28Azure',
    'pragmaticexternal:BlackJack29Azure',
    'pragmaticexternal:BlackJack30Azure',
    'pragmaticexternal:BlackJack31Azure',
    'pragmaticexternal:BlackJack32Azure',
  ],
  popular: [
    // pragmatic req
    'monopoly:pqe4eud2fnorlatr',
    '1101',
    'crazytime:pqe3bikvb6ebirul',
    '204',
    'dealnodeal:qssk5kltwxctu7un',
    '801',
    'roulette:phi5cefi6faxd2gn',
    '103',
    '545',
    '230',
    '701',
    '902',
    '901',
    '1401',

    'evolution:crazytime',
    'crazytime:pqe3bikvb6ebirul', // sf

    'pragmaticexternal:SweetBonanzaCandyland',
    '1101',

    'evolution:monopoly',
    'monopoly:pqe4eud2fnorlatr',

    'atmosphera:musicwheel',

    'evolution:GonzosTreasureHunt',
    'gonzotreasurehunt:pghg7ndeqhrqcmth',

    'pragmaticexternal:AutoRoulette',
    '225',

    'evolution:dealnodeal',
    'dealnodeal:qssk5kltwxctu7un',

    'evolution:dream_catcher',
    'moneywheel:pqe3l4gnb6ebjfmv',

    'evolution:lightning_roulette',
    'roulette:phi5cefi6faxd2gn',

    'pragmaticexternal:MegaWheel',
    '801',

    'evolution:sidebetcity',
    'sidebetcity:SBCTable00000001',

    'evolution:lightningdice',
    'lightningdice:pizx5gn2jx3tfyda',

    'atmosphera:Keno',
    'alg:Roulette360',
    'alg:LasVegasRoulette',
    'alg:BondRoulette',
    'ezugi:CricketWar',

    'pragmaticexternal:DragonTigerLive',
    '108',

    'pragmaticexternal:ONEBlackjack',
    '901',

    'pragmaticexternal:MegaSicBo',
    '701',

    'pragmaticexternal:MegaRoulette',
    '204',

    'ezugi:BetonTeenPatti',
    'ezugi:SpeedAutoRoulette',
    'ezugi:BlackjackSalonPrive',

    'evolution:lightning_roulette',
    'roulette:phi5cefi6faxd2gn',

    'evolution:americanroulette',
    'americanroulette:pizrsbcjjx3s6p63',

    'evolution:auto_roulette',
    'roulette:pizrtlifoyfs7mzm',

    'evolution:instantroulette',
    'roulette:pizx4cc5jx3tfwsj',

    'evolution:BacBo',
    'bacbo:p4fp37k5qyeoucds',

    'evolution:GoldenWealthBaccarat',
    'baccarat:pr7kkkepcriirmzx',

    'evolution:XXXTremeLightningRoulette',
    'roulette:qgvm5pn3cinbjyze',

    'evolution:double_ball',
    'roulette:pl3zd3r4ntsl4fwh',

    'evolution:craps',
    'craps:pgwkdys4johfvwtq',

    'evolution:dragon_tiger',
    'dragontiger:pizw2vqojx3tepdd',

    'evolution:fan_tan',
    'fantan:pr7jwqjtxgrypwrn',
  ],

  showGames: [
    'evolution:monopoly',
    'monopoly:pqe4eud2fnorlatr',

    'pragmaticexternal:SweetBonanzaCandyland',
    '1101',

    'evolution:crazytime',
    'crazytime:pqe3bikvb6ebirul', // sf

    'evolution:GonzosTreasureHunt',
    'gonzotreasurehunt:pghg7ndeqhrqcmth',

    'pragmaticexternal:MegaWheel',
    '801',

    'evolution:dealnodeal',
    'dealnodeal:qssk5kltwxctu7un',

    'atmosphera:musicwheel',

    'evolution:dream_catcher',
    'moneywheel:pqe3l4gnb6ebjfmv',

    'evolution:megaball',
    'megaball:onepgisriztulcgm',

    'pragmaticexternal:MegaRoulette',
    '204',

    'evolution:cashorcrash',
    'cashorcrash:pr7jkubciopipkjk',

    'evolution:lightningdice',
    'lightningdice:pizx5gn2jx3tfyda',

    'pragmaticexternal:MegaSicBo',
    '701',

    'evolution:lightning_roulette',
    'roulette:phi5cefi6faxd2gn',

    'pragmaticexternal:ONEBlackjack',

    'evolution:top_card',
    'rng-topcard:qmhdfc3g7qc4eqrw', // hz

    'evolution:sidebetcity',
    'sidebetcity:SBCTable00000001',

    'pragmaticexternal:DragonTigerLive',
    '108',
  ],
  firstPerson: [
    'evolution:FastpayFirstPersonBlackjack',
    'rng-blackjack:rng-bj-standard1', // hz

    'evolution:FirstPersonGoldenWealthBaccarat',
    'baccarat:pr7kkkepcriirmzx',

    'evolution:FirstPersonLightningBaccarat',
    'baccarat:pn6wbyb7wnrdzrlq',

    'evolution:FirstPersonLightningBlackjack',

    'evolution:GoodmanFirstPersonBlackjack',

    'evolution:GslotFirstPersonBlackjack',

    'evolution:RoobetFirstPersonBlackjack',

    'evolution:blackjack_rng',
    'rng-blackjack:rng-bj-standard1',

    'evolution:moneywheel_rng',

    'evolution:rng-craps',
    'rng-craps:pizxhr5zxtstfevd',

    'evolution:rng-megaball',
    'rng-megaball:pn6web77ee7dzknk',

    'evolution:rngbaccarat',
    'rng-baccarat:pizxb4yjxtste54v',

    'evolution:rngdragontiger',
    'rng-dragontiger:qmhbwwnmxai4cplw',

    'evolution:rngtopcard',
    'rng-topcard:qmhdfc3g7qc4eqrw',

    'evolution:roulette_fp_rng',
    'rng-roulette:pizxpmonjx3tfhvv',

    'evolution:roulette_rng',
    'rng-roulette:pizxusaejx3tfnsv',
  ],
  roulette: [
    'evolution:lightning_roulette',
    'roulette:phi5cefi6faxd2gn',

    'evolution:TurkishLightningRoulette',
    'roulette:pvylynumqaa5ikjz',

    'evolution:XXXTremeLightningRoulette',
    'roulette:qgvm5pn3cinbjyze',

    'evolution:americanroulette',
    'americanroulette:pizrsbcjjx3s6p63',

    'evolution:auto_roulette',
    'roulette:pizrtlifoyfs7mzm',

    'evolution:instantroulette',
    'roulette:pizx4cc5jx3tfwsj',

    'pragmaticexternal:MegaRoulette',
    '204',

    'pragmaticexternal:SpeedRoulette',
    '203',

    'pragmaticexternal:AutoRoulette',
    '225',

    'Rouletteroyal_original',
    'atmosphera:RouletteEng',
    'alg:SilverCrownRoulette',
    'alg:Roulette360',
    'alg:OracleRoulette',
    'alg:LasVegasRoulette',
    'alg:BondRoulette',
    'alg:AmericanRoulette',
    'alg:GermaniaAutoRoulette',
    'alg:Oracle360Roulette',
    'alg:OracleCasino',
    'alg:PortomasoCasino',
    'alg:PortomasoLumia',
    'alg:AbsoluteBright',
    'alg:AbsoluteWhite',
    'alg:AutomaticRoulette1',
    'alg:AutomaticRoulette2',

    'evolution:BucharestAutoRoulette',

    'evolution:DragonaraRoulette',

    'evolution:americanroulette',
    'americanroulette:pizrsbcjjx3s6p63',

    'evolution:arabic_roulette',

    'evolution:auto_la_partage',
    'roulette:pizrubscoyfs7nss',

    'evolution:auto_roulette_vip',
    'roulette:pizrutqcoyfs7ogj',

    'evolution:casino_malta',
    'roulette:qgvphexscinbr6wp',

    'evolution:deutsches_roulette',

    'evolution:double_ball',
    'roulette:pl3zd3r4ntsl4fwh',

    'evolution:flemishroulette',

    'evolution:french_roulette',

    'evolution:french_roulette_gold',
    'roulette:pizxzxaijx3tftzw',

    'evolution:immersive_roulette',
    'roulette:pizx3ax5oyftgp3a',

    'evolution:instantroulette',
    'roulette:pizx4cc5jx3tfwsj',

    'evolution:japanese_roulette',

    'evolution:london_roulette',

    'evolution:netherland_roulette',

    'evolution:norsk_roulette',

    'evolution:roulette',
    'roulette:pizyhwn5oyftg676',

    'evolution:roulette_direct',
    'evolution:ruleta_envivo',
    'evolution:russian_roulette',
    'evolution:salon_private_roulette_one',

    'evolution:speed_auto_roulette',
    'roulette:pizyjnioxtstgml3',

    'evolution:speed_roulette',
    'roulette:pwilndzkhas3ox5w',

    'evolution:suomalainen_roulette',
    'evolution:svensk_roulette',
    'evolution:turkce_roulette',

    'evolution:vip_roulette',
    'roulette:pizxyg3yxtstfygm',

    'pragmaticexternal:IndianRoulette',
    '229',

    'pragmaticexternal:LiveRouletteA',

    'pragmaticexternal:Roulette10Ruby',
    '230',

    'pragmaticexternal:Roulette9TheClub',
    'pragmaticexternal:RouletteAzure',
    'pragmaticexternal:RouletteGermany',
    'pragmaticexternal:RouletteMacao',
    'pragmaticexternal:RouletteRussia',

    'pragmaticexternal:SpeedRoulette',
    '203',

    'ezugi:AutomaticRoulette',
    'ezugi:AutomaticRoulette1',
    'ezugi:CumbiaRuleta1',
    'ezugi:DiamondRoulette',
    'ezugi:FiestaRoulette',
    'ezugi:NamasteRoulette',
    'ezugi:OracleCasinoRoulette',
    'ezugi:OracleCasinoRoulette360',
    'ezugi:PortomasoCasinoRoulette',
    'ezugi:RouletteGold2',
    'ezugi:RouletteGold3',
    'ezugi:RouletteLobby',
    'ezugi:SpeedAutoRoulette',
    'ezugi:SpeedRoulette',
    'ezugi:TurkceRulet',
    'ezugi:TurkishRoulette',
    'luckystreak:AutoRoulette1',
    'luckystreak:FashionRoulette',
    'luckystreak:Oracle360',
    'luckystreak:PortomasoOracleRoulette1',
    'luckystreak:PortomasoOracleRoulette2',
    'luckystreak:Roulette1',
    'luckystreak:Roulette2',
  ],
  blackJack: [
    'evolution:LightningBlackjack',

    'pragmaticexternal:ONEBlackjack2Indigo',
    '902',

    'ezugi:FiestaBlackjack',

    'evolution:BlackjacVIPGamma',
    'blackjack:pizwdpahjx3tdtrc',

    'evolution:BlackjackClassic73',
    'evolution:BlackjackClassic74',
    'evolution:BlackjackClassic75',
    'evolution:BlackjackClassic76',
    'evolution:BlackjackClassic77',
    'evolution:BlackjackClassic78',
    'evolution:BlackjackClassic79',
    'evolution:BlackjackClassic80',
    'evolution:BlackjackClassic81',
    'blackjack:piztk4maxtstavha',

    'evolution:BlackjackVIP17',
    'blackjack:ptsyy2qwz4vt3a4s',

    'evolution:BlackjackVIP18',
    'blackjack:ptszf7rnmaed4g6b',

    'evolution:BlackjackVIP19',
    'blackjack:ptszwqjemaed5kth',

    'evolution:BlackjackVIP20',
    'evolution:BlackjackVIP21',
    'evolution:BlackjackVIP22',

    'evolution:ClassicSpeedBlackjack40',
    'evolution:ClassicSpeedBlackjack8',
    'blackjack:pizy45nhoyfthyfj',

    'evolution:SalonPriveBlackjackG',
    'evolution:bjclassic1',
    'evolution:bjclassic2',
    'evolution:bjclassic3',
    'evolution:bjclassic4',
    'evolution:bjclassic5',
    'evolution:bjclassic6',
    'evolution:bjclassic7',
    'evolution:bjclassic8',
    'evolution:bjclassic9',
    'evolution:bjclassic10',
    'evolution:bjclassic11',
    'evolution:bjclassic12',
    'evolution:bjclassic13',
    'evolution:bjclassic14',
    'evolution:bjclassic15',
    'evolution:bjclassic16',
    'evolution:bjclassic17',
    'evolution:bjclassic18',
    'evolution:bjclassic19',
    'evolution:bjclassic20',
    'evolution:bjclassic21',
    'evolution:bjclassic22',
    'evolution:bjclassic23',
    'evolution:bjclassic24',
    'evolution:bjclassic25',
    'evolution:bjclassic26',
    'evolution:bjclassic27',
    'evolution:bjclassic28',
    'evolution:bjclassic29',
    'evolution:bjclassic30',
    'evolution:bjclassic31',
    'evolution:bjclassic32',
    'evolution:bjclassic33',
    'evolution:bjclassic34',
    'evolution:bjclassic35',
    'evolution:bjclassic36',
    'evolution:bjclassic37',
    'evolution:bjclassic38',
    'evolution:bjclassic39',
    'evolution:bjclassic40',
    'evolution:bjclassic41',
    'evolution:bjclassic42',
    'evolution:bjclassic43',
    'evolution:bjclassic44',
    'evolution:bjclassic45',
    'evolution:bjclassic46',
    'evolution:bjclassic47',
    'evolution:bjclassic48',
    'evolution:bjclassic49',
    'evolution:bjclassic50',
    'evolution:bjclassic51',
    'evolution:bjclassic52',
    'evolution:bjclassic53',
    'evolution:bjclassic54',
    'evolution:bjclassic55',
    'evolution:bjclassic56',
    'evolution:bjclassic57',
    'evolution:bjclassic58',
    'evolution:bjclassic59',
    'evolution:bjclassic60',
    'evolution:bjclassic61',
    'evolution:bjclassic62',
    'evolution:bjclassic63',
    'evolution:bjclassic64',
    'evolution:bjclassic65',
    'evolution:bjclassic66',
    'evolution:bjclassic67',
    'evolution:bjclassic68',
    'evolution:bjclassic69',
    'evolution:bjclassic70',
    'evolution:bjclassic71',
    'evolution:bjclassic72',

    'evolution:blackjack',
    '105',

    'evolution:blackjack_a',
    'blackjack:piztijunjx3tamq7',

    'evolution:blackjack_b',

    'evolution:blackjack_c',
    'blackjack:piztk4maxtstavha',

    'evolution:blackjack_d',
    'evolution:blackjack_e',
    'evolution:blackjack_f',
    'evolution:blackjack_g',
    'evolution:blackjack_h',
    'evolution:blackjack_i',
    'evolution:blackjack_j',
    'evolution:blackjack_k',
    'evolution:blackjack_l',

    'evolution:blackjack_rng',
    'rng-blackjack:rng-bj-standard1',

    'evolution:blackjack_classic1',
    'evolution:blackjack_classic2',
    'evolution:blackjack_classic3',
    'evolution:blackjack_classic4',
    'evolution:blackjack_classic5',
    'evolution:blackjack_classic6',
    'evolution:blackjack_classic7',
    'evolution:blackjack_classic8',
    'evolution:blackjack_classic9',

    'evolution:blackjack_diamond_vip',
    'blackjack:pizt3ejfjx3tbbn3',

    'evolution:blackjack_vip_1',
    'blackjack:patbjjf2vgsumaki',

    'evolution:blackjack_vip_2',
    'blackjack:patncj7lee3u4add',

    'evolution:blackjack_vip_1',
    'blackjack:patni5mh7uju4tzd',

    'evolution:blackjack_vip_3',
    'blackjack:patni5mh7uju4tzd',

    'evolution:blackjack_vip_4',
    'blackjack:patnpahx7uju44hy',

    'evolution:blackjack_vip_5',
    'blackjack:patnvirlee3u426w',

    'evolution:blackjack_vip_6',
    'blackjack:patn35uo7uju5opz',

    'evolution:blackjack_vip_7',
    'blackjack:patoecgkvgsu5p33',

    'evolution:blackjack_vip_8',
    'blackjack:patoqdvy7uju6j2l',

    'evolution:blackjack_vip_9',
    'blackjack:pd4r3e3t4osfmmqe',

    'evolution:blackjack_vip_10',
    'blackjack:pd4so2zbgdwfnupd',

    'evolution:blackjack_vip_11',
    'blackjack:pd4sweujanafnkkl',

    'evolution:blackjack_vip_12',
    'blackjack:pd4s757ngdwfog3c',

    'evolution:blackjack_vip_13',
    'blackjack:pd4te7xy4osfnzsr',

    'evolution:blackjack_vip_14',
    'blackjack:pd4tklmzanafoawk',

    'evolution:blackjack_vip_15',
    'blackjack:pd4tzdfganaforhb',

    'evolution:blackjack_vip_16',
    'blackjack:pd4uexhmgdwfppwz',

    'evolution:blackjack_vip_a',
    'blackjack:pizv4pbbxtstdr5j',

    'evolution:blackjack_vip_b',
    'blackjack:pizv54rqxtstdtqo',

    'evolution:blackjack_vip_c',
    'blackjack:pizwa7tkxtstdxio',

    'evolution:blackjack_vip_d',
    'blackjack:pizwbu5bxtstdybw',

    'evolution:blackjack_vip_e',
    'blackjack:pizwcezlxtstdyt3',

    'evolution:blackjack_vip_f',

    'evolution:blackjack_vip_g',
    'blackjack:pizwdaktjx3tds77',

    'evolution:blackjack_vip_h',
    'blackjack:pizwkexpjx3td3ts',

    'evolution:blackjack_vip_i',
    'blackjack:pizwlcxsxtstedji',

    'evolution:blackjack_vip_j',
    'blackjack:pizwls5hjx3td5h4',

    'evolution:blackjack_vip_k',
    'blackjack:pizwl66boyfteyrx',

    'evolution:blackjack_vip_l',
    'blackjack:nbjettfehawanhes',

    'evolution:blackjack_vip_m',
    'blackjack:pizwopczxtstehjw',

    'evolution:blackjack_vip_n',
    'blackjack:pizwo2rpjx3tebc6',

    'evolution:blackjack_vip_o',
    'blackjack:pizwpnmajx3tebzo',

    'evolution:blackjack_vip_p',
    'blackjack:pizwpzcqjx3techo',

    'evolution:blackjack_vip_q',
    'blackjack:pizwqh5jjx3teczg',

    'evolution:blackjack_vip_r',
    'blackjack:pizwst5voyftfaju',

    'evolution:blackjack_vip_s',
    'blackjack:pizwtcrqoyftfazp',

    'evolution:blackjack_vip_t',
    'blackjack:pizwt43mjx3tehcj',

    'evolution:blackjack_vip_u',

    'evolution:blackjack_vip_v',

    'evolution:blackjack_vip_w',

    'evolution:blackjack_vip_x',
    'blackjack:pizwul56jx3tehuw',

    'evolution:blackjack_vip_y',

    'evolution:blackjack_vip_z',
    'blackjack:pizwuyuvoyftfc6j',

    'evolution:blackjack_white_1',
    'evolution:blackjack_white_2',
    'evolution:blackjack_white_3',
    'evolution:classic_speed_blackjack_1',
    'evolution:classic_speed_blackjack_2',
    'evolution:classic_speed_blackjack_3',
    'evolution:classic_speed_blackjack_4',
    'evolution:classic_speed_blackjack_5',
    'evolution:classic_speed_blackjack_6',
    'evolution:classic_speed_blackjack_7',
    'evolution:classic_speed_blackjack_8',
    'evolution:classic_speed_blackjack_9',
    'evolution:classic_speed_blackjack_10',
    'evolution:classic_speed_blackjack_11',
    'evolution:classic_speed_blackjack_12',
    'evolution:classic_speed_blackjack_13',
    'evolution:classic_speed_blackjack_14',
    'evolution:classic_speed_blackjack_15',
    'evolution:classic_speed_blackjack_16',
    'evolution:classic_speed_blackjack_17',
    'evolution:classic_speed_blackjack_18',
    'evolution:classic_speed_blackjack_19',
    'evolution:classic_speed_blackjack_20',
    'evolution:classic_speed_blackjack_21',
    'evolution:classic_speed_blackjack_22',
    'evolution:classic_speed_blackjack_23',
    'evolution:classic_speed_blackjack_24',
    'evolution:classic_speed_blackjack_25',
    'evolution:classic_speed_blackjack_26',
    'evolution:classic_speed_blackjack_27',
    'evolution:classic_speed_blackjack_28',
    'evolution:classic_speed_blackjack_29',
    'evolution:classic_speed_blackjack_30',
    'evolution:classic_speed_blackjack_31',
    'evolution:classic_speed_blackjack_32',
    'evolution:classic_speed_blackjack_33',
    'evolution:classic_speed_blackjack_34',
    'evolution:classic_speed_blackjack_35',
    'evolution:classic_speed_blackjack_36',
    'evolution:classic_speed_blackjack_37',
    'evolution:classic_speed_blackjack_38',
    'evolution:classic_speed_blackjack_39',
    'evolution:classic_speed_blackjack_40',
    'evolution:classic_speed_blackjack_41',
    'evolution:classic_speed_blackjack_42',
    'evolution:classic_speed_blackjack_43',
    'evolution:classic_speed_blackjack_44',
    'evolution:classic_speed_blackjack_45',
    'evolution:classic_speed_blackjack_46',
    'evolution:classic_speed_blackjack_47',
    'evolution:classic_speed_blackjack_48',
    'evolution:classic_speed_blackjack_49',
    'evolution:classic_speed_blackjack_50',

    'evolution:freebet_blackjack',
    'evolution:infinite_blackjack',
    'blackjack:pqe4k7w4iesbl2wz',

    'evolution:party_bj',
    'blackjack:pizt5omqxtstbjxw',

    'evolution:platinum_vip',
    'blackjack:pizt6gtzoyftb7kq',

    'evolution:powerblackjack',
    'blackjack:pl32m2awurdl6mxl',

    'evolution:salon_private_blackjack_one',
    'evolution:salon_private_blackjack_two',
    'evolution:salon_private_blackjack_three',
    'evolution:salon_private_blackjackd',
    'evolution:salon_private_blackjacke',
    'evolution:salon_private_blackjackf',
    'evolution:silver1',
    'evolution:silver2',
    'evolution:silver3',
    'evolution:silver4',
    'evolution:silver5',
    'evolution:silver6',
    'evolution:silver7',

    'evolution:speed_blackjack_a',
    'evolution:speed_blackjack_b',
    'evolution:speed_blackjack_c',

    'evolution:speed_blackjack_d',
    'blackjack:pizyxkbjoyfthrsx',

    'evolution:speed_blackjack_e',
    'blackjack:pizyx363oyfthsgs',

    'evolution:speed_blackjack_f',

    'evolution:speed_blackjack_g',
    'blackjack:pizyyit4xtstg52q',

    'evolution:speed_blackjack_h',
    'blackjack:pizyytxxxtstg6jp',

    'evolution:speed_blackjack_i',
    'blackjack:pizyzas5oyfthtrc',

    'evolution:speed_blackjack_j',
    'blackjack:pizyzmu3xtstg7ix',

    'pragmaticexternal:BlackJack22Azure',
    'pragmaticexternal:BlackJack23Azure',
    'pragmaticexternal:BlackJack24Azure',
    'pragmaticexternal:BlackJack25Azure',
    'pragmaticexternal:BlackJack26Azure',
    'pragmaticexternal:BlackJack30Azure',
    'pragmaticexternal:BlackJack31Azure',
    'pragmaticexternal:BlackJack32Azure',
    'pragmaticexternal:Blackjack33TheClub',
    'pragmaticexternal:Blackjack34TheClub',
    'pragmaticexternal:Blackjack35TheClub',
    'pragmaticexternal:Blackjack36TheClub',
    'pragmaticexternal:Blackjack38Ruby',
    'pragmaticexternal:Blackjack45Ruby',
    'pragmaticexternal:Blackjack46Ruby',
    'pragmaticexternal:Blackjack47Ruby',
    'pragmaticexternal:Blackjack48Ruby',
    'pragmaticexternal:Blackjack49Ruby',
    'pragmaticexternal:Blackjack50Ruby',
    'pragmaticexternal:Blackjack51Ruby',
    'pragmaticexternal:BlackjackA',
    'pragmaticexternal:BlackjackE',
    'pragmaticexternal:BlackjackAzureA',
    'pragmaticexternal:BlackjackAzureB',
    'pragmaticexternal:BlackjackAzureC',
    'pragmaticexternal:BlackjackAzureD',
    'pragmaticexternal:BlackjackAzureE',
    'pragmaticexternal:BlackjackAzureF',
    'pragmaticexternal:BlackjackAzureG',
    'pragmaticexternal:BlackjackAzureH',
    'pragmaticexternal:BlackjackAzureI',
    'pragmaticexternal:BlackjackAzureJ',
    'pragmaticexternal:LiveBlackjackB',
    'pragmaticexternal:LiveBlackjackC',
    'pragmaticexternal:LiveBlackjackD',
    'pragmaticexternal:SpeedBlackjack3Ruby',
    'pragmaticexternal:SpeedBlackjack4Ruby',
    'pragmaticexternal:VIPBlackjack1Ruby',
    'pragmaticexternal:VIPBlackjack2Ruby',
    'pragmaticexternal:VIPBlackjack3Ruby',
    'pragmaticexternal:VIPBlackjack4Ruby',
    'pragmaticexternal:VIPBlackjack5Ruby',
    'ezugi:BlackRussianBlackjack',
    'ezugi:Blackjack1',
    'ezugi:Blackjack3',
    'ezugi:Blackjack7',
    'ezugi:BlackjackGold4',
    'ezugi:BlackjackGold5',
    'ezugi:BlackjackGold6',
    'ezugi:BlackjackLobby',
    'ezugi:BlackjackPlatinum1',
    'ezugi:BlackjackSalonPrive',
    'ezugi:MamboUnlimitedBlackjack',
    'ezugi:MerengueBlackjack1',
    'ezugi:MerengueBlackjack2',
    'ezugi:TurkishBlackjack',
    'ezugi:UnlimitedBlackjack',
    'ezugi:UnlimitedTurkishBlackjack',
    'ezugi:VIPBlackjack',
    'ezugi:VIPBlackjackwithSurrender',
    'ezugi:WhiteRussianBlackjack',
    'luckystreak:Blackjack16',
    'luckystreak:Blackjack2',
    'luckystreak:Blackjack3',
    'luckystreak:Blackjack6',
    'luckystreak:Blackjack7',
    'luckystreak:BlackjackVIP',
  ],
  lotto: [
    'atmosphera:Bingo38',
    'atmosphera:Bingo37',
    'atmosphera:Keno',
    'ezugi:BetOnNumbersLobby',
    'ezugi:BetonNumbers',
    'ezugi:Lucky7',
  ],
  poker: [
    'evolution:casino_holdem',
    'holdem:pizwz25poyftfi3n',

    'evolution:stud_poker',
    'csp:pknmv7g6xyewzy2m',

    'evolution:texas_holdem_bonus',
    'thb:pl4behykntsml4ry',

    'evolution:three_card_poker',
    'tcp:pqe5sayjiesbochz',

    'evolution:triple_card',

    'evolution:two_hand_casino_oldem',
    'dhp:pqezumiofnorgakc',

    'evolution:ultimate_holdem',
    'uth:pqe5sygniesbodxh',

    'ezugi:CasinoHold',
    'ezugi:PokerLobby',
  ],
  baccarat: [
    'evolution:GoldenWealthBaccarat',
    'baccarat:pr7kkkepcriirmzx',

    'evolution:PeekBaccarat',
    'baccarat:qb237rpynisbqxwe',

    'evolution:SalonPriveBaccaratF',
    'evolution:SalonPriveBaccaratG',

    'evolution:baccarat_a',
    'baccarat:pizr4rkijx3s63pj',

    'evolution:baccarat_b',
    'baccarat:pizr45mcxtss7cj7',

    'evolution:baccarat_c',
    'baccarat:pizr5iwzxtss7cuf',

    'evolution:baccarat_controlled',

    'evolution:baccarat_squeeze',
    'baccarat:pizsgwutxtss7nbk',

    'evolution:nocomm_speed_baccarat',
    'baccarat:pizyeqq3jx3tgawr',

    'evolution:nocomm_speed_baccaratb',
    'baccarat:pizyew2cxtstgguv',

    'evolution:nocomm_speed_baccaratc',
    'baccarat:pizyfehtxtstgheq',

    'evolution:salon_private_baccarat',
    'evolution:salon_private_baccarat_2',
    'evolution:salon_private_baccarat_c',
    'evolution:salon_private_baccarat_d',
    'evolution:salon_private_baccarat_e',

    'evolution:speed_baccarata',
    'baccarat:pizym7q7jx3tglbk',

    'evolution:speed_baccaratb',
    'baccarat:pizynirtoyfthfyc',

    'evolution:speed_baccaratc',
    'baccarat:pizynvjgoyfthghm',

    'evolution:speed_baccaratd',
    'baccarat:pizyoevtxtstgr7g',

    'evolution:speed_baccarate',
    'baccarat:pizyovyejx3tgnff',

    'evolution:speed_baccaratf',
    'baccarat:pizyo5anxtstgsy6',

    'evolution:speed_baccaratg',
    'baccarat:pizypnp6jx3tgobl',

    'evolution:speed_baccarath',
    'baccarat:pizypxzhxtstgtyg',

    'evolution:speed_baccarati',
    'baccarat:pizyp76ljx3tgoxo',

    'evolution:speed_baccaratj',
    'baccarat:pizyqhgwoyfthjfe',

    'evolution:speed_baccaratk',
    'baccarat:pizyqm3ijx3tgpgq',

    'evolution:speed_baccaratl',
    'baccarat:pizyqs2lxtstguv4',

    'evolution:speed_baccaratm',
    'baccarat:pizyq5o3xtstgvci',

    'evolution:speed_baccaratn',
    'baccarat:pizyrhl2xtstgvmv',

    'evolution:speed_baccarato',
    'baccarat:pizyrxtfxtstgv6w',

    'evolution:speed_baccaratp',
    'baccarat:pizyscwdxtstgwl6',

    'evolution:speed_baccarataq',
    'baccarat:pizysjcojx3tgrp6',

    'evolution:super_six',

    'pragmaticexternal:Baccarat7',
    'pragmaticexternal:Baccarat8',
    'pragmaticexternal:Baccarat9',
    'pragmaticexternal:Baccarat10',
    'pragmaticexternal:Baccarat11',
    'pragmaticexternal:Baccarat12',
    'pragmaticexternal:Baccarat13',
    'pragmaticexternal:LiveBaccaratA',
    'pragmaticexternal:LiveBaccaratB',
    'pragmaticexternal:LiveBaccaratC',
    'pragmaticexternal:LiveBaccaratD',
    'pragmaticexternal:LiveBaccaratE',
    'pragmaticexternal:SpeedBaccaratA',
    'pragmaticexternal:SpeedBaccaratB',
    'pragmaticexternal:SpeedBaccaratC',
    'pragmaticexternal:SpeedBaccaratD',
    'pragmaticexternal:SpeedBaccaratE',
    'pragmaticexternal:SpeedBaccarat100',
    'pragmaticexternal:SpeedBaccarat11',
    'pragmaticexternal:SpeedBaccarat12',
    'pragmaticexternal:SpeedBaccarat13',
    'pragmaticexternal:SpeedBaccarat7',
    'pragmaticexternal:SpeedBaccarat8',
    'pragmaticexternal:SpeedBaccarat9',
    'ezugi:BaccaratLobby',
    'ezugi:GoldenBaccarat',
    'ezugi:GoldenBaccaratKnockOut',
    'ezugi:GoldenBaccaratSuperSix',
    'ezugi:NoCommissionBaccarat',
    'ezugi:SalsaBaccarat4',
    'ezugi:SpeedBaccaratCricket',
    'luckystreak:Baccarat',
    'luckystreak:Baccarat4',
    'luckystreak:XBaccaratM1',
    'luckystreak:XBaccaratM2',
    'luckystreak:XBaccaratM3',
    'luckystreak:XBaccaratM4',
  ],
  dice: [
    'evolution:lightningdice',
    'lightningdice:pizx5gn2jx3tfyda',

    'evolution:BacBo',
    'bacbo:p4fp37k5qyeoucds',

    'evolution:craps',
    'craps:pgwkdys4johfvwtq',

    'evolution:super_sicbo',
    'sicbo:pizy6cwtjx3tg7wd',

    'pragmaticexternal:MegaSicBo',
    '701',

    'ezugi:Sicbo',
  ],
}
