import { IConvertedValidateCodeResponse, IValidateCodeResponse } from 'shared/models/Auth'

import { BaseHttpService } from '../BaseHttpService'
import { QueryTypes } from '../data'

class PromocodeApi extends BaseHttpService {
  constructor(baseUrl: string) {
    super(baseUrl)
    this.baseUrl = `${baseUrl}/api/referral`
  }

  async activatePromocode(promocode: string) {
    return this.sendQuery({
      url: `${this.baseUrl}/activate/`,
      type: QueryTypes.POST,
      data: { code: promocode },
    })
  }

  async checkRegistrationValidate(code: string) {
    return this.sendQuery<IValidateCodeResponse, IConvertedValidateCodeResponse>({
      url: `${this.baseUrl}/register/validate_code/?code=${code}`,
      type: QueryTypes.GET,
      converterSuccess: data => ({ partnerId: data.partner_id }),
    })
  }
}

export default PromocodeApi
