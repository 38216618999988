import React, { useCallback, useState } from 'react';
import ReactInterval from 'react-interval';
import block from 'bem-cn';
import dayjs from 'dayjs';

import { Clock } from './Clock';

import './Timer.scss';

// example countDownDate : 'December 17, 2022 13:24:00'
export const SpinTimer: React.FC<{
  countDownDate: string
  getNewTime: () => string
  setCountDownTime: (a: string) => void
}> = ({ countDownDate, setCountDownTime, getNewTime }) => {
  const b = block('wheel-timer')
  const [residualTime, setResidualTime] = useState({
    days: '0',
    hours: '00',
    minutes: '00',
    seconds: '00',
  })

  const startTimer = useCallback(() => {
    const dateEnd = dayjs(countDownDate)
    let currentDate = dayjs()

    const daysDifference = Math.trunc(dateEnd.diff(currentDate, 'day'))

    currentDate = currentDate.add(daysDifference, 'day')

    const hoursDifference = Math.trunc(dateEnd.diff(currentDate, 'hour', true))

    currentDate = currentDate.add(hoursDifference, 'hours')

    const minutesDifference = Math.trunc(dateEnd.diff(currentDate, 'minutes'))

    currentDate = currentDate.add(minutesDifference, 'minutes')

    const secondDifference = Math.trunc(dateEnd.diff(currentDate, 'seconds'))

    if (dateEnd.diff(dayjs(), 'seconds') < 0) {
      setCountDownTime(getNewTime())
    }

    setResidualTime({
      days: `${daysDifference}`,
      hours: hoursDifference < 10 ? `0${hoursDifference}` : `${hoursDifference}`,
      minutes: minutesDifference < 10 ? `0${minutesDifference}` : `${minutesDifference}`,
      seconds: secondDifference < 10 ? `0${secondDifference}` : `${secondDifference}`,
    })
  }, [countDownDate])

  return (
    <div className={b()}>
      <div className={b('container')}>
        <ReactInterval enabled timeout={250} callback={startTimer} />
        <Clock
          timerHours={residualTime.hours}
          timerSeconds={residualTime.seconds}
          timerMinutes={residualTime.minutes}
        />
      </div>
    </div>
  )
}
