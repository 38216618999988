import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'
import { useHistory } from 'react-router-dom'
import block from 'bem-cn'

import { MODAL_ROUTE_PATH } from 'router/modalRoutes'
import { ROUTE_URLS } from 'router/routes'

import { getRouteParamsByStrings } from 'shared/utils/getRouteParamsByStrings'

import { ISlotViewItem } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'
import { ProvidersType } from 'modules/SlotsRoot/types/slot'

import { selectIsUserAuthenticated } from 'features/auth/selectors'
import { selectLocaleDict } from 'features/locale/selectors'
import { actions } from 'features/slots'
import { selectGameList, selectGameUrl, selectSessionUrlComm } from 'features/slots/selectors'

import { useModalContext } from 'components/HOC/withModalQuery/ModalContext'

import { addNotify } from 'components/Notify'
import Spinner from 'components/Spinner'

import './SlotRedirect.scss'

interface ISlotFrameRouteMatch {
  provider: ProvidersType
  gameId: string
  demoStart: string
}

const b = block('slot-redirect')

const SlotRedirect: React.FC<{ path?: string }> = ({ path = '' }) => {
  const dispatch = useDispatch()
  const { push } = useHistory()
  const { closeModal } = useModalContext()

  const match = useRouteMatch<ISlotFrameRouteMatch>()

  const params = getRouteParamsByStrings<ISlotFrameRouteMatch>(MODAL_ROUTE_PATH.REPLACING_SLOT_FRAME_DEMO, path) || {
    provider: '',
    gameId: '',
    demoStart: '',
  }
  const { provider, gameId, demoStart } = path ? params : match.params

  const locale = useSelector(selectLocaleDict, shallowEqual).common
  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)
  const gameList = useSelector(selectGameList, shallowEqual)
  const sessionUrl = useSelector(selectGameUrl, shallowEqual)
  const sessionUrlComm = useSelector(selectSessionUrlComm, shallowEqual)

  const [isComeFirstComm, setIsComeFirstComm] = useState(false)

  const onCloseModal = useCallback(() => {
    closeModal(path)()
  }, [closeModal, path])

  const callback = useCallback(() => {
    if (path) {
      onCloseModal()
    } else {
      push(ROUTE_URLS.SLOTS_GAMETYPE('casino'))
    }

    if (isAuth) {
      addNotify(locale.tryVpn)
    } else {
      addNotify(locale.pleaseAuthSlots)
    }
  }, [path, onCloseModal, push, locale, isAuth])

  const providerGameList = useMemo(
    () => gameList.filter((t: ISlotViewItem) => t.routeProvider === provider),
    [provider, gameList],
  )

  useEffect(() => {
    if (!isComeFirstComm) {
      setIsComeFirstComm(true)
    }
  }, [sessionUrlComm])

  useEffect(() => {
    if (sessionUrlComm?.isSuccess && sessionUrl) {
      onCloseModal()
      setTimeout(() => {
        location.href = sessionUrl
      }, 0)
    }

    if ((!isAuth && !demoStart) || (sessionUrlComm.error && isComeFirstComm)) {
      callback()
    }
  }, [sessionUrlComm, sessionUrl, isComeFirstComm])

  useEffect(() => {
    if (isAuth) {
      if (!providerGameList.length && provider) {
        dispatch(actions.getGameList({ id: provider, provider }))
      }
    }
  }, [])

  useEffect(() => {
    if (providerGameList.length && provider) {
      dispatch(actions.getSessionUrl({ provider, gameId, withRedirect: true, callback, demoStart }))
    }
    return () => {
      dispatch(actions.clearSessionUrl())
    }
  }, [gameId, provider, providerGameList, dispatch])

  return <div className={b()}>{sessionUrlComm?.isLoading ? <Spinner isLoading /> : null}</div>
}

export default SlotRedirect
