import React, { forwardRef, useCallback, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import block from 'bem-cn'
import { AnimatePresence } from 'framer-motion'

import { ROUTE_URLS } from 'router/routes'

import logoImg from 'shared/img/sidebar/logo.svg'
import { ReactComponent as UserIcon } from 'shared/img/user.svg'

import { selectIsUserAuthenticated, selectUserData } from 'features/auth/selectors'
import { selectLocaleDict } from 'features/locale/selectors'
import { changeOpenedLeftSidePanel, changeOpenedRightSidePanel } from 'features/sidePanel/actions'
import { selectOpenedLeftSidePanel, selectOpenedRightSidePanel } from 'features/sidePanel/selectors'

import { SidePanel } from 'components/SidePanel/SidePanel'
import { SignInMobile } from 'components/SignInMobile/SignInMobile'

import { ReactComponent as BurgerMenuIcon } from './img/burger-menu.svg'
import { ReactComponent as ExitIcon } from './img/exit.svg'
import { MainMenu } from './MainMenu/MainMenu'
import { ProfilePanel } from './ProfilePanel/ProfilePanel'

import './MobileMenu.scss'

const b = block('mobile-menu')

const MobileMenuContent: React.FC = () => {
  const dispatch = useDispatch()

  const isRightPanelOpened = useSelector(selectOpenedRightSidePanel, shallowEqual)
  const isLeftPanelOpened = useSelector(selectOpenedLeftSidePanel, shallowEqual)
  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)
  const user = useSelector(selectUserData, shallowEqual)
  const locale = useSelector(selectLocaleDict, shallowEqual).common

  const [isOpenMenu] = useState(false)

  const handleOpenRightPanel = useCallback(() => {
    dispatch(changeOpenedRightSidePanel(!isRightPanelOpened))
  }, [dispatch, isRightPanelOpened])

  const handleOpenLeftPanel = useCallback(() => {
    dispatch(changeOpenedLeftSidePanel(!isLeftPanelOpened))
  }, [dispatch, isLeftPanelOpened])

  return (
    <div className='px-15'>
      <AnimatePresence exitBeforeEnter>
        {isRightPanelOpened && (
          <SidePanel isLeft={false}>
            {isAuth ? (
              <ProfilePanel closePanel={handleOpenRightPanel} />
            ) : (
              <SignInMobile setIsOpen={handleOpenRightPanel} />
            )}
          </SidePanel>
        )}
        {isLeftPanelOpened && (
          <SidePanel isLeft>
            <MainMenu />
          </SidePanel>
        )}
      </AnimatePresence>

      <header className={b()}>
        <div onClick={handleOpenLeftPanel} className={b('left-side')}>
          {!isOpenMenu ? <BurgerMenuIcon /> : <ExitIcon />}
        </div>

        <Link className={b('logo-wrapper')} to={ROUTE_URLS.MAIN()}>
          {!isOpenMenu && <img src={logoImg} alt="Logo" className={b('logo')} />}spineo.io
        </Link>

        <div className={b('right-content')}>
          {!isOpenMenu && isAuth ? (
            <>
              <Link to={ROUTE_URLS.PROFILE_DEPOSIT()} className={b('btn-deposit')} />
              <div
                className={b('user-header', { 'self-reg': user?.isSelfRegistered })}
                onClick={() => handleOpenRightPanel()}>
                <UserIcon className={b('user-header-svg')} />

                <div className={b('user-header-info')}>
                  <div
                    className={b(
                      'user-header-balance',
                    )}>{`${user?.balance} ${user?.currency} + ${user?.bonusBalance} B`}</div>
                  <div className={b('user-header-name')}>{user?.username}</div>
                </div>
              </div>
            </>
          ) : (
            !isOpenMenu &&
            !isAuth && (
              <>
              <div className={b('button-container')} onClick={() => handleOpenRightPanel()}>
                  {locale.signIn}
                </div>
                <Link to={ROUTE_URLS.AUTH_SIGN_UP()} className={b('button-container', { register: true })}>
                  {locale.register}
                </Link>
                
              </>
            )
          )}
        </div>
      </header>
    </div>
  )
}

export const MobileMenu = forwardRef<HTMLDivElement, { isScrolled: boolean }>((props, ref) => (
  <div className={b('wrapper').mix(props.isScrolled ? 'mobile_top' : '')} ref={ref}>
    <MobileMenuContent />
    {/* <Sidebar /> */}
  </div>
))
