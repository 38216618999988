import React, { Suspense,useCallback, useMemo, useState } from 'react'
import { shallowEqual,useSelector } from 'react-redux'
import block from 'bem-cn'

import { ReactComponent as ArrowSVG } from 'shared/img/arrow-down-triangle.svg'

import useInfiniteVerticalScroll from 'shared/hooks/useInfiniteVerticalScroll'

import { IFiltersSlots } from 'modules/SlotsRoot/types/filters'
import { ISlotsGameType, ISlotViewItem } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'

import { selectLocaleDict } from 'features/locale/selectors'

import SlotsByParameter from './SlotsByParameter/SlotsByParameter'

import './FilterResults.scss'

const b = block('filter-results')

const groupsPerRender = 3

interface IFilterResultsProps {
  returnToParametes: () => void
  gameType: ISlotsGameType
  slots: ISlotViewItem[]
  providers: string[]
  categories: string[]
  filters: IFiltersSlots
  tabByGameType: (slot: ISlotViewItem) => string[]
}

const FilterResults: React.FC<IFilterResultsProps> = ({
  returnToParametes,
  gameType,
  slots,
  providers,
  categories,
  filters,
  tabByGameType,
}) => {
  const locale = useSelector(selectLocaleDict, shallowEqual).slots

  const { selectedCategories, selectedProviders } = filters

  const [groupBy, setGroupBy] = useState<'providers' | 'categories'>('providers')
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)

  const slotsByCtg = useMemo(
    () =>
      selectedCategories.map((category, index) => {
        const filteredSlots = slots.filter(slot => {
          const slotTabs = tabByGameType(slot)
          if (category === 'all') {
            return slots
          }

          return slotTabs.includes(category)
        })

        return <SlotsByParameter key={index} slots={filteredSlots} parameter={category} />
      }),
    [selectedCategories, slots, tabByGameType],
  )

  const slotsByProvider = useMemo(() => {
    if (selectedProviders.length !== 0) {
      return selectedProviders.map((provider, index) => {
        const filteredSlots = slots.filter(slot => slot.provider === provider)
        return <SlotsByParameter key={index} slots={filteredSlots} parameter={provider} />
      })
    }
    return providers
      .map((provider, index) => {
        const filteredSlots = slots.filter(slot => slot.provider === provider)
        if (filteredSlots.length !== 0) {
          return <SlotsByParameter key={index} slots={filteredSlots} parameter={provider} />
        }
        return null
      })
      .filter(provider => provider !== null)
  }, [selectedProviders, slots, providers])

  const currentRenderingList = useMemo(
    () => (groupBy === 'providers' ? slotsByProvider : slotsByCtg),
    [groupBy, slotsByCtg, slotsByProvider],
  )

  const { current, ref }: { current: ISlotViewItem[]; ref: any } = useInfiniteVerticalScroll({
    data: currentRenderingList,
    loadingCount: groupsPerRender,
  })

  const loading = useMemo(
    () => (
      <>
        {current.length !== currentRenderingList.length &&
          currentRenderingList.length !== 0 &&
          currentRenderingList.length > groupsPerRender && (
            <div ref={ref} className={b('loading')}>
              <span>{locale.loading}</span>
            </div>
          )}
      </>
    ),
    [current, currentRenderingList, locale, ref],
  )

  return (
    <div className={b()}>
      <div className={b('wrapper')}>
        <div className={b('header')}>
          <span className={b('title')}>{locale.searchResults}</span>
          <span className={b('go-back')} onClick={returnToParametes}>
            {locale.goBack}
          </span>
        </div>
        <div className={b('parameters')}>
          <div className={b('parameter')}>
            <span className={b('left-side')}>{locale.categories}: </span>
            <span className={b('right-side')}>{selectedCategories.join(', ')}</span>
          </div>
          <div className={b('parameter')}>
            <span className={b('left-side')}>{locale.providers}: </span>
            <span className={b('right-side')}>
              {selectedProviders.length === 0 ? 'all' : selectedProviders.join(', ')}
            </span>
          </div>
          <div className={b('parameter')}>
            <span className={b('left-side')}>{locale.gamesFound}: </span>
            <span className={b('right-side', { isGame: true })}>{slots.length}</span>
          </div>
        </div>
        <div className={b('group-by')} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
          <span className={b('left-side')}>{locale.groupBy}: </span>
          <span className={b('right-side')}>{locale[groupBy].toUpperCase()}</span>
          <ArrowSVG className={b('arrow')} />
          <div className={b('dropdown', { isOpen: isDropdownOpen })}>
            <span className={b('dropdown-item')} onClick={() => setGroupBy('providers')}>
              {locale.providers}
            </span>
            <span className={b('dropdown-item')} onClick={() => setGroupBy('categories')}>
              {locale.categories}
            </span>
          </div>
        </div>
      </div>
      <div className={b('slots-container')}>
        {current}
        {loading}
      </div>
    </div>
  )
}

export default FilterResults
