import React, { useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import block from 'bem-cn'

import logoImg from 'shared/img/logo.png'

import { selectIsUserAuthenticated } from 'features/auth/selectors'
import { selectLocaleDict } from 'features/locale/selectors'
import { hidePersonalJackpotNotify } from 'features/notifies/actions'
import { selectNotifies } from 'features/notifies/selectors'

import Button from 'components/UIKit/Button/Button'

import PersonalJackpotPolling from './PersonalJacpotPolling/PersonalJackpotPolling'

import './PersonalJackpotNotify.scss'

const b = block('personal-jackpot-notify')

const PersonalJackpotNotify = () => {
  const dispatch = useDispatch()

  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)
  const locale = useSelector(selectLocaleDict, shallowEqual).bonuses
  const personalJackpotNotify = useSelector(selectNotifies, shallowEqual).personalJackpot

  const [temporarilyClosedJackpots, setTemporarilyClosedJackpots] = useState<number[]>([])

  const hideNotify = (id: number) => (e: React.MouseEvent) => {
    dispatch(hidePersonalJackpotNotify(id))
  }

  const temporarilyHideNotify = (id: number) => {
    setTemporarilyClosedJackpots(prev => [...prev, id])
  }

  const items = useMemo(
    () =>
      Object.keys(personalJackpotNotify.all)
        .filter(
          jackpotId =>
            !personalJackpotNotify.hide.includes(+jackpotId) && !temporarilyClosedJackpots.includes(+jackpotId),
        )
        .map(jackpot => {
          const currId = Number(jackpot)
          // eslint-disable-next-line max-len
          const amount = `${personalJackpotNotify.all[currId]?.amount} ${personalJackpotNotify.all[currId].amountCurrency}`

          return (
            <div className={b()} key={personalJackpotNotify.all[currId]?.id}>
              <div onClick={() => temporarilyHideNotify(currId)} className={b('background')} />
              <div className={b('container')}>
                <img src={logoImg} alt="Logo" className={b('logo')} />
                <div className={b('content')}>
                  <span className={b('text')}>{locale.youWonJackpot}</span>
                  <div className={b('amount-container')}>
                    <div className={b('amount')}>{amount}</div>
                  </div>
                  <div className={b('button-wrapper')}>
                    <Button text={locale.pickUp} callback={hideNotify(currId)} colorType="primary" uppercase />
                  </div>
                </div>
              </div>
            </div>
          )
        }),
    [locale, personalJackpotNotify, temporarilyClosedJackpots],
  )

  return (
    <>
      {isAuth ? <PersonalJackpotPolling /> : null}
      {items}
    </>
  )
}

export default PersonalJackpotNotify
