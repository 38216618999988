import { combineReducers } from 'redux'

import { makeCommunicationByIdReducer } from 'shared/utils/communication/reducer/makeCommunicationByIdReducer'
import { makeCommunicationReducer } from 'shared/utils/communication/reducer/makeCommunicationReducer'

import * as actions from '../actions'
import { ISlotsState } from '../types'

export default combineReducers<ISlotsState['communications']>({
  getGameList: makeCommunicationByIdReducer(actions.getGameList),
  getSessionUrl: makeCommunicationReducer(actions.getSessionUrl),
  addFavoriteSlot: makeCommunicationReducer(actions.addFavoriteSlot),
  removeFavoriteSlot: makeCommunicationReducer(actions.removeFavoriteSlot),
})
