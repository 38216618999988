import { IApplicationState } from 'setup/store'

export const selectOpenedFrames = (state: IApplicationState) => state.frameManager.data.openedFrames

export const selectCountFrames = (state: IApplicationState) => state.frameManager.data.countFrames

export const selectFramesCommunication = (state: IApplicationState) => state.frameManager.communications.getFrameSession

export const selectFrameSessionLoading = (state: IApplicationState) =>
  Object.values(state.frameManager.communications.getFrameSession).some(t => t?.isLoading)
