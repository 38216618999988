export const deleteEmptyFields = filterObj => {
  let newObj = {}
  Object.entries(filterObj).forEach(([key, value]) => {
    if (value && value !== null) {
      newObj = {
        ...newObj,
        [key]: value,
      }
    }
  })

  return newObj
}
