import React, { useCallback, useContext, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import block from "bem-cn";
import { Formik } from "formik";
import google from "shared/img/google.svg";
import telegram from "shared/img/telegram.svg";

import { MODAL_ROUTE_URLS } from "router/modalRoutes";

import { mediaSize } from "shared/style/var";

import { actions as authActions } from "features/auth";
import {
  selectAuthCommunication,
  selectIsUserAuthenticated,
} from "features/auth/selectors";
import { selectLocaleDict } from "features/locale/selectors";
import { changeOpenSupportForm } from "features/supportForm/actions";

import { ModalContext } from "components/HOC/withModalQuery/ModalContext";

import Button from "components/UIKit/Button/Button";
import { Input } from "components/UIKit/Input/Input";

import {
  StyledAuthForm,
  StyledAuthInputWrapper,
  StyledAuthModal,
} from "../styles/AuthStyles";

import "./SignIn.scss";

const b = block("sign-in");

const SignIn: React.FC = () => {
  const dispatch = useDispatch();
  const { replaceLastModal, closeModal } = useContext(ModalContext);
  const isMobileOrTablet = useMediaQuery({ maxWidth: mediaSize.tablet });

  const { isLoading } = useSelector(
    selectAuthCommunication,
    shallowEqual
  ).login;
  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual);
  const locale = useSelector(selectLocaleDict, shallowEqual).auth;
  const localeCommon = useSelector(selectLocaleDict, shallowEqual).common;

  const [state] = useState({
    username: "",
    password: "",
  });

  useEffect(() => {
    if (isMobileOrTablet) {
      closeModal(MODAL_ROUTE_URLS.SIGN_IN())();
    }
  }, [isMobileOrTablet, closeModal]);

  const onSubmit = useCallback(
    (values: typeof state) => {
      dispatch(
        authActions.usernameSignIn(values, {
          callback: closeModal(MODAL_ROUTE_URLS.SIGN_IN()),
        })
      );
    },
    [dispatch, isAuth]
  );

  const handleSupportClick = useCallback(() => {
    dispatch(changeOpenSupportForm(true));
  }, [dispatch]);

  return (
    <StyledAuthModal>
      <div className={b()}>
        <div className={b("header buttonLogin")}>{locale.login}</div>
        <Formik
          initialValues={{ username: state.username, password: state.password }}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, handleBlur, handleSubmit }) => (
            <StyledAuthForm
              style={{ alignItems: "flex-start" }}
              onSubmit={handleSubmit}
            >
              <StyledAuthInputWrapper
                style={{ marginLeft: 0 }}
                className={b("input-wrapper")}
              >
                <span className={b("label")}>{`${locale.emailAddress}`}</span>
                <Input
                  type="text"
                  name="username"
                  placeholder={`${locale.username}`}
                  value={values.username}
                  onChange={handleChange}
                  isRequired
                  onBlur={handleBlur}
                  height="40px"
                />
              </StyledAuthInputWrapper>
              <StyledAuthInputWrapper style={{ marginLeft: 0 }}>
                <span className={b("label")}>{`${locale.password} *`}</span>
                <Input
                  name="password"
                  type="password"
                  placeholder={`${locale.password}`}
                  value={values.password}
                  isRequired
                  onChange={handleChange}
                  onBlur={handleBlur}
                  height="40px"
                />
              </StyledAuthInputWrapper>
              <div
                onClick={replaceLastModal(MODAL_ROUTE_URLS.FORGOT_PASSWORD())}
                className={b("registration-link", { password: true })}
              >
                {locale.forgotPassword}
              </div>
              <div className={b("bottom-content")}>
                <div className={b("button-container")}>
                  <Button
                    type="submit"
                    text={localeCommon.login}
                    colorType="gradient"
                    disabled={isLoading}
                    loading={isLoading}
                    rounded
                  />
                </div>
              </div>
              <div style={{ color: "rgba(151, 170, 192, 1)" }}>
                {locale.dontHaveAccount}{" "}
                <span className="cursor-pointer" style={{ color: "white" }}>{locale.signUp}</span>
              </div>
              <div className="line-container">
                <span className="text">Or</span>
                <hr className="line" />
              </div>
              <div className="oauth">
                <div>
                  <img src={google} width={15} />
                  Google
                </div>
                <div>
                  {" "}
                  <img src={telegram} width={15} />
                  Telegram
                </div>
              </div>
            </StyledAuthForm>
          )}
        </Formik>
      </div>
    </StyledAuthModal>
  );
};

export default SignIn;
