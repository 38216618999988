import React, { useEffect, useRef } from 'react'
import block from 'bem-cn'
import screenfull from 'screenfull'

interface SlotIFrameProps {
  sessionUrl: string
  isFullscreen: boolean
  onExitFullScreen: () => void
}
const b = block('slot-iframe')
const SlotIFrame: React.FC<SlotIFrameProps> = ({ sessionUrl, isFullscreen, onExitFullScreen }) => {
  const frameRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    if (isFullscreen && screenfull.isEnabled && frameRef?.current) {
      screenfull.request(frameRef.current as unknown as Element)
      onExitFullScreen()
    }
  }, [isFullscreen, onExitFullScreen])

  return (
    <iframe ref={frameRef} title="slots-frame" className={b('iframe')} width="100%" height="100%" src={sessionUrl} />
  )
}

export default SlotIFrame
