import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'

import { ROUTE_PATHS } from '../../router/routes'
import Filter from './components/Filter/Filter'
import MyGames from './components/MyGames/MyGames'
import SlotFrame from './components/SlotFrame/SlotFrame'
import SlotsLayout from './components/SlotsLayout/SlotsLayout'

export class SlotsModule {
  getRoutes() {
    return (
      <Route key="slots" path={ROUTE_PATHS.SLOTS}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Switch>
            <Route key={ROUTE_PATHS.SLOTS_FILTER} exact path={ROUTE_PATHS.SLOTS_FILTER} component={Filter} />
            <Route key={ROUTE_PATHS.SLOTS_MY_GAMES} exact path={ROUTE_PATHS.SLOTS_MY_GAMES} component={MyGames} />
            <Route
              key={ROUTE_PATHS.SLOTS_PROVIDER_FREE_SPIN}
              exact
              path={ROUTE_PATHS.SLOTS_PROVIDER_FREE_SPIN}
              component={SlotFrame}
            />
            <Route
              key={ROUTE_PATHS.SLOTS_PROVIDER_DEMO_START}
              exact
              path={ROUTE_PATHS.SLOTS_PROVIDER_DEMO_START}
              component={SlotFrame}
            />
            <Route key={ROUTE_PATHS.SLOTS_PROVIDER} exact path={ROUTE_PATHS.SLOTS_PROVIDER} component={SlotFrame} />
            <Route exact path={ROUTE_PATHS.SLOTS_GAMETYPE}>
              <SlotsLayout />
            </Route>
            <Route exact path={ROUTE_PATHS.SLOTS}>
              <Redirect to={ROUTE_PATHS.SLOTS_CASINO} />
            </Route>
          </Switch>
        </QueryParamProvider>
      </Route>
    )
  }
}
