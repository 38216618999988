/* eslint-disable max-len */
import { projectDomainName } from 'shared/constants'

export const common: { [key: string]: string } = {
  minAmountIs: 'Minimal amount is $AMOUNT',
  save: 'Save',
  selectedItems: 'items selected',
  selectAll: 'Select all',
  expander: 'Expander',
  parisSportif: 'Paris sportif',
  info: 'Info',
  virtuals: 'Virtual Games',
  mainBets: 'Main bet',
  total: 'Total',
  whatsApp: 'WhatsApp',
  handicap: 'Handicap',
  doubleChance: 'Double chance',
  loading: 'Loading',
  admin: 'Admin panel',
  results: 'Results',
  search: 'Search',
  contacts: 'Contacts',
  partners: 'Partners',
  terms: 'Terms',
  termsConditions: 'Terms & Conditions',
  yes: 'Yes',
  no: 'No',
  amount: 'Amount:',
  myGames: 'My games',
  copy: 'Copy',
  line: 'Sport',
  liveSport: 'Live sport',
  live: 'Live',
  sport: 'Sport',
  prematch: 'Prematch',
  soccer: 'Soccer',
  iceHockey: 'Ice hockey',
  slots: 'Games',
  betOnCasino: 'Bet on Casino',
  casinoGames: 'Casino Games',
  sportBetting: 'Sport betting',
  betgames: 'Betgames',
  casinolive: 'Livecasino',
  language: 'Language',
  products: 'Products',
  about: 'About',
  help: 'Help',
  promotions: 'Promotions',
  footerRights: 'All rights reserved',
  cashout: 'Cashout',
  KYC: 'AML & KYC Policies',
  FAQ: 'FAQ',
  footerText: 'The maximum win per spin is 100,000 EUR',
  beResponsible: 'Be responsible and follow your gaming habits!',
  upbutton: 'to top',
  authorization: 'Sign In',
  registration: 'Register',
  myBets: 'My bets',
  login: 'Login',
  topUp: 'Deposit',
  userInfo: 'Personal data',
  withdrawal: 'Withdrawal money',
  betHistory: 'Bet history',
  slotsHistory: 'Slots history',
  virtualGames: 'Virtual games',
  newVirtuals: 'New virtuals',
  payHistory: 'Operations history',
  present: 'Bonuses',
  phone: 'Dowload application',
  logout: 'Log out',
  promotionalCodes: 'Activate promocode',
  odd: 'Odds',
  decimal: 'Decimal',
  american: 'American',
  fractional: 'Fractional',
  download: 'Download',
  mobileApplication: 'Mobile application',
  oneClick: 'In 1 click',
  main: 'Main',
  home: 'Home',
  affiliate: 'Affiliate',
  topLeagues: 'Top leagues',
  upcomingEvents: 'Upcoming events',
  favorite: 'Favorite',
  esport: 'Esport',
  casino: 'Casino',
  liveCasino: 'Live casino',
  basket: 'Bet slip',
  bonuses: 'Bonuses',
  downloadApp: 'Download app',
  information: 'Information',
  settings: 'Settings',
  accountSettings: 'Account settings',
  ticket: 'Ticket',
  promocodes: 'Promocodes',
  paymentMethods: 'Payment methods',
  aboutUs: 'About us',
  close: 'Close',
  coef: 'Odd',
  quest: 'About us',
  cybersport: 'Cybersport',
  totalizator: 'Totalizator',
  phoneCode: 'Check your phone end enter code',
  submit: 'Change password',
  newPass: 'Enter new password',
  serve: 'Serve',
  continue: 'Save and continue',
  enterLogin: 'Enter your phone or email',
  confirmPass: 'Enter password again',
  feedbackPhone: 'Feedback phone',
  support: 'Support',
  support24: 'Support 24/7',
  skillGames: 'Skill games',
  legalNotice: 'Legal Notice',
  otherGames: 'Other Games',
  virtualSport: 'Virtual sport',
  tvGames: 'TV Games',
  ourCashiers: 'Our box office',
  ourSocials: 'Our socials',
  license: `PASSIONBET N.V.
  Registration number: 142633
  Abraham de Veerstraat 7, Willemstad, Curacao`,
  onСooperation: 'On cooperation',
  onlineChat: 'Online chat',
  aboutUsTitle: 'About us',
  emptyFullEvent: 'This event has ended',
  inDeveloping: 'In developing',
  signIn: 'Login',
  send: 'Send',
  delete: 'Delete',
  sendUsFeedBack: 'Send us feedback form',
  termsAndConditions: 'Terms and conditions',
  resposibleGamingStatement: 'Resposible gaming statement',
  privacyPolicy: 'Privacy policy',
  todays: 'Todays',
  revenueReport: 'Revenue Report',
  accountList: 'Account List',
  newUser: 'New User',
  transfers: 'Transfers',
  bets: 'Bets',
  sportsBetting: 'Sports betting',
  betSlip: 'Bet slip',
  liveBetting: 'Live betting',
  myFavorites: 'My favorites',
  recoveryPassword: 'Recovery password',
  bc: 'New Pari Sportif',
  newPassword: 'New password',
  highlights: 'Highlights',
  mostPlayedEvents: 'Most played events',
  lastMinuteEvents: 'Last minute events',
  today: 'Today',
  langSelection: 'Language selection',
  info4characters:
    'Field cannot be empty. At least 4 characters must be entered. Special characters are not allowed...',
  typeSearchTerm: 'Type search term',

  siteStreet: 'Heelsumstraat 51 E-Commerce Park',
  siteCity: 'Willemstad',
  curacao: 'Curaçao',
  contactsText1:
    'Sports betting is associated with problem gambling. Play responsibly. As a player, you are required to comply with the norms and laws of government institutions and authorities in your country.',
  contactsText3: 'Minors are prohibited from participating in sports betting or other games on this website.',
  contactsText4: 'All information on this website is subject to change at any time without prior notice.',

  aboutUsText1:
    'Sports betting is associated with problem gambling. Play responsibly. As a player, you are required to comply with the norms and laws of government institutions and authorities in your country.',
  aboutUsText3: 'Minors are prohibited from participating in sports betting or other games on this website.',
  aboutUsText4: 'All information on this website is subject to change at any time without prior notice.',
  aboutUsText3List: [
    'That he/she is at least 18 years old. In case of any doubt the bookmaker has the right to request a proof of identity.',
    'That he/she does not have any prior knowledge of the outcome of the particular event for which the bet is placed.',
    'That the means used for the betting stake do not originate in any kind of legally forbidden action, and that these means are at his/her unlimited disposal.',
    'That the online bet is placed at the location where his/her personal computer is (the IP address is decisive in this case). The bookmaker always accepts bets from this site and reserves the right to cancel bets without giving any reasons if the placement of sport bets violates any regulations at this location.',
  ],
  aboutUsText5: '5. The betting contract is concluded as soon as the bookmaker has accepted a betting offer.',
  aboutUsText6:
    'For the interpretation of the Terms of Contract, the recordings of the bookmaker apply. When the betting slip is handed over to the betting customer, the betting customer accepts the correctness of the slip when he/she accepts it without any objection. Complaints made later will not be accepted.',
  aboutUsText7:
    'The bookmaker has the right to voluntarily correct typos, calculation errors and odds at any time and also after the conclusion of a contract. The bookmakers right to appeal due to a mistake remains unaffected.',
  aboutUsText8:
    'If it should become clear after the conclusion of a betting contract that the terms of the betting contract are, for whatever reason, neither determined nor determinable, then the bet is void and the betting stake must be refunded.',
  aboutUsText9:
    '6. The betting customers independent right of revocation after the conclusion of a betting contract is excluded.',
  setLanguage: 'Set language',

  gamingHistory: 'Gaming history',
  logToPlaceBet: 'To place bets, you need to be logged in',
  enableScrolling: 'Enable Scrolling',
  disableScrolling: 'Disable Scrolling',
  balance: 'Balance',
  bonusBalance: 'Bonus balance',
  accountStatement: 'Account statement',
  company: 'Company',
  bettingRules: 'Betting rules',
  responsibleGaming: 'Responsible gaming',
  username: 'Username',

  website: 'Website',
  description: 'Description',
  id: 'Id',
  status: 'Status',
  name: 'Website name',
  link: 'Website url',
  addWebsite: 'Add Website',

  changeWebsiteInfo: 'Change Website Info',
  deleteWebsite: 'Delete Website',
  areYouWantDelete: 'Are you want delete this website?',

  dateJoined: 'Date joined',
  balanceCurrency: 'Balance currency',
  isBanned: 'Is banned',
  profitFromAgent: 'Profit From Agent',

  contactsText: 'If you have any questions or problems, write to us on Facebook or Whatsapp',
  userData: 'User data',
  crash: 'Crash',
  evoAutoGames: 'Evo auto games',
  setOdds: 'Set odds',
  sessionExpired: 'Session expired!',
  sessionExpiredText: 'Your session has expired because you have been idle for at least 20 minutes on this page',
  availableToWithdraw: 'Available to withdraw',
  yesterday: 'Yesterday',
  currentMonth: 'Current month',
  thirtyDays: '30 Days',
  summaryStatistic: 'Summary statistic',
  filter: 'Filter',
  commision: 'Commision',
  deposits: 'Deposits',
  registrations: 'Registrations',
  mainMenu: 'Main menu',
  profilePage: 'Profile page',
  mainPage: 'Main page',
  websites: 'Websites',
  marketingTools: 'Marketing tools',
  promoCodes: 'Promo codes',
  affiliateLinks: 'Affiliate links',
  childStatistic: 'Child statistic',
  reports: 'Reports',
  fullReport: 'Full report',
  playerReport: 'Player report',

  action: 'Action',
  copied: 'Copied!',

  ftext1:
    'Partners-1xBet uses cookies to enhance your website experience. By continuing to browse the Partners-1xBet website, you consent to the use of these cookies.',
  ffindOut: 'Find out more',
  ftext2: 'If you have any issues with the website, you can contaсt us using this',
  fform: 'form',
  brouchure: 'Brouchure',
  news: 'News',
  cookiePolicy: 'Cookie policy',
  appAndroid: 'App for Android™',
  copyright: 'Copyright © 2007-2022 «Partners-1xBet». All rights reserved.',

  dateFrom: 'Date from',
  dateTo: 'Date to',

  depositsCount: 'Deposits count',
  depositsSum: 'Deposits sum',
  revenueShare: 'Revenue share',
  currency: 'Currency',
  howItWorks: 'How it works',
  register: 'Register',
  fastAndSimple: 'Fast and easy',
  topUpYouAccount: 'Top up your account',
  haveFunWithBonuses: 'Have fun with bonuses',
  play: 'Play',
  overThanGames: 'Over 3500 games',
  fastPayouts: 'Fast payouts',
  getPayoutLessThan: 'Get paid in less than an hour',
  showMore: 'Show more',
  meetBgonzo: 'Meet BGonzo!',
  more: 'More',
  meetGonzos: 'Meet Gonzos',
  learnMore: 'Learn more',
  joinGonzos: 'JOIN GONZOS AND PLAY',
  regulations: 'Regulations',
  topCasino: 'Top casino games',
  rules: 'Rules',
  games: 'Games',
  betOnGames: 'Bet on games',
  hot: 'Hot',

  comingSoon: 'Coming soon',
  promo: 'Promo',
  great: 'Great',
}
