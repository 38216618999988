import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import block from 'bem-cn'

import { ReactComponent as SearchSVG } from 'shared/img/slots/search.svg'

import { selectLocaleDict } from 'features/locale/selectors'

import './EmptySearchResult.scss'

const b = block('empty-search')

export const EmptySearchResult: React.FC = () => {
  const locale = useSelector(selectLocaleDict, shallowEqual).slots

  return (
    <div className={b()}>
      <SearchSVG className={b('icon')} />
      <h3 className={b('title')}>{locale.emptySearch}</h3>
    </div>
  )
}
