import React, { useEffect, useState } from 'react'
import block from 'bem-cn'

import { ReactComponent as ArrowIcon } from 'shared/img/arrow2.svg'

import './ScrollToTop.scss'

interface IScrollToTopProps {
  scrollRef: React.RefObject<HTMLDivElement> | null
}

const b = block('scroll-to-top')

export const ScrollToTop: React.FC<IScrollToTopProps> = ({ scrollRef }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    scrollRef?.current?.addEventListener('scroll', () => {
      const scrolledValue = scrollRef?.current?.scrollTop || 0

      if (scrolledValue > 300) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    })
  }, [scrollRef])

  const scrollToTopHandler = () => scrollRef?.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

  return (
    <div className={b()}>
      <div className={b('button', { isHidden: !isVisible })} onClick={scrollToTopHandler}>
        <ArrowIcon className={b('button-arrow')} />
      </div>
    </div>
  )
}
