import React, { Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import block from 'bem-cn'

import { MODAL_ROUTE_PATH, MODAL_ROUTE_URLS } from 'router/modalRoutes'

import { mediaSize } from 'shared/style/var'
import { getRouteParamsByStrings } from 'shared/utils/getRouteParamsByStrings'

import { ModalContext } from 'components/HOC/withModalQuery/ModalContext'

import Spinner from 'components/Spinner'

import { ISideMenuLinks, sideMenuLinks } from './data'
import { ProfileSideMenu } from './SideMenu/ProfileSideMenu'

import './ProfileDesktop.scss'

interface IProps {
  path: string
}

interface ITab {
  tab: string
}

const b = block('profile-desktop')

export const ProfileDesktop: React.FC<IProps> = ({ path }) => {
  const params = getRouteParamsByStrings<ITab>(MODAL_ROUTE_PATH.PROFILE_DESKTOP, path)!.tab

  const [selectedLink, setSelectedLink] = useState<ISideMenuLinks | null>(null)

  const { replaceLastModal, closeModal } = useContext(ModalContext)

  const isMobileOrTablet = useMediaQuery({ maxWidth: mediaSize.tablet })

  useEffect(() => {
    if (isMobileOrTablet) {
      closeModal(MODAL_ROUTE_URLS.PROFILE_DESKTOP(params))()
    }
  }, [isMobileOrTablet])

  useEffect(() => {
    if (sideMenuLinks.find(link => link.title === params) !== undefined) {
      setSelectedLink(sideMenuLinks.find(link => link.title === params)!)
    } else setSelectedLink(sideMenuLinks[0])
  }, [params])

  const handleLinkClick = useCallback(
    (link: ISideMenuLinks) => {
      replaceLastModal(MODAL_ROUTE_URLS.PROFILE_DESKTOP(link.title))()
    },
    [replaceLastModal],
  )

  const componentToRender = useMemo(() => {
    if (selectedLink) {
      const Component = selectedLink?.Component
      return (
        <Suspense fallback={<Spinner isLoading />}>
          <Component />
        </Suspense>
      )
    }
    return <Spinner isLoading />
  }, [selectedLink, isMobileOrTablet])

  return (
    <div className={b('wrapper')}>
      <ProfileSideMenu selectedLink={selectedLink} handleLinkClick={handleLinkClick} />
      <div className={b('content-container')}>{componentToRender}</div>
    </div>
  )
}
