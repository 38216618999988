import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { actions as userSettingsActions } from 'features/userSettings'
import { selectLanguage } from 'features/userSettings/selectors'

const SetAppLanguage: React.FC = () => {
  const dispatch = useDispatch()

  const lang = useSelector(selectLanguage, shallowEqual)

  useEffect(() => {
    dispatch(userSettingsActions.changeLang(lang))
  }, [lang])

  return null
}

export default SetAppLanguage
