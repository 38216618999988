import React, { useCallback, useContext } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import block from 'bem-cn'

import { MODAL_ROUTE_URLS } from 'router/modalRoutes'

import logoImg from 'shared/img/logo.png'

import { selectLocaleDict } from 'features/locale/selectors'

import { ModalContext } from 'components/HOC/withModalQuery/ModalContext'

import Button from 'components/UIKit/Button/Button'

import {
  StyledAuthBody,
  StyledAuthForm,
  StyledAuthLogo,
  StyledAuthModal,
  StyledButtonCointaner,
} from '../styles/AuthStyles'

import './VerifyEmail.scss'

const b = block('verify-email')

export const VerifyEmail = () => {
  const { replaceLastModal } = useContext(ModalContext)
  const locale = useSelector(selectLocaleDict, shallowEqual).auth
  const localeCommon = useSelector(selectLocaleDict, shallowEqual).common

  const onGoToLogin = useCallback(() => {
    replaceLastModal(MODAL_ROUTE_URLS.SIGN_IN())()
  }, [replaceLastModal])

  return (
    <StyledAuthModal>
      <div className={b('header')}>{localeCommon.great}</div>

      <StyledAuthForm>
        <StyledAuthBody>{locale.verifyEmailSuccess}</StyledAuthBody>
        <StyledButtonCointaner>
          <Button callback={onGoToLogin} colorType="primary" text={locale.signIn} />
        </StyledButtonCointaner>
      </StyledAuthForm>
    </StyledAuthModal>
  )
}
