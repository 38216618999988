import React, { useCallback, useContext, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import block from 'bem-cn'

import { usePromotionsCount } from 'shared/hooks/usePromotionsCount'
import { useMediaMaxTablet } from 'shared/style/var'

import { selectLocaleDict } from 'features/locale/selectors'
import { changeOpenedLeftSidePanel } from 'features/sidePanel/actions'

import { useModalContext } from 'components/HOC/withModalQuery/ModalContext'

import PromotionsPolling from 'components/pollings/PromotionsPolling/PromotionsPolling'
import Sidebar from 'components/Sidebar/Sidebar'

import {
  fourthItemsGroup,
  headerItems,
  IMenuItem,
  // secondItemsGroup,
  thirdItemsGroup,
  tvGamesItemsGroup,
  virtualItemsGroup,
} from '../data/links/mainMenu'

import './MainMenu.scss'

const b = block('main-menu')
const b1 = block('header-links')
const b2 = block('links-group')
const b3 = block('single-links')

export const MainMenu: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const isMobile = useMediaMaxTablet()
  const { pushNewModal } = useModalContext()

  const locale = useSelector(selectLocaleDict, shallowEqual).common

  const { count: promosCount } = usePromotionsCount()

  const pushLink = useCallback(
    (link: string, news?: boolean, modalLink?: string) => {
      if (news) {
        window.open(link)
      }

      if (modalLink && isMobile) {
        pushNewModal(modalLink)()
      } else {
        history.push(link)
      }

      dispatch(changeOpenedLeftSidePanel(false))
    },
    [dispatch, history, pushNewModal],
  )

  const headerLinks = useMemo(
    () => (
      <div className={b1()}>
        {headerItems.map((item, index) => (
          <div className={b1('link')} key={index} onClick={() => pushLink(item.link)}>
            {item.title === 'promotions' ? (
              <>
                <PromotionsPolling />
                {promosCount ? <span className={b1('link-count')}>{promosCount}</span> : null}
              </>
            ) : null}
            <div className={b1('image')}>
              <i className="dynamic_icon">{item?.image}</i>
            </div>
            <span>{locale[item.title] || item.title}</span>
          </div>
        ))}
      </div>
    ),
    [locale, pushLink, promosCount],
  )

  const linksGroup = useMemo(
    () => (group: IMenuItem) =>
      (
        <div className={b2()}>
          {group.name && (
            <span className={b2('title', { name: group.name })}>
              {locale[group.name].toUpperCase() || group.name.toUpperCase()}
            </span>
          )}
          <div className={b2('links')}>
            {group.items.map((item, index) => (
              <div
                className={b2('link')}
                key={index}
                onClick={() => pushLink(item.link, item.title === 'news', item.modalLink)}>
                {item.image && (
                  <div className={b2('image')}>
                    <i className="dynamic_icon">{item?.image}</i>
                  </div>
                )}
                <span>{locale[item.title] || item.title}</span>
                {item.isHot ? <span className={b2('hot')}>{locale.hot}</span> : null}
              </div>
            ))}
          </div>
        </div>
      ),
    [pushLink, locale],
  )

  return (
    <div className={b('wrapper')}>
      {/* {headerLinks}
      {linksGroup(thirdItemsGroup)}
      {linksGroup(tvGamesItemsGroup)}
      {linksGroup(virtualItemsGroup)}
      {linksGroup(fourthItemsGroup)} */}
      <Sidebar />
    </div>
  )
}
