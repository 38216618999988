import dayjs from 'dayjs'

import {
  IPrizeItem,
  IPrizeItemDTO,
  IPublicSettings,
  IPublicSettingsDTO,
  ISpinHistoryDTO,
  ISpinHistoryItem,
  ISpinWheel,
  ISpinWheelDTO,
} from 'shared/models/Wheel/Wheel.types'

export class WheelGameConverter {
  convertSpinHistory = (data: ISpinHistoryDTO[]): ISpinHistoryItem[] =>
    data.map(t => ({
      id: t.id,
      date: dayjs(t.created_at).format('DD/MM/YY HH:mm'),
      type: 'spin',
      winAmount: t.win_amount,
      betAmount: t.bet_amount,
      isFree: t.is_free,
      prizeName: t.prize_name,
      userBalanceAfter: t.user_balance_after,
      userBalanceBefore: t.user_balance_before,
      userId: t.user_id,
    }))

  convertPrizesList = ({ data, currency }: { data: IPrizeItemDTO[]; currency: string }): IPrizeItem[] =>
    data
      .map(t => ({
        id: t.id,
        amount: t.amount,
        isPresent: t.is_present,
        name: t.name,
        currency: t.currency,
      }))
      .filter(t => t.currency === currency || t.isPresent)

  convertPublicSettings = (data: IPublicSettingsDTO): IPublicSettings => ({
    freeSpinTime: data.free_spin_time,
    paidSpinCost: data.paid_spin_cost,
    freeSpinMinBalance: data.free_spin_min_balance,
    paidSpinMinBalance: data.paid_spin_min_balance,
    freeSpinsAvailable: data.free_spins_available,
    hasFreeSpin: data.free_spins_available > 0,
  })

  convertSpinWheel = (data: ISpinWheelDTO): ISpinWheel => ({
    name: data.name,
    amount: data.amount,
    isPresent: data.is_present,
    currency: data.currency,
  })
}
