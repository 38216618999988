import { createAction } from '@reduxjs/toolkit'

import { defaultCurrency } from 'shared/constants'
import { ISpinWheel, ISpinWheelData } from 'shared/models/Wheel/Wheel.types'
import { makeCommunicationActionCreator } from 'shared/utils/communication/actions/makeCommunicationActionCreator'

import { addNotify } from 'components/Notify'

export const getSpinHistory = makeCommunicationActionCreator({
  loading: '@wheelGame/GET_SPIN_HISTORY_LOADING',
  success: '@wheelGame/GET_SPIN_HISTORY_SUCCESS',
  error: '@wheelGame/GET_SPIN_HISTORY_ERROR',
  reset: '@wheelGame/GET_SPIN_HISTORY_RESET',
})(
  async ({
    deps: {
      extra: { api },
    },
  }) => {
    const response = await api.wheelGame.getSpinHistory()
    return response.data
  },
)

export const getPrizesList = makeCommunicationActionCreator({
  loading: '@wheelGame/GET_PRIZES_LIST_LOADING',
  success: '@wheelGame/GET_PRIZES_LIST_SUCCESS',
  error: '@wheelGame/GET_PRIZES_LIST_ERROR',
  reset: '@wheelGame/GET_PRIZES_LIST_RESET',
})(
  async ({
    deps: {
      extra: { api },
      getState,
    },
  }) => {
    const currency = getState().auth.data.user?.currency || defaultCurrency
    const response = await api.wheelGame.getPrizesList({ currency })
    return response.data
  },
)

export const getPublicSettings = makeCommunicationActionCreator({
  loading: '@wheelGame/GET_PUBLIC_SETTINGS_LOADING',
  success: '@wheelGame/GET_PUBLIC_SETTINGS_SUCCESS',
  error: '@wheelGame/GET_PUBLIC_SETTINGS_ERROR',
  reset: '@wheelGame/GET_PUBLIC_SETTINGS_RESET',
})(
  async ({
    deps: {
      extra: { api },
    },
  }) => {
    const response = await api.wheelGame.getPublicSettings()
    return response.data
  },
)

export const spinWheel = makeCommunicationActionCreator({
  loading: '@wheelGame/SPIN_WHEEL_LOADING',
  success: '@wheelGame/SPIN_WHEEL_SUCCESS',
  error: '@wheelGame/SPIN_WHEEL_ERROR',
  reset: '@wheelGame/SPIN_WHEEL_RESET',
})<ISpinWheelData, ISpinWheel>(
  async ({
    deps: {
      extra: { api },
      dispatch,
    },
    payload,
  }) => {
    const response = await api.wheelGame.spinWheel(payload)
    dispatch(getPublicSettings())
    return response.data
  },
)

export const clearSpinInfo = createAction('@wheelGame/CLEAR_SPIN_INFO')
