import React from 'react'

import BalancePolling from './BalancePolling/BalancePolling'
import LimitsPolling from './LimitsPolling/LimitsPolling'

export const AppGlobalPolling = () => (
  <>
    <BalancePolling />
    <LimitsPolling />
  </>
)
