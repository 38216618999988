import React, { useCallback, useContext, useRef, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import block from 'bem-cn'

import { MODAL_ROUTE_URLS } from 'router/modalRoutes'
import { ROUTE_URLS } from 'router/routes'

import { ReactComponent as GiftIcon } from 'shared/img/gift.svg'
import logoImg from 'shared/img/logo.png'
import { ReactComponent as WhatsAppIcon } from 'shared/img/whatsapp-svg.svg'

import { whatsappLink } from 'shared/constants'
import { useClickOutside } from 'shared/hooks/useClickOutside'
import { useDate } from 'shared/hooks/useDate'
import { usePromotionsCount } from 'shared/hooks/usePromotionsCount'
import { ProfileTabs } from 'shared/models/Profile'
import { mediaSize } from 'shared/style/var'

import { selectIsUserAuthenticated, selectUserData } from 'features/auth/selectors'
import { selectCountFrames } from 'features/frameManager/selectors'
import { selectLocaleDict } from 'features/locale/selectors'

import { ModalContext } from 'components/HOC/withModalQuery/ModalContext'

import PromotionsPolling from 'components/pollings/PromotionsPolling/PromotionsPolling'
import SetLanguage from 'components/SetLanguage/SetLanguage'
import Button from 'components/UIKit/Button/Button'
import WheelButton from 'components/WheelGame/WheelButton/WheelButton'
import { TextField } from '@mui/material'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Search from '@mui/icons-material/Search'
import logo from '../../shared/img/sidebar/logo.svg'

import { ProfileDropdown } from './ProfileDropdown/ProfileDropdonwn'

import './Header.scss'

const b = block('header-block')
const b2 = block('isAuth-button')

export const Header: React.FC = () => {
  const isMobileOrTablet = useMediaQuery({ maxWidth: mediaSize.tablet })
  const { pushNewModal } = useContext(ModalContext)

  const locale = useSelector(selectLocaleDict, shallowEqual).common
  const localePayment = useSelector(selectLocaleDict, shallowEqual).payment
  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)
  const user = useSelector(selectUserData, shallowEqual)
  const isFrameOpen = useSelector(selectCountFrames, shallowEqual) > 0

  const profileDropdownRef = useRef(null)
  const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false)
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  useClickOutside(profileDropdownRef, () => setIsOpenDropdown(false))

  const { time, zone } = useDate()
  const { count: promosCount } = usePromotionsCount()
  const inputRef = useRef<any>()

  const handleIconClick = () => {
    inputRef.current.focus()
  }

  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen(!isSidebarOpen)
  }, [isSidebarOpen])

  const handleProfileClick = useCallback(() => {
    setIsOpenDropdown(!isOpenDropdown)
  }, [isOpenDropdown])

  if (isFrameOpen) return null

  return (
    <header className={b()}>
      {!isMobileOrTablet && (
        <OutlinedInput
          id="outlined-adornment-password"
          type="text"
          placeholder="Search"
          inputRef={inputRef}
          aria-label="Search"
          sx={{ backgroundColor: '#271937', color: 'white', height: 40, width: '35%' }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" edge="end" onClick={handleIconClick}>
                <Search color="primary" sx={{ color: 'white', height: 20, width: 20 }} />
              </IconButton>
            </InputAdornment>
          }
        />
      )}

      {isMobileOrTablet && (
        <div className={b('logo-container')}>
          <Link className={b('logo')} to={ROUTE_URLS.MAIN()}>
            <span>
              <img src={logo} alt="logo" />
              <span>spineo.io</span>
            </span>
          </Link>
        </div>
      )}

      <div className={b('promotions_wrapper')}>
        {/* <a className={b('promotions-link')} href={whatsappLink}>
          <WhatsAppIcon className={b('promotions-link-ico')} />

          {locale.whatsApp}
        </a> */}

        {isAuth && (
          <div
            className={b('promotions-link')}
            onClick={() => pushNewModal(MODAL_ROUTE_URLS.PROFILE_DESKTOP(ProfileTabs.BONUS_PROMOTIONS))()}>
            <PromotionsPolling />
            {promosCount ? <span className={b('promotions-link-count')}>{promosCount}</span> : null}

            <GiftIcon className={b('promotions-link-ico')} />
            {locale.promo}
          </div>
        )}
      </div>
      {/* <div className={b('wheel-container')}>
        <WheelButton />
      </div> */}
      <div className={b('login')}>
        <>
          <div className={b('login-button')}>
            <Button text={locale.login} callback={pushNewModal(MODAL_ROUTE_URLS.SIGN_IN())} uppercase />
          </div>
          <div className={b('login-button')}>
            <Button
              text={locale.register}
              callback={pushNewModal(MODAL_ROUTE_URLS.SIGN_UP())}
              colorType="primary"
              uppercase
              className="register-button"
            />
          </div>
        </>

        {/* <SetLanguage /> */}
      </div>
    </header>
  )
}
