import React from 'react'
import block from 'bem-cn'
import { AnimatePresence, motion, PanInfo } from 'framer-motion'

import './AnimationPromotionHOC.scss'

const b = block('animation-hoc')

const bonusNotificationVariants = {
  open: {
    top: 70,
    opacity: 1,
    y: '10%',
  },
  closed: {
    opacity: 0,
    top: 0,
    display: 'hidden',
    y: '-100%',
  },
}

interface IAnimationPromotionHOC {
  isShowBonusNotification: boolean
  handleFinishDrag: (info: PanInfo) => void
}

const AnimationPromotionHOC: React.FC<IAnimationPromotionHOC> = ({
  children,
  handleFinishDrag,
  isShowBonusNotification,
}) => {
  const onHandleFinishDrag = handleFinishDrag
  return (
    <AnimatePresence exitBeforeEnter>
      {isShowBonusNotification && (
        <motion.div
          initial="closed"
          drag="y"
          dragTransition={{ timeConstant: 100, power: 0.2 }}
          transition={{ duration: 0.4 }}
          dragElastic={0.2}
          dragConstraints={{ top: 0, bottom: 0 }}
          onDragEnd={(e, info) => {
            onHandleFinishDrag(info)
          }}
          animate={bonusNotificationVariants.open}
          exit={bonusNotificationVariants.closed}
          variants={bonusNotificationVariants}
          className={b('bonus-notification')}>
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default AnimationPromotionHOC
