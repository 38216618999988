import React, { useCallback, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import block from 'bem-cn'
import { AnimatePresence, motion } from 'framer-motion'

import { ReactComponent as StarSVG } from 'shared/img/slots/star.svg'

import useInfiniteVerticalScroll from 'shared/hooks/useInfiniteVerticalScroll'

import { ISlotViewItem } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'

import { selectIsUserAuthenticated } from 'features/auth/selectors'
import { selectLocaleDict } from 'features/locale/selectors'
import { changeOpenedRightSidePanel } from 'features/sidePanel/actions'

import Button from 'components/UIKit/Button/Button'

import SlotsIcon from '../SlotsIcon/SlotsIcon'
import { ReactComponent as FavoriteBigStar } from './img/favorite_big_star.svg'

import './SlotsByCategory.scss'

const b = block('slots-by-type')
const slotsPerRender = 10

interface ISlotsByCategory {
  category: string
  slots: ISlotViewItem[]
}

const SlotsByCategory: React.FC<ISlotsByCategory> = ({ category, slots }) => {
  const locale = useSelector(selectLocaleDict, shallowEqual).slots
  const localeAuth = useSelector(selectLocaleDict, shallowEqual).auth
  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)
  const dispatch = useDispatch()
  const { current, ref }: { current: ISlotViewItem[]; ref: any } = useInfiniteVerticalScroll({
    data: slots,
    loadingCount: slotsPerRender,
  })
  const handleOpenRightPanel = useCallback(() => {
    dispatch(changeOpenedRightSidePanel(true))
  }, [dispatch])
  const allSlots = useMemo(
    () => <ul className={b('slots')}>{current && current.map(slot => <SlotsIcon key={slot.gameId} slot={slot} />)}</ul>,
    [current],
  )
  const loading = useMemo(
    () => (
      <>
        {current.length !== slots.length && slots.length && slots.length > slotsPerRender && (
          <div ref={ref} className={b('loading')}>
            <span>{locale.loading}</span>
          </div>
        )}
      </>
    ),
    [current, locale, ref, slots],
  )
  if (!isAuth && category === 'favorite')
    return (
      <div className={b()}>
        <div className={b('favorite')}>
          <FavoriteBigStar className={b('favorite-svg')} />
          <div className={b('favorite-text')}>{locale.authFavorite}</div>
          <div className={b('favorite-button')}>
            <Button
              text={localeAuth.login.toUpperCase()}
              type="submit"
              variant="main"
              callback={handleOpenRightPanel}
            />
          </div>
        </div>
      </div>
    )
  if (category === 'favorite' && slots.length === 0)
    return (
      <div className={b()}>
        <div className={b('header')}>{category}</div>
        <div className={b('favorite', { add: true })}>
          <div className={b('favorite-text', { add: true })}>{locale.addFavoritesSlots}</div>
          <StarSVG className={b('favorite-svg', { add: true })} />
        </div>
      </div>
    )
  return (
    <div className={b()}>
      <div className={b('header')}>{category}</div>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1 }}>
          {allSlots}
          {loading}
        </motion.div>
      </AnimatePresence>
    </div>
  )
}

export default SlotsByCategory
