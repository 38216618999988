export const slots: { [key: string]: string } = {
  availableTwoGames: 'Parallel operation of two or more games for this game is not available',
  demoStart: 'Demo',
  youWin: 'You have won',
  sessionEnd: 'Session ended',
  slots: 'Casino',
  liveCasino: 'Live casino',
  casino: 'Casino',
  changeOrientation: 'Please change screen orientation',
  authFavorite: 'Log in to see all your Favorite game',
  goPlay: 'Go play',
  play: 'Play',
  freeSpins: 'Free spins',
  all: 'All',
  startPlay: 'Start play',
  showAll: 'Show all',
  virtualGames: 'Virtual games',
  evolution: 'Evolution',
  xpg: 'XPG',
  pleaseAuth: 'To use casino, please log in.',
  popular: 'Popular',
  roulette: 'Roulette',
  slotMachines: 'Slot machines',
  lotto: 'Lotto',
  cardGames: 'Card games',
  searchGames: 'Search games',
  showProviders: 'Show providers',
  goToCasino: 'Go to casino',
  search: 'Search',
  searchSlots: 'Search slots',
  allGames: 'All games',
  casinoGames: 'Casino games',
  slotSuccessfullyAdded: 'Slot successfully added',
  slotSuccessfullyRemoved: 'Slot successfully removed',
  evoAutoGames: 'Evo auto games',
  winner: 'Winner',
  favorites: 'Favorites',
  favorite: 'Favorite',
  mostPopular: 'Most popular',
  gameFilter: 'Game Filter',
  advancedSearch: 'Advanced search',
  categories: 'Categories',
  providers: 'Providers',
  reset: 'Reset',
  show: 'Show',
  themes: 'Themes',
  searchResults: 'Search Results',
  goBack: 'Go back',
  gamesFound: 'Games found',
  groupBy: 'Group by',
  seeMore: 'See more',
  addGame: '+ Add game',

  developer: 'Developer',
  nowPlaying: 'Now playing slots',
  searchGame: 'Search game',
  emptySearch: 'No search results',
  slotName: 'Slot name',

  new: 'New',
  fruits: 'Fruits',
  games: 'Games',
  race: 'Race',
  tableGames: 'Table Games',
  hot: 'Hot',
  wild: 'Wild',
  books: 'Books',
  jokers: 'Jokers',
  egypt: 'Egypt',
  asia: 'Asia',
  megaways: 'Megaways',
  ancient: 'Ancient',
  wolfs: 'Wolfs',
  dragonsAndTigers: 'Dragons And Tigers',
  luxury: 'Luxury',
  worldOfSea: 'World Of Sea',
  girls: 'Girls',
  oldSchool: 'Old School',
  luckyClower: 'Lucky Clower',
  battles: 'Battles',
  gangsta: 'Gangsta',
  sport: 'Sport',
  sweets: 'Sweets',
  fantasy: 'Fantasy',
  tvBet: 'TV Bet',

  pragmaticplay: 'Pragmaticplay',
  amatic: 'Amatic',
  pragmatic: 'Pragmatic',
  evoplay: 'EvoPlay',
  mascot: 'Mascot',
  microgaming: 'Microgaming',
  netent: 'Netent',
  tomhorn: 'Tomhorn',
  vivogaming: 'Vivogaming',
  igrosoft: 'Igrosoft',
  betsolutions: 'Betsolutions',
  kagaming: 'KAGaming',
  greentube: 'Greentube',
  gaminator: 'Gaminator',
  quickspin: 'Quickspin',
  wazdan: 'Wazdan',
  playtech: 'Playtech',
  apollo: 'Apollo',
  merkur: 'Merkur',
  kajot: 'Kajot',
  playson: 'Playson',
  booongo: 'Booongo',
  konami: 'Konami',
  aristocrat: 'aristocrat',
  igt: 'Igt',
  habanero: 'Habanero',
  spadegaming: 'Spadegaming',
  cqgaming: 'SqGaming',
  austria: 'Austria',
  fishing: 'Fishing',
  '1spin4win': 'Spin4win',
  '7mojos': 'Mojos',
  alg: 'Alg',
  provisiongaming: 'Provisiongaming',
  atmosfera: 'Atmosfera',
  belatra: 'Belatra',
  augustgaming: 'Augustgaming',
  blueprint: 'Blueprint',
  kiron: 'Kiron',
  spearhead: 'Spearhead',
  ezugi: 'Ezugi',
  gameart: 'Gameart',
  felixgaming: 'Felixgaming',
  fugaso: 'Fugaso',
  igtech: 'Igtech',
  bet2tech: 'Bet2tech',
  leander: 'Leander',
  goldenrace: 'Goldenrace',
  tvbet: 'Tvbet',
  kalamba: 'Kalamba',
  luckystreak: 'Luckystreak',
  mancala: 'Mancala',
  fazi: 'Fazi',
  mrslotty: 'Mrslotty',
  eagaming: 'Eagaming',
  netgame: 'Netgame',
  nucleus: 'Nucleus',
  onlyplay: 'Onlyplay',
  platipus: 'Platipus',
  swintt: 'Swintt',
  thunderkick: 'Thunderkick',
  yggdrasil: 'Yggdrasil',
  zillion: 'Zillion',
  learnMore: 'Learn More',
  firstSlideTitle: 'Rejoice in the coming of spring',
  firstSlideCasinoText: 'Become a winner and dive into water sports with prizes',
  secondSlideTitle: 'Biggest start-up bonus',
  secondSlideCasinoText:
    'Get 200 Winspins, up to €200 cashback and a special surprise every Thursday within a month of signing up!',
  thirdSlideTitle: 'First LIVE bingo show',
  thirdSlideCasinoText: 'Play MegaBola today!',
  showGames: 'Show Games',
  tryVpn: 'Try enabling vpn',
  yourFavoritesList: 'Your favorite slots list',
  addFavoritesSlots: 'Add them by clicking on:',
  addToFavorites: 'Add to favorites',
  removeFromFavorites: 'Remove from favorites',

  top: 'Top',
}
