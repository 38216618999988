import React, { useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { selectIsUserAuthenticated } from 'features/auth/selectors'
import { selectLocaleDict } from 'features/locale/selectors'
import { hideCashbackNotify } from 'features/notifies/actions'
import { selectNotifies } from 'features/notifies/selectors'

import CashbacksPolling from 'components/notifies/CashbackNotify/CashbacksPolling/CashbacksPolling'

import NotifyContainer from '../NotifyContainer/NotifyContainer'

const CashbackNotify = () => {
  const dispatch = useDispatch()

  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)
  const locale = useSelector(selectLocaleDict, shallowEqual).bonuses
  const cashbackNotify = useSelector(selectNotifies, shallowEqual).cashback

  const [temporarilyClosedCashbacks, setTemporarilyClosedCashbacks] = useState<number[]>([])

  const hideNotify = (id: number) => {
    dispatch(hideCashbackNotify(id))
  }

  const temporarilyHideNotify = (id: number) => {
    setTemporarilyClosedCashbacks(prev => [...prev, id])
  }

  const items = useMemo(
    () =>
      Object.keys(cashbackNotify.all)
        .filter(
          cashbackId =>
            !cashbackNotify?.hide?.includes(+cashbackId) && !temporarilyClosedCashbacks.includes(+cashbackId),
        )
        .map(cashback => {
          const currID = Number(cashback)
          const amount = `${cashbackNotify.all[currID]?.amount} ${cashbackNotify.all[currID]?.amountCurrency}`

          return (
            <NotifyContainer
              key={currID}
              title={locale.cashbackNotify}
              value={amount}
              callback={() => hideNotify(currID)}
              backgroundCallback={() => temporarilyHideNotify(currID)}
            />
          )
        }),
    [cashbackNotify, locale, temporarilyClosedCashbacks],
  )

  return (
    <>
      {isAuth ? <CashbacksPolling /> : null}
      {items}
    </>
  )
}

export default CashbackNotify
