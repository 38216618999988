import { AnyAction, applyMiddleware, combineReducers, compose, createStore, Reducer, Store } from 'redux'
import { persistStore } from 'redux-persist'
import { Persistor } from 'redux-persist/es/types'
import thunk from 'redux-thunk'

import { BaseHttpService } from 'services/api/BaseHttpService'

import * as AuthFeature from 'features/auth'
import * as BonusHistoryFeature from 'features/bonusHistory'
import * as BonusSystemFeature from 'features/bonusSystem'
import * as FrameManagerFeature from 'features/frameManager'
import * as LocaleFeature from 'features/locale'
import * as NotifiesFeature from 'features/notifies'
import * as PaymentFeature from 'features/payment'
import * as ProfileFeature from 'features/profile'
import * as PromocodeFeature from 'features/promocode'
import * as SettingsFeature from 'features/settings'
import * as SidePanelFeature from 'features/sidePanel'
import * as SlotsFeature from 'features/slots'
import * as SupportFormFeature from 'features/supportForm'
import * as UserSettingsFeature from 'features/userSettings'
import * as WheelGameFeature from 'features/wheelGame'

import { IExtra } from '../shared/types/redux'
import { makeUnauthorizedInterceptor } from './interceptors/unauthorizedInterceptor'

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: typeof compose
  }
}
export interface IApplicationState {
  auth: AuthFeature.types.IAuthStatePersisted
  locale: LocaleFeature.types.ILocaleState
  userSettings: UserSettingsFeature.types.IUserSettingsStatePersisted
  sidePanel: SidePanelFeature.types.ISidePanelState
  settings: SettingsFeature.types.ISettingsState
  profile: ProfileFeature.types.IProfileState
  slots: SlotsFeature.types.ISlotsStatePersisted
  payment: PaymentFeature.types.IPaymentsState
  bonusHistory: BonusHistoryFeature.types.IBonusHistoryState
  wheelGame: WheelGameFeature.types.IWheelGameState
  bonusSystem: BonusSystemFeature.types.IBonusSystemState
  frameManager: FrameManagerFeature.types.IFrameManagerState
  promocode: PromocodeFeature.types.IPromocodesState
  notifies: NotifiesFeature.types.INotifiesStatePersisted
  supportForm: SupportFormFeature.types.ISupportFormState
}

type ConfigureStore = {
  store: Store<IApplicationState>
  persistor: Persistor
}

export function configureStore(extra: IExtra): ConfigureStore {
  const middleware = thunk.withExtraArgument<IExtra>(extra)

  const composeEnhancers: any =
    window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV !== 'production'
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (arg: any) => arg

  const reducer: Reducer<IApplicationState, AnyAction> = combineReducers({
    auth: AuthFeature.reducer,
    locale: LocaleFeature.reducer,
    userSettings: UserSettingsFeature.reducer,
    sidePanel: SidePanelFeature.reducer,
    settings: SettingsFeature.reducer,
    profile: ProfileFeature.reducer,
    slots: SlotsFeature.reducer,
    payment: PaymentFeature.reducer,
    bonusHistory: BonusHistoryFeature.reducer,
    wheelGame: WheelGameFeature.reducer,
    bonusSystem: BonusSystemFeature.reducer,
    frameManager: FrameManagerFeature.reducer,
    promocode: PromocodeFeature.reducer,
    notifies: NotifiesFeature.reducer,
    supportForm: SupportFormFeature.reducer,
  })

  const store = createStore(reducer, compose(applyMiddleware(middleware), composeEnhancers))

  const persistor = persistStore(store)

  BaseHttpService.addErrorInterceptor(makeUnauthorizedInterceptor(store))

  return { store, persistor }
}
