import { ROUTE_URLS } from 'router/routes'

import { IUser } from 'shared/models/User'

export type UserValues = keyof IUser

export interface IUserDataLinks {
  title: string
  value?: UserValues[]
  link?: string
}

export interface IAccordionLinks {
  name: string
  links: { title: string; link: string; isDisplay?: boolean }[]
}

export const userDataLinks: IUserDataLinks[] = [
  {
    title: 'profile',
    value: ['firstName', 'lastName'],
    link: ROUTE_URLS.PROFILE_INDEX(),
  },
  {
    title: 'accountId',
    value: ['id'],
    link: ROUTE_URLS.PROFILE_INDEX(),
  },
  {
    title: 'balance',
    value: ['balance', 'currency'],
  },
]

export const getAccordionLinks = (isBonusTransferAvailable: boolean): IAccordionLinks[] => [
  {
    name: 'account',
    links: [
      { title: 'myAccount', link: ROUTE_URLS.PROFILE_INDEX() },
      { title: 'profile', link: ROUTE_URLS.PROFILE_USERINFO() },
      { title: 'changePassword', link: ROUTE_URLS.PROFILE_CHANGE_PASSWORD() },
      { title: 'deposit', link: ROUTE_URLS.PROFILE_DEPOSIT() },
      { title: 'withdrawal', link: ROUTE_URLS.PROFILE_WITHDRAW() },
    ],
  },
  {
    name: 'history',
    links: [
      {
        title: 'transactions',
        link: ROUTE_URLS.PROFILE_TRANSACTIONS(),
      },
    ],
  },
  {
    name: 'bonuses',
    links: [
      {
        title: 'exchangeBonusBalance',
        link: ROUTE_URLS.PROFILE_EXCHANGE_BONUS_BALANCE(),
        isDisplay: isBonusTransferAvailable,
      },
      {
        title: 'bonusHistory',
        link: ROUTE_URLS.PROFILE_BONUSHISTORY(),
      },
      {
        title: 'bonusPromotions',
        link: ROUTE_URLS.PROFILE_BONUS_PROMOTIONS(),
      },
    ],
  },
]
