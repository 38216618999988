import dayjs from 'dayjs'

let currentDay = 0

export const days = [...Array(3).keys()].map(number =>
  dayjs(
    `${dayjs()
      .startOf('day')
      .add(number + 1, 'day')
      .add(9, 'hours')
      .format('YYYY-MM-DD')}T00:11+01:00`,
  ).format('YYYY-MM-DD HH:mm:ss'),
);

export const getDay = (time = '09:00:00') =>
  dayjs(`${dayjs().startOf('day').add(1, 'day').format('YYYY-MM-DD')}T${time}+00:00`).format('YYYY-MM-DD HH:mm:ss');

export const getNewTime = () => {
  currentDay += 1;
  return days[currentDay];
};
