import { ISlotsStatePersisted } from 'features/slots/types'

type ReturnMigrationType = (state: ISlotsStatePersisted) => ISlotsStatePersisted

export const slotsMigrations: Record<string, ReturnMigrationType> = {
  20: state => ({
    ...state,
    data: {
      ...state.data,
      gameList: [],
    },
  }),
  22: state => ({
    ...state,
    data: {
      ...state.data,
      gameList: [],
      externalGames: {
        top: {
          lastLoadDate: null,
          games: [],
        },
        new: {
          lastLoadDate: null,
          games: [],
        },
        wager: {
          lastLoadDate: null,
          games: [],
        },
      },
    },
  }),
}
