import { createReducer } from '@reduxjs/toolkit'

import { languages } from 'shared/locale'
import { locale } from 'shared/locale/en-US'

import * as actions from '../actions'
import { IUserSettingsState } from '../types'

const initialState: IUserSettingsState = {
  data: {
    lang: languages.EN,
    isChanged: false,
  },
}

export default createReducer(initialState, builder => {
  builder.addCase(actions.changeLang.fulfilled, (state, action) => {
    state.data.lang = action.payload
    state.data.isChanged = true
  })
})
