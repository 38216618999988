import { BonusPromotionStatus, IUserPromotion } from 'shared/models/Promotion'
import { makeCommunicationActionCreator } from 'shared/utils/communication/actions/makeCommunicationActionCreator'
// eslint-disable-next-line max-len
import { makeCommunicationByIdActionCreator } from 'shared/utils/communication/actions/makeCommunicationByIdActionCreator'

import { addNotify } from 'components/Notify'

export const exchangeBonusBalanceToBalance = makeCommunicationActionCreator({
  loading: '@bonusSystem/EXCHANGET_BONUS_BALANCE_TO_BALANCE_LOADING',
  success: '@bonusSystem/EXCHANGET_BONUS_BALANCE_TO_BALANCE_SUCCESS',
  error: '@bonusSystem/EXCHANGET_BONUS_BALANCE_TO_BALANCE_ERROR',
  reset: '@bonusSystem/EXCHANGET_BONUS_BALANCE_TO_BALANCE_RESET',
})<{ amount: number }, void, { callback: () => void }>(
  async ({
    deps: {
      extra: { api },
      getState,
    },
    payload,
    args,
  }) => {
    const state = getState()
    const locale = state.locale.data.bonuses
    const response = await api.bonusSystem.exchangeBonusBalanceToBalance(payload)
    if (response.success) {
      addNotify(locale.balanceConvertedSuccess, 'success')
    }
    if (args?.callback) {
      args.callback()
    }
  },
)

export const getUserPromotions = makeCommunicationActionCreator({
  loading: '@bonusSystem/GET_USER_PROMOTIONS_LOADING',
  success: '@bonusSystem/GET_USER_PROMOTIONS_SUCCESS',
  error: '@bonusSystem/GET_USER_PROMOTIONS_ERROR',
  reset: '@bonusSystem/GET_USER_PROMOTIONS_RESET',
})<void, IUserPromotion[]>(
  async ({
    deps: {
      extra: { api },
    },
  }) => {
    const response = await api.bonusSystem.getUserPromotions()
    return response.data
  },
)

export const changeActiveUserPromotion = makeCommunicationByIdActionCreator({
  loading: '@bonusSystem/CHANGE_ACTIVE_USER_PROMOTION_LOADING',
  success: '@bonusSystem/CHANGE_ACTIVE_USER_PROMOTION_SUCCESS',
  error: '@bonusSystem/CHANGE_ACTIVE_USER_PROMOTION_ERROR',
  reset: '@bonusSystem/CHANGE_ACTIVE_USER_PROMOTION_RESET',
})<{ id: string; status?: BonusPromotionStatus; callback?: () => void }, { id: string }>(
  async ({
    deps: {
      extra: { api },
      getState,
      dispatch,
    },
    payload,
  }) => {
    const { id, status = BonusPromotionStatus.available, callback } = payload
    const locale = getState().locale.data.bonuses

    const response = await api.bonusSystem.changeActiveUserPromotion({ id })

    const localeStatus = status === BonusPromotionStatus.available ? locale.successActivate : locale.cancelBonus

    if (response.success) {
      addNotify(localeStatus, 'success')

      if (callback) {
        callback()
      }
    }

    dispatch(getUserPromotions())

    return { id }
  },
)

export const getMoneyFromPromotion = makeCommunicationByIdActionCreator({
  loading: '@bonusSystem/GET_MONEY_USER_PROMOTION_LOADING',
  success: '@bonusSystem/GET_MONEY_USER_PROMOTION_SUCCESS',
  error: '@bonusSystem/GET_MONEY_USER_PROMOTION_ERROR',
  reset: '@bonusSystem/GET_MONEY_USER_PROMOTION_RESET',
})<{ id: string; status?: BonusPromotionStatus; callback?: () => void }, { id: string; data: IUserPromotion }>(
  async ({
    deps: {
      extra: { api },
      getState,
    },
    payload,
  }) => {
    const { id, callback } = payload
    const locale = getState().locale.data.bonuses

    const response = await api.bonusSystem.getMoneyFromPromotion({ id })
    if (response.success) {
      addNotify(locale.moneySuccessfully, 'success')

      if (callback) {
        callback()
      }
    }
    return { id, data: response.data }
  },
)
