import { IApplicationState } from 'setup/store'

import { defaultCurrency } from 'shared/constants'

export const selectIsUserAuthenticated = (state: IApplicationState) => state.auth.data.isAuth

export const selectAuthCommunication = (state: IApplicationState) => state.auth.communications

export const selectUserData = (state: IApplicationState) => state.auth.data.user

export const selectUserCurrency = (state: IApplicationState) => state.auth.data.user?.currency ?? defaultCurrency

export const selectPartnerId = (state: IApplicationState) => state.auth.data.partnerId

export const selectCheckPromocodeRegistrationComm = (state: IApplicationState) =>
  state.auth.communications.checkRegistrationValidate
