import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTE_PATHS } from 'router/routes'

import ProtectedRoute from 'components/helpers/ProtectedRoute/ProtectedRoute'

const SignUp = React.lazy(() => import('pages/Auth/SignUp/SignUp'))

export class AuthModule {
  getRoutes() {
    return (
      <Route key={ROUTE_PATHS.AUTH} path={ROUTE_PATHS.AUTH}>
        <Switch>
          <ProtectedRoute
            key={ROUTE_PATHS.AUTH_SIGN_UP_WITH_PROMOCODE}
            path={ROUTE_PATHS.AUTH_SIGN_UP_WITH_PROMOCODE}
            forAuth={false}
            component={SignUp}
          />
          <ProtectedRoute
            key={ROUTE_PATHS.AUTH_SIGN_UP}
            path={ROUTE_PATHS.AUTH_SIGN_UP}
            forAuth={false}
            component={SignUp}
          />
        </Switch>
      </Route>
    )
  }
}
