/* eslint-disable no-debugger */
import { toast } from 'react-toastify'

import {
  IConvertedValidateCodeResponse,
  IResendEmailPayload,
  IUserSignUp,
  IVerifyEmailPayload,
} from 'shared/models/Auth'
import { ILoginServer } from 'shared/models/Login'
import { RoleTypes } from 'shared/models/Roles'
import { IUser } from 'shared/models/User'
import { makeCommunicationActionCreator } from 'shared/utils/communication/actions/makeCommunicationActionCreator'

import { IUserPayload } from 'features/profile/types'

import { addNotify } from 'components/Notify'

import { IResetPasswordConfirmPayload, IResetPasswordPayload } from './types'

export const logoutSuccessActionType = '@auth/LOGOUT_SUCCESS'

export const logout = makeCommunicationActionCreator({
  loading: '@auth/LOGOUT_LOADING',
  success: logoutSuccessActionType,
  error: '@auth/LOGOUT_LOADING_ERROR',
  reset: '@auth/LOGOUT_LOADING_RESET',
})(
  async ({
    deps: {
      extra: { api },
    },
  }) => {
    await api.auth.logout()
  },
)

export const usernameSignIn = makeCommunicationActionCreator({
  loading: '@auth/USERNAME_SIGN_IN_LOADING',
  success: '@auth/USERNAME_SIGN_IN_SUCCESS',
  error: '@auth/USERNAME_SIGN_IN_ERROR',
  reset: '@auth/USERNAME_SIGN_IN_RESET',
})<ILoginServer, IUser, { callback: () => void }>(
  async ({
    deps: {
      extra: { api },
      dispatch,
      getState,
    },
    payload,
    args,
  }) => {
    const locale = getState().locale.data.auth
    const response = await api.auth.usernameSignIn({ data: payload })
    if (response.success) {
      if (response.data.user.role !== RoleTypes[RoleTypes.P]) {
        dispatch(logout())
        addNotify(locale.onlyUsersCanUse)
      }
    }
    if (args?.callback) {
      args.callback()
    }
    return response.data.user
  },
)

export const signUp = makeCommunicationActionCreator({
  loading: '@auth/USERNAME_SIGN_UP_LOADING',
  success: '@auth/USERNAME_SIGN_UP_SUCCESS',
  error: '@auth/USERNAME_SIGN_UP_ERROR',
  reset: '@auth/USERNAME_SIGN_UP_RESET',
})<IUserSignUp, void, { callback: () => void }>(
  async ({
    deps: {
      extra: { api },
      getState,
    },
    payload,
    args,
  }) => {
    const locale = getState().locale.data.auth

    const response = await api.auth.signUp({ data: payload })

    if (response.success) {
      // dispatch(usernameSignIn({ username: payload.username, password: payload.password1 }))
      // addNotify(locale.checkEmail)

      if (args?.callback) {
        args.callback()
      }
    }
  },
)

export const resetPassword = makeCommunicationActionCreator({
  loading: '@auth/RESET_PASSWORD_LOADING',
  success: '@auth/RESET_PASSWORD_SUCCESS',
  error: '@auth/RESET_PASSWORD_ERROR',
  reset: '@auth/RESET_PASSWORD_RESET',
})<IResetPasswordPayload, void>(
  async ({
    deps: {
      extra: { api },
      getState,
    },
    payload,
  }) => {
    const locale = getState().locale.data.auth
    const response = await api.auth.passwordReset(payload.email)
    if (response.success) {
      addNotify(locale.resetPasswordEmailSent, 'success')
      payload.callback()
    }
  },
)

export const resetPasswordConfirm = makeCommunicationActionCreator({
  loading: '@auth/RESET_PASSWORD_CONFIRM_LOADING',
  success: '@auth/RESET_PASSWORD_CONFIRM_SUCCESS',
  error: '@auth/RESET_PASSWORD_CONFIRM_ERROR',
  reset: '@auth/RESET_PASSWORD_CONFIRM_RESET',
})<IResetPasswordConfirmPayload, void>(
  async ({
    deps: {
      extra: { api },
      getState,
    },
    payload,
  }) => {
    const locale = getState().locale.data.auth
    const response = await api.auth.passwordResetConfirm(payload.data)
    if (response.success) {
      addNotify(locale.recoverySuccess, 'success')
      payload.callback()
    }
  },
)

export const getUserData = makeCommunicationActionCreator({
  loading: '@auth/GET_USER_DATA_LOADING',
  success: '@auth/GET_USER_DATA_SUCCESS',
  error: '@auth/GET_USER_DATA_ERROR',
  reset: '@auth/GET_USER_DATA_RESET',
})<void, IUser>(
  async ({
    deps: {
      extra: { api },
      dispatch,
      getState,
    },
  }) => {
    const locale = getState().locale.data.auth
    const response = await api.auth.getUserData()
    if (response.success) {
      if (response.data.role !== RoleTypes[RoleTypes.P]) {
        dispatch(logout())
        addNotify(locale.onlyUsersCanUse)
      }
    }
    return response.data
  },
)

export const updateUserData = makeCommunicationActionCreator({
  loading: '@auth/UPDATE_USER_DATA_LOADING',
  success: '@auth/UPDATE_USER_DATA_SUCCESS',
  error: '@auth/UPDATE_USER_DATA_ERROR',
  reset: '@auth/UPDATE_USER_DATA_RESET',
})<IUserPayload, IUser>(
  async ({
    deps: {
      extra: { api },
    },
    payload,
  }) => {
    const response = await api.auth.updateUserData(payload)
    return response.data
  },
)

export const verifyEmail = makeCommunicationActionCreator({
  loading: '@auth/VERIFY_EMAIL_LOADING',
  success: '@auth/VERIFY_EMAIL_SUCCESS',
  error: '@auth/VERIFY_EMAIL_ERROR',
  reset: '@auth/VERIFY_EMAIL_RESET',
})<IVerifyEmailPayload, void, { callback: () => void }>(
  async ({
    deps: {
      extra: { api },
      getState,
    },
    payload,
    args,
  }) => {
    const { locale } = getState()
    const response = await api.auth.verifyEmail(payload)
    if (response.success) {
      toast(locale.data.auth.verifyEmailSuccess)
      if (args?.callback) {
        args.callback()
      }
    }
  },
)

export const resendEmail = makeCommunicationActionCreator({
  loading: '@auth/RESEND_EMAIL_LOADING',
  success: '@auth/RESEND_EMAILL_SUCCESS',
  error: '@auth/RESEND_EMAIL_ERROR',
  reset: '@auth/RESEND_EMAIL_RESET',
})<IResendEmailPayload, void, { callback: () => void }>(
  async ({
    deps: {
      extra: { api },
      getState,
    },
    payload,
    args,
  }) => {
    const { locale } = getState()
    const response = await api.auth.resendEmail(payload)
    if (response.success) {
      toast(locale.data.auth.resendEmailSuccess)
      if (args?.callback) {
        args.callback()
      }
    }
  },
)

export const checkRegistrationValidate = makeCommunicationActionCreator({
  loading: '@auth/CHECK_REGISTRATION_VALIDATE_LOADING',
  success: '@auth/CHECK_REGISTRATION_SUCCESS',
  error: '@auth/CHECK_REGISTRATION_ERROR',
  reset: '@auth/CHECK_REGISTRATION_RESET',
})<string, IConvertedValidateCodeResponse>(
  async ({
    deps: {
      extra: { api },
    },
    payload,
  }) => {
    const response = await api.auth.checkRegistrationValidate(payload)
    return response.data
  },
)
