import React, { useState } from "react";
import block from "bem-cn";
import "./Content.scss";

const ContentSection = () => {
  const [expanded, setExpanded] = useState(false);
  const b = block("content");

  return (
    <section className={b()}>
      <div className={b("container")}>
        <div className={b("first-container")}>
          <h1 className={b("title")}>Play Online Casino Games on Spineo.io</h1>
          <p className={b("text")}>
            Welcome aboard spineo.io, a forward-looking online casino and sports
            booking site! Be our guest, and let us take you on an unforgettable
            trip around every corner of this online gambling palace. We are
            really excited to share so many things with our new players!
          </p>
        </div>

        <div className={b("second-container", { expanded })}>
          <h2>What is Spineo.io & Why You Should Play It</h2>
          <p>
            Let's get acquainted from the very beginnin spineo.io site is an
            inclusive gambling resort with thousands of games available to
            players around the globe - from the America to Eurasia and Africa.
            Therefore, the online casino speaks 7 languages: English, German,
            French, Spanish, Arabic, Japanese, and Turkish.
          </p>
          <p>
            The site has two rooms - the spineo.io online casino with nearly 10k
            games by 60+ software developers and the booking.com with 30+
            sports. Both online environments feature all the latest releases and
            hottest events players might be interested in
          </p>
          {expanded && (
            <p>
              We welcome newcomers and show gratitude to our dedicated online casino players by offering them advantageous promotions. So whether you are a new or seasoned player familiar with all the best games and deals, we will pleasantly surprise you throughout your entire stay. But let's take it slow. There are many things you have yet to learn
            </p>
          )}
        </div>
        <button className={b("button")} onClick={() => setExpanded(!expanded)}>
          {expanded ? "Read Less" : "Read More"}
        </button>
      </div>
    </section>
  );
};

export default ContentSection;
