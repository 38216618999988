import React, { useCallback, useContext, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import block from 'bem-cn'

import { selectIsUserAuthenticated, selectUserData } from 'features/auth/selectors'
import { selectLocaleDict } from 'features/locale/selectors'

import { ISideMenuLinks, sideMenuLinks } from '../data/index'

import './ProfileSideMenu.scss'

const b = block('profile-side-menu')

interface IProps {
  selectedLink: ISideMenuLinks | null
  handleLinkClick: (a: ISideMenuLinks) => void
}

export const ProfileSideMenu: React.FC<IProps> = ({ selectedLink, handleLinkClick }) => {
  const locale = useSelector(selectLocaleDict, shallowEqual).profile

  const links = useMemo(
    () => (
      <>
        {sideMenuLinks.map((item, index) => {
          const isActive = item.title === selectedLink?.title

          return (
            <div className={b('link', { isActive })} key={index} onClick={() => handleLinkClick(item)}>
              <span>{locale[item.title]}</span>
            </div>
          )
        })}
      </>
    ),
    [locale, selectedLink, handleLinkClick],
  )

  return <div className={b('wrapper')}>{links}</div>
}
