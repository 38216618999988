import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'

import MainPage from 'pages/MainPage/MainPage'

import { ROUTE_PATHS } from '../../router/routes'

export class MainPageModule {
  getRoutes() {
    return (
      <Route key={ROUTE_PATHS.MAIN} path={ROUTE_PATHS.MAIN}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Switch>
            <Route key={ROUTE_PATHS.MAIN} path={ROUTE_PATHS.MAIN} component={MainPage} />
          </Switch>
        </QueryParamProvider>
      </Route>
    )
  }
}
