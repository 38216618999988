import React, { useContext } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import block from 'bem-cn'

import { MODAL_ROUTE_URLS } from 'router/modalRoutes'

import { ISpinWheel } from 'shared/models/Wheel/Wheel.types'

import { selectLocaleDict } from 'features/locale/selectors'

import { ModalContext } from 'components/HOC/withModalQuery/ModalContext'

import luckyImg from '../img/lucky-lottery.png'
import coinsImg from '../img/lucky-lottery-coins.png'

// import dtIconPng from '../img/dt-icon.png'
// import appleLogoWhite from '../img/apple-logo-white.png'
import './Roulette.scss'

const b = block('roulette-game')

interface IRouletteWinProps {
  spinInfo: ISpinWheel
}

const RouletteWin: React.FC<IRouletteWinProps> = ({ spinInfo }) => {
  const { replaceLastModal } = useContext(ModalContext)

  const locale = useSelector(selectLocaleDict, shallowEqual).wheelGame
  if (!spinInfo) return null

  return (
    <div className={b('win-block')}>
      <div className={b('top-block')}>
        <img src={luckyImg} className={b('top-block__img', { lucky: true })} alt="lucky-img" />
        <img src={coinsImg} className={b('top-block__img', { coins: true })} alt="coins-img" />
      </div>
      <div className={b('mid-block')}>
        {/* <img src={spinInfo.icon} className={b('mid-block__icon')} alt="spin-info-img" /> */}
        <h3 className={b('mid-block__value')}>+ {isNaN(+spinInfo.name) ? spinInfo.name : spinInfo.amount}</h3>
        <h3 className={b('mid-block__currency')}>{spinInfo.currency}</h3>
      </div>
      <div className={b('bot-block')}>
        <button
          onClick={replaceLastModal(MODAL_ROUTE_URLS.WHEEL_GAME_HISTORY())}
          className={b('btn-view')}
          aria-label={locale.viewHistory}>
          <div className={b('btn-view_inner')}>{locale.viewHistory}</div>
        </button>
      </div>
    </div>
  )
}

export default RouletteWin
