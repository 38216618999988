import React from 'react'
import { Route, Switch } from 'react-router-dom'

// import { lazyImportComponent } from 'shared/helpers/lazyImportComponent'
import { ROUTE_PATHS } from '../../router/routes'

const VerifyEmailLayout = React.lazy(() => import('./view/VerifyEmailLayout'))

export class VerifyEmailModule {
  getRoutes() {
    return <Route key={ROUTE_PATHS.VERIFY} path={ROUTE_PATHS.VERIFY_EMAIL} component={VerifyEmailLayout} />
  }
}
