import React, { useContext, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom'
import block from 'bem-cn'
import { motion } from 'framer-motion'

import { MODAL_ROUTE_URLS } from 'router/modalRoutes'

import { mediaSize } from 'shared/style/var'
import { mainSliderData, mainSliderMobileData } from 'shared/utils/slider/mainPage'

import { withFetchSlotsLayoutContentDesktop } from 'modules/SlotsRoot/components/withFetchSlotsLayoutContent'

import { selectIsUserAuthenticated } from 'features/auth/selectors'
import { selectOpenedRightSidePanel } from 'features/sidePanel/selectors'

import { ModalContext } from 'components/HOC/withModalQuery/ModalContext'

import DefineDeviceComponent from 'components/DefineDeviceComponent'
import Slider from 'components/Slider'

import './MainPage.scss'

const SlotsPreview = React.lazy(() => import('components/slots/SlotsPreview/SlotsPreview'))

const b = block('main-page')

const MainPage: React.FC = () => {
  const history = useHistory()
  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)
  const { pushNewModal } = useContext(ModalContext)
  const isRightPanelOpened = useSelector(selectOpenedRightSidePanel, shallowEqual)
  const isMobileOrTablet = useMediaQuery({ maxWidth: mediaSize.tablet })
  const dispatch = useDispatch()

  const desktopSliderItems = useMemo(
    () =>
      mainSliderData.map((item, index) => {
        const clickHandler = () => {
          // if (!isAuth) {
          //   if (isMobileOrTablet) {
          //     dispatch(changeOpenedRightSidePanel(!isRightPanelOpened))
          //   } else {
          //     pushNewModal(MODAL_ROUTE_URLS.SIGN_IN())()
          //   }
          // } else {
          // }
          history.push(item.route)
        }
        return (
          <div className="each-slide" key={`home-slider-${index}`} onClick={clickHandler}>
            <img draggable={false} src={item.img} alt="banner" className="backgroung-image" />
          </div>
        )
      }),
    [history],
  )

  const mobileSliderItems = useMemo(
    () =>
      mainSliderMobileData.map((item, index) => {
        const clickHandler = () => {
          // if (!isAuth) {
          //   if (isMobileOrTablet) {
          //     dispatch(changeOpenedRightSidePanel(!isRightPanelOpened))
          //   } else {
          //     pushNewModal(MODAL_ROUTE_URLS.SIGN_IN())()
          //   }
          // } else {
          // }
          history.push(item.route)
        }
        return (
          <div className="each-slide" key={`home-slider-mobile-${index}`} onClick={clickHandler}>
            <img draggable={false} src={item.img} alt="banner" className="backgroung-image" />
          </div>
        )
      }),
    [dispatch, history, isAuth, isMobileOrTablet, isRightPanelOpened, pushNewModal],
  )

  const desktopSlider = <Slider items={desktopSliderItems} />

  const mobileSlider = <Slider items={mobileSliderItems} />

  return (
    <motion.div
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.15 }}
      className={b()}>
      <DefineDeviceComponent mobileComponent={mobileSlider} desktopComponent={desktopSlider} />
      <SlotsPreview />
    </motion.div>
  )
}

export default withFetchSlotsLayoutContentDesktop(MainPage)
