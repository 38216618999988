import React, { useCallback } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link, useRouteMatch } from 'react-router-dom'
import block from 'bem-cn'

import { useMediaMaxTablet } from 'shared/style/var'

import { selectIsUserAuthenticated } from 'features/auth/selectors'
import { selectLocaleDict } from 'features/locale/selectors'

import { useModalContext } from 'components/HOC/withModalQuery/ModalContext'

import { addNotify } from 'components/Notify'

import { INavigationItem } from '../data'

import './SidebarLinks.scss'

const b = block('navigation-header__item')

const SidebarLinks: React.FC<INavigationItem> = props => {
  const { route, text, icon, isAuthReq = false, isNew = false, modalRoute, isTopGame = false } = props

  const isMobile = useMediaMaxTablet()
  const { pushNewModal } = useModalContext()
  const isActive = useRouteMatch(route)?.isExact

  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)
  const locale = useSelector(selectLocaleDict, shallowEqual).common
  const localeSlots = useSelector(selectLocaleDict, shallowEqual).slots

  const onClickNavItem = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (!isAuth && isAuthReq) {
        e.preventDefault()
        addNotify(localeSlots.pleaseAuth)
      }

      if (modalRoute && isMobile) {
        e.preventDefault()
        pushNewModal(modalRoute)()
      }
    },
    [isAuth, isAuthReq, localeSlots, pushNewModal, isMobile, modalRoute],
  )

  return (
    <Link className={b()} to={route} onClick={onClickNavItem}>
      <span className={b('icon-bg', { active: isActive })}>
        {props.svg ? <props.svg className={b('icon-svg')} /> : null}
      </span>

      <div className={b('inner')}>
        <div className={b('info')}>
          {isTopGame ? <span className={b('info-text', { top: true })}>{localeSlots.top}</span> : null}
          {isNew ? <span className={b('info-text', { new: true })}>{localeSlots.new}</span> : null}
        </div>

        <span className={b('link-text')}>{locale[text] || text}</span>
      </div>
    </Link>
  )
}

export default SidebarLinks
