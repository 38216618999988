import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import block from 'bem-cn'
import { motion } from 'framer-motion'

import { ReactComponent as CloseSVG } from 'shared/img/cross.svg'
import { ReactComponent as StarSVG } from 'shared/img/slots/star.svg'

import { selectLocaleDict } from 'features/locale/selectors'

import './SlotPreview.scss'

interface ISlotsPreview {
  img: string
  name: string
  isFavorite: boolean
  playRoute: string
  demoRoute?: string

  favoriteHandler(e: React.MouseEvent<HTMLDivElement>): void

  close(): void

  clickHandler(e: React.MouseEvent<HTMLAnchorElement>, isDemo?: boolean): void
}

const b = block('slot-preview')

const SlotPreview: React.FC<ISlotsPreview> = ({
  name,
  img,
  isFavorite,
  favoriteHandler,
  playRoute,
  demoRoute,
  close,
  clickHandler,
}) => {
  const locale = useSelector(selectLocaleDict, shallowEqual).slots

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.25 }}
      className={b()}>
      <img src={img} alt="background" className={b('background')} />
      <div className={b('header')}>
        <CloseSVG className={b('header-close')} onClick={close} />
      </div>
      <div className={b('card')}>
        <div className={b('card-name')}>{name}</div>
        <img src={img} alt="card-img" className={b('card-img')} />
        <div className={b('card-favorite')} onClick={favoriteHandler}>
          <StarSVG className={b('card-favorite-svg', { active: isFavorite })} />
        </div>
      </div>
      <div className={b('buttons-container')}>
        <Link className={b('button', { type: 'main' })} to={playRoute} onClick={clickHandler}>
          {locale.play}
        </Link>
        {demoRoute && (
          <Link className={b('button')} to={demoRoute} onClick={e => clickHandler(e, true)}>
            {locale.demoStart}
          </Link>
        )}
      </div>
    </motion.div>
  )
}

export default SlotPreview
