import { IApplicationState } from 'setup/store'

export const selectSpinHistory = (state: IApplicationState) => state.wheelGame.data.spinHistory

export const selectWheelGamePrizesList = (state: IApplicationState) => state.wheelGame.data.prizesList

export const selectSpinIsLoading = (state: IApplicationState) => state.wheelGame.communications.spinWheel

export const selectWheelPublicSettings = (state: IApplicationState) => state.wheelGame.data.publicSettings

export const selectSpinWheelInfo = (state: IApplicationState) => state.wheelGame.data.spinWheel
