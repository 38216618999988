import { useCallback, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

const useInfiniteVerticalScroll = ({ data, loadingCount }: { data: any; loadingCount: number }) => {
  const [state, setState] = useState({
    prev: -loadingCount,
    next: 0,
    current: [],
  })

  const getMoreData = useCallback(
    (dataProp, stateProp) => {
      setState({
        prev: stateProp.prev + loadingCount,
        next: stateProp.next + loadingCount,
        current: stateProp.current.concat(dataProp.slice(stateProp.prev + loadingCount, stateProp.next + loadingCount)),
      })
      // setCurrent(currentProp.concat(dataProp.slice(countProp.prev + loadingCount, countProp.next + loadingCount)));
      // setCount({ prev: countProp.prev + loadingCount, next: countProp.next + loadingCount });
    },
    [loadingCount],
  )

  useEffect(() => {
    const newCount = {
      prev: -loadingCount,
      next: 0,
    }
    setState({
      ...state,
      ...newCount,
    })

    getMoreData(data, { ...newCount, current: [] })
  }, [data])

  const { ref, inView } = useInView({
    threshold: 0,
  })

  useEffect(() => {
    if (inView) {
      getMoreData(data, state)
    }
  }, [inView])

  return { current: state.current, ref }
}

export default useInfiniteVerticalScroll
