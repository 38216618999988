import expireIn from 'lib/redux-persist-transform'
import { combineReducers } from 'redux'
import { getPersistConfig } from 'redux-deep-persist'
import { MigrationManifest } from 'redux-persist'
import createMigrate from 'redux-persist/es/createMigrate'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import { ISlotsState } from '../types'
import communications from './communications'
import data from './data'
import { slotsMigrations } from './migrations/migrations'

const reducedWithComm = combineReducers<ISlotsState>({
  communications,
  data,
})

const expireInValue = 2 * 60 * 60 * 1000
const expirationKey = 'expirationKeyTransform'

const persistConfig = getPersistConfig({
  key: 'slots',
  storage,
  version: 22,
  migrate: createMigrate(slotsMigrations as unknown as MigrationManifest, { debug: false }),
  blacklist: ['communications', 'data.gameUrl', 'data.freeSpinInfo', 'data.freeSpinGameUrl'],
  rootReducer: reducedWithComm,
  transforms: [expireIn(expireInValue, expirationKey, [])],
})

export const reducer = persistReducer(persistConfig, reducedWithComm)
