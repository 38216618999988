import React, { Suspense, useCallback, useContext, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import block from 'bem-cn'

import { MODAL_ROUTE_PATH } from 'router/modalRoutes'

import { mediaSize } from 'shared/style/var'
import { getRouteParamsByStrings } from 'shared/utils/getRouteParamsByStrings'

import withQueryParams from 'modules/SlotsRoot/components/SlotsLayout/withQueryParams'
import { IFiltersSlots } from 'modules/SlotsRoot/types/filters'
import { ISlotsGameType, ISlotViewItem } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'
import { ProvidersType } from 'modules/SlotsRoot/types/slot'

import { selectIsUserAuthenticated } from 'features/auth/selectors'
import { actions } from 'features/frameManager'
import { selectLocaleDict } from 'features/locale/selectors'

import { ModalContext } from 'components/HOC/withModalQuery/ModalContext'

import { addNotify } from 'components/Notify'
import SlotsLayoutContent from 'components/slots/SlotsLayoutContent'
import Spinner from 'components/Spinner'

import './SlotAddModal.scss'

interface ISlotAddModal {
  filters: IFiltersSlots
  path: string
}

interface ParamsMatch {
  demoStart: string
  order: string
  gameType: string
}

const b = block('slot-add-modal')
const SlotAddModal: React.FC<ISlotAddModal> = ({ path, filters }) => {
  const params = getRouteParamsByStrings<ParamsMatch>(MODAL_ROUTE_PATH.ADD_SLOT_FRAME, path)
  const isMobileOrTablet = useMediaQuery({ maxWidth: mediaSize.tablet })
  const locale = useSelector(selectLocaleDict, shallowEqual).slots
  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)
  const { setOpenedModals } = useContext(ModalContext)
  const dispatch = useDispatch()
  const isNeedRedirect = useMemo(
    () => isAuth && !params?.demoStart && isMobileOrTablet,
    [isAuth, params, isMobileOrTablet],
  )
  const callback = React.useCallback(
    (id: string) => () => {
      dispatch(actions.removeFrame(id))
      addNotify(locale.tryVpn)
    },
    [dispatch, locale],
  )
  const getSessionFrameUrl = useCallback(
    (frameGameId: string, frameProvider: ProvidersType, frameHasFreeSpins: boolean | undefined) => () => {
      dispatch(
        actions.getFrameSession({
          id: frameGameId,
          order: Number(params?.order),
          provider: frameProvider,
          gameId: frameGameId,
          isDesktop: !isMobileOrTablet,
          callback: callback(frameGameId),
          demoStart: params?.demoStart,
          hasFreeSpins: frameHasFreeSpins,
          withRedirect: isNeedRedirect,
        }),
      )
    },
    [callback, params, dispatch, isMobileOrTablet, isNeedRedirect],
  )

  const addFrameHandler = useCallback(
    (slot: ISlotViewItem) => () => {
      dispatch(
        actions.addFrame({
          id: slot.gameId,
          order: Number(params?.order),
          provider: slot.routeProvider as ProvidersType,
          gameId: slot.gameId,
          getSession: getSessionFrameUrl(slot.gameId, slot.routeProvider as ProvidersType, slot.hasFreeSpins),
        }),
      )
      setOpenedModals([])
    },
    [dispatch, params, getSessionFrameUrl, setOpenedModals],
  )
  return (
    <div className={b()}>
      <div className={b('title')}>{locale.addGame.slice(2)}</div>
      <Suspense fallback={<Spinner isLoading />}>
        <SlotsLayoutContent
          modal
          addFrame={addFrameHandler}
          gameTypeModal={params?.gameType as ISlotsGameType}
          filters={filters}
        />
      </Suspense>
    </div>
  )
}

export default withQueryParams(SlotAddModal)
