import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import block from 'bem-cn'

import { ISlotViewItem } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'
import { slotsTopArray } from 'modules/SlotsRoot/utils/slots'

import { selectLocaleDict } from 'features/locale/selectors'

import SlotsIcon from '../SlotsIcon/SlotsIcon'
import { ReactComponent as LeftSVG } from './img/left.svg'
import { ReactComponent as RightSVG } from './img/right.svg'

import './TopSlotsList.scss'

const b = block('top-slots-list')

interface ITopSlotsList {
  slots: ISlotViewItem[]
  gameType: string
}

const TopSlotsList: React.FC<ITopSlotsList> = ({ slots, gameType }) => {
  const locale = useSelector(selectLocaleDict, shallowEqual).slots
  const topSlots = useMemo(
    () =>
      slots
        .filter(slot => slotsTopArray[gameType]?.includes(slot.gameId))
        .sort(
          (slotA, slotB) =>
            slotsTopArray[gameType]?.indexOf(slotA.gameId) - slotsTopArray[gameType]?.indexOf(slotB.gameId),
        ),
    [gameType, slots],
  )
  const slotsRef = useRef<HTMLDivElement>(null)
  const scroll = {
    current: (slotsRef?.current?.scrollLeft || 0) + (slotsRef?.current?.clientWidth || 0),
    width: slotsRef?.current?.scrollWidth,
    clientWidth: slotsRef?.current?.clientWidth,
  }
  const [isScrolling, setIsScrolling] = useState(false)

  const scrollCategoriesHandler = useCallback(
    direction => () => {
      if (!isScrolling) {
        setIsScrolling(true)
        setTimeout(() => setIsScrolling(false), 700)
      }
      return (
        !isScrolling &&
        slotsRef?.current?.scrollBy({
          left: (direction === 'left' ? -1 : 1) * slotsRef?.current?.offsetWidth,
          behavior: 'smooth',
        })
      )
    },
    [isScrolling, slotsRef],
  )

  useEffect(() => {
    slotsRef?.current?.scrollTo({ left: 0 })
  }, [topSlots])

  const slotsList = useMemo(
    () => topSlots.map(slot => <SlotsIcon key={`${slot.gameId}_${slot.provider}`} slot={slot} />),
    [topSlots],
  )

  return (
    <div className={b()}>
      <div className={b('container')}>
        <div className={b('header')}>{locale.mostPopular}</div>
        <div className={b('button-container')}>
          <div className={b('button', { not_active: scroll.current === scroll.clientWidth })}>
            <LeftSVG onClick={scrollCategoriesHandler('left')} />
          </div>
          <div className={b('button', { not_active: scroll.current === scroll.width && scroll.current !== 0 })}>
            <RightSVG onClick={scrollCategoriesHandler('right')} />
          </div>
        </div>
      </div>
      <div className={b('scroll-container')}>
        <div className={b('content')} ref={slotsRef}>
          {slotsList}
        </div>
      </div>
    </div>
  )
}

export default memo(TopSlotsList)
