import React, { RefObject, useEffect } from 'react'

export const useClickOutside = <T extends HTMLElement = HTMLElement>(ref: RefObject<T>, callback: () => void) => {
  useEffect(() => {
    const handleClickOutside = (ev: any) => {
      if (!ref.current?.contains(ev.target)) {
        callback()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
  }, [])

  return {}
}
