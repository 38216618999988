import { createReducer } from '@reduxjs/toolkit'

import * as actions from '../actions'
import { IAuthState } from '../types'

const initialState: IAuthState['data'] = {
  user: null,
  isAuth: false,
  partnerId: '',
}

export default createReducer(initialState, builder => {
  builder
    .addCase(actions.logout.success, (state, action) => {
      state.isAuth = false
      state.user = null
    })
    .addCase(actions.usernameSignIn.success, (state, action) => {
      state.user = action.payload
      state.isAuth = true
    })
    .addCase(actions.getUserData.success, (state, action) => {
      state.user = action.payload
      state.isAuth = true
    })
    .addCase(actions.updateUserData.success, (state, action) => {
      state.user = action.payload
    })
    .addCase(actions.checkRegistrationValidate.success, (state, action) => {
      state.partnerId = action.payload.partnerId
    })
})
