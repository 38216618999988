import React, { useMemo, useState } from 'react'
import block from 'bem-cn'
import { Autoplay } from 'swiper'
import { Swiper, SwiperClass, SwiperProps, SwiperSlide } from 'swiper/react'

import './Slider.scss'

import 'swiper/scss'
import 'swiper/scss/autoplay'

interface ISlider {
  items: React.ReactNode[]
}

const b = block('slider')

const Slider: React.FC<ISlider> = ({ items }) => {
  const [swiperRef, setSwiperRef] = useState<SwiperClass>()

  const properties: SwiperProps = useMemo(
    () => ({
      loop: items?.length > 1,
      autoplay: items?.length > 1 ? { delay: 5000 } : false,
      speed: 900,
      allowTouchMove: false,
    }),
    [items?.length],
  )

  const sliderList = useMemo(() => items.map((slide, index) => <SwiperSlide key={index}>{slide}</SwiperSlide>), [items])

  return (
    <div className={b()}>
      <Swiper
        onSwiper={setSwiperRef}
        {...properties}
        className="mySwiper"
        modules={[Autoplay]}
        style={{ direction: 'ltr' }}>
        {sliderList}
      </Swiper>
      <div className={b('height')} />
    </div>
  )
}

export default Slider
