/* eslint-disable no-debugger */
import { createAction } from '@reduxjs/toolkit'

import { makeCommunicationActionCreator } from 'shared/utils/communication/actions/makeCommunicationActionCreator'
// eslint-disable-next-line max-len
import { makeCommunicationByIdActionCreator } from 'shared/utils/communication/actions/makeCommunicationByIdActionCreator'

import { IGetGameList } from 'modules/SlotsRoot/api'
import { slotProvidersConfiguration } from 'modules/SlotsRoot/configuration'
import {
  IFavoriteSlotPayload,
  IGetExtraDataSuccess,
  IGetGameListInField,
  IGetGameListReplaced,
  IGetSessionUrlPayload,
} from 'modules/SlotsRoot/types/actions'
import { ISlotViewItem } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'

import { addNotify } from 'components/Notify'

// -----

export const GET_GAME_LIST_REPLACED = '@slots/GET_GAME_LIST_REPLACED'
export const GET_GAME_LIST_SUCCESS_IN_FIELD = '@slots/GET_GAME_LIST_SUCCESS_IN_FIELD'
export const ACTION_PROCESSING_BY_PROVIDER = '@slots/ACTION_PROCESSING_BY_PROVIDER'
export const GET_EXTRA_DATA_SUCCESS = '@slots/GET_EXTRA_DATA_SUCCESS'
export const ACTION_FAILURE = '@slots/ACTION_FAILURE'

export const LOAD_SESSION = '@slots/LOAD_SESSION'
export const SET_BACK_URL = '@slots/SET_BACK_URL'
export const CLEAR_SESSION = '@slots/CLEAR_SESSION'

export const FAVORITE_ADD_SLOT_SUCCESS = '@slots/FAVORITE_ADD_SLOT_SUCCESS'
export const FAVORITE_REMOVE_SLOT_SUCCESS = '@slots/FAVORITE_REMOVE_SLOT_SUCCESS'

// -----

export const getGameListReplaced = createAction<IGetGameListReplaced>(GET_GAME_LIST_REPLACED)
export const getGameListInField = createAction<IGetGameListInField>(GET_GAME_LIST_SUCCESS_IN_FIELD)
export const getExtraDataSuccess = createAction<IGetExtraDataSuccess>(GET_EXTRA_DATA_SUCCESS)
export const setActionFailure = createAction<string>(ACTION_FAILURE)

export const loadSession = createAction<string>(LOAD_SESSION)
export const setBackUrl = createAction<string>(SET_BACK_URL)
export const clearSessionUrl = createAction(CLEAR_SESSION)

export const addFavoriteSlotAction = createAction<string>(FAVORITE_ADD_SLOT_SUCCESS)
export const removeFavoriteSlotAction = createAction<string>(FAVORITE_REMOVE_SLOT_SUCCESS)

// -----

export const getGameList = makeCommunicationByIdActionCreator({
  loading: '@slots/GET_GAME_LIST_LOADING',
  success: '@slots/GET_GAME_LIST_SUCCESS',
  error: '@slots/GET_GAME_LIST_ERROR',
  reset: '@slots/GET_GAME_LIST_RESET',
})<IGetGameList, any>(
  async ({
    deps: {
      extra: { api },
      dispatch,
      getState,
    },
    payload,
  }) => {
    const { id, provider } = payload

    const data = slotProvidersConfiguration[provider]

    if (!data) {
      throw new SyntaxError(`Provider "${provider}" not found in configuration`)
    }

    const state = getState()
    const { isAuth } = state.auth.data
    const providerList = state.slots.data.gameList.filter((t: ISlotViewItem) => t.routeProvider === provider)
    const listHasDefaultList = providerList.some((t: ISlotViewItem) => t.isDefault)

    let extraData = state.slots.data.extraData?.[provider] || {}

    const isExtraDataExist = provider in state.slots.data.extraData

    if (!isExtraDataExist) {
      const extraDataResponses = await Promise.all(
        data.extraData?.map(async _extraData => {
          if (_extraData.authRequired && !isAuth) return { data: {} }
          const { url, converter } = _extraData
          const result = await api.slots.getExtraData({ url, converter })
          if (result.success) {
            dispatch(getExtraDataSuccess({ provider, data: result.data }))
          }
          return result
        }) || [],
      )

      extraDataResponses.forEach((extraDataParam: any) => {
        extraData = {
          ...extraData,
          ...extraDataParam.data,
        }
      })
    }

    if (
      providerList.length &&
      !listHasDefaultList &&
      (!data.extraData || (data.extraData.length && Object.keys(state.slots.data.extraData[provider] || {}).length))
    ) {
      return { id }
    }

    const response = await api.slots.getGameList({ id, provider })

    if (response.success) {
      if (!data.uniqueField) {
        dispatch(getGameListReplaced({ gameList: response.data, provider }))
      } else {
        dispatch(getGameListInField({ gameList: response.data, provider }))
      }
      return { id }
    }
    dispatch(setActionFailure(response.errorMessage))

    return { id }
  },
)

//

export const getSessionUrl = makeCommunicationActionCreator({
  loading: '@slots/GET_SESSION_URL_LOADING',
  success: '@slots/GET_SESSION_URL_SUCCESS',
  error: '@slots/GET_SESSION_URL_ERROR',
  reset: '@slots/GET_SESSION_URL_RESET',
})<IGetSessionUrlPayload, void>(
  async ({
    deps: {
      extra: { api },
      dispatch,
      getState,
    },
    payload,
  }) => {
    const { provider, gameId, withRedirect = false, isDesktop = false, callback, demoStart, hasFreeSpins } = payload

    const state = getState()
    const { lang } = state.userSettings.data
    const extraData = state.slots.data.extraData[provider] || {}
    const { gameList } = state.slots.data
    const providerGameList = gameList.filter((t: ISlotViewItem) => t.routeProvider === provider)

    const response = await api.slots.getSessionUrl({
      provider,
      gameId,
      extraData,
      gameList: providerGameList,
      params: { lang, isDesktop },
      demoStart,
      hasFreeSpins,
    })

    if (response.success) {
      dispatch(loadSession(response.data))
    }
  },
)

export const getFavoriteGameList = makeCommunicationActionCreator({
  loading: '@slots/GET_FAVORITE_GAME_LIST_LOADING',
  success: '@slots/GET_FAVORITE_GAME_LIST_SUCCESS',
  error: '@slots/GET_FAVORITE_GAME_LIST_ERROR',
  reset: '@slots/GET_FAVORITE_GAME_LIST_RESET',
})(
  async ({
    deps: {
      extra: { api },
    },
  }) => {
    const response = await api.slots.getFavoriteGameList()
    return response.data
  },
)

export const addFavoriteSlot = makeCommunicationActionCreator({
  loading: '@slots/ADD_FAVORITE_SLOT_LOADING',
  success: '@slots/ADD_FAVORITE_SLOT_SUCCESS',
  error: '@slots/ADD_FAVORITE_SLOT_ERROR',
  reset: '@slots/ADD_FAVORITE_SLOT_RESET',
})<IFavoriteSlotPayload, any>(
  async ({
    deps: {
      extra: { api },
      dispatch,
      getState,
    },
    payload,
  }) => {
    const { provider, gameId } = payload
    const localeSlots = getState().locale.data.slots

    const response = await api.slots.addSlot(provider, gameId)

    if (response.success) {
      dispatch(addFavoriteSlotAction(`${gameId}_${provider}`))
      addNotify(localeSlots.slotSuccessfullyAdded, 'success')
    } else {
      addNotify(response.errorMessage, 'error')
    }
  },
)

export const removeFavoriteSlot = makeCommunicationActionCreator({
  loading: '@slots/REMOVE_FAVORITE_SLOT_LOADING',
  success: '@slots/REMOVE_FAVORITE_SLOT_SUCCESS',
  error: '@slots/REMOVE_FAVORITE_SLOT_ERROR',
  reset: '@slots/REMOVE_FAVORITE_SLOT_RESET',
})<IFavoriteSlotPayload, any>(
  async ({
    deps: {
      extra: { api },
      dispatch,
      getState,
    },
    payload,
  }) => {
    const { provider, gameId } = payload
    const localeSlots = getState().locale.data.slots

    const response = await api.slots.removeSlot(provider, gameId)

    if (response.success) {
      dispatch(removeFavoriteSlotAction(`${gameId}_${provider}`))
      addNotify(localeSlots.slotSuccessfullyRemoved, 'success')
    } else {
      addNotify(response.errorMessage, 'error')
    }
  },
)
