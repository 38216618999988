import { createReducer } from '@reduxjs/toolkit'

import * as actions from '../actions'
import { ISidePanelState } from '../types'

const initialState: ISidePanelState = {
  isLeftPanelOpen: false,
  isRightPanelOpen: false,
}

export default createReducer(initialState, builder => {
  builder.addCase(actions.changeOpenedLeftSidePanel, (state, action) => {
    state.isLeftPanelOpen = action.payload
  })
  builder.addCase(actions.changeOpenedRightSidePanel, (state, action) => {
    state.isRightPanelOpen = action.payload
  })
})
