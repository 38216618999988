import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'
import block from 'bem-cn'
import { AnimatePresence, motion } from 'framer-motion'

import { ReactComponent as CloseImg } from 'shared/img/modal-close.svg'

import { selectLocaleDict } from 'features/locale/selectors'

import Button from '../UIKit/Button/Button'

import './Modal.scss'

interface IModalProps {
  open: boolean
  handleClose: () => void
  bgColor?: string
  hideCloseBtn?: boolean
  noPaddings?: boolean
  handleSave?: () => void
  needSaveBtn?: () => boolean
}

const b = block('modal')

const Modal: React.FC<IModalProps> = ({
  open,
  handleClose,
  children,
  bgColor = '#332640',
  hideCloseBtn = false,
  noPaddings = false,
  handleSave,
  needSaveBtn = false,
}) => {
  const locale = useSelector(selectLocaleDict).common
  const stop = (e: React.SyntheticEvent) => e.stopPropagation()

  const content = (
    <AnimatePresence exitBeforeEnter>
      {open && (
        <motion.div
          className={b()}
          onMouseDown={handleClose}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.25 }}>
          <div className={b('wrapper')}>
            <div className={b('app-container', { noPaddings })}>
              <div
                className={b('content-container')}
                style={{ background: bgColor }}
                onMouseDown={stop}
                onMouseUp={stop}
                onClick={stop}>
                {needSaveBtn && (
                  <div className={b('save-block')}>
                    <Button text={locale.save} callback={handleSave} />
                  </div>
                )}
                {!hideCloseBtn && (
                  <div className={b('close-block')} onClick={handleClose}>
                    <CloseImg className={b('close-img')} />
                  </div>
                )}
                {children}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )

  const node = document.getElementById('modal_root')
  if (node !== null) {
    return ReactDOM.createPortal(content, node)
  }
  return null
}

export default Modal
