/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { LoadingButton as MButton } from '@mui/lab'
import { ButtonProps, ButtonPropsVariantOverrides, createTheme, ThemeProvider } from '@mui/material'
import block from 'bem-cn'

import './Button.scss'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    main: true
  }
}

type CustomButtonProps = ButtonProps & {
  text?: string | number
  callback?: (a: any) => void
  disabled?: boolean
  uppercase?: boolean
  link?: string
  variant?: string
  loading?: boolean
  rounded?: boolean
  colorType?: 'main' | 'secondary' | 'tertiary' | 'primary' | 'deposit' | 'gradient' | 'withdrawal' 
  appearance?: 'ghost'
}

const Button: React.FC<CustomButtonProps> = ({
  text,
  callback,
  variant = 'contained',
  children,
  disabled = false,
  uppercase = false,
  colorType = 'main',
  type,
  loading,
  rounded = false,
  link,
  ...props
}) => {
  const b = block('button')
  const history = useHistory()

  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            '& svg *': {
              stroke: '#fff',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: rounded ? 5 : 2,
          },
        },
        variants: [
          {
            props: { variant: 'main' },
            style: {
              textTransform: 'none',
              fontVariant: 'inherit',
              fontStretch: 'inherit',
              fontFamily: 'inherit',
              fontSize: 14,
              color: 'white',
              height: '28px',
              '&:hover': {
                backgroundColor: '#63b75d',
                borderColor: '#63b75d',
                opacity: 0.8,
              },
              '&:focus': {
                backgroundColor: '#63b75d',
              },
              '&:disabled': {
                backgroundColor: '#63b75d',
                opacity: 0.5,
                '&:hover': {
                  opacity: 0.5,
                },
              },
            },
          },
          {
            props: { variant: 'outlined' },
            style: {
              borderColor: '#63b75d',
              '&:focus': {
                backgroundColor: '#63b75d',
              },
              '&:disabled': {
                backgroundColor: '#63b75d',
                opacity: 0.5,
                '&:hover': {
                  opacity: 0.5,
                },
              },
              textTransform: 'none',
              fontVariant: 'inherit',
              fontStretch: 'inherit',
              fontFamily: 'inherit',
              fontSize: 14,
              height: '28px',
              backgroundColor: 'transparent',
              color: 'white',
              '&:hover': {
                background: 'transparent',
                opacity: 0.8,
                color: 'white',
                borderColor: '#63b75d',
              },
            },
          },
        ],
      },
    },
  })

  const onClick = useCallback(
    (...args) => {
      if (link && !disabled) {
        history.push(link)
      } else if (callback && !disabled) {
        callback(args)
      }
    },
    [history, link, callback, disabled],
  )

  return (
    <div className={b('button-container', { uppercase, disabled, loading })}>
      <ThemeProvider theme={theme}>
        <MButton
          {...props}
          disableElevation
          type={type}
          loading={loading}
          variant={variant}
          disabled={disabled}
          className={b({ colors: colorType }, { variant })}
          onClick={disabled ? (d: any) => d : onClick}>
          {text}
          {children}
        </MButton>
      </ThemeProvider>
    </div>
  )
}

export default Button
