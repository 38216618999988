import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTE_PATHS } from 'router/routes'

const PromotionsPage = React.lazy(() => import('pages/PromotionsPage/PromotionsPage'))

export class PromotionsModule {
  getRoutes() {
    return (
      <Route key={ROUTE_PATHS.PROMOTIONS} path={ROUTE_PATHS.PROMOTIONS}>
        <Switch>
          <Route key={ROUTE_PATHS.PROMOTIONS} path={ROUTE_PATHS.PROMOTIONS} component={PromotionsPage} />
        </Switch>
      </Route>
    )
  }
}
