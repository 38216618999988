import { ROUTE_URLS } from 'router/routes'
import img1 from '../../../shared/img/footer/kryptocasinos.svg'

import img2 from '../../../shared/img/footer/sigma_star.svg'
import img3 from '../../../shared/img/footer/sigma_casino.svg'
import img4 from '../../../shared/img/footer/sigma_casino2.svg'

interface routesPops {
  route: string
  text: string
}

const GameItem: routesPops[] = [
  { text: 'slots', route: ROUTE_URLS.SLOTS_GAMETYPE('tv-games') },
  { text: 'table games', route: ROUTE_URLS.TABLEGAMES() },
  { text: 'live games', route: ROUTE_URLS.LIVEGAME() },
  { text: 'other games', route: ROUTE_URLS.OTHERGAMES() },
  { text: 'jackpot games', route: ROUTE_URLS.JACKPOTGAMES() },
  { text: 'feature buy', route: ROUTE_URLS.FEATURE_BUY() },
]

const CasinoItem: routesPops[] = [
  { text: 'promotion', route: ROUTE_URLS.PROMOTIONS() },
  { text: 'affiliate program', route: ROUTE_URLS.AFFILIATE_PROGRAM() },
  { text: 'about us', route: ROUTE_URLS.ABOUTUS() },
]

const LegalItem: routesPops[] = [
  { text: 'privacy policy', route: ROUTE_URLS.PRIVACY_POLICY() },
  { text: 'sportsbook T&C', route: ROUTE_URLS.SPORTSBOOK_POLICY() },
  { text: 'terms & conditions', route: ROUTE_URLS.TERMSCONDITIONS_POLICY() },
  { text: 'other games', route: ROUTE_URLS.OTHERGAMES() },
  { text: 'bonus terms & conditions', route: ROUTE_URLS.BONUS_TERMS_POLICY() },
  { text: 'responsible gambling', route: ROUTE_URLS.RESPONSIBLE_GAMBLING() },
  { text: 'underage gaming policy', route: ROUTE_URLS.UNDERAGE_GAMING_POLICY() },
]

const SupportItem: routesPops[] = [
  { text: 'email support', route: ROUTE_URLS.VERIFY() },
  { text: 'FAQ', route: ROUTE_URLS.HELP_FAQ() },
]

const copyRightItem = {
  item1: {
    copyRightImage: img1,
  },
  item2: {
    copyRightImage: img2,
  },
  item3: {
    copyRightImage: img3,
  },

  item4: {
    copyRightImage: img4,
  },
}

export { copyRightItem, GameItem, CasinoItem, LegalItem, SupportItem }
