import { ICashbackHistoryFilters, IGetBonusHistoryFilters } from 'shared/models/BonusSystem'
import { deleteEmptyFields } from 'shared/utils/filters'

import { BaseHttpService } from '../BaseHttpService'
import { QueryTypes } from '../data'
import { BonusSystemConverter as BonusSystem } from './BonusSystemConverter'

class BonusSystemApi extends BaseHttpService {
  private converter

  constructor(baseUrl: string) {
    super(baseUrl)
    this.baseUrl = `${baseUrl}/api/bonus-system`
    this.converter = new BonusSystem()
  }

  async exchangeBonusBalanceToBalance({ amount }: { amount: number }) {
    return this.sendQuery({
      url: `${this.baseUrl}/bonusbalance-to-balance/`,
      type: QueryTypes.POST,
      data: { amount },
    })
  }

  async loadCashbackHistory(filters: ICashbackHistoryFilters) {
    const filtersWithFields = deleteEmptyFields(filters)

    return this.sendQuery({
      url: `${this.baseUrl}/cashback/history`,
      type: QueryTypes.GET,
      data: { params: filtersWithFields },
      converterSuccess: this.converter.convertBonusHistory,
    })
  }

  async loadBonusHistory(filters: IGetBonusHistoryFilters) {
    const filtersWithFields = deleteEmptyFields(filters)

    return this.sendQuery({
      url: `${this.baseUrl}/bonusbalance/history`,
      type: QueryTypes.GET,
      data: { params: filtersWithFields },
      converterSuccess: this.converter.convertBonusHistory,
    })
  }

  async getUserPromotions() {
    return this.sendQuery({
      url: `${this.baseUrl}/promotions/user/`,
      type: QueryTypes.GET,
      converterSuccess: this.converter.convertUserPromotions,
    })
  }

  async changeActiveUserPromotion({ id }: { id: string }) {
    return this.sendQuery({
      url: `${this.baseUrl}/promotions/change-active/${id}`,
      type: QueryTypes.POST,
    })
  }

  async getMoneyFromPromotion({ id }: { id: string }) {
    return this.sendQuery({
      url: `${this.baseUrl}/promotions/user/promotion/${id}/pay`,
      type: QueryTypes.POST,
      converterSuccess: this.converter.convertUserPromotionItem,
    })
  }
}

export default BonusSystemApi
