import React, { FunctionComponent, SVGProps } from 'react'
import { ROUTE_URLS } from 'router/routes'
import { ReactComponent as PromotionsIcon } from '../../../shared/img/sidebar/promotions.svg';
import { ReactComponent as jackpot } from '../../../shared/img/sidebar/jackpot.svg';
import { ReactComponent as liveSupport } from '../../../shared/img/sidebar/live-support.svg';

import { ReactComponent as LiveCasinoIcon } from '../../../shared/img/sidebar/live-casino.svg';
import { ReactComponent as TableGamesIcon } from '../../../shared/img/sidebar/table-games.svg';
import { ReactComponent as NewGamesIcon } from '../../../shared/img/sidebar/new-games.svg';
import { ReactComponent as SlotsIcon } from '../../../shared/img/sidebar/slots.svg';
import { ReactComponent as JackpotGamesIcon } from '../../../shared/img/sidebar/jackpot-games.svg';
import { ReactComponent as ProvidersIcon } from '../../../shared/img/sidebar/providers.svg';
import { ReactComponent as TopGamesIcon } from '../../../shared/img/sidebar/top-games.svg';

export interface INavigationItem {
  text: string
  route: string
  icon?: string
  svg?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  modalRoute?: string
  isAuthReq?: boolean
  isNew?: boolean
  isTopGame?: boolean
}

export const forYouLinks: INavigationItem[] = [
  { text: 'Promotions', route: ROUTE_URLS.PROMOTIONS(), svg: PromotionsIcon },
  { text: 'Jackpot', route: ROUTE_URLS.JACKPOT(), svg: jackpot },
  { text: 'Live Support', route: ROUTE_URLS.LIVESUPPORT(), svg: liveSupport },
]

export const gamesLinks: INavigationItem[] = [
  { text: 'Top Games', route: ROUTE_URLS.TOPGAMES(), svg: TopGamesIcon },
  { text: 'New Games', route: ROUTE_URLS.NEWGAMES(), svg: NewGamesIcon },
  { text: 'Slots', route: ROUTE_URLS.SLOTS(), svg: SlotsIcon },
  { text: 'Live Casino', route: ROUTE_URLS.LIVECASINO(), svg: LiveCasinoIcon},
  { text: 'Table Games', route: ROUTE_URLS.TABLEGAMES(), svg: TableGamesIcon },
  { text: 'Jackpot Games', route: ROUTE_URLS.JACKPOTGAMES(), svg: JackpotGamesIcon },
  { text: 'Providers', route: ROUTE_URLS.PROVIDERS(), svg: ProvidersIcon },
]


