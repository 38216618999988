import { createAction } from '@reduxjs/toolkit'

import { IDefaultPageFilters } from 'services/api/ProfileApi/types/types'

import { defaultCurrency } from 'shared/constants'
import {
  AvailableCryptoCurrenciesList,
  ICreateCryptoPaymentPayload,
  IGetCryptoMinAmountPayload,
} from 'shared/models/Crypto'
import {
  IDepositCountry,
  IDepositPayload,
  IDepositPayloadDTO,
  IDepositResponse,
  OrderProvidersListType,
} from 'shared/models/Deposit'
import { makeCommunicationActionCreator } from 'shared/utils/communication/actions/makeCommunicationActionCreator'
// eslint-disable-next-line max-len
import { makeCommunicationByIdActionCreator } from 'shared/utils/communication/actions/makeCommunicationByIdActionCreator'
import { defaultDepositConfig } from 'shared/utils/deposit'

import { addNotify } from 'components/Notify'

import {
  IApproveListItem,
  IConvertedHistoryPayment,
  ICreateCryptoPaymentData,
  ICreateDepositPayload,
  ICreatePayoutPayload,
  ICryptoCurrencyWithMinAndMaxItem,
  ICryptoMinAmount,
  IDefaultDataConverted,
  IDefaultFilters,
} from '../../services/api/PaymentApi/types/types'
import { ISystemListState } from './types'
/* eslint-disable no-debugger */

export const clearDepositCreated = createAction('CLEAR_DEPOSIT_CREATED')

export const clearCryptoPaymentData = createAction('CLEAR_CRYPTO_PAYMENT_DATA')

export const getDepositSystemList = makeCommunicationActionCreator({
  loading: '@payment/GET_DEPOSIT_SYSTEM_LIST_LOADING',
  success: '@payment/GET_DEPOSIT_SYSTEM_LIST_SUCCESS',
  error: '@payment/GET_DEPOSIT_SYSTEM_LIST_ERROR',
  reset: '@payment/GET_DEPOSIT_SYSTEM_LIST_RESET',
})<IDefaultFilters, ISystemListState>(
  async ({
    deps: {
      extra: { api },
    },
    payload,
  }) => {
    const response = await api.payment.getInvoiceSystemList(payload)
    return response.data
  },
)

export const createDeposit = makeCommunicationByIdActionCreator({
  loading: '@payment/CREATE_DEPOSIT_LOADING',
  success: '@payment/CREATE_DEPOSIT_SUCCESS',
  error: '@payment/CREATE_DEPOSIT_ERROR',
  reset: '@payment/CREATE_DEPOSIT_RESET',
})<ICreateDepositPayload, IApproveListItem>(
  async ({
    deps: {
      extra: { api },
    },
    payload,
  }) => {
    const response = await api.payment.setInvoice(payload)
    return { id: payload.id, ...response.data }
  },
)

export const createPayout = makeCommunicationActionCreator({
  loading: '@payment/CREATE_PAYOUT_LOADING',
  success: '@payment/CREATE_PAYOUT_SUCCESS',
  error: '@payment/CREATE_PAYOUT_ERROR',
  reset: '@payment/CREATE_PAYOUT_RESET',
})<ICreatePayoutPayload, IApproveListItem, { callback: () => void }>(
  async ({
    deps: {
      extra: { api },
      getState,
    },
    payload,
    args,
  }) => {
    const locale: any = getState().locale.data.payment

    const response = await api.payment.setPayout(payload)
    if (response.success) {
      addNotify(locale.paymentStatuses[response.data.status || ''], 'success')
      if (args?.callback) {
        args.callback()
      }
      return response.data
    }
    return response.data
  },
)

export const getPayoutSystemList = makeCommunicationActionCreator({
  loading: '@payment/GET_PAYOUT_SYSTEM_LIST_LOADING',
  success: '@payment/GET_PAYOUT_SYSTEM_LIST_SUCCESS',
  error: '@payment/GET_PAYOUT_SYSTEM_LIST_ERROR',
  reset: '@payment/GET_PAYOUT_SYSTEM_LIST_RESET',
})<IDefaultFilters, ISystemListState>(
  async ({
    deps: {
      extra: { api },
    },
    payload,
  }) => {
    const response = await api.payment.getPayoutSystemList(payload)
    return response.data
  },
)

export const getDepositList = makeCommunicationActionCreator({
  loading: '@payment/GET_DEPOSIT_LIST_LOADING',
  success: '@payment/GET_DEPOSIT_LIST_SUCCESS',
  error: '@payment/GET_DEPOSIT_LIST_ERROR',
  reset: '@payment/GET_DEPOSIT_LIST_RESET',
})<IDefaultFilters, IDefaultDataConverted<IApproveListItem>>(
  async ({
    deps: {
      extra: { api },
    },
    payload,
  }) => {
    const response = await api.payment.getInvoiceList(payload)
    return response.data
  },
)

export const getPayoutList = makeCommunicationActionCreator({
  loading: '@payment/GET_PAYOUT_LIST_LOADING',
  success: '@payment/GET_PAYOUT_LIST_SUCCESS',
  error: '@payment/GET_PAYOUT_LIST_ERROR',
  reset: '@payment/GET_PAYOUT_LIST_RESET',
})<IDefaultFilters, IDefaultDataConverted<IApproveListItem>>(
  async ({
    deps: {
      extra: { api },
    },
    payload,
  }) => {
    const response = await api.payment.getPayoutList(payload)
    return response.data
  },
)

export const getCryptoCurrenciesList = makeCommunicationActionCreator({
  loading: '@payment/GET_CRYPTO_CURRENCIES_LIST_LOADING',
  success: '@payment/GET_CRYPTO_CURRENCIES_LIST_SUCCESS',
  error: '@payment/GET_CRYPTO_CURRENCIES_LIST_ERROR',
  reset: '@payment/GET_CRYPTO_CURRENCIES_LIST_RESET',
})<void, ICryptoCurrencyWithMinAndMaxItem[]>(
  async ({
    deps: {
      extra: { api },
    },
  }) => {
    const response = await api.payment.getCryptoCurrencies()
    return response.data
  },
)

export const createCryptoPayment = makeCommunicationByIdActionCreator({
  loading: '@payment/CREATE_CRYPTO_PAYMENT_LOADING',
  success: '@payment/CREATE_CRYPTO_PAYMENT_SUCCESS',
  error: '@payment/CREATE_CRYPTO_PAYMENT_ERROR',
  reset: '@payment/CREATE_CRYPTO_PAYMENT_RESET',
})<ICreateCryptoPaymentPayload, ICreateCryptoPaymentData>(
  async ({
    deps: {
      extra: { api },
    },
    payload,
  }) => {
    const response = await api.payment.createCryptoPayment({ id: payload.id })

    if (response.success) {
      window?.fbq?.('track', 'Lead')
    }
    return response.data
  },
)

export const getCountriesForCryptoTransferOrder = makeCommunicationActionCreator({
  loading: '@payment/GET_COUNTRIES_CRYPTO_TRANSFER_ORDER_LOADING',
  success: '@payment/GET_COUNTRIES_CRYPTO_TRANSFER_ORDER_SUCCESS',
  error: '@payment/GET_COUNTRIES_CRYPTO_TRANSFER_ORDER_ERROR',
  reset: '@payment/GET_COUNTRIES_CRYPTO_TRANSFER_ORDER_RESET',
})<OrderProvidersListType, IDepositCountry[]>(
  async ({
    deps: {
      extra: { api },
    },
    payload,
  }) => {
    const response = await api.payment.getCountriesForCryptoTransferOrder(payload)
    return response.data
  },
)

export const createCryptoTransferOrder = makeCommunicationActionCreator({
  loading: '@payment/CREATE_CRYPTO_TRANSFER_ORDER_LOADING',
  success: '@payment/CREATE_CRYPTO_TRANSFER_ORDER_SUCCESS',
  error: '@payment/CREATE_CRYPTO_TRANSFER_ORDER_ERROR',
  reset: '@payment/CREATE_CRYPTO_TRANSFER_ORDER_RESET',
})<{ createPayment: ICreateCryptoPaymentPayload; transfer: IDepositPayload; callback: (url: string) => void }, void>(
  async ({
    deps: {
      extra: { api },
    },
    payload,
  }) => {
    const createCryptoPaymentResponse = await api.payment.createCryptoPayment(payload.createPayment)

    if (createCryptoPaymentResponse.success) {
      const dtoPayload: IDepositPayloadDTO = {
        amount: payload.transfer.amount.toString(),
        country: payload.transfer.country,
        wallet_address: createCryptoPaymentResponse.data.address,
        currency: defaultDepositConfig.currency,
        provider_code: defaultDepositConfig.provider_code,
        payment_method: defaultDepositConfig.payment_method,
        order_id: createCryptoPaymentResponse.data.orderId,
      }

      const response = await api.payment.createCryptoTransferOrder(dtoPayload)

      if (response.success) {
        console.log(response.data.redirectUrl)
        payload.callback(response.data.redirectUrl)
      }
    }
  },
)

export const getCryptoMinAmount = makeCommunicationByIdActionCreator({
  loading: '@payment/GET_CRYPTO_MIN_AMOUNT_LOADING',
  success: '@payment/GET_CRYPTO_MIN_AMOUNT__SUCCESS',
  error: '@payment/GET_CRYPTO_MIN_AMOUNT_ERROR',
  reset: '@payment/GET_CRYPTO_MIN_AMOUNT_RESET',
})<IGetCryptoMinAmountPayload, ICryptoMinAmount>(
  async ({
    deps: {
      extra: { api },
      getState,
    },
    payload,
  }) => {
    const currency = getState().auth.data.user?.currency || defaultCurrency

    const response = await api.payment.getCryptoMinAmount({ id: payload.id, fiat_currency: currency })
    return { id: response.data.currency, ...response.data }
  },
)

export const getOnlinePaymentHistory = makeCommunicationActionCreator({
  loading: '@profile/GET_HISTORY_PAYMENT_LOADING',
  success: '@profile/GET_HISTORY_PAYMENT_SUCCESS',
  error: '@profile/GET_HISTORY_PAYMENT_ERROR',
  reset: '@profile/GET_HISTORY_PAYMENT_RESET',
})<IDefaultPageFilters, IConvertedHistoryPayment>(
  async ({
    deps: {
      extra: { api },
    },
    payload,
  }) => {
    const response = await api.payment.getOnlinePaymentHistory(payload)
    return response.data
  },
)

export const cancelOnlinePayout = makeCommunicationByIdActionCreator({
  loading: '@profile/CANCEL_ONLINE_PAYMENT_LOADING',
  success: '@profile/CANCEL_ONLINE_PAYMENT_SUCCESS',
  error: '@profile/CANCEL_ONLINE_PAYMENT_ERROR',
  reset: '@profile/CANCEL_ONLINE_PAYMENT_RESET',
})<{ id: string }, { id: string }, { callback: () => void }>(
  async ({
    deps: {
      extra: { api },
      getState,
    },
    payload,
    args,
  }) => {
    const state = getState()
    const locale = state.locale.data.payment
    await api.payment.cancelOnlinePayout(payload.id)
    addNotify(locale.payoutCanceledSuccess, 'success')
    args?.callback()
    return { id: payload.id }
  },
)
