import React from 'react'

import MobileFilter from 'components/slots/MobileFilter/MobileFilter'

const Filter: React.FC = () => (
  <div>
    <MobileFilter />
  </div>
)

export default Filter
