import React from 'react'
import block from 'bem-cn'
import { copyRightItem } from '../data'
import "./copyRightSection.scss"
import img5 from "../../../shared/img/footer/Vector-18.svg"

const CopyRightSection = () => {
  const b = block('copy-right')

  return (
    <section className={b()}>
      <div className={b('copyright-container')}>
        <div className={b('copyleft')}>
          <h3>Copyright © 2024 spineo.io All rights reserved.</h3>
        </div>
        <div className={b('images')}>
          {Object.values(copyRightItem).map((item, index) => (
            <img key={index} src={item.copyRightImage} alt="" />
          ))}
          <div className="container">
            <img src={img5}className="image" alt="Vector-18" />
            <h3>18 <span>+</span></h3>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CopyRightSection
