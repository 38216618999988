/* eslint-disable camelcase */
import dayjs from 'dayjs'

import { IProfile, IServerProfile, IServerUserInfo, IUserInfo } from 'shared/models/Profile'

import { TransactionType } from '../PaymentApi/types/common'
import { IDefaultDataResponse, IDefaultDataResponseByService } from '../PaymentApi/types/types'
import { IConvertedTransactions, ITransactionListItemDTO } from './types/types'

export class ProfileConverter {
  convertProfileInfo(data: IServerProfile): IProfile {
    return {
      id: data.id,
      username: data.username,
      dateJoined: dayjs(data.date_joined).format('DD/MM/YYYY'),
      balanceCurrency: data.balance_currency,
      balance: data.balance,
      isBanned: data.is_banned,
    }
  }

  convertUserInfo(data: IServerUserInfo): IUserInfo {
    return {
      firstName: data.first_name,
      lastName: data.last_name,
      gender: data.gender,
      email: data.email,
      dateOfBirth: data.date_of_birth,
      country: data.country,
      city: data.city,
      mobileNumber: data.mobile_number,
    }
  }

  convertTransfersList = (data: IDefaultDataResponse<ITransactionListItemDTO>): IConvertedTransactions => {
    const withdrawals =
      data?.results?.map(item => {
        const { id, sender, recipient, status, amount, amount_currency, completed_at, created_at } = item

        return {
          id,
          fromId: sender.id,
          toId: recipient.id,
          amount: +(+amount).toFixed(2),
          currency: amount_currency,
          createdAt: dayjs(created_at).format('HH:mm:ss DD.MM.YYYY'),
          completedAt: completed_at ? dayjs(completed_at).format('HH:mm:ss DD.MM.YYYY') : '-',
          status,
          description: item.rejection_reason,
          recipientBalanceAfter: (+item.recipient_balance_after).toFixed(2),
          recipientBalanceBefore: (+item.recipient_balance_before).toFixed(2),
          senderBalanceAfter: (+item.sender_balance_after).toFixed(2),
          senderBalanceBefore: (+item.sender_balance_before).toFixed(2),
          type: item.payout ? ('withdraw' as TransactionType) : ('deposit' as TransactionType),
          paymentSystem: item.payout ? item.payout?.payout_system?.name : item.deposit?.payment_system?.name,
        }
      }) || []

    return {
      page: data.page,
      count: data.count,
      history: withdrawals,
    }
  }
}
