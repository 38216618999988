import block from 'bem-cn'
import React from 'react'
import './GameSection.scss'
import { Link } from 'react-router-dom'
import { ROUTE_URLS } from 'router/routes'
import PlayIcon from '../../shared/img/games-section/play-icon.png'

// Update the Icon property to React.ComponentType
export interface GameSectionProps {
  Icon: React.ComponentType // Use React.ComponentType for components
  headingText: string
  iconBg: string
  btnText: string
  image: string[]
}

// Update the props type for the GameSection component
const GameSection: React.FC<{ data: GameSectionProps }> = ({ data }) => {
  const { Icon, headingText, iconBg, btnText, image } = data
  const b = block('game-section')


  return (
    <section className={b()}>
      <div className={b('top-container')}>
        <div className={b('heading')}>
          <span className={`${b('heading-icon')} ${iconBg}`} >
            <Icon /> 
          </span>
          <h3>{headingText}</h3>
        </div>
        <Link className={b('button-link')} to={ROUTE_URLS.MAIN()}>
          {btnText}
        </Link>
      </div>

      <div className={b('games-container')}>
        {image.map((item, index) => (
          <div className={b('game-item')} key={index}>
            <img src={item} alt={item} className="game-img" />
            <div className={b('overlay')}>
              <div className="play-icon-bg">
                <img src={PlayIcon} alt="play-game" />
              </div>
              <p className='game-details'>
                <span style={{fontSize: 16, fontWeight: 'bold'}}>Aviator</span>
                <br /> <span style={{fontSize: 12}}>Pragmatic Play</span>
                <br />
                <span>Demo</span>{' '}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className={b('button-container1')}>
              <Link className={b('button-link-mobile')} to={ROUTE_URLS.MAIN()}>
                {btnText}
              </Link>
            </div>
    </section>
  )
}

export default GameSection
