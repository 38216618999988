import { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import intersection from 'lodash.intersection'
import uniq from 'lodash.uniq'

import { selectFavoriteList } from 'features/slots/selectors'

import { tabs } from '../data/tabs'
import { tabsLive } from '../data/tabsLive'
import { IFiltersSlots } from '../types/filters'
import { ISlotsGameType, ISlotViewItem } from '../types/interfaces/Slots/Slots'
import {
  categoriesSlots,
  categoriesSlotsFreeSpin,
  categoriesSlotsLive,
  categoriesSlotsTvGames,
  liveCasinoProviders,
  providersSortArray,
  virtualProviders,
} from '../utils/slots'
import { filterHiddenGames } from './useFilterSlots.utils'

export interface IUsefilterSlotsParameters {
  filters: IFiltersSlots
  slots: ISlotViewItem[]
  slotsCount?: number
  gameType?: ISlotsGameType
}

const useFilterSlots = ({ filters, slots: unFilteredSlots, gameType }: IUsefilterSlotsParameters) => {
  const { selectedCategories: sCategories, selectedProviders, searchString = '' } = filters
  const selectedCategories = useMemo(() => sCategories.filter(t => t !== 'all'), [sCategories])
  const favoriteSlots = useSelector(selectFavoriteList, shallowEqual)

  const slots = useMemo(() => filterHiddenGames(unFilteredSlots), [unFilteredSlots])

  const slotsByType = useMemo(() => {
    switch (gameType) {
      case 'casino':
        return slots.filter(game => ['deleted providers names'].includes(game.provider)) // deleted providers
      case 'live-casino':
        return slots.filter(game => liveCasinoProviders.includes(game.provider))
      case 'virtual':
        return slots.filter(game => virtualProviders.includes(game.provider))
      case 'free-spin':
        return unFilteredSlots.filter(game => game.hasFreeSpins)
      case 'wagering':
        return slots.filter(game => ['wagering games IDs list'].includes(game.gameId))
      default:
        return slots
    }
  }, [gameType, slots, unFilteredSlots])

  const tabByGameType = useMemo(
    () => (slot: ISlotViewItem) => {
      switch (gameType) {
        case 'live-casino':
          return slot.tabLive
        default:
          return slot.tab
      }
    },
    [gameType],
  )

  const tabNamesByGameType = useMemo(() => {
    switch (gameType) {
      case 'live-casino':
        return tabsLive
      default:
        return tabs
    }
  }, [gameType])

  const filteredSlots: ISlotViewItem[] = useMemo(
    () =>
      slotsByType
        .filter(slot => {
          const slotTab = tabByGameType(slot)
          return (
            (slot.gameName.toUpperCase().includes(searchString.toUpperCase()) || !searchString) &&
            (selectedProviders.includes(slot.provider.toLowerCase()) || !selectedProviders.length) &&
            (intersection(selectedCategories, slotTab).length ||
              !selectedCategories.length ||
              selectedCategories.includes('favorite'))
          )
        })
        .sort(
          (a, b) =>
            (providersSortArray.indexOf(a.provider) + 1 || Infinity) -
            (providersSortArray.indexOf(b.provider) + 1 || Infinity - 1),
        )
        .sort((a, b) => {
          const sortByCategory = tabNamesByGameType[selectedCategories[0]] || tabNamesByGameType.popular

          return (
            (sortByCategory.indexOf(a.gameId) + 1 || Infinity) - (sortByCategory.indexOf(b.gameId) + 1 || Infinity - 1)
          )
        }),
    [selectedCategories, slotsByType, selectedProviders, searchString, tabByGameType, tabNamesByGameType],
  )

  const providers = useMemo(() => {
    const allFilteredProviders = slotsByType.filter(
      temp =>
        intersection(selectedCategories, temp.tab).length ||
        intersection(selectedCategories, temp.tabLive).length ||
        !selectedCategories.length ||
        (favoriteSlots.includes(`${temp.gameId}_${temp.provider}`) && selectedCategories.includes('favorite')),
    )
    let countFilteredProviders: { [provider: string]: number } = { all: allFilteredProviders.length }
    allFilteredProviders
      .map(t => t.provider)
      .forEach(provider => {
        countFilteredProviders[provider] = (countFilteredProviders[provider] || 0) + 1
      })
    return {
      list: uniq(allFilteredProviders.map(t => t.provider)).sort(
        (a: string, b: string) =>
          (providersSortArray.indexOf(a.toLowerCase()) + 1 || Infinity - 1) -
          (providersSortArray.indexOf(b.toLowerCase()) + 1 || Infinity),
      ),
      counts: countFilteredProviders,
    }
  }, [slotsByType, selectedCategories, favoriteSlots])

  const categories = useMemo(() => {
    switch (gameType) {
      case 'casino':
        return categoriesSlots
      case 'live-casino':
        return categoriesSlotsLive
      case 'tv-games':
        return categoriesSlotsTvGames
      case 'free-spin':
        return categoriesSlotsFreeSpin
      default:
        return categoriesSlots
    }
  }, [gameType])

  const resultProviders = useMemo(() => ['all', ...providers.list], [providers.list])

  return {
    favoriteSlots,
    slots: filteredSlots,
    providers: resultProviders,
    providersCounts: providers.counts,
    categories,
    tabByGameType,
  }
}

export default useFilterSlots
