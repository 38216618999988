import React, { memo, ReactNode, useCallback, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useParams } from 'react-router-dom'
import block from 'bem-cn'
import { AnimatePresence } from 'framer-motion'

import { MODAL_ROUTE_URLS } from 'router/modalRoutes'
import { ROUTE_URLS } from 'router/routes'

import dropAndWinsPng from 'shared/img/slots/drop-and-wins-logo-white.png'
import { ReactComponent as PlaySVG } from 'shared/img/slots/play.svg'
import { ReactComponent as StarSVG } from 'shared/img/slots/star.svg'

import { useMediaMaxTablet } from 'shared/style/var'

import { ISlotsGameType, ISlotViewItem, SlotsGameType } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'
import { Providers } from 'modules/SlotsRoot/types/slot'
import { newProviderList } from 'modules/SlotsRoot/utils/slots'

import { selectIsUserAuthenticated } from 'features/auth/selectors'
import { selectOpenedFrames } from 'features/frameManager/selectors'
import { selectLocaleDict } from 'features/locale/selectors'
import { actions as slotsActions } from 'features/slots'
import { selectFavoriteList } from 'features/slots/selectors'

import { useModalContext } from 'components/HOC/withModalQuery/ModalContext'

import LoadableImage from 'components/LoadableImage'
import { addNotify } from 'components/Notify'
import { Tooltip } from 'components/Tooltip/Tooltip'

import SlotPreview from './SlotPreview/SlotPreview'

import './SlotsIcon.scss'

export interface ISlotsIconProps {
  slot: ISlotViewItem
  wide?: boolean
  preview?: boolean
  addFrame?: (slot: ISlotViewItem) => () => void
  Image?: ReactNode
}

const b = block('slots-icon')

const SlotsIcon: React.FC<ISlotsIconProps> = ({ slot, wide, preview, addFrame, Image }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const isMobile = useMediaMaxTablet()
  const { pushNewModal } = useModalContext()

  const { gameType: gameTypeParams }: { gameType: ISlotsGameType } = useParams()
  const gameType = gameTypeParams || 'casino'

  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)
  const locale = useSelector(selectLocaleDict, shallowEqual).slots
  const favoriteList = useSelector(selectFavoriteList, shallowEqual)
  const openedFrames = useSelector(selectOpenedFrames, shallowEqual)

  const [isError, setIsError] = useState(false)
  const [isPreviewOpen, setIsPreviewOpen] = useState(false)

  const isFavorite = useMemo(
    () =>
      slot.tab.indexOf('favorite') !== -1 ||
      favoriteList?.findIndex(t => t === `${slot.gameId}_${slot.provider}`) !== -1,
    [favoriteList, slot],
  )

  const onFavoriteClick = useCallback(
    e => {
      if (!isAuth) {
        addNotify(locale.pleaseAuth)
        return
      }
      e.preventDefault()
      e.stopPropagation()
      if (isFavorite) {
        dispatch(slotsActions.removeFavoriteSlot(slot))
      } else {
        dispatch(slotsActions.addFavoriteSlot(slot))
      }
    },
    [slot, isFavorite, isAuth, dispatch, locale],
  )

  const isOutcomeOpened = false

  const checkIsOutcomeOpened = useCallback(
    e => {
      if (isOutcomeOpened) {
        e.preventDefault()
        addNotify(locale.availableTwoGames)
      }
    },
    [isOutcomeOpened, locale],
  )

  const onClick = useCallback(
    (e, isDemo = false) => {
      if (!isAuth && !isDemo) {
        e.preventDefault()
        addNotify(locale.pleaseAuth)
      }

      if (isMobile && (isAuth || isDemo)) {
        e.preventDefault()
        pushNewModal(
          isDemo
            ? MODAL_ROUTE_URLS.REPLACING_SLOT_FRAME_DEMO(slot.routeProvider, slot.gameId)
            : MODAL_ROUTE_URLS.REPLACING_SLOT_FRAME(slot.routeProvider, slot.gameId),
        )()
      }

      checkIsOutcomeOpened(e)
    },
    [checkIsOutcomeOpened, isAuth, locale, isMobile, pushNewModal, slot],
  )

  const onPreviewClick = () => setIsPreviewOpen(prevState => !prevState)

  const overlayContentByCasinoType = React.useMemo(() => {
    switch (gameType) {
      case SlotsGameType.freeSpin:
        return (
          <div className={b('overlay__content')}>
            <div className={b('favorite-wrapper')} onClick={onFavoriteClick}>
              <Tooltip text={isFavorite ? locale.removeFromFavorites : locale.addToFavorites}>
                <StarSVG className={b('favorite', { isActive: isFavorite }).toString()} />
              </Tooltip>
            </div>
            <Link
              to={ROUTE_URLS.SLOTS_PROVIDER_FREE_SPIN(
                slot.routeProvider,
                slot.gameId,
                SlotsGameType.freeSpin,
                gameType,
              )}
              className={b('overlay__icon-wrapper')}
              onClick={onClick}>
              <PlaySVG className={b('overlay__icon')} />
            </Link>
            {!isOutcomeOpened && (
              <div className={b('overlay__title', { demo: slot.hasDemoMode })}>{locale.freeSpins}</div>
            )}
          </div>
        )
      default:
        return !addFrame ? (
          <div className={b('overlay__content')}>
            <div className={b('favorite-wrapper')} onClick={onFavoriteClick}>
              <Tooltip text={isFavorite ? locale.removeFromFavorites : locale.addToFavorites}>
                <StarSVG className={b('favorite', { isActive: isFavorite }).toString()} />
              </Tooltip>
            </div>
            <div className={b('overlay__content_header')}>{slot.gameName}</div>
            {!isOutcomeOpened && (
              <div className={b('overlay__title', { demo: slot.hasDemoMode && !slot.tabLive.length })}>
                {!slot.hasDemoMode || slot.tabLive.length ? (
                  <Link
                    className={b('overlay__button', { yellow: true })}
                    to={{
                      state: { prevPath: `${location.pathname}${location.search}` },
                      pathname: ROUTE_URLS.SLOTS_PROVIDER(slot.routeProvider, slot.gameId, gameType),
                    }}
                    onClick={onClick}>
                    {locale.play}
                  </Link>
                ) : (
                  <>
                    <Link
                      className={b('overlay__button', { yellow: true })}
                      to={{
                        state: { prevPath: `${location.pathname}${location.search}` },
                        pathname: ROUTE_URLS.SLOTS_PROVIDER(slot.routeProvider, slot.gameId, gameType),
                      }}
                      onClick={onClick}>
                      {locale.play}
                    </Link>
                    <Link
                      className={b('overlay__button')}
                      onClick={checkIsOutcomeOpened}
                      to={ROUTE_URLS.SLOTS_PROVIDER_DEMO_START(slot.routeProvider, slot.gameId, gameType, 'd')}>
                      {locale.demoStart}
                    </Link>
                  </>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className={b('add-frame-container')}>
            <div className={b('add-frame')} onClick={addFrame(slot)}>
              +
            </div>
          </div>
        )
    }
  }, [
    gameType,
    onFavoriteClick,
    isFavorite,
    locale,
    slot,
    onClick,
    isOutcomeOpened,
    addFrame,
    location,
    checkIsOutcomeOpened,
  ])

  const isDropAndWinSlot = useMemo(() => slot.tab.includes('Drops & Wins'), [slot])
  const isNewProvider = useMemo(() => newProviderList.includes(slot.provider), [slot])

  return (
    <div className={b({ wide: !!(wide && slot.imageUrlWide) }, { preview })} key={slot.gameId}>
      {isNewProvider ? <div className={b('label-new')}>{locale.new}</div> : null}

      <div className={b('disabled-overlay', { disabled: isOutcomeOpened })} />

      <div className={b('img-container')}>
        <div className={b('overlay')}>{overlayContentByCasinoType}</div>
        <div className={b('img-content')}>
          {Image ||
            (isError ? (
              <div className={b('img-plug')}>{slot.gameName}</div>
            ) : (
              <LoadableImage
                className={b('img', { wide })}
                src={wide && slot.imageUrlWide ? slot.imageUrlWide : slot.imageUrl}
                onError={() => setIsError(true)}
                animationType="smallToBig"
              />
            ))}
          {isDropAndWinSlot && (
            <div className={b('dropWins-wrapper')}>
              <img src={dropAndWinsPng} alt="Drops & Wins" className={b('dropWins-image')} />
            </div>
          )}
        </div>
      </div>
      <div className={b('open-preview')} onClick={onPreviewClick} />
      <AnimatePresence>
        {isPreviewOpen && (
          <SlotPreview
            img={slot.imageUrl}
            name={slot.gameName}
            isFavorite={isFavorite}
            playRoute={ROUTE_URLS.SLOTS_PROVIDER(slot.routeProvider, slot.gameId, gameType)}
            demoRoute={
              !slot.hasDemoMode || slot.tabLive.length
                ? undefined
                : ROUTE_URLS.SLOTS_PROVIDER_DEMO_START(slot.routeProvider, slot.gameId, gameType, 'd')
            }
            clickHandler={onClick}
            favoriteHandler={onFavoriteClick}
            close={onPreviewClick}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default memo(SlotsIcon)
