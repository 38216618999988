import { createReducer } from '@reduxjs/toolkit'

import { locale } from 'shared/locale/en-US'

import * as actions from '../actions'
import { ILocaleState } from '../types'

const initialState: ILocaleState = {
  data: locale,
}

export default createReducer(initialState, builder => {
  builder.addCase(actions.changeLang, (state, action) => {
    state.data = action.payload
  })
})
