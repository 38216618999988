import React, { useState } from "react";
import "./HeaderAchievements.scss";
import block from "bem-cn";
import { IoIosArrowDown } from "react-icons/io";
import jackpot from "../../shared/img/achievements/jackpots.png";

import { prizeData } from "./data/index";
import icon1 from "../../shared/img/headerAch/Vector.svg";
import PopupSection from "./pop-up/PopUp";

const HeaderAchievements = () => {
  const b = block("header-achievements");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  return (
    <section className={b()}>
      <div className={b("updated-wrapper")}>
        <article className={b("wrapper")}>
          <div className={b("section")}>
            <span>Jackpots</span>
            <span className={b("section-logo")}>
              <img src={jackpot} alt="jackpot" /> Spineo.io
            </span>
          </div>
          <div className={b("prize-container")}>
            {prizeData.map((item, index) => (
              <ul
                className={
                  item.text1 != "Middle"
                    ? b("prize-section")
                    : b("prize-section2")
                }
                key={index}
              >
                <li
                  className={b("prize-img")}
                  style={!item.svg ? { paddingLeft: 0 } : { paddingLeft: 0 }}
                >
                  {item.svg && <img src={item.svg} alt="awards" />}
                </li>
                <li>{item.text1}</li>
                <li>{item.text2}</li>
              </ul>
            ))}
          </div>

          <div className={b("prize-container-mobile")}>
            <h2>Prize Pool</h2>
            <h3>400 000.40 USDT</h3>
          </div>
          <div className={b("icon-drop")}>
            <img src={icon1} alt="" />
            <div className={b("arrow")} onClick={toggleDrawer}>
              <IoIosArrowDown className={b("arrow-icon")} />
            </div>
          </div>
        </article>
        <div className={b("wrapper2")}>More Info</div>
      </div>
      {isDrawerOpen && (
        <PopupSection onClose={toggleDrawer} isOpen={isDrawerOpen} />
      )}
    </section>
  );
};

export default HeaderAchievements;
