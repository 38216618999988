/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { selectIsUserAuthenticated } from 'features/auth/selectors'

const protectedModal = (WrappedComponent: React.FC) => (props: any) => {
  const { goBack } = useHistory()
  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)

  useEffect(() => {
    if (isAuth) {
      goBack()
    }
  }, [])

  return <WrappedComponent {...props} />
}

export { protectedModal }
