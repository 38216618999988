import { IApplicationState } from 'setup/store'

import { INotifiesStatePersisted } from 'features/notifies/types'

type RetunMigrationType = (state: IApplicationState) => {
  notifies: INotifiesStatePersisted
}

export const notifiesMigration: Record<string, RetunMigrationType> = {
  1: (state: IApplicationState) => ({
    notifies: {
      ...state.notifies,
      personalJackpot: {
        all: {},
        hide: [],
      },
    },
  }),
}
