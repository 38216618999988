/* eslint-disable max-len */
import React, { useEffect, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useParams } from 'react-router-dom'
import { animateScroll } from 'react-scroll'
import block from 'bem-cn'

import { mediaSize } from 'shared/style/var'

import { withFetchSlotsLayoutContentDesktop } from 'modules/SlotsRoot/components/withFetchSlotsLayoutContent'
import { useFilterSlots } from 'modules/SlotsRoot/hooks'
import { IFiltersSlots } from 'modules/SlotsRoot/types/filters'
import { ISlotsGameType, ISlotViewItem } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'

import { selectOpenedFrames } from 'features/frameManager/selectors'
import { selectLocaleDict } from 'features/locale/selectors'
import { selectFavoriteList, selectGameList, selectGameListLoading } from 'features/slots/selectors'

import Spinner from 'components/Spinner'

import { AllSlotsList } from './AllSlotsList/AllSlotsList'
import SlotsNavigation from './SlotsNavigation/SlotsNavigation'
import TopSlotsList from './TopSlotsList/TopSlotsList'

import './SlotsLayoutContent.scss'

const FilterBlock = React.lazy(() => import('components/slots/FilterBlock/FilterBlock'))

interface ISlotsLayoutContentProps {
  filters: IFiltersSlots
  modal?: boolean
  addFrame?: (slot: ISlotViewItem) => () => void
  gameTypeModal?: ISlotsGameType
}

const b = block('slots-layout-content')

const SlotsLayoutContent: React.FC<ISlotsLayoutContentProps> = ({ filters, modal, addFrame, gameTypeModal }) => {
  const { selectedProviders, selectedCategories } = filters

  const locale = useSelector(selectLocaleDict, shallowEqual).slots

  const gameList = useSelector(selectGameList, shallowEqual)

  const favoritesList = useSelector(selectFavoriteList, shallowEqual)
  const openedFrames = useSelector(selectOpenedFrames, shallowEqual)
  const openedGames = useMemo(() => openedFrames.map(frame => frame.gameId), [openedFrames])
  const gameListLoading = useSelector(selectGameListLoading, shallowEqual)

  const { gameType: gameTypeParams }: { gameType: ISlotsGameType } = useParams()

  let gameType = gameTypeModal || gameTypeParams

  const isMobileOrTablet = useMediaQuery({ maxWidth: mediaSize.tablet })

  const filteredGameList = useMemo(
    () => gameList.filter(game => !openedGames.includes(game.gameId)),
    [gameList, openedGames],
  )

  const { slots, providers, categories, providersCounts } = useFilterSlots({
    filters,
    slots: filteredGameList,
    gameType,
  })

  const filterCategories = useMemo(() => ['favorite', 'all', ...categories], [categories])

  const filteredListProviders = useMemo(
    () => slots.filter(t => !selectedProviders[0] || t.provider === selectedProviders[0]),
    [slots, selectedProviders],
  )

  const slotsList = useMemo(() => {
    if (selectedCategories.indexOf('favorite') !== -1) {
      return filteredListProviders.filter(slot => favoritesList.indexOf(`${slot.gameId}_${slot.provider}`) !== -1)
    }
    return filteredListProviders
  }, [favoritesList, filteredListProviders, selectedCategories])

  useEffect(() => {
    animateScroll.scrollToTop()
  }, [])

  return (
    <div className={b({ modal })}>
      <Spinner isLoading={gameListLoading} />
      {!modal && <TopSlotsList slots={gameList} gameType={gameType} />}
      <FilterBlock
        isMobileOrTablet={isMobileOrTablet}
        locale={locale}
        providers={providers}
        providersCounts={providersCounts}
        categories={filterCategories}
        filters={filters}
      />
      <div className={b('wrapper')}>
        <div className={b('container')}>
          <AllSlotsList modal={modal} addFrame={addFrame} slotsList={slotsList} filters={filters} />
        </div>
      </div>
      {!modal && <SlotsNavigation />}
    </div>
  )
}

export default withFetchSlotsLayoutContentDesktop(SlotsLayoutContent)
