import styled from 'styled-components'

import { device } from 'shared/style/var'

interface IStyledAuthInputWrapperProps {
  withBottomText?: boolean
}

export const StyledAuthForm = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  flex-grow: 1;
  font-size: 1.1429rem;
  padding: 16px 27.5px 60px;
  font-size: 12px;
  /* padding-bottom: 1.7rem !important;
  @media screen and ${device.tabletMax} {
    padding: 4.8rem 2.7rem;
  } */
`
export const StyledAuthHeader = styled.h3`
  font-size: 1.8rem;
  font-weight: 700;
  color: #000;
  text-align: center;
  margin-bottom: 1.7rem;
  text-transform: capitalize;
`

export const StyledAuthLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5rem;
  svg {
    height: 8rem;
    path:first-child {
      fill: rgb(117, 86, 190);
    }
  }
`

export const StyledAuthBody = styled.div`
  color: #fff;
  text-align: center;
  margin: 1rem 0;
  font-size: 1.8rem;
`

export const StyledAuthPaginator = styled.div`
  border-top: 1px solid #ccc;
  align-items: center;
  justify-content: center;
  height: 9rem;
  color: rgb(77, 77, 77);
  padding: 2rem 2rem;
  display: block;
  width: 100%;
  text-align: center;
`
export const StyledAuthPaginatorItem = styled.span`
  align-items: center;
  justify-content: center;
  color: rgb(77, 77, 77);
  font-size: 1.4rem;
  display: block;
  width: 100%;
  text-align: center;
`
export const StyledAuthPaginatorLink = styled.div`
  display: inline;
  text-align: center;
  text-decoration: none;
  margin-left: 5px;
  color: #095a89;
  cursor: pointer;
`

export const StyledAuthInputWrapper = styled.div<IStyledAuthInputWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  width: 100%;
  min-width: 160px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
`

export const StyledAuthHeaderLocale = styled.span`
  text-transform: lowercase;
  margin-left: 0.5rem;
`

export const StyledButtonCointaner = styled.div`
  display: inline-block;
  margin: 1.7rem auto;
  height: 48px;
  min-width: 140px;
`

export const StyledAuthModal = styled.div<{ width?: string }>`
  width: ${props => (props.width ? props.width : '435px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;

  @media screen and ${device.tabletMax} {
    width: 100%;
  }
`
