/* eslint-disable max-len */
export const payment: { [key: string]: string } = {
  redirecting: 'Redirecting...',
  weAreProcessingRedirectTo: 'We are processing redirect to payment system',
  ifRedirectDidntHappen: 'If redirect did not happen, press the button "redirect"',
  redirect: 'Redirect',
  enterAmountMoreThan: 'Enter amount more than :amount',
  transactions: 'Transactions',
  numberLenghtErr: 'Lenght of bank number should be 12-19',
  balance: 'Balance',
  number: 'Number',
  all: 'All',
  withdrawDescription:
    'Attention! Due to technical problems, payments to the bank account may be delayed. We apologize for the inconvenience caused!',

  setTopUpMethod: 'Choose one of the replenishment methods',
  setWitdrawalpMethod: 'Choose one of the withdrawal methods',
  setAmount: 'Indicate the amount to replenish:',
  setAmountWithdrawal: 'Indicate the amount for withdrawal:',
  setReqWithdrawal: 'Indicate details:',
  topUp: 'TopUp account',
  topUpRules: 'Rules of replenishment',
  amount: 'Amount',
  phone: 'Phone number',
  phoneRegulation: 'Enter a phone number in international format',
  visa: 'VISA',
  mastercard: 'Mastercard',
  payHistory: 'Operations history',
  backToProfile: 'Back to profile',
  litecoin: 'Litecoin',
  bitcoin: 'Bitcoin',
  payeer: 'Payeer',
  perfectMoney: 'Perfect Money',
  advcash: 'AdvCash',
  replenish: 'Replenish',
  withdrawal: 'Withdrawal',
  withdrawalMoney: 'Withdrawal money',
  withdrawalRules: 'Withdrawal rules',
  withdrawalParagraph1: 'To withdraw funds, you must perform the following steps:',
  withdrawalParagraph2: 'The minimum amount to withdrawal 15$.',
  withdrawalItem1: '1. Choose pay system',
  withdrawalItem2: '2. Enter details and amount',
  withdrawalItem3: '3. Press the withdrawal button',
  requisites: 'Requisites',
  date: 'Date',
  operationType: 'Operation type',
  status: 'Status',
  success: 'Success',
  minAmount: 'Select an amount greater than the minimum',
  reject: 'Successfully rejected',
  withdrawalSuccess: 'Withdrawal ordered successfully',
  errorPayment: 'Currently only replenishment of ruble accounts is possible',
  amountLess: 'Amount less than minimal',
  accountStatement: 'Account statement',
  transactionType: 'Transaction type',
  selectPeriod: 'Select period',
  from: 'From',
  to: 'To',
  selectTransactionType: 'Select transaction type',
  showAll: 'Show all transaction type',
  show: 'Show',
  deposit: 'Deposit',
  technicalDescription:
    'Please be informed that due to maintenance of banks, at midnight deposits from accounts may be delayed up to 30 minutes.',
  limits: 'Limits',
  max: 'max',
  min: 'min',
  send: 'Send',

  noData: 'No data',
  withdrawalStatuses: {
    ALL: 'All',
    OPEN: 'Created',
    IN_PROCESS: 'During',
    COMPLETED: 'Paid out',
    BLOCKED: 'Blocked',
    REJECTED: 'Canceled',
  },
  paymentSystemList: {
  },
  paymentStatuses: {
    10: 'Started',
    20: 'Requested',
    21: 'Approved',
    22: 'Approving failed',
    27: 'Manual process required',
    30: 'Success',
    40: 'Failed',
    45: 'Expired',
  },
  transactionStatuses: {
    ALL: 'All',
    COMPLETED: 'Completed',
    OPEN: 'Waiting',
    IN_PROCESS: 'Waiting',
    REJECTED: 'Rejected',
  },
  createdAt: 'Created at',
  amountCurrency: 'Currency',
  paymentSystem: 'Payment system',
  promocodeSuccess: 'Promo code successfully activated',
  promocode: 'Promo code',
  activatePromocode: 'Activate promo code',
  activate: 'Activate',
  payouts: 'Payouts',
  deposits: 'Deposits',
  continue: 'Continue',
  confirm: 'Confirm',
  copyThisAddresOrScanQr: 'Copy this address or scan this QR-code',
  minimumPaymentAmount: 'Minimum payment amount',
  Manualprocessrequired: 'In processing',
  Waiting: 'In processing',
  Started: 'In processing',
  Finished: 'Success',
  type: 'Type',
  payout: 'Withdrawals',
  payoutCanceledSuccess: 'Payout have been canceled successfully',
  cancel: 'Cancel',
  address: 'Address',
  firstName: 'First name',
  lastName: 'Last name',
  inputNameAttention: 'Enter valid data, VISA/MASTERCARD top-up requires KYC verification',
}
