import dayjs from 'dayjs'

import { IBonusHistoryItemDTO, IConvertedBonusHistory } from 'shared/models/BonusSystem'
import { BonusPromotionStatus, IServerUserPromotion, IUserPromotion } from 'shared/models/Promotion'

import { IDefaultDataResponse } from '../PaymentApi/types/types'

export class BonusSystemConverter {
  convertBonusHistory = (data: IDefaultDataResponse<IBonusHistoryItemDTO>): IConvertedBonusHistory => {
    const bonusHistory =
      data?.results
        ?.map(item => ({
          id: item.id,
          amount: Math.floor(+item.amount * 100) / 100,
          amountCurrency: item.amount_currency,
          date: dayjs(item.date).format('DD.MM.YYYY HH:mm'),
          type: item.type,
          bonusBalanceBeforeCurrency: item.bonus_balance_before_currency,
          bonusBalanceBefore: +(+item.bonus_balance_before).toFixed(2),
          bonusBalanceAfterCurrency: item.bonus_balance_after_currency,
          bonusBalanceAfter: +(+item.bonus_balance_after).toFixed(2),
        }))
        .filter(item => item.amount >= 0.01) || []

    return {
      page: data.page,
      count: data.count,
      history: bonusHistory,
    }
  }

  convertUserPromotionItem = (bonus: IServerUserPromotion): IUserPromotion => ({
    id: bonus.id,
    promotionId: bonus.promotion.id,
    active: bonus.promotion.active,
    status: bonus.status,
    name: bonus.promotion.name,
    expirationDate: dayjs(bonus.expiration_time).format('YYYY-MM-DD HH:mm:ss'),
    wager: +(+bonus.wager).toFixed(2),
    wagerCurrency: bonus.wager_currency,
    wagerComplete: +(+bonus.wager_completion).toFixed(2),
    wagerCompleteCurrency: bonus.wager_completion_currency,
    wagerCoefficient: bonus.promotion.wager_coefficient,
    depositCoefficient: bonus.promotion.deposit_coefficient,
    freeSpinsCount: bonus.freespins_count?.count || 0,
    freeSpinsGrantCount: bonus.promotion?.freespins_grant_amount || 0,
    promotionGrandAmount: Math.floor(+bonus.promotion_grant_amount * 100) / 100,
    promotionGrandAmountCurrency: bonus.promotion_grant_amount_currency,
    isFreeSpins: bonus.name.indexOf('freespins') === -1,
  })

  convertUserPromotions = (data: IServerUserPromotion[]): IUserPromotion[] =>
    data
      .sort((a, b) => (dayjs(a.expiration_time).isAfter(b.expiration_time) ? 1 : -1))
      .map(bonus => this.convertUserPromotionItem(bonus))
}
