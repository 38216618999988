import React, { useContext } from 'react'
import block from 'bem-cn'
import './Banner.scss'
import { Link } from 'react-router-dom'
import Button from 'components/UIKit/Button/Button'
import { ModalContext } from 'components/HOC/withModalQuery/ModalContext'
import { MODAL_ROUTE_URLS } from 'router/modalRoutes'
import banner1 from '../../shared/img/banner/banner1.bg.svg'

const Banner = () => {
  const b = block('banner')
  const { pushNewModal } = useContext(ModalContext)

//   const buttonStyle = {
//     backgroundColor: '#150D1F',
//     color: 'white',
//     border: 'none',
//     fontSize: '12px',
//     borderRadius: '5px',
//     cursor: 'pointer',
//     fontWeight: '500',
//     lineHeight: '18px',
//     padding: '16px 19px',
//  };

 

  const handleClick = () => {}
  return (
    <section className={`${b()} px-15`}>
      <div className={b('container')}>
        <div className={b('left-container')}>
          <div className={b('left')}>
            <h1>
              Crypto
              <span> Gambling At</span>
              <span>Its Best</span>
            </h1>
            <div className={b('btn-container')}>

            <Button
              text="Create Account"
              callback={() => pushNewModal(MODAL_ROUTE_URLS.SIGN_UP())}
              sx={{
                backgroundColor: 'rgba(21, 13, 31, 1) !important',
                color: 'white',
                border: 'none',
                fontSize: '12px ',
                borderRadius: '5px',
                cursor: 'pointer',
                fontWeight: '500',
                marginTop: '30px',
                lineHeight: '18px',
                padding: '14px 18px',
                textTransform: 'uppercase', 
                '&:hover': {
                  backgroundColor: '#000',
                },
              }}
            />
            </div>

          </div>
          <div className={b('right-img')}>
            <img src={banner1} alt="" />
          </div>
        </div>

        <div className={b('center-container')}>
          <div className={b('center-text')}>
            <h3>Up to</h3>
            <h2>1 bTC + 100 FS</h2>
            <button className={b('button2')}> Code: BETSFTD</button>
          </div>
          <div className={b('bottom-text')}>
            <h2>First Deposit</h2>
            <p>Bonus</p>
          </div>
        </div>

        <div className={b('right-container')}>
          <div className={b('top-content')}>
            <h2>Cashback</h2>
            <p>Every Single Day</p>
          </div>
          <div className={b('bottom-text-left')}>
            <h3>Up To</h3>
            <h4>20%</h4>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banner
