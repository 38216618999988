import dayjs from 'dayjs'

import { ILoginServer, ILoginServerDTO } from 'shared/models/Login'
import { IServerUser, IUser } from 'shared/models/User'
import { transformedRoles } from 'shared/utils/roles'
import { SignInType } from 'shared/utils/types/Auth.type'

import { getUserType } from './utils/getUserType'

export class AuthConverter {
  convertUserData = (data: IServerUser): IUser => ({
    id: +data.id,
    login: data.email,
    currency: data.balance_currency,
    firstName: data.first_name,
    lastName: data.last_name,
    role: data.role,
    username: data.username,
    balance: Math.floor(+data.balance * 100) / 100,
    parent: data.parent,
    email: data.email,
    createdAt: dayjs(data.created_at).format('DD/MM/YYYY HH:mm'),
    dateJoined: data.date_joined,
    isSelfRegistered: data.is_self_registered,
    userTypes: getUserType(data),
    bonusBalance: Math.floor(+data.bonus_balance * 100) / 100,
  })

  convertSignInData = (data: ILoginServerDTO): SignInType => {
    const user = this.convertUserData(data.user)
    return {
      user,
      accessToken: data.access_token,
      refreshToken: data.refresh_token,
    }
  }

  convertBalance = (data: IServerUser) => {
    const convertedData = {
      balance: +(+data.balance).toFixed(2),
      bonusBalance: data?.bonus_balance || 0,
      isBanned: data.is_banned || false,
      currency: data.balance_currency,
    }
    return convertedData
  }
}
