import React, { useContext, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useHistory, useParams } from 'react-router-dom'
import block from 'bem-cn'
import { motion } from 'framer-motion'

import { MODAL_ROUTE_URLS } from 'router/modalRoutes'

import { mediaSize } from 'shared/style/var'
import {
  sliderDesktopData,
  sliderDesktopLiveData,
  sliderMobileData,
  sliderMobileLiveData,
} from 'shared/utils/slider/mainPage'

import { IFiltersSlots } from 'modules/SlotsRoot/types/filters'
import { ISlotsGameType } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'

import { selectIsUserAuthenticated } from 'features/auth/selectors'
import { changeOpenedRightSidePanel } from 'features/sidePanel/actions'
import { selectOpenedRightSidePanel } from 'features/sidePanel/selectors'

import { ModalContext } from 'components/HOC/withModalQuery/ModalContext'

import DefineDeviceComponent from 'components/DefineDeviceComponent'
import Slider from 'components/Slider'
import SlotsLayoutContent from 'components/slots/SlotsLayoutContent'

import withQueryParams from './withQueryParams'

import './SlotsLayout.scss'

interface ISlotsLayoutProps {
  filters: IFiltersSlots
}

const getSlidesByGameType = (type: ISlotsGameType, isDesktop?: boolean) => {
  switch (type) {
    case 'casino':
      return isDesktop ? sliderDesktopData : sliderMobileData
    case 'live-casino':
      return isDesktop ? sliderDesktopLiveData : sliderMobileLiveData
    default:
      return isDesktop ? sliderDesktopData : sliderMobileData
  }
}

const b = block('slots-layout')

const SlotsLayout: React.FC<ISlotsLayoutProps> = props => {
  const { pushNewModal } = useContext(ModalContext)
  const history = useHistory()
  const isRightPanelOpened = useSelector(selectOpenedRightSidePanel, shallowEqual)
  const isMobileOrTablet = useMediaQuery({ maxWidth: mediaSize.tablet })
  const dispatch = useDispatch()
  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)
  const { gameType }: { gameType: ISlotsGameType } = useParams()

  const desktopSliderItems = useMemo(() => {
    const slides = getSlidesByGameType(gameType, true)
    return slides.map((item, index) => {
      const clickHandler = () => {
        if (!isAuth) {
          if (isMobileOrTablet) dispatch(changeOpenedRightSidePanel(!isRightPanelOpened))
          else pushNewModal(MODAL_ROUTE_URLS.SIGN_IN())()
        } else history.push(item.route)
      }
      return (
        <div className="each-slide" key={`home-slider-${index}`} onClick={clickHandler}>
          <img draggable={false} src={item.img} alt="banner" className="backgroung-image" />
        </div>
      )
    })
  }, [dispatch, gameType, history, isAuth, isMobileOrTablet, isRightPanelOpened, pushNewModal])

  const mobileSliderItems = useMemo(() => {
    const slides = getSlidesByGameType(gameType, false)
    return slides.map((item, index) => {
      const clickHandler = () => {
        if (!isAuth) {
          if (isMobileOrTablet) dispatch(changeOpenedRightSidePanel(!isRightPanelOpened))
          else pushNewModal(MODAL_ROUTE_URLS.SIGN_IN())()
        } else history.push(item.route)
      }
      return (
        <div className="each-slide" key={`home-slider-mobile-${index}`} onClick={clickHandler}>
          <img draggable={false} src={item.img} alt="banner" className="backgroung-image" />
        </div>
      )
    })
  }, [dispatch, gameType, history, isAuth, isMobileOrTablet, isRightPanelOpened, pushNewModal])

  const desktopSlider = <Slider items={desktopSliderItems} />

  const mobileSlider = <Slider items={mobileSliderItems} />

  return (
    <motion.div
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.15 }}
      className={b('wrapper')}>
      <DefineDeviceComponent mobileComponent={mobileSlider} desktopComponent={desktopSlider} />
      <SlotsLayoutContent {...props} />
    </motion.div>
  )
}

export default withQueryParams(SlotsLayout)
