import React, { useMemo } from 'react'
import block from 'bem-cn'

import { IFiltersSlots } from 'modules/SlotsRoot/types/filters'

import './SimpleListItem.scss'

interface ISimpleListItemProps {
  content: string
  isActive: boolean
  handleClick: () => void
}

const b = block('simple-list-item')

const SimpleListItem: React.FC<ISimpleListItemProps> = ({ content, isActive, handleClick }) => (
  <div className={b({ isActive })} onClick={handleClick}>
    {content}
  </div>
)

export default SimpleListItem
