import { generatePath } from 'react-router-dom'

import { ProvidersType } from 'modules/SlotsRoot/types/slot'

export enum MODAL_ROUTE_PATH {
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  FORGOT_PASSWORD = '/forgot-password',
  SIGN_UP_WITH_PROMOCODE = '/sign-up/:promocode',
  DEPOSIT = '/deposit/:paymentSystem',
  WITHDRAWAL = '/withdrawal/:payoutSystem',
  RESET_PASSWORD = '/reset-password/:uid/:token',
  WHEEL_GAME = '/wheel-game',
  WHEEL_GAME_HISTORY = '/wheel-game-history',
  RESEND_EMAIL = '/resend-email',
  VERIFY_EMAIL = '/verify-email',
  EMAIL_SENT = '/email-sent',
  SUCCESS_REGISTER = '/success-register',
  CONFIRM_ACTIVATE_BONUS = '/confirm-activate-bonus/:bonusId',
  PROFILE_DESKTOP = '/profile-desktop/:tab',
  PROFILE_DESKTOP_OPTION = '/profile-desktop/:tab/:option',
  CHANGE_PASSWORD = '/change-password',
  ADD_SLOT_FRAME = '/add-slot-frame/:gameType/:order',
  ADD_SLOT_FRAME_DEMO = '/add-slot-frame/:gameType/:order/:demoStart',
  REPLACING_SLOT_FRAME = '/slots/:provider/:gameId',
  REPLACING_SLOT_FRAME_DEMO = '/slots-demo/:provider/:gameId/:demoStart',
  PAYMENT_REDIRECTION = '/payment-redirection/:url',
}

export const MODAL_ROUTE_URLS = {
  SIGN_IN: () => generatePath(MODAL_ROUTE_PATH.SIGN_IN),
  SIGN_UP: () => generatePath(MODAL_ROUTE_PATH.SIGN_UP),
  VERIFY_EMAIL: () => generatePath(MODAL_ROUTE_PATH.VERIFY_EMAIL),
  EMAIL_SENT: () => generatePath(MODAL_ROUTE_PATH.EMAIL_SENT),
  SUCCESS_REGISTER: () => generatePath(MODAL_ROUTE_PATH.SUCCESS_REGISTER),
  FORGOT_PASSWORD: () => generatePath(MODAL_ROUTE_PATH.FORGOT_PASSWORD),
  SIGN_UP_WITH_PROMOCODE: (promocode: string) => generatePath(MODAL_ROUTE_PATH.SIGN_UP_WITH_PROMOCODE, { promocode }),
  DEPOSIT: (paymentSystem: string) => generatePath(MODAL_ROUTE_PATH.DEPOSIT, { paymentSystem }),
  WITHDRAWAL: (payoutSystem: string) => generatePath(MODAL_ROUTE_PATH.WITHDRAWAL, { payoutSystem }),
  RESET_PASSWORD: (uid: string, token: string) => generatePath(MODAL_ROUTE_PATH.RESET_PASSWORD, { uid, token }),
  WHEEL_GAME: () => generatePath(MODAL_ROUTE_PATH.WHEEL_GAME),
  WHEEL_GAME_HISTORY: () => generatePath(MODAL_ROUTE_PATH.WHEEL_GAME_HISTORY),
  RESEND_EMAIL: () => generatePath(MODAL_ROUTE_PATH.RESEND_EMAIL),
  CONFIRM_ACTIVATE_BONUS: (bonusId: string) => generatePath(MODAL_ROUTE_PATH.CONFIRM_ACTIVATE_BONUS, { bonusId }),
  PROFILE_DESKTOP: (tab: string) => generatePath(MODAL_ROUTE_PATH.PROFILE_DESKTOP, { tab }),
  PROFILE_DESKTOP_OPTION: (tab: string, option: string) =>
    generatePath(MODAL_ROUTE_PATH.PROFILE_DESKTOP_OPTION, { tab, option }),
  CHANGE_PASSWORD: () => generatePath(MODAL_ROUTE_PATH.CHANGE_PASSWORD),
  ADD_SLOT_FRAME: (order: number, gameType: string, demoStart?: string) =>
    demoStart
      ? generatePath(MODAL_ROUTE_PATH.ADD_SLOT_FRAME_DEMO, {
          gameType,
          order,
          demoStart,
        })
      : generatePath(MODAL_ROUTE_PATH.ADD_SLOT_FRAME, {
          gameType,
          order,
        }),
  REPLACING_SLOT_FRAME: (routeProvider: ProvidersType, gameId: string) =>
    generatePath(MODAL_ROUTE_PATH.REPLACING_SLOT_FRAME, { provider: routeProvider, gameId }),
  REPLACING_SLOT_FRAME_DEMO: (routeProvider: ProvidersType, gameId: string) =>
    generatePath(MODAL_ROUTE_PATH.REPLACING_SLOT_FRAME_DEMO, { provider: routeProvider, gameId, demoStart: 'd' }),
  PAYMENT_REDIRECTION: (url: string) => generatePath(MODAL_ROUTE_PATH.PAYMENT_REDIRECTION, { url }),
}
