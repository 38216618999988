import React from 'react'
import ReactDOM from 'react-dom'
import { Provider, useDispatch } from 'react-redux'
import { BrowserRouter, Route } from 'react-router-dom'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { AnyAction } from 'redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ThunkDispatch } from 'redux-thunk'
import { QueryParamProvider } from 'use-query-params'

import { configureStore, IApplicationState } from 'setup/store'

import {
  AuthModule,
  HelpPageModule,
  MainPageModule,
  ProfilePageModule,
  PromotionsModule,
  SlotsModule,
  TermsPageModule,
  VerifyEmailModule,
} from 'modules'
import { createRoutes } from 'modules/createRoutes'

import SetAppLanguage from 'components/SetAppLanguage/SetAppLanguage'

import App from './App'
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary'
import Api from './services/api'

import 'rsuite/dist/rsuite.min.css'
import 'react-toastify/dist/ReactToastify.css'
import './index.scss'

dayjs.extend(utc)
dayjs.extend(timezone)

const api = new Api('')

const modules = [
  new AuthModule(),
  new ProfilePageModule(),
  new TermsPageModule(),
  new HelpPageModule(),
  new MainPageModule(),
  new SlotsModule(),
  new VerifyEmailModule(),
  new PromotionsModule(),
]

const childrens = createRoutes(modules)

const { store, persistor } = configureStore({ api })

export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<ThunkDispatch<IApplicationState, any, AnyAction>>()

ReactDOM.render(
  <BrowserRouter>
    <QueryParamProvider ReactRouterRoute={Route}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SetAppLanguage />
          <ErrorBoundary>
            <App>{childrens}</App>
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    </QueryParamProvider>
  </BrowserRouter>,
  document.getElementById('root'),
)
