import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTE_PATHS } from 'router/routes'

const HelpPage = React.lazy(() => import('pages/HelpPage/HelpPage'))

export class HelpPageModule {
  getRoutes() {
    return (
      <Route key={ROUTE_PATHS.HELP} path={ROUTE_PATHS.HELP}>
        <Switch>
          <Route key={ROUTE_PATHS.HELP} path={ROUTE_PATHS.HELP} component={HelpPage} />
        </Switch>
      </Route>
    )
  }
}
