import React, { useContext } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import block from 'bem-cn'

import { MODAL_ROUTE_URLS } from 'router/modalRoutes'
import { ROUTE_URLS } from 'router/routes'

import logoSm from 'shared/img/logoSm.svg'

import { ProfileTabs } from 'shared/models/Profile'

import { selectIsUserAuthenticated, selectUserData } from 'features/auth/selectors'
import { selectLocaleDict } from 'features/locale/selectors'

import { ModalContext } from 'components/HOC/withModalQuery/ModalContext'

import Button from 'components/UIKit/Button/Button'

import './SlotFrameHeader.scss'

const b = block('slot-frame-header')

const SlotFrameHeader: React.FC = () => {
  const { goBack } = useHistory()
  const { pushNewModal } = useContext(ModalContext)

  const user = useSelector(selectUserData, shallowEqual)
  const locale = useSelector(selectLocaleDict, shallowEqual).common
  const localeProfile = useSelector(selectLocaleDict, shallowEqual).profile
  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)

  return (
    <div className={b()}>
      <div className={b('left')}>
        <Link className={b('logo-wrapper')} to={ROUTE_URLS.SLOTS_CASINO()}>
          <img src={logoSm} alt="Logo" className={b('logo')} />
        </Link>
        <div className={b('arrow')} onClick={goBack} />
      </div>
      <div className={b('right')}>
        {isAuth ? (
          <>
            <div className={b('btn-deposit')}>
              <Button
                text={localeProfile.deposit}
                colorType="deposit"
                callback={pushNewModal(MODAL_ROUTE_URLS.PROFILE_DESKTOP(ProfileTabs.DEPOSIT))}
              />
            </div>
            <span className={b('balance-text')}>{locale.balance}</span>
            <span
              className={b('balance-amount')}>{`: ${user?.balance} ${user?.currency} + ${user?.bonusBalance} B`}</span>
          </>
        ) : (
          <div className={b('login-button')}>
            <Button text={locale.login} colorType="secondary" callback={pushNewModal(MODAL_ROUTE_URLS.SIGN_IN())} />
          </div>
        )}
      </div>
    </div>
  )
}

export default SlotFrameHeader
