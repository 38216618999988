import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import block from 'bem-cn'

import logoImg from 'shared/img/logo.png'

import { selectLocaleDict } from 'features/locale/selectors'

import Button from 'components/UIKit/Button/Button'

import './NotifyContainer.scss'

interface INotifyContainerProps {
  title: string
  value: string
  callback: () => void
  backgroundCallback: () => void
}

const b = block('notify-container')

const NotifyContainer: React.FC<INotifyContainerProps> = ({ title, value, callback, backgroundCallback }) => {
  const locale = useSelector(selectLocaleDict, shallowEqual).bonuses

  return (
    <div className={b()}>
      <div className={b('background')} onClick={backgroundCallback} />
      <div className={b('container')}>
        <div className={b('content')}>
          <img src={logoImg} alt="Logo" className={b('logo')} />
          <span className={b('text')}>{title}</span>
          <div className={b('amount-container')}>
            <div className={b('amount')}>{value}</div>
          </div>
        </div>
        <div className={b('button-wrapper')} onClick={callback}>
          {locale.receive}
        </div>
      </div>
    </div>
  )
}

export default NotifyContainer
