export const slotsSortArray: (string | number)[] = []

export const slotsTopArray: { [key: string]: (string | number)[] } = {
  casino: [
    'pragmaticexternal:GatesOfOlympus1',
    'vs20olympgate',

    'pragmaticexternal:ChilliHeat',
    'vs25chilli',

    'vs20olympgate_prg',
    'habanero:SGHotHotFruit',
    'allways_fruits_original',
    'vswaysdogs_prg',
    'bookofdead_png',

    'pragmaticexternal:5Lions',
    'vswayslions',

    'pragmaticexternal:5LionsMegaways1',
    'vswayslions',

    'pragmaticexternal:WildWestGold',
    'vs40wildwest',

    'pragmaticexternal:TheDogHouseMegaways1',
    'vswaysdogs',

    'pragmaticexternal:BiggerBassBonanza',
    'vs12bbb',

    'vs576treasures_prg',
    'vs12bbb_prg',
    'vs25chilli_prg',
    'vs40wildwest_prg',

    'pragmaticexternal:SweetBonanza',
    'vs20fruitsw',

    'vs20fruitsw_prg',
    'vs25wolfgold_prg',
    'vs25chilli_prg',
    'vs20rhinoluxe_prg',
    'vswayswerewolf_prg',
    'vs4096bufking_prg',
    'vs243lions_prg',
    'vs10bookoftut_prg',
    'gonzos_quest_touch',
    'qso_bbw',
    'quickspin:BigBadWolfMegaways',
    'qso_talesofdoctordolittle',
    'qso_mountainkingl',
    'snakearena_rg',
    'templetumble_rg',
    'ironbank_rg',
  ],
  'live-casino': [
    // pragmatic req
    'monopoly:pqe4eud2fnorlatr',
    '1101',
    'crazytime:pqe3bikvb6ebirul',
    '204',
    'dealnodeal:qssk5kltwxctu7un',
    '801',
    'roulette:phi5cefi6faxd2gn',
    '103',
    '545',
    '230',
    '701',
    '902',
    '901',
    '1401',

    'evolution:crazytime',
    'crazytime:pqe3bikvb6ebirul', // sf

    'pragmaticexternal:SweetBonanzaCandyland',
    '1101',

    'evolution:monopoly',
    'monopoly:pqe4eud2fnorlatr',
    'evolution:GonzosTreasureHunt',
    'gonzotreasurehunt:pghg7ndeqhrqcmth',

    'pragmaticexternal:AutoRoulette',
    '225',

    'evolution:dealnodeal',
    'dealnodeal:qssk5kltwxctu7un',
    'evolution:dream_catcher',
    'moneywheel:pqe3l4gnb6ebjfmv',
    'evolution:lightning_roulette',
    'roulette:phi5cefi6faxd2gn',

    'pragmaticexternal:MegaWheel',
    '801',

    'evolution:sidebetcity',
    'sidebetcity:SBCTable00000001',
    'evolution:lightningdice',
    'lightningdice:pizx5gn2jx3tfyda',
    'alg:Roulette360',
    'alg:LasVegasRoulette',
    'alg:BondRoulette',
    'ezugi:CricketWar',

    'pragmaticexternal:DragonTigerLive',
    '108',

    'pragmaticexternal:ONEBlackjack',
    '901',

    'pragmaticexternal:MegaSicBo',
    '701',

    'pragmaticexternal:MegaRoulette',
    '204',

    'ezugi:BetonTeenPatti',
    'ezugi:SpeedAutoRoulette',
    'ezugi:BlackjackSalonPrive',
    'evolution:lightning_roulette',
    'roulette:phi5cefi6faxd2gn',
    'evolution:americanroulette',
    'americanroulette:pizrsbcjjx3s6p63',
    'evolution:auto_roulette',
    'roulette:pizrtlifoyfs7mzm',
    'evolution:instantroulette',
    'roulette:pizx4cc5jx3tfwsj',
    'evolution:BacBo',
    'bacbo:p4fp37k5qyeoucds',
    'evolution:GoldenWealthBaccarat',
    'baccarat:pr7kkkepcriirmzx',
    'evolution:XXXTremeLightningRoulette',
    'roulette:qgvm5pn3cinbjyze',
    'evolution:double_ball',
    'roulette:pl3zd3r4ntsl4fwh',
    'evolution:craps',
    'craps:pgwkdys4johfvwtq',
    'evolution:dragon_tiger',
    'dragontiger:pizw2vqojx3tepdd',
    'evolution:fan_tan',
    'fantan:pr7jwqjtxgrypwrn',
  ],
  'tv-games': [
    'atmosphera:musicwheel',
    'atmosphera:Keno',
    'atmosphera:AutoRoulette',
    'atmosphera:Bingo37',
    'atmosphera:Bingo38',
    'atmosphera:RouletteEng',
    'infin:1Bet',
    'infin:5Bet',
    'infin:7Bet',
    'infin:AndarBahar',
    'infin:BlackJack',
    'infin:FruitRace',
    'infin:Jokerbet',
    'infin:Lucky6',
  ],
  virtual: [
    'infin:ArgentinaLeague',
    'infin:BasketballOnDemand',
    'infin:Championsgoldenrace',
    'infin:Championsondemand',
    'infin:ColombiaLeague',
    'infin:ColombiaLeagueOnDemand',
    'infin:Dog6',
    'infin:Dog6HOdds',
    'infin:Dog6OnDemand',
    'infin:DogHOddsOnDemand',
    'infin:EnglandLeague',
    'infin:EnglandLeagueOnDemand',
    'infin:Euro2020',
    'infin:Euro2020OnDemand',
    'infin:FranceLeague',
    'infin:FranceLeaugeOnDemand',
    'infin:GermanyLeague',
    'infin:GermanyLeagueOnDemand',
    'infin:Horses6V2',
    'infin:Horses6V2OD',
    'infin:ItalyLeague',
  ],
}

export const categoriesSlots = [
  'Drops & Wins',
  'popular',
  'megaways',
  'games',
  'roulette',
  'cardGames',
  'lotto',
  'fruits',
  'hot',
  'wild',
  'books',
  'jokers',
  'egypt',
  'asia',
  'wolfs',
  'worldOfSea',
  'girls',
  'oldSchool',
  'luckyClower',
  'gangsta',
  'sport',
  'sweets',
  'fantasy',
] // борьба с оптимизацией

export const categoriesSlotsLive = [
  'Drops & Wins',
  'popular',
  'showGames',
  // 'firstPerson',
  'roulette',
  'blackJack',
  'lotto',
  'poker',
  'baccarat',
  'dice',
]

export const categoriesSlotsTvGames = ['popular', 'showGames', 'roulette', 'lotto']

export const categoriesSlotsFreeSpin = [
  'popular',
  'fruits',
  'oldSchool',
  'wolfs',
  'gangsta',
  'fantasy',
  'wild',
  'books',
  'sport',
  'girls',
  'worldOfSea',
  'asia',
  'megaways',
]

export const providersSortArray = [
  'favorite',
  'all',
  'pragmaticplay',
  'evolution',
  'pragmaticplaylive',
  'xpg',
  'vivogaming',
  'habanero',
  // 'pragmatic',
  'evoPlay',
  'netent',
  'quickspin',
  'yggdrasil',
  'mascot',
  'microgaming',
  'Tomhorn',
  'igrosoft',
  'betsolutions',
  'KAGaming',
  'greentube',
  'gaminator',
  'wazdan',
  'playtech',
  'apollo',
  'merkur',
  'kajot',
  'playson',
  'booongo',
  'konami',
  'aristocrat',
  'igt',
].map(provider => provider.toLowerCase())

export const categoriesSortArray = ['megaways', 'games', 'girls', 'tablegames', 'popular', 'roulette', 'gangsta']

export const liveCasinoProviders = [
  'xpg',
  'evolution',
  'vivogaming',
  'ezugi',
  'luckystreak',
  'pragmaticplaylive',
  'alg',
].map(provider => provider.toLowerCase())

export const virtualProviders = ['goldenrace'].map(provider => provider.toLowerCase())

export const tvGamesProviders = ['atmosfera', 'tvbet', 'atmosphera'].map(provider => provider.toLowerCase())

export const newProviderList = ['vivogaming', 'xpg']
