import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { selectAuthCommunication, selectIsUserAuthenticated } from 'features/auth/selectors'
import { actions as slotsActions } from 'features/slots'

import { slotProvidersConfiguration } from '../configuration'

const withFetchSlotsLayoutContent = (WrappedComponent: React.FC<any>) => (props: any) => {
  const dispatch = useDispatch()

  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)
  const logoutCommunications = useSelector(selectAuthCommunication, shallowEqual).logout

  useEffect(() => {
    Object.entries(slotProvidersConfiguration).forEach(configuration => {
      if (configuration[1].platform?.indexOf('desktop') !== -1 || !configuration[1].platform)
        dispatch(
          slotsActions.getGameList({
            id: configuration[0],
            provider: configuration[0],
          }),
        )
    })
    if (isAuth && !logoutCommunications.isLoading) {
      dispatch(slotsActions.getFavoriteGameList())
    }
  }, [isAuth, dispatch])

  return <WrappedComponent {...props} />
}

export { withFetchSlotsLayoutContent as withFetchSlotsLayoutContentDesktop }
