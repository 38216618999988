import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import block from 'bem-cn'

import withQueryParams from 'modules/SlotsRoot/components/SlotsLayout/withQueryParams'
import { useFilterSlots } from 'modules/SlotsRoot/hooks'
import { IFiltersSlots } from 'modules/SlotsRoot/types/filters'
import { ISlotsGameType } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'

import { selectLocaleDict } from 'features/locale/selectors'
import { selectFavoriteList, selectGameList } from 'features/slots/selectors'

import { themeItems } from 'components/slots/MobileFilter/FilterParamters/data'
import SlotsByCategory from 'components/slots/SlotsByCategory/SlotsByCategory'
import SlotsNavigation from 'components/slots/SlotsNavigation/SlotsNavigation'

import './MyGames.scss'

const b = block('my-games')

interface IMyGames {
  filters: IFiltersSlots
}
const MyGames: React.FC<IMyGames> = ({ filters }) => {
  const { gameType }: { gameType: ISlotsGameType } = useParams()

  const locale = useSelector(selectLocaleDict, shallowEqual).common
  const favoritesList = useSelector(selectFavoriteList, shallowEqual)
  const gameList = useSelector(selectGameList, shallowEqual)

  const { slots, categories, tabByGameType } = useFilterSlots({
    filters,
    slots: gameList,
    gameType,
  })

  const themesArray = useMemo(() => themeItems.filter(item => item.gameType === gameType), [gameType])

  const slotsCategories = ['favorite', 'all', ...categories].filter(item => !themesArray?.[0]?.themes.includes(item))

  const items = useMemo(
    () =>
      slotsCategories.map(category => {
        const filteredSlots = slots.filter(slot => {
          const slotTab = tabByGameType(slot)
          switch (category) {
            case 'favorite':
              return favoritesList.includes(`${slot.gameId}_${slot.provider}`)
            case 'all':
              return slots
            default:
              return slotTab.includes(category)
          }
        })
        return <SlotsByCategory key={category} category={category} slots={filteredSlots} />
      }),
    [favoritesList, slots, slotsCategories, tabByGameType],
  )

  return (
    <>
      <div className={b()}>
        <div className={b('header')}>{locale.myGames}</div>
        {items}
      </div>
      <SlotsNavigation />
    </>
  )
}

export default withQueryParams(MyGames)
