import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import { Store } from 'redux'

import { IApplicationState } from 'setup/store'

import { IListOfErrors } from 'shared/models/AppError'

import { logout } from 'features/auth/actions'

import { authExcluding } from './helpers/authExcluding'
import { checkIsMultiError } from './helpers/checkIsMultiError'
import { convertInvalidField } from './helpers/convertInvalidField'

export const makeUnauthorizedInterceptor = (store: Store<IApplicationState, any>) => (error: AxiosError) => {
  const state = store.getState()
  const localeErrors = state.locale.data.errorStatusMessage

  const { isAuth } = state.auth.data

  if (isAuth) {
    if (error.response && error.response.status === 401 && error.request.responseURL.indexOf('accounts/user') !== -1) {
      store.dispatch(logout())
      return
    }
  }

  if (error.request && error.request.responseURL.indexOf('accounts/login') !== -1) {
    const isAuthError = authExcluding(error, store)

    if (isAuthError) {
      throw new Error(JSON.stringify(error.response?.data || ''))
    }
  }

  if (error.response?.status !== 500 && checkIsMultiError(error)) {
    const convertedField: IListOfErrors = convertInvalidField(error)

    convertedField.listOfErrors.forEach(err =>
      toast.error(localeErrors[err.code] || err.message || localeErrors.defaultMessage),
    )

    throw new Error(JSON.stringify(convertedField) || '')
  }

  if (error.response) {
    toast.error(
      localeErrors[error.response?.data?.code] ||
        error.response?.data?.message ||
        error.response?.data?.detail ||
        localeErrors.defaultMessage,
    )
  }
  throw new Error(JSON.stringify(error.response?.data || ''))
}
