import { ROUTE_URLS } from 'router/routes'

import homed1 from 'shared/img/slider/home/main-d1.webp'
import homed2 from 'shared/img/slider/home/main-d2.webp'
import homed3 from 'shared/img/slider/home/main-d3.webp'
import homed4 from 'shared/img/slider/home/main-d4.webp'
import homem1 from 'shared/img/slider/home/main-m1.webp'
import homem2 from 'shared/img/slider/home/main-m2.webp'
import homem3 from 'shared/img/slider/home/main-m3.webp'
import homem4 from 'shared/img/slider/home/main-m4.webp'
import slotsd1 from 'shared/img/slider/slots/cas-d1.webp'
import slotsd2 from 'shared/img/slider/slots/cas-d2.webp'
import slotsm1 from 'shared/img/slider/slots/cas-m1.webp'
import slotsm2 from 'shared/img/slider/slots/cas-m2.webp'
import slotsd1Live from 'shared/img/slider/slots/caslive-d1.webp'
import slotsd2Live from 'shared/img/slider/slots/caslive-d2.webp'
import slotsd3Live from 'shared/img/slider/slots/caslive-d3.webp'
import slotsd4Live from 'shared/img/slider/slots/caslive-d4.webp'
import slotsm1Live from 'shared/img/slider/slots/caslive-m1.webp'
import slotsm2Live from 'shared/img/slider/slots/caslive-m2.webp'
import slotsm3Live from 'shared/img/slider/slots/caslive-m3.webp'
import slotsm4Live from 'shared/img/slider/slots/caslive-m4.webp'

const mainSliderData = [
  {
    img: homed1,
    route: '#',
  },
  {
    img: homed2,
    route: ROUTE_URLS.SLOTS_CASINO(),
  },
  {
    img: homed3,
    route: '/slots/casino?categories=Drops%20%26%20Wins',
  },
  {
    img: homed4,
    route: '#',
  },
]

const mainSliderMobileData = [
  {
    img: homem1,
    route: '#',
  },
  {
    img: homem2,
    route: ROUTE_URLS.SLOTS_CASINO(),
  },
  {
    img: homem3,
    route: '/slots/casino?categories=Drops%20%26%20Wins',
  },
  {
    img: homem4,
    route: '#',
  },
]

const sliderDesktopData = [
  {
    img: slotsd1,
    route: ROUTE_URLS.SLOTS_CASINO(),
  },
  {
    img: slotsd2,
    route: ROUTE_URLS.SLOTS_CASINO(),
  },
]

const sliderMobileData = [
  {
    img: slotsm1,
    route: ROUTE_URLS.SLOTS_CASINO(),
  },
  {
    img: slotsm2,
    route: ROUTE_URLS.SLOTS_CASINO(),
  },
]

const sliderDesktopLiveData = [
  {
    img: slotsd1Live,
    route: ROUTE_URLS.LIVECASINO(),
  },
  {
    img: slotsd2Live,
    route: ROUTE_URLS.LIVECASINO(),
  },
  {
    img: slotsd3Live,
    route: ROUTE_URLS.LIVECASINO(),
  },
  {
    img: slotsd4Live,
    route: ROUTE_URLS.LIVECASINO(),
  },
]

const sliderMobileLiveData = [
  {
    img: slotsm1Live,
    route: ROUTE_URLS.LIVECASINO(),
  },
  {
    img: slotsm2Live,
    route: ROUTE_URLS.LIVECASINO(),
  },
  {
    img: slotsm3Live,
    route: ROUTE_URLS.LIVECASINO(),
  },
  {
    img: slotsm4Live,
    route: ROUTE_URLS.LIVECASINO(),
  },
]

export {
  mainSliderData,
  mainSliderMobileData,
  sliderDesktopData,
  sliderDesktopLiveData,
  sliderMobileData,
  sliderMobileLiveData,
}
