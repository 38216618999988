import React, { useCallback, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import block from 'bem-cn'

import { ISlotViewItem } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'

import { actions as slotsActions } from 'features/slots'
import { selectFavoriteList } from 'features/slots/selectors'

import { selectIsUserAuthenticated } from '../../../../../../features/auth/selectors'
import { ReactComponent as CloseSVG } from './img/close.svg'
import { ReactComponent as AddFavoriteSVG } from './img/favorite.svg'
import { ReactComponent as FullScreenSVG } from './img/fullScreen.svg'
import { ReactComponent as NewPageSVG } from './img/newPage.svg'
import { ReactComponent as RefreshSVG } from './img/refresh.svg'

import './SlotFrameControls.scss'

const b = block('slot-frame-controls')

interface ISlotFrameControls {
  slot: ISlotViewItem | undefined
  toggleFullscreen(): void
  toggleClose(): void
  toggleRefresh: (() => void) | undefined
  toggleNewPage: (() => void) | undefined
}
const SlotFrameControls: React.FC<ISlotFrameControls> = ({
  toggleFullscreen,
  toggleClose,
  toggleRefresh,
  toggleNewPage,
  slot,
}) => {
  const dispatch = useDispatch()

  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)
  const favoriteList = useSelector(selectFavoriteList, shallowEqual)

  const isFavorite = useMemo(
    () => isAuth && favoriteList?.includes(`${slot?.gameId}_${slot?.provider}`),
    [isAuth, favoriteList, slot],
  )

  const refreshHandler = () => toggleRefresh && toggleRefresh()
  const newPageHandler = () => toggleNewPage && toggleNewPage()

  const favoriteHandler = useCallback(() => {
    if (isFavorite) {
      dispatch(slotsActions.removeFavoriteSlot(slot))
    } else {
      dispatch(slotsActions.addFavoriteSlot(slot))
    }
  }, [slot, isFavorite, dispatch])

  return (
    <div className={b()}>
      <CloseSVG className={b('button')} onClick={toggleClose} />
      <NewPageSVG className={b('button')} onClick={newPageHandler} />
      <FullScreenSVG className={b('button')} onClick={toggleFullscreen} />
      <RefreshSVG className={b('button')} onClick={refreshHandler} />
      <AddFavoriteSVG className={b('button', { active: isFavorite })} onClick={favoriteHandler} />
    </div>
  )
}

export default SlotFrameControls
