import React, { useState } from 'react'
import { ArrayParam,useQueryParam, withDefault } from 'use-query-params'

export const slotsCategoryFilterQuery = 'categories'
export const slotsProviderFilterQuery = 'providers'

const withQueryParams = (WrappedComponent: React.FC<any>) => (props: any) => {
  const [selectedProviders, setSelectedProviders] = useQueryParam(slotsProviderFilterQuery, withDefault(ArrayParam, []))
  const [selectedCategories, setSelectedCategories] = useQueryParam(
    slotsCategoryFilterQuery,
    withDefault(ArrayParam, []),
  )
  const [searchString, setSearchString] = useState('')

  return (
    <WrappedComponent
      {...props}
      filters={{
        selectedProviders,
        setSelectedProviders,
        selectedCategories,
        setSelectedCategories,
        searchString,
        setSearchString,
      }}
    />
  )
}

export default withQueryParams
