import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import block from 'bem-cn'
import { AnimatePresence, motion } from 'framer-motion'

import sizes from 'shared/style/export-sizes.scss'

import { selectIsUserAuthenticated } from 'features/auth/selectors'
import { changeOpenedLeftSidePanel, changeOpenedRightSidePanel } from 'features/sidePanel/actions'
import { selectOpenedLeftSidePanel, selectOpenedRightSidePanel } from 'features/sidePanel/selectors'

import './SidePanel.scss'

const b = block('side-panel')

export const SidePanel: React.FC<{ isLeft: boolean }> = ({ isLeft, children }) => {
  const dispatch = useDispatch()

  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)
  const isOpenedLeftPanel = useSelector(selectOpenedLeftSidePanel, shallowEqual)
  const isOpenedRightPanel = useSelector(selectOpenedRightSidePanel, shallowEqual)

  const x = useMemo(() => {
    if (isLeft) {
      return `-${sizes['side-panel-width']}`
    }
    return `+${sizes['side-panel-width']}`
  }, [isLeft])

  const variants = {
    open: { display: 'flex', x: 0 },
    closed: { display: 'flex', x },
  }

  const handleCloseLeftPanel = useCallback(() => {
    dispatch(changeOpenedLeftSidePanel(false))
  }, [dispatch])

  const handleCloseRightPanel = useCallback(() => {
    dispatch(changeOpenedRightSidePanel(false))
  }, [dispatch])

  useEffect(() => {
    const html = document.getElementsByTagName('html')?.[0]
    if (html) {
      if (isOpenedLeftPanel || isOpenedRightPanel) {
        document.getElementsByTagName('html')[0].style.overflow = 'hidden'
      } else {
        document.getElementsByTagName('html')[0].style.overflow = 'initial'
      }
    }
  }, [isOpenedLeftPanel, isOpenedRightPanel])

  const content = (
    <div className={b('wrapper', { closed: !isOpenedLeftPanel && !isOpenedRightPanel })}>
      {!isLeft && (
        <div
          className={b('blur-background', { isActive: isOpenedLeftPanel || isOpenedRightPanel })}
          onClick={handleCloseRightPanel}
        />
      )}
      <motion.div
        key={`sidePanel${isLeft.toString()}`}
        initial="closed"
        animate={isOpenedLeftPanel || isOpenedRightPanel ? 'open' : 'closed'}
        variants={variants}
        transition={{ duration: 0.35 }}
        exit={{ x }}
        className={b({
          leftSide: isLeft,
          rightSide: !isLeft,
        })}>
        {children}
      </motion.div>
      {isLeft && (
        <div
          className={b('blur-background', { isActive: isOpenedLeftPanel || isOpenedRightPanel })}
          onClick={handleCloseLeftPanel}
        />
      )}
    </div>
  )

  const node = document.getElementById('side-panel-root')
  if (node !== null) {
    return ReactDOM.createPortal(content, node)
  }
  return null
}
