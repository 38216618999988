import { IApplicationState } from 'setup/store'

export const selectSlotsState = (state: IApplicationState) => state.slots.data
export const selectSlotsCommunication = (state: IApplicationState) => state.slots.communications

export const selectGameList = (state: IApplicationState) => state.slots.data.gameList
export const selectFavoriteList = (state: IApplicationState) => state.slots.data.favorite
export const selectGameUrl = (state: IApplicationState) => state.slots.data.gameUrl
export const selectBackUrl = (state: IApplicationState) => state.slots.data.backUrl

export const selectGameListComm = (state: IApplicationState) => state.slots.communications.getGameList
export const selectSessionUrlComm = (state: IApplicationState) => state.slots.communications.getSessionUrl

export const selectGameListLoading = (state: IApplicationState) =>
  Object.values(state.slots.communications.getGameList).some(t => t?.isLoading)
