import React, { useCallback, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import block from 'bem-cn'
import { Formik } from 'formik'

import { MODAL_ROUTE_PATH } from 'router/modalRoutes'
import { ROUTE_PATHS } from 'router/routes'

import { validateConfirmPassword } from 'shared/helpers/validateConfirmPassword'
import { getRouteParamsByStrings } from 'shared/utils/getRouteParamsByStrings'

import { resetPasswordConfirm } from 'features/auth/actions'
import { selectAuthCommunication, selectIsUserAuthenticated } from 'features/auth/selectors'
import { selectLocaleDict } from 'features/locale/selectors'

import Button from 'components/UIKit/Button/Button'
import { Input } from 'components/UIKit/Input/Input'

import { StyledAuthForm, StyledAuthHeader, StyledAuthModal } from '../styles/AuthStyles'

import '../ForgotPassword/ForgotPassword.scss'

const b = block('forgot-password')
interface IResetPassword {
  path: string
}

interface ParamsMatch {
  uid: string
  token: string
}

interface IResetPasswordState {
  newPassword: string
  repeatNewPassword: string
}

const ResetPassword: React.FC<IResetPassword> = ({ path }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = getRouteParamsByStrings<ParamsMatch>(MODAL_ROUTE_PATH.RESET_PASSWORD, path)
  const { isLoading } = useSelector(selectAuthCommunication, shallowEqual).resetPasswordConfirm
  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)

  const [state] = useState<IResetPasswordState>({
    newPassword: '',
    repeatNewPassword: '',
  })

  const onSubmit = useCallback(
    values => {
      const data = {
        new_password1: values.newPassword,
        new_password2: values.repeatNewPassword,
        uid: params?.uid || '',
        token: params?.token || '',
      }
      dispatch(resetPasswordConfirm({ data, callback: () => history.push(ROUTE_PATHS.MAIN) }))
    },
    [dispatch, history, params?.token, params?.uid],
  )

  const locale = useSelector(selectLocaleDict, shallowEqual).auth

  return (
    <StyledAuthModal>
      <div className={b()}>
        <Formik
          initialValues={{
            newPassword: state.newPassword,
            repeatNewPassword: state.repeatNewPassword,
          }}
          validate={values => {
            const errors: Record<string, string> = {}
            if (validateConfirmPassword(values.newPassword, values.repeatNewPassword)) {
              errors.repeatNewPassword = locale.passwordsNotMatched
            }
            return errors
          }}
          onSubmit={onSubmit}>
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <StyledAuthForm onSubmit={handleSubmit} className={b('wrapper')}>
              <StyledAuthHeader>
                <span className={b('header-locale')}>{locale.restorePassword}</span>
              </StyledAuthHeader>
              <div className={b('input-wrapper')}>
                <Input
                  type="password"
                  name="newPassword"
                  placeholder={`${locale.newPassword} *`}
                  value={values.newPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isRequired
                />
              </div>
              <div className={b('input-wrapper')}>
                <Input
                  type="password"
                  name="repeatNewPassword"
                  placeholder={`${locale.repeat} *`}
                  value={values.repeatNewPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isRequired
                  error={errors.repeatNewPassword && touched.repeatNewPassword && errors.repeatNewPassword}
                />
              </div>
              <div className={b('button-container')}>
                <Button
                  type="submit"
                  colorType="main"
                  text={locale.restorePassword}
                  disabled={isLoading}
                  loading={isLoading}
                />
              </div>
            </StyledAuthForm>
          )}
        </Formik>
      </div>
    </StyledAuthModal>
  )
}

export default ResetPassword
