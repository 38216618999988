/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useRouteMatch } from 'react-router'
import { useHistory } from 'react-router-dom'
import block from 'bem-cn'

import { ROUTE_URLS } from 'router/routes'

import { mediaSize } from 'shared/style/var'

import { ISlotsGameType, ISlotViewItem } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'
import { ProvidersType } from 'modules/SlotsRoot/types/slot'

import { selectIsUserAuthenticated } from 'features/auth/selectors'
import { actions } from 'features/frameManager'
import { selectCountFrames, selectFramesCommunication, selectOpenedFrames } from 'features/frameManager/selectors'
import { selectLocaleDict } from 'features/locale/selectors'
import { selectGameList, selectGameUrl } from 'features/slots/selectors'

import { addNotify } from 'components/Notify'
import SlotEmptyIframe from 'components/SlotEmptyIframe/SlotEmptyIframe'
import SlotIFrame from 'components/SlotIFrame/SlotIFrame'

import RouteReplacingSlots from '../RouteReplacingSlots'
import { withFetchSlotsLayoutContentDesktop } from '../withFetchSlotsLayoutContent'
import SlotFrameControls from './components/SlotFrameControls/SlotFrameControls'
import SlotFrameHeader from './components/SlotFrameHeader/SlotFrameHeader'

import './SlotFrame.scss'

interface ISlotFrameRouteMatch {
  gameType: ISlotsGameType
  provider: ProvidersType
  gameId: string
  demoStart?: string
  freeSpin?: string
}

const b = block('slot-frame')

const SlotFrame: React.FC = () => {
  const dispatch = useDispatch()
  const { goBack } = useHistory()
  const isMobileOrTablet = useMediaQuery({ maxWidth: mediaSize.tablet })
  const match = useRouteMatch<ISlotFrameRouteMatch>()

  const { gameType, provider, gameId, demoStart, freeSpin } = match.params

  const sessionUrl = useSelector(selectGameUrl, shallowEqual)
  const sessionCommunication = useSelector(selectFramesCommunication, shallowEqual)
  const gameList = useSelector(selectGameList, shallowEqual)
  const locale = useSelector(selectLocaleDict, shallowEqual).slots
  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)
  const openedFrames = useSelector(selectOpenedFrames, shallowEqual)
  const countFrames = useSelector(selectCountFrames, shallowEqual)

  const isMultiple = useMemo(() => countFrames > 1, [countFrames])

  const isNeedRedirect = useMemo(() => isAuth && !demoStart && isMobileOrTablet, [isAuth, demoStart, isMobileOrTablet])

  const callback = React.useCallback(
    (id: string) => () => {
      dispatch(actions.removeFrame(id))
      addNotify(locale.tryVpn)
    },
    [dispatch, locale],
  )

  const fullScreenHandler = useCallback(
    (id: string | undefined, newStatus: boolean) => () => {
      if (id) dispatch(actions.changeIsFullscreenFrame(id, newStatus))
    },
    [dispatch],
  )

  const closeHandler = useCallback(
    (id: string | undefined) => () => {
      if (id) {
        dispatch(actions.removeFrame(id))
        goBack()
      }
    },
    [dispatch, goBack],
  )

  const newPageHandler = useCallback(
    (frameGameId: string, frameProvider: ProvidersType) => () => {
      if (demoStart) {
        window.open(ROUTE_URLS.SLOTS_PROVIDER_DEMO_START(frameProvider, frameGameId, gameType, demoStart))
      } else window.open(ROUTE_URLS.SLOTS_PROVIDER(frameProvider, frameGameId, gameType))
    },
    [demoStart, gameType],
  )

  const getSessionFrameUrl = useCallback(
    (frameGameId: string, order: number, frameProvider: ProvidersType, frameHasFreeSpins: boolean | undefined) =>
      () => {
        dispatch(
          actions.getFrameSession({
            id: frameGameId,
            order,
            provider: frameProvider,
            gameId: frameGameId,
            isDesktop: !isMobileOrTablet,
            callback: callback(frameGameId),
            demoStart,
            hasFreeSpins: frameHasFreeSpins,
            withRedirect: isNeedRedirect,
            isFreeSpins: !!freeSpin,
          }),
        )
      },
    [callback, demoStart, dispatch, isMobileOrTablet, isNeedRedirect],
  )

  const sessionStarted = useMemo(
    () => !sessionCommunication[gameId]?.isLoading && sessionCommunication[gameId]?.isSuccess,
    [sessionCommunication],
  )

  const providerGameList = useMemo(
    () => gameList.filter((t: ISlotViewItem) => t.routeProvider === provider),
    [provider, gameList],
  )

  const currentSlot = useMemo(() => providerGameList.find(slots => slots.gameId === gameId), [providerGameList, gameId])

  useEffect(() => {
    dispatch(
      actions.addFrame({
        id: gameId,
        order: 1,
        provider,
        gameId,
        getSession: getSessionFrameUrl(gameId, 1, provider, currentSlot?.hasFreeSpins),
      }),
    )
    dispatch(actions.changeCountFrames(1))
    return () => {
      dispatch(actions.removeAllFrames())
      dispatch(actions.changeCountFrames(0))
    }
  }, [])

  if (isNeedRedirect) {
    return <RouteReplacingSlots />
  }

  return isAuth || demoStart ? (
    <>
      <SlotFrameHeader />

      <div className={b({ isMobileOfTablet: isMobileOrTablet, demo: !!demoStart })}>
        <div className={b('bg-image')} style={{ backgroundImage: `url(${currentSlot?.imageUrl})` }} />
        <div className={b('view-center', { multiple: isMultiple })}>
          {isMobileOrTablet ? (
            <iframe title="slots-frame" className={b('iframe')} width="100%" height="100%" src={sessionUrl} />
          ) : (
            Array(countFrames)
              .fill(1)
              .map((_, order) => {
                const frame = openedFrames?.find(openedFrame => openedFrame.order === order + 1)
                const slot = gameList.find(slotItem => slotItem.gameId === frame?.gameId)
                const hasFreeSpins = providerGameList.find(slots => slots.gameId === gameId)?.hasFreeSpins
                return (
                  <div key={order} className={b('iframe-container', { multiple: isMultiple })}>
                    <>
                      {frame?.sessionUrl ? (
                        <SlotIFrame
                          key={frame.id}
                          sessionUrl={frame.sessionUrl}
                          isFullscreen={frame.isFullscreen}
                          onExitFullScreen={fullScreenHandler(frame.id, false)}
                        />
                      ) : (
                        !frame && (
                          <SlotEmptyIframe
                            gameType={gameType}
                            demoStart={demoStart}
                            order={order + 1}
                            getSessionFrameUrl={getSessionFrameUrl}
                          />
                        )
                      )}
                      <SlotFrameControls
                        toggleFullscreen={fullScreenHandler(frame?.id, true)}
                        toggleClose={closeHandler(frame?.id)}
                        toggleRefresh={frame && getSessionFrameUrl(frame.id, order + 1, frame.provider, hasFreeSpins)}
                        toggleNewPage={frame && newPageHandler(frame.id, frame.provider)}
                        slot={slot}
                      />
                    </>
                  </div>
                )
              })
          )}
        </div>
      </div>
    </>
  ) : (
    <RouteReplacingSlots />
  )
}

export default withFetchSlotsLayoutContentDesktop(SlotFrame)
