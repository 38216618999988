import { defaultCurrency } from '..'

const currencySymbols: Record<string, string> = {
  EUR: '€',
  ESD: '$',
  RUB: '₽',
  UAH: '₴',
  TJS: 'SM',
  KGS: 'с',
  UZS: "So'm",
  AUD: '$',
  USD: '$',
  CNY: '¥',
  TWD: 'NT$',
  GBP: '£',
  KRW: '₩',
}

export const getCurrencySymbol = (currency: string | undefined) => currencySymbols[currency || defaultCurrency] || ''
