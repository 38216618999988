/* eslint-disable max-len */
import React, { Suspense, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import block from 'bem-cn'
import { AnimatePresence, motion } from 'framer-motion'

import { ReactComponent as WhatsAppIcon } from 'shared/img/whatsapp-svg.svg'

import { whatsappLink } from 'shared/constants'
import sizes from 'shared/style/export-sizes.scss'
import { mediaSize } from 'shared/style/var'

import { selectIsUserAuthenticated } from 'features/auth/selectors'
import { selectCountFrames } from 'features/frameManager/selectors'
import { changeOpenedLeftSidePanel, changeOpenedRightSidePanel } from 'features/sidePanel/actions'
import { selectOpenedLeftSidePanel, selectOpenedRightSidePanel } from 'features/sidePanel/selectors'

import { ModalContext, ModalQueryContext } from 'components/HOC/withModalQuery/ModalContext'
import withModalQuery from 'components/HOC/withModalQuery/withModalQuery'
import ModalManager from 'components/ModalManager/ModalManager'
import Notify from 'components/Notify/Notify'

import { DeviceDetect } from 'components/DeviceDetect/DeviceDetect'
import { Footer } from 'components/Footer/Footer'
import { Header } from 'components/Header/Header'
import { MobileMenu } from 'components/MobileMenu/MobileMenu'
import NavigationHeader from 'components/NavigationHeader/NavigationHeader'
import CashbackNotify from 'components/notifies/CashbackNotify/CashbackNotify'
import FirstDepositNotify from 'components/notifies/FirstDepositNotify/FirstDepositNotify'
import PersonalJackpotNotify from 'components/notifies/PersonalJackpotNotify/PersonalJackpotNotify'
import PageLoading from 'components/PageLoading/PageLoading'
import { AppGlobalPolling } from 'components/pollings/AppGlobalPolling'
import BonusPromotionNotification from 'components/ProfileComponents/BonusPromotions/BonusPromotionNotification/BonusPromotionNotification'
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop'

// import { Support } from 'components/Support/Support'
import './App.scss'
import HeaderAchievements from 'components/HeaderAchievements/HeaderAchievements'
import Sidebar from 'components/Sidebar/Sidebar'
import GetCrypto from 'components/GetCrypto/GetCrypto'
import GameSection from 'components/GameSection/GameSection'
import AcceptedCurrencies from 'components/AcceptedCurrencies/AcceptedCurrencies'
import NewFooter from 'components/NewFooter/NewFooter'
import ContentSection from 'components/Content/ContentSection'
import Banner from 'components/Banner/Banner'

const b = block('app')

interface IAppProps {
  modalFilters: ModalQueryContext
}

const App: React.FC<IAppProps> = ({ children, modalFilters }) => {
  const dispatch = useDispatch()

  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)
  const isOpenLeftPanel = useSelector(selectOpenedLeftSidePanel, shallowEqual)
  const isOpenRightPanel = useSelector(selectOpenedRightSidePanel, shallowEqual)
  const isFrameOpen = useSelector(selectCountFrames, shallowEqual) > 0

  const isMobileOrTablet = useMediaQuery({ maxWidth: mediaSize.tablet })

  const [isScrolled, setIsScrolled] = useState<boolean>(true)

  const returnAnimation = useMemo(() => {
    if (isOpenRightPanel) {
      return {
        x: `-${sizes['side-panel-width']}`,
      }
    }
    if (isOpenLeftPanel) {
      return {
        x: `${sizes['side-panel-width']}`,
      }
    }
    return {
      x: 0,
    }
  }, [isOpenLeftPanel, isOpenRightPanel])

  const mobileMenuRef = useRef<HTMLDivElement>(null)
  const appContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const scrollContainer = appContainerRef?.current
    const mobileMenu = mobileMenuRef?.current

    const scrollHandler = () => {
      if (scrollContainer?.scrollTop === 0 && !mobileMenu?.classList.contains('mobile_top')) {
        setIsScrolled(true)
      } else if (scrollContainer?.scrollTop !== 0 && mobileMenu?.classList.contains('mobile_top')) {
        setIsScrolled(false)
      }
    }
    scrollContainer?.addEventListener('scroll', scrollHandler, { capture: true })

    return () => {
      scrollContainer?.removeEventListener('scroll', scrollHandler, { capture: true })
    }
  }, [])

  useLayoutEffect(() => {
    setTimeout(() => localStorage.removeItem('reloadCount'), 2000)
  }, [])

  useEffect(() => {
    if (!isMobileOrTablet) {
      dispatch(changeOpenedLeftSidePanel(false))
      dispatch(changeOpenedRightSidePanel(false))
    }
  }, [isMobileOrTablet, dispatch])

  return (
    <div className={b()}>
      <CashbackNotify />
      <FirstDepositNotify />
      <PersonalJackpotNotify />

      {/* <InstallPWANotification /> */}
      <AppGlobalPolling />

      <div className={b('container')} ref={appContainerRef} id="app-container-id">
        <ModalContext.Provider value={modalFilters}>
          <Notify />
          <ModalManager />
          <DeviceDetect />
          {isAuth && <BonusPromotionNotification />}

          <section className={b('container-main')}>
            <div className={b('sidebar-container')}>
              <Sidebar />
            </div>
            <motion.div
              className={b('main-header')}
              initial={{ x: 0 }}
              animate={returnAnimation}
              transition={{ duration: 0.35 }}>
              <Header />

              <div className={b('main')}>
                {/* <div className={b('main-inner', { isFrameOpen })}> */}
                <div className={b('main-inner')}>
                  {/* <NavigationHeader /> */}
                  
                  <MobileMenu ref={mobileMenuRef} isScrolled={isScrolled} />

                  {/* {isMobileOrTablet && (
                    <motion.div
                      className={b('support-btn')}
                      drag="y"
                      dragElastic={0.2}
                      dragConstraints={{ top: 0, bottom: 0 }}>
                      <a href={whatsappLink}>
                        <WhatsAppIcon />
                      </a>
                    </motion.div>
                  )} */}
                  {/* <Support /> */}

                  {/* <Suspense fallback={<PageLoading isLoading />}>
                  <div className={b('main-content')}>
                    <AnimatePresence exitBeforeEnter>{children}</AnimatePresence>
                  </div>

                
                </Suspense> */}
                   <HeaderAchievements />
                  <Banner />
                  <GetCrypto />
                  {/* <GameSection /> */}
                  <ContentSection />
                  <AcceptedCurrencies />
                  <NewFooter />

                  <ScrollToTop scrollRef={appContainerRef} />
                  {/* <Footer scrollRef={appContainerRef} /> */}
                </div>
              </div>
            </motion.div>
          </section>
        </ModalContext.Provider>
      </div>
    </div>
  )
}

export default withModalQuery(App)
