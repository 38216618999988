import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import block from 'bem-cn'
import { AnimatePresence, motion } from 'framer-motion'

import useOnScreen from 'shared/hooks/useOnScreen'

import './LoadableImage.scss'

interface ILoadableImageProps {
  src: string
  alt?: string
  onLoad?: () => void
  onError?: () => void
  className?: string
  classNameContainer?: string
  hidden?: boolean
  animationType?: 'smallToBig' | 'slide' | 'appearance'
}

const b = block('loadable-image')

const LoadableImage: React.FC<ILoadableImageProps> = props => {
  const {
    src,
    alt = '',
    onLoad = () => {},
    onError = () => {},
    className = '',
    hidden = false,
    classNameContainer = '',
    animationType = '',
  } = props
  const imageRef = useRef<HTMLImageElement>(null)

  const { isVisible, containerRef } = useOnScreen()

  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (!isVisible || isLoaded) {
      return
    }
    if (imageRef.current) {
      imageRef.current.onload = () => {
        if (imageRef.current) {
          setIsLoaded(true)
          onLoad()
        }
      }
    }
  }, [isVisible, onLoad, isLoaded])

  const _onError = useCallback(() => {
    setIsLoaded(true)
    onError()
  }, [onError])

  const variants = useMemo(() => {
    switch (animationType) {
      case 'smallToBig':
        return {
          visible: { opacity: 1, scale: 1 },
          hidden: { opacity: 0.95, scale: 0.85 },
        }
      case 'slide':
        return {
          visible: { opacity: 1, left: 0 },
          hidden: { opacity: 0.95, left: 150 },
        }
      case 'appearance':
        return {
          visible: { opacity: 1 },
          hidden: { opacity: 0.1 },
        }
      default:
        return { visible: { opacity: 1, scale: 1 }, hidden: { opacity: 0, scale: 1 } }
    }
  }, [animationType])

  return (
    <motion.div
      ref={containerRef}
      className={b('container', {
        loaded: isLoaded,
      }).mix(classNameContainer)}
      initial="hidden"
      animate={isLoaded ? 'visible' : 'hidden'}
      transition={{ duration: 0.2, ease: 'easeIn' }}
      variants={variants}>
      {(isVisible || isLoaded) && (
        <img
          onError={_onError}
          ref={imageRef}
          className={b('image', {
            loaded: isLoaded,
            hidden,
          }).mix(className)}
          src={src}
          alt={alt}
        />
      )}
    </motion.div>
  )
}

export default LoadableImage
