import block from 'bem-cn'
import React from 'react'
import { IoIosArrowDown } from 'react-icons/io'
import icon1 from '../../../shared/img/headerAch/Vector.svg'
import jackpot from '../../../shared/img/achievements/jackpots.png'
import './PopUp.scss'
import { prizeDataMobile } from '../data'

type PopupSectionProps = {
  onClose: () => void
  isOpen: boolean
}
const PopupSection: React.FC<PopupSectionProps> = ({ isOpen, onClose }) => {
  const b = block('header-popUp')
  const drawerClass = b('drawer', { modifiers: { open: isOpen } })
  return (
    <section className={b('drawerClass')}>
      <article className={b('wrapper')}>
        <div className={b('prize-container')}>
          {prizeDataMobile.map((item, index) => (
            <ul className={b('prize-section')} key={index}>
              <li className={b('prize-img')} style={!item.svg ? { paddingLeft: 40 } : { paddingLeft: 0 }}>
                {item.svg && <img src={item.svg} alt="awards" />}
              </li>
              <div className={b('prize-text')}>
                <li>{item.text1}</li>
                <li>{item.text2}</li>
              </div>
            </ul>
          ))}
        </div>
      </article>
    </section>
  )
}

export default PopupSection
