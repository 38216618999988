import { createReducer } from '@reduxjs/toolkit'

import * as actions from '../actions'
import { IBonusSystemState } from '../types'

const initialState: IBonusSystemState['data'] = {
  promotions: [],
}

export default createReducer(initialState, builder => {
  builder
    .addCase(actions.getUserPromotions.success, (state, action) => {
      state.promotions = action.payload
    })
    .addCase(actions.getMoneyFromPromotion.success, (state, action) => {
      state.promotions = state.promotions.map(card =>
        String(card.id) === action.payload.id ? action.payload.data : card,
      )
    })
})
