import React from 'react'

import { ProfileTabs, ProfileTabsType } from 'shared/models/Profile'

const PersonalInformation = React.lazy(
  () => import('components/ProfileComponents/PersonalInformation/PersonalInformation'),
)
const Bonuses = React.lazy(() => import('components/ProfileComponents/Bonuses/Bonuses'))
const Deposit = React.lazy(() => import('components/ProfileComponents/Payment/Deposit/Deposit'))
const Withdrawal = React.lazy(() => import('components/ProfileComponents/Payment/Withdrawal/Withdrawal'))
const Transactions = React.lazy(() => import('components/ProfileComponents/Transactions/Transactions'))
const BonusPromotions = React.lazy(() => import('components/ProfileComponents/BonusPromotions/BonusPromotions'))

export interface ISideMenuLinks {
  title: ProfileTabsType
  Component: React.FC
}

export const sideMenuLinks: ISideMenuLinks[] = [
  { title: ProfileTabs.PROFILE, Component: PersonalInformation },
  { title: ProfileTabs.BONUSES, Component: Bonuses },
  { title: ProfileTabs.TRANSACTIONS, Component: Transactions },
  { title: ProfileTabs.DEPOSIT, Component: Deposit },
  { title: ProfileTabs.WITHDRAWAL, Component: Withdrawal },
  { title: ProfileTabs.BONUS_PROMOTIONS, Component: BonusPromotions },
]
