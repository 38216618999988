import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { MODAL_ROUTE_PATH } from 'router/modalRoutes'

import { useDebounce } from 'shared/hooks/useDebounce'
import { ICommonError } from 'shared/models/AppError'
import { getRouteParamsByStrings } from 'shared/utils/getRouteParamsByStrings'

import { checkRegistrationValidate } from 'features/auth/actions'
import { selectCheckPromocodeRegistrationComm } from 'features/auth/selectors'
import { selectLocaleDict } from 'features/locale/selectors'

interface ParamsMatch {
  promocode: string
}

export const usePromocode = ({ modalPath, isModal = true }: { modalPath: string; isModal?: boolean }) => {
  const dispatch = useDispatch()
  const urlParams = useParams<ParamsMatch>()
  const urlPromocodeParams = urlParams?.promocode ? urlParams : null

  const locale = useSelector(selectLocaleDict, shallowEqual).auth
  const errorLocale = useSelector(selectLocaleDict, shallowEqual).errorStatusMessage
  const checkPromocodeComm = useSelector(selectCheckPromocodeRegistrationComm, shallowEqual)

  const params = isModal
    ? getRouteParamsByStrings<ParamsMatch>(MODAL_ROUTE_PATH.SIGN_UP_WITH_PROMOCODE, modalPath)
    : urlPromocodeParams

  const [isActivePromocode, setIsActivePromocode] = useState(params !== null)

  const [promocode, setPromocode] = useState(params !== null ? params.promocode : '')

  const onPromocodeChange = useCallback((value: string) => {
    setPromocode(value)
  }, [])

  const debouncedPromocode = useDebounce(promocode, 1200)

  const changeIsActivePromocode = useCallback(() => {
    setIsActivePromocode(prev => !prev)
  }, [setIsActivePromocode])

  useEffect(() => {
    if (debouncedPromocode) {
      dispatch(checkRegistrationValidate(debouncedPromocode))
    }
  }, [debouncedPromocode])

  const promocodeErrors = useMemo(() => {
    if (!promocode) return ''
    if (checkPromocodeComm.error !== undefined && !checkPromocodeComm.isLoading) {
      const errorCheckPromocode = checkPromocodeComm.error as unknown as ICommonError
      return errorLocale[errorCheckPromocode.code]
    }
    if (checkPromocodeComm.isLoading) return `${locale.checking}..`
    return ''
  }, [checkPromocodeComm.isLoading, errorLocale, promocode, checkPromocodeComm.error, locale])

  return {
    promocode,
    onPromocodeChange,
    isActivePromocode,
    changeIsActivePromocode,
    promocodeErrors,
    isHasPromoInUrl: params !== null,
  }
}
