import { createAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

import { IConvertedBonusHistory } from 'shared/models/BonusSystem'
import { makeCommunicationActionCreator } from 'shared/utils/communication/actions/makeCommunicationActionCreator'

export const hideFirstDepositNotify = createAction('@notifies/HIDE_FIRST_DEPOSIT_NOTIFY')
export const hideCashbackNotify = createAction<number>('@notifies/HIDE_CASHBACK_NOTIFY')
export const hidePersonalJackpotNotify = createAction<number>('@notifies/HIDE_PERSONAL_JCAKPOT')

export const getFirstDeposit = makeCommunicationActionCreator({
  loading: '@notifies/GET_FIRST_DEPOSIT_LOADING',
  success: '@notifies/GET_FIRST_DEPOSIT_SUCCESS',
  error: '@notifies/GET_FIRST_DEPOSIT_ERROR',
  reset: '@notifies/GET_FIRST_DEPOSIT_RESET',
})<undefined, IConvertedBonusHistory>(
  async ({
    deps: {
      extra: { api },
    },
  }) => {
    const data = {
      page: 1,
      page_size: 1,
      date_from: '2022-12-29T12:00:00.000000Z', // история с 29 декабря для миграции
      ordering: '-date',
      type: 'FirstDeposit',
    }

    const response = await api.bonusSystem.loadBonusHistory(data)
    return response.data
  },
)

export const getPersonalJackpots = makeCommunicationActionCreator({
  loading: '@notifies/GET_PERSONAL_JACKPOTS_LOADING',
  success: '@notifies/GET_PERSONAL_JACKPOTS_SUCCESS',
  error: '@notifies/GET_PERSONAL_JACKPOTS_ERROR',
  reset: '@notifies/GET_PERSONAL_JACKPOTS_RESET',
})<undefined, IConvertedBonusHistory>(
  async ({
    deps: {
      extra: { api },
    },
  }) => {
    const data = {
      page: 1,
      page_size: 2,
      date_from: '2022-12-29T12:00:00.000000Z', // история с 29 декабря
      ordering: '-date',
      type: 'PersonalJackpot',
    }

    const response = await api.bonusSystem.loadBonusHistory(data)
    return response.data
  },
)

export const getCashbackList = makeCommunicationActionCreator({
  loading: '@notifies/GET_CASHBACK_LIST_LOADING',
  success: '@notifies/GET_CASHBACK_LIST_SUCCESS',
  error: '@notifies/GET_CASHBACK_LIST_ERROR',
  reset: '@notifies/GET_CASHBACK_LIST_RESET',
})<undefined, IConvertedBonusHistory>(
  async ({
    deps: {
      extra: { api },
    },
  }) => {
    const data = {
      page: 1,
      page_size: 2,
      date_from: '2022-12-29T12:00:00.000000Z', // история с 29 декабря
      ordering: '-date',
    }

    const response = await api.bonusSystem.loadCashbackHistory(data)
    return response.data
  },
)
