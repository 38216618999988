import iphoneImg from '../img/apple-logo-white.png'

export const wheelPrizesIconsByName: Record<string, string> = {
  Iphone: iphoneImg,
}

export const PROCCESS_ROTATE_DURATION = 4
export const PROCCESS_ROTATE_COUNT = 360 * 3

export const prizeAngles = {
  0: 180,
  1: 157.5,
  2: 135,
  3: 112.5,
  4: 90,
  5: 67.5,
  6: 45,
  7: 22.5,
  8: 0,
  9: 337.5,
  10: 315,
  11: 292.5,
  12: 270,
  13: 247.5,
  14: 225,
  15: 202.5,
}
