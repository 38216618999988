import { createReducer } from '@reduxjs/toolkit'

import * as actions from '../actions'
import { IPaymentsState } from '../types'

const initialState: IPaymentsState['data'] = {
  deposit: {
    createdDeposit: null,
    createdSuccessfully: false,
    systemList: {
      page: 1,
      count: 0,
      list: [],
    },
    depositList: {
      page: 1,
      count: 0,
      list: [],
    },
  },
  payout: {
    createdPayout: null,
    systemList: {
      page: 1,
      count: 0,
      list: [],
    },
    payoutList: {
      page: 1,
      count: 0,
      list: [],
    },
  },
  paymentHistory: {
    items: [],
    page: 1,
    count: 0,
  },
  cryptoCurrenciesList: [],
  cryptoPaymentData: null,
  cryptoMinAmountByCurrency: null,
  countriesForCryptoTransfer: [],
}

export default createReducer(initialState, builder => {
  builder
    .addCase(actions.getDepositSystemList.success, (state, action) => {
      state.deposit.systemList = action.payload
    })
    .addCase(actions.clearDepositCreated, state => {
      state.deposit.createdSuccessfully = false
    })
    .addCase(actions.createDeposit.success, (state, action) => {
      state.deposit.createdDeposit = action.payload
      state.deposit.createdSuccessfully = true
    })
    .addCase(actions.createPayout.success, (state, action) => {
      state.payout.createdPayout = action.payload
    })
    .addCase(actions.getPayoutSystemList.success, (state, action) => {
      state.payout.systemList = action.payload
    })
    .addCase(actions.getDepositList.success, (state, action) => {
      state.deposit.depositList = action.payload
    })
    .addCase(actions.getPayoutList.success, (state, action) => {
      state.payout.payoutList = action.payload
    })
    .addCase(actions.getCryptoCurrenciesList.success, (state, action) => {
      state.cryptoCurrenciesList = action.payload
    })
    .addCase(actions.createCryptoPayment.success, (state, action) => {
      state.cryptoPaymentData = action.payload
    })
    .addCase(actions.clearCryptoPaymentData, state => {
      state.cryptoPaymentData = initialState.cryptoPaymentData
    })
    .addCase(actions.getCryptoMinAmount.success, (state, action) => {
      state.cryptoMinAmountByCurrency = {
        ...(state.cryptoMinAmountByCurrency ?? {}),
        [action.payload.currency]: action.payload,
      }
    })
    .addCase(actions.getOnlinePaymentHistory.success, (state, action) => {
      state.paymentHistory = action.payload
    })
    .addCase(actions.getCountriesForCryptoTransferOrder.success, (state, action) => {
      state.countriesForCryptoTransfer = action.payload
    })
})
