import React, { memo, useCallback, useContext, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import block from 'bem-cn'

import { MODAL_ROUTE_URLS } from 'router/modalRoutes'

import { useFilterSlots } from 'modules/SlotsRoot/hooks'
import { ISlotsGameType, ISlotViewItem } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'
import { Providers, ProvidersType } from 'modules/SlotsRoot/types/slot'

import { selectIsUserAuthenticated } from 'features/auth/selectors'
import { actions } from 'features/frameManager'
import { selectOpenedFrames } from 'features/frameManager/selectors'
import { selectLocaleDict } from 'features/locale/selectors'
import { selectFavoriteList, selectGameList } from 'features/slots/selectors'

import { ModalContext } from 'components/HOC/withModalQuery/ModalContext'

import SlotsIcon from 'components/slots/SlotsIcon/SlotsIcon'

import { IFiltersSlots } from '../../modules/SlotsRoot/types/filters'

import './SlotEmptyIframe.scss'

interface ISlotEmptyIframe {
  order: number
  demoStart?: string
  getSessionFrameUrl: (
    frameGameId: string,
    order: number,
    frameProvider: ProvidersType,
    frameHasFreeSpins: boolean | undefined,
  ) => () => void
  gameType: ISlotsGameType
}

const b = block('slot-empty-iframe')
const SlotEmptyIframe: React.FC<ISlotEmptyIframe> = ({ order, getSessionFrameUrl, demoStart, gameType }) => {
  const locale = useSelector(selectLocaleDict, shallowEqual).slots
  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)
  const gameList = useSelector(selectGameList, shallowEqual)
  const favoritesList = useSelector(selectFavoriteList, shallowEqual)
  const openedFrames = useSelector(selectOpenedFrames, shallowEqual)
  const openedGames = openedFrames.map(frame => frame.gameId)
  const { pushNewModal } = useContext(ModalContext)
  let filters: IFiltersSlots = {
    setSelectedProviders: () => null,
    selectedProviders: [],
    selectedCategories: [],
    setSelectedCategories: () => null,
    searchString: '',
    setSearchString: () => null,
  }
  const dispatch = useDispatch()

  const { slots } = useFilterSlots({
    filters,
    slots: gameList.filter(game => !openedGames.includes(game.gameId)),
    gameType,
  })

  const slotClickHandler = useCallback(
    (slot: ISlotViewItem) => () => {
      const provider = slot.routeProvider as ProvidersType
      dispatch(
        actions.addFrame({
          id: slot.gameId,
          order,
          provider,
          gameId: slot.gameId,
          getSession: getSessionFrameUrl(slot.gameId, order, provider, false),
        }),
      )
    },
    [dispatch, getSessionFrameUrl, order],
  )

  const addFrameHandler = useCallback(() => {
    pushNewModal(MODAL_ROUTE_URLS.ADD_SLOT_FRAME(order, gameType, demoStart))()
  }, [pushNewModal, order, gameType, demoStart])

  const popularSlots = useMemo(() => {
    const popularSlotsList = slots
      .filter(slot => slot.tab.includes('popular') || slot.tabLive.includes('popular'))
      .slice(0, 4)
    return (
      <div className={b('slots')}>
        {popularSlotsList &&
          popularSlotsList.map((slot, id) => <SlotsIcon key={id} slot={slot} addFrame={slotClickHandler} />)}
      </div>
    )
  }, [slotClickHandler, slots])

  const favoriteSlotsList = useMemo(
    () => slots.filter(slot => favoritesList.includes(`${slot.gameId}_${slot.provider}`)),
    [favoritesList, slots],
  )
  const favoriteSlots = useMemo(
    () => (
      <div className={b('slots')}>
        {favoriteSlotsList &&
          favoriteSlotsList.map((slot, id) => <SlotsIcon key={id} slot={slot} addFrame={slotClickHandler} />)}
      </div>
    ),
    [favoriteSlotsList, slotClickHandler],
  )

  return (
    <div className={b()}>
      <div className={b('button-wrapper')} onClick={addFrameHandler}>
        {locale.addGame}
      </div>
      <div className={b('slots-container')}>
        <div className={b('slots-header')}>{locale.mostPopular}</div>
        {popularSlots}
      </div>
      {isAuth && favoriteSlotsList.length > 0 && (
        <div className={b('slots-container')}>
          <div className={b('slots-header')}>{locale.favorites}</div>
          {favoriteSlots}
        </div>
      )}
    </div>
  )
}

export default memo(SlotEmptyIframe)
