import { createAction } from '@reduxjs/toolkit'

// eslint-disable-next-line max-len
import { makeCommunicationByIdActionCreator } from 'shared/utils/communication/actions/makeCommunicationByIdActionCreator'

import { ISlotViewItem } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'
import { ProvidersType } from 'modules/SlotsRoot/types/slot'

interface IAddFramePayload {
  id: string
  order: number
  provider: ProvidersType
  gameId: string
  withRedirect?: boolean
  isDesktop?: boolean
  attempts?: number
  callback: () => void
  demoStart?: string
  isFreeSpins?: boolean
  hasFreeSpins?: boolean
}

interface IAddFrameResponse {
  id: string
  payload: string
}

interface IAddFrameSession {
  session: string
  id: string
}

interface IAddFrame {
  id: string
  order: number
  provider: ProvidersType
  gameId: string
  getSession: () => void
}

const attemptsToLoadSessionUrl = 3

export const addFrameSession = createAction<IAddFrameSession>('@frameManager/ADD_FRAME_SESSION')

export const getFrameSession = makeCommunicationByIdActionCreator({
  loading: '@frameManager/GET_FRAME_SESSION_LOADING',
  success: '@frameManager/GET_FRAME_SESSION_SUCCESS',
  error: '@frameManager/GET_FRAME_SESSION_ERROR',
  reset: '@frameManager/GET_FRAME_SESSION_RESET',
})<IAddFramePayload, IAddFrameResponse>(
  async ({
    deps: {
      extra: { api },
      dispatch,
      getState,
    },
    payload,
  }) => {
    const {
      id,
      order,
      provider,
      gameId,
      withRedirect = false,
      isDesktop = false,
      attempts = 0,
      callback,
      hasFreeSpins,
      isFreeSpins,
      demoStart,
    } = payload
    let currentAttempts = attempts + 1
    const state = getState()
    const { lang } = state.userSettings.data
    const extraData = state.slots.data.extraData[provider] || {}
    const { gameList } = state.slots.data
    const providerGameList = gameList.filter((t: ISlotViewItem) => t.routeProvider === provider)

    let response

    try {
      response = await api.slots.getSessionUrl({
        provider,
        gameId,
        extraData,
        gameList: providerGameList,
        params: { lang, isDesktop },
        demoStart,
        hasFreeSpins,
      })

      dispatch(
        addFrameSession({
          session: response.data,
          id: gameId,
        }),
      )

      if (withRedirect) {
        location.href = response.data
      }

      return { id, payload: response.data }
    } catch (e) {
      if (currentAttempts >= attemptsToLoadSessionUrl) {
        callback()
      } else {
        dispatch(
          getFrameSession({
            id: gameId,
            order,
            provider,
            gameId,
            withRedirect,
            isDesktop,
            attempts: currentAttempts,
            callback,
            hasFreeSpins,
          }),
        )

        throw new Error(JSON.stringify(e) || '')
      }
      return { id } as IAddFrameResponse
    }
  },
)

export const addFrame = createAction('@frameManager/ADD_FRAME', (payload: IAddFrame) => {
  payload.getSession()
  return {
    payload: {
      id: payload.id,
      order: payload.order,
      gameId: payload.gameId,
      isFullscreen: false,
      provider: payload.provider,
    },
  }
})

export const removeFrame = createAction<string>('@frameManager/REMOVE_FRAME')

export const removeAllFrames = createAction('@frameManager/REMOVE_ALL_FRAMES')

export const changeIsFullscreenFrame = createAction(
  '@frameManager/CHANGE_IS_FULL_OPEN_FRAME',
  (id: string, val: boolean) => ({
    payload: {
      id,
      val,
    },
  }),
)

export const changeCountFrames = createAction<number>('@frameManager/CHANGE_COUNT_FRAMES')
