import React, { useMemo } from 'react'
import block from 'bem-cn'

import './WagerCompletionScale.scss'

interface IWagerCompletionScale {
  wager: number
  wagerComplete: number
}

const b = block('wager-completion-scale')

const WagerCompletionScale: React.FC<IWagerCompletionScale> = ({ wager, wagerComplete }) => {
  const progressAsPercentage = useMemo(() => (100 * wagerComplete) / wager, [wager, wagerComplete])

  return (
    <div className={b()}>
      <div className={b('scale-container')}>
        <div className={b('main-line')} />
        <div className={b('progress-line')} style={{ width: `${progressAsPercentage}%` }} />
        <div className={b('slide-point')} style={{ left: `${progressAsPercentage}%` }}>
          <div className={b('slide-point-outer')} />
        </div>
      </div>
      <div className={b('numbers')}>
        <span>{wagerComplete}</span>
        <span>{wager}</span>
      </div>
    </div>
  )
}

export default WagerCompletionScale
