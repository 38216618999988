import React, { useCallback, useContext, useState } from 'react'
import ReactDOM from 'react-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import block from 'bem-cn'
import { Formik } from 'formik'

import { MODAL_ROUTE_URLS } from 'router/modalRoutes'

import { usernameSignIn } from 'features/auth/actions'
import { selectAuthCommunication } from 'features/auth/selectors'
import { selectLocaleDict } from 'features/locale/selectors'

import { ModalContext } from 'components/HOC/withModalQuery/ModalContext'

import Button from 'components/UIKit/Button/Button'
import { Input } from 'components/UIKit/Input/Input'

import './SignInMobile.scss'

const b = block('sign-in-mobile')

interface IProps {
  setIsOpen: () => void
}

export const SignInMobile: React.FC<IProps> = ({ setIsOpen }) => {
  const dispatch = useDispatch()
  const { pushNewModal } = useContext(ModalContext)

  const { isLoading } = useSelector(selectAuthCommunication, shallowEqual).login
  const locale = useSelector(selectLocaleDict, shallowEqual).auth
  const localeCommon = useSelector(selectLocaleDict, shallowEqual).common

  const [state] = useState({
    username: '',
    password: '',
  })

  const handleFormSubmit = useCallback(
    (values: typeof state) => {
      dispatch(usernameSignIn(values, { callback: setIsOpen }))
    },
    [dispatch, setIsOpen],
  )

  return (
    <Formik initialValues={{ username: state.username, password: state.password }} onSubmit={handleFormSubmit}>
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <form className={b('form')} onSubmit={handleSubmit}>
          <div className={b('form_input-wrapper')}>
            <Input
              type="text"
              name="username"
              placeholder={`${locale.username}`}
              value={values.username}
              onChange={handleChange}
              isRequired
              onBlur={handleBlur}
              rounded
            />
          </div>
          <div className={b('form_input-wrapper')}>
            <Input
              type="password"
              name="password"
              placeholder={`${locale.password}`}
              value={values.password}
              onChange={handleChange}
              isRequired
              onBlur={handleBlur}
              rounded
            />
          </div>
          <div className={b('form_button-container')}>
            <Button
              text={localeCommon.login}
              type="submit"
              colorType="primary"
              disabled={isLoading}
              loading={isLoading}
              uppercase
              rounded
            />
          </div>
          <div onClick={pushNewModal(MODAL_ROUTE_URLS.FORGOT_PASSWORD())} className={b('form_forgot-password')}>
            {locale.forgotPassword}
          </div>
        </form>
      )}
    </Formik>
  )
}
