import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { selectIsUserAuthenticated } from 'features/auth/selectors'
import { selectLocaleDict } from 'features/locale/selectors'
import { hideFirstDepositNotify } from 'features/notifies/actions'
import { selectNotifies } from 'features/notifies/selectors'

import NotifyContainer from '../NotifyContainer/NotifyContainer'
import FirstDepositPolling from './FirstDepositPolling/FirstDepositPolling'

const FirstDepositNotify = () => {
  const dispatch = useDispatch()

  const isAuth = useSelector(selectIsUserAuthenticated, shallowEqual)
  const locale = useSelector(selectLocaleDict, shallowEqual).bonuses
  const { item, isShowed } = useSelector(selectNotifies, shallowEqual).firstDeposit

  const [temporarilyHidden, setTemporarilyHidden] = useState<boolean>(false)

  const hideNotify = () => {
    dispatch(hideFirstDepositNotify())
  }

  const temporarilyHideNotify = () => {
    setTemporarilyHidden(true)
  }

  return (
    <>
      {isAuth && !isShowed && !item && !temporarilyHidden ? <FirstDepositPolling /> : null}
      {isShowed || (!item && !isShowed) || temporarilyHidden ? null : (
        <NotifyContainer
          title={locale.firstDepositNotify}
          value={`${item?.amount} ${item?.amountCurrency}`}
          callback={hideNotify}
          backgroundCallback={temporarilyHideNotify}
        />
      )}
    </>
  )
}

export default FirstDepositNotify
