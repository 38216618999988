import React, { useCallback, useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import block from "bem-cn";
import { Formik } from "formik";
import { Persist as FormikPersist } from "formik-persist";

import { MODAL_ROUTE_URLS } from "router/modalRoutes";
import { ROUTE_URLS } from "router/routes";

import { defaultCurrency } from "shared/constants";
import { transLang } from "shared/locale";
import { useMediaMaxTablet } from "shared/style/var";

import { actions as authActions } from "features/auth";
import { selectAuthCommunication } from "features/auth/selectors";
import { selectLocaleDict } from "features/locale/selectors";
import { getCurrenciesList } from "features/settings/actions";
import { selectCurrenciesList } from "features/settings/selectors";
import { selectLanguage } from "features/userSettings/selectors";
import google from "shared/img/google.svg";
import telegram from "shared/img/telegram.svg";

import { useModalContext } from "components/HOC/withModalQuery/ModalContext";

import Loader from "components/Loader/Loader";
import { addNotify } from "components/Notify";
import Button from "components/UIKit/Button/Button";
import { Checkbox } from "components/UIKit/Checkbox/Checkbox";
import { Input } from "components/UIKit/Input/Input";
import { Select } from "components/UIKit/Select/Select";

import {
  StyledAuthForm,
  StyledAuthInputWrapper,
  StyledAuthModal,
} from "../styles/AuthStyles";
import { registrationFields } from "./data";
import { usePromocode } from "./promocode/usePromocode";

// import "./SignUp.scss";
import "../SignIn/SignIn.scss";
import { CheckBox } from "@mui/icons-material";

interface ISignUpProps {
  path: string; // from ModalManager
}

const b = block("sign-in");

const SignUp: React.FC<ISignUpProps> = ({ path: modalPath }) => {
  const dispatch = useDispatch();
  const { replaceLastModal } = useModalContext();
  const isMobile = useMediaMaxTablet();
  const lang = useSelector(selectLanguage, shallowEqual);
  const locale = useSelector(selectLocaleDict, shallowEqual).auth;
  const localeCommon = useSelector(selectLocaleDict, shallowEqual).common;
  const currencies = useSelector(selectCurrenciesList, shallowEqual);
  const { isLoading } = useSelector(
    selectAuthCommunication,
    shallowEqual
  ).signUp;

  const {
    promocode,
    onPromocodeChange,
    isActivePromocode,
    changeIsActivePromocode,
    isHasPromoInUrl,
  } = usePromocode({
    modalPath,
    isModal: !isMobile,
  });

  useEffect(() => {
    dispatch(getCurrenciesList());
  }, []);

  const onSubmitHandler = useCallback(
    (values: typeof registrationFields) => {
      const userData = {
        username: values.email,
        password1: values.password,
        password2: values.password,
        referral_code: (isActivePromocode && promocode) || undefined,
        email: values.email,
        // currency: values.currency,
        language: transLang[lang],
      };

      dispatch(
        authActions.signUp(userData, {
          callback: () => {
            replaceLastModal(MODAL_ROUTE_URLS.SUCCESS_REGISTER())();
            // dispatch(
            //   authActions.usernameSignIn({
            //     username: userData.username,
            //     password: userData.password1,
            //   }),
            // )
            // history.push(ROUTE_URLS.MAIN())
          },
        })
      );
    },
    [dispatch, lang, promocode, isActivePromocode, replaceLastModal]
  );

  const currenciesListSelectData = useMemo(
    () =>
      currencies.map((t) => ({
        value: t,
      })),
    [currencies]
  );

  const onToggleCheckbox = () => {
    if (isHasPromoInUrl) return;
    changeIsActivePromocode();
    onPromocodeChange("");
  };

  return (
    <>
      <StyledAuthModal>
        <div className={b()}>
          <div className={b("header")}>{locale.registration}</div>

          <Formik
            key={currencies.length}
            initialValues={{ ...registrationFields, currency: defaultCurrency }}
            enableReinitialize
            validate={(values) => {
              const errors: Record<string, string> = {};

              if (!values.condition) {
                errors.condition = locale.requiredToAccept;
              }
              if (!values.currency) {
                errors.currency = locale.required;
              }
              if (!values.password.match("[^ ]{8,}$")) {
                errors.password = `${locale.minSymbols}: 8`;
              }
              return errors;
            }}
            onSubmit={(values) => onSubmitHandler(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
              isValid,
            }) => (
              <StyledAuthForm
                style={{ alignItems: "flex-start" }}
                onSubmit={handleSubmit}
              >
                <StyledAuthInputWrapper
                  style={{ marginLeft: 0 }}
                  className={b("input-wrapper")}
                >
                  <span className={b("label")}>{`${locale.emailAddress}`}</span>
                  <Input
                    type="text"
                    name="username"
                    placeholder={`${locale.username}`}
                    value={values.username}
                    onChange={handleChange}
                    isRequired
                    onBlur={handleBlur}
                    height="40px"
                  />
                </StyledAuthInputWrapper>
                <StyledAuthInputWrapper style={{ marginLeft: 0 }}>
                  <span className={b("label")}>{`${locale.password} *`}</span>
                  <Input
                    name="password"
                    type="password"
                    placeholder={`${locale.password}`}
                    value={values.password}
                    isRequired
                    onChange={handleChange}
                    onBlur={handleBlur}
                    height="40px"
                  />
                </StyledAuthInputWrapper>
                <div className="agreeTerms" onClick={onToggleCheckbox}>
                  <Checkbox name="isActivePromocode" checked={isActivePromocode} disabled={false} />
                  {locale.agreedTerms}
                </div>
                <div className={b("bottom-content")}>
                  <div className={b("button-container")}>
                    <Button
                      type="submit"
                      text={localeCommon.registration}
                      colorType="gradient"
                      disabled={isLoading}
                      loading={isLoading}
                      rounded
                    />
                  </div>
                </div>
                <div style={{ color: "white" }}>
                  {locale.signInDescription}{" "}
                  <span className="cursor-pointer" style={{ color: "white" }}>
                    {locale.signIn}
                  </span>
                </div>
                <div className="line-container">
                  <span className="text">Or</span>
                  <hr className="line" />
                </div>
                <div className="oauth">
                  <div>
                    <img src={google} width={15} />
                    Google
                  </div>
                  <div>
                    {" "}
                    <img src={telegram} width={15} />
                    Telegram
                  </div>
                </div>

                <FormikPersist name="signup-form-1" />
              </StyledAuthForm>
            )}
          </Formik>
        </div>
      </StyledAuthModal>
    </>
  );
};

export default SignUp;
