import React from 'react'

import { MODAL_ROUTE_PATH } from 'router/modalRoutes'

import SlotRedirect from 'modules/SlotsRoot/components/SlotRedirect/SlotRedirect'

import { EmailSent } from 'pages/Auth/EmailSent/EmailSent'
import ForgotPassword from 'pages/Auth/ForgotPassword/ForgotPassword'
import { ResetEmail } from 'pages/Auth/ResetEmail/ResetEmail'
import ResetPassword from 'pages/Auth/ResetPassword/ResetPassword'
import SignIn from 'pages/Auth/SignIn/SignIn'
import SignUp from 'pages/Auth/SignUp/SignUp'
import { SuccessRegister } from 'pages/Auth/SuccessRegister/SuccessRegister'
import { VerifyEmail } from 'pages/Auth/VerifyEmail/VerifyEmail'

// eslint-disable-next-line max-len
import { ConfirmActivateBonus } from 'components/ProfileComponents/BonusPromotions/ConfirmActivateBonus/ConfirmActivateBonus'
import PaymentRedirection from 'components/ProfileComponents/Payment/Deposit/PaymentRedirection/PaymentRedirection'
import ChangePassword from 'components/ProfileDesktop/ChangePassword/ChangePassword'
import { ProfileDesktop } from 'components/ProfileDesktop/ProfileDesktop'
import SlotAddModal from 'components/SlotAddModal/SlotAddModal'
import { WheelGame } from 'components/WheelGame/WheelGame'
import WheelGameHistory from 'components/WheelGame/WheelGameHistory/WheelGameHistory'

interface IModalRoutesItem {
  id: string
  route: string
  Component: React.FC<any>
  bgColor?: string
  hideCloseBtn?: boolean
  noPaddings?: boolean
}

export const modalRoutes: IModalRoutesItem[] = [
  {
    id: 'sign-in',
    route: MODAL_ROUTE_PATH.SIGN_IN,
    Component: SignIn,
  },
  {
    id: 'sign-up',
    route: MODAL_ROUTE_PATH.SIGN_UP,
    Component: SignUp,
  },
  {
    id: 'sign-up-with-code',
    route: MODAL_ROUTE_PATH.SIGN_UP_WITH_PROMOCODE,
    Component: SignUp,
  },
  {
    id: 'profile-desktop',
    route: MODAL_ROUTE_PATH.PROFILE_DESKTOP || MODAL_ROUTE_PATH.PROFILE_DESKTOP_OPTION,
    Component: ProfileDesktop,
  },
  {
    id: 'change-password',
    route: MODAL_ROUTE_PATH.CHANGE_PASSWORD,
    Component: ChangePassword,
  },
  {
    id: 'forgot-password',
    route: MODAL_ROUTE_PATH.FORGOT_PASSWORD,
    Component: ForgotPassword,
  },
  {
    id: 'reset-password',
    route: MODAL_ROUTE_PATH.RESET_PASSWORD,
    Component: ResetPassword,
  },
  {
    id: 'wheel-game',
    route: MODAL_ROUTE_PATH.WHEEL_GAME,
    Component: WheelGame,
    bgColor: 'transparent',
  },
  {
    id: 'wheel-game-history',
    route: MODAL_ROUTE_PATH.WHEEL_GAME_HISTORY,
    Component: WheelGameHistory,
    bgColor: 'transparent',
  },
  {
    id: 'payment-redirection',
    route: MODAL_ROUTE_PATH.PAYMENT_REDIRECTION,
    Component: PaymentRedirection,
    bgColor: 'transparent',
  },
  {
    id: 'resend-email',
    route: MODAL_ROUTE_PATH.RESEND_EMAIL,
    Component: ResetEmail,
  },
  {
    id: 'verify-email',
    route: MODAL_ROUTE_PATH.VERIFY_EMAIL,
    Component: VerifyEmail,
    bgColor: 'linear-gradient(180deg, #181123 0%, #31273e 68.75%)',
  },
  {
    id: 'email-sent',
    route: MODAL_ROUTE_PATH.EMAIL_SENT,
    Component: EmailSent,
    bgColor: 'linear-gradient(180deg, #181123 0%, #31273e 68.75%)',
  },
  {
    id: 'success-register',
    route: MODAL_ROUTE_PATH.SUCCESS_REGISTER,
    Component: SuccessRegister,
    bgColor: 'linear-gradient(180deg, #181123 0%, #31273e 68.75%)',
  },
  {
    id: 'add-slot-frame',
    route: MODAL_ROUTE_PATH.ADD_SLOT_FRAME,
    Component: SlotAddModal,
  },
  {
    id: 'replacing-slot',
    route: MODAL_ROUTE_PATH.REPLACING_SLOT_FRAME,
    Component: SlotRedirect,
    bgColor: 'transparent',
    hideCloseBtn: true,
    noPaddings: true,
  },
  {
    id: 'replacing-slot-demo',
    route: MODAL_ROUTE_PATH.REPLACING_SLOT_FRAME_DEMO,
    Component: SlotRedirect,
    bgColor: 'transparent',
    hideCloseBtn: true,
    noPaddings: true,
  },
  {
    id: 'confirm-activate-bonus',
    route: MODAL_ROUTE_PATH.CONFIRM_ACTIVATE_BONUS,
    Component: ConfirmActivateBonus,
    bgColor: 'linear-gradient(180deg, #181123 0%, #31273e 68.75%)',
  },
]
