import {
  IConvertedValidateCodeResponse,
  IResendEmailPayload,
  IUserSignUp,
  IValidateCodeResponse,
  IVerifyEmailPayload,
} from 'shared/models/Auth'
import { ILoginServer, ILoginServerDTO } from 'shared/models/Login'
import { IServerUser, IUser } from 'shared/models/User'
import { SignInType } from 'shared/utils/types/Auth.type'

import { IUserPayload } from 'features/profile/types'

import { BaseHttpService } from '../BaseHttpService'
import { QueryTypes } from '../data'
import { AuthConverter } from './AuthConverter'
import { IResetPasswordConfirm } from './types/types'

class AuthApi extends BaseHttpService {
  private converter

  private referral

  constructor(baseUrl: string) {
    super(baseUrl)
    this.baseUrl = `${baseUrl}/api/accounts`
    this.referral = `${baseUrl}/api/referral`
    this.converter = new AuthConverter()
  }

  async logout() {
    return this.sendQuery({
      url: `${this.baseUrl}/logout/`,
      type: QueryTypes.POST,
    })
  }

  async usernameSignIn({ data }: { data: ILoginServer }) {
    return this.sendQuery<ILoginServerDTO, SignInType, ILoginServer>({
      url: `${this.baseUrl}/login/`,
      type: QueryTypes.POST,
      data,
      converterSuccess: this.converter.convertSignInData,
    })
  }

  async signUp({ data }: { data: IUserSignUp }) {
    return this.sendQuery({
      url: `${this.baseUrl}/registration/`,
      type: QueryTypes.POST,
      data,
    })
  }

  async passwordReset(email: string) {
    return this.sendQuery<string, string>({
      url: `${this.baseUrl}/password/reset/`,
      type: QueryTypes.POST,
      data: { email },
    })
  }

  async passwordResetConfirm(data: IResetPasswordConfirm) {
    return this.sendQuery<IResetPasswordConfirm, IResetPasswordConfirm>({
      url: `${this.baseUrl}/password/reset/confirm/`,
      type: QueryTypes.POST,
      data,
    })
  }

  async getUserData() {
    return this.sendQuery<IServerUser, IUser, ILoginServer>({
      url: `${this.baseUrl}/user/`,
      type: QueryTypes.GET,
      converterSuccess: this.converter.convertUserData,
    })
  }

  async updateUserData(data: IUserPayload) {
    return this.sendQuery<IServerUser, IUser>({
      url: `${this.baseUrl}/user/`,
      type: QueryTypes.PATCH,
      data,
      converterSuccess: this.converter.convertUserData,
    })
  }

  async verifyEmail({ key }: { key: string }) {
    return this.sendQuery<IVerifyEmailPayload, IVerifyEmailPayload>({
      url: `${this.baseUrl}/registration/verify-email/`,
      type: QueryTypes.POST,
      data: { key },
    })
  }

  async resendEmail({ email }: IResendEmailPayload) {
    return this.sendQuery({
      url: `${this.baseUrl}/registration/resend-email/`,
      type: QueryTypes.POST,
      data: { email },
    })
  }

  async checkRegistrationValidate(code: string) {
    return this.sendQuery<IValidateCodeResponse, IConvertedValidateCodeResponse>({
      url: `${this.referral}/register/validate_code/?code=${code}`,
      type: QueryTypes.GET,
      converterSuccess: data => ({ partnerId: data.partner_id }),
    })
  }
}

export default AuthApi
