/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { ChangeEvent, useCallback, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { createTheme, Input as MInput, InputAdornment, ThemeProvider } from '@mui/material'
import block from 'bem-cn'

import { ReactComponent as CrossSVG } from 'shared/img/cross.svg'

import { selectLocaleDict } from 'features/locale/selectors'

import { ReactComponent as EyeIcon } from './img/eye-icon.svg'
import { ReactComponent as EyeSlashIcon } from './img/eye-icon-slash.svg'

import './Input.scss'

interface IInputProps {
  label?: string
  placeholder?: string
  value: string | number
  onChange: (e: React.ChangeEvent<{ value: string }>) => void
  name?: string
  isRequired?: boolean
  type?: string
  errors?: any
  error?: string | boolean
  isAllowedToDelete?: boolean
  deleteInputCallback?: (e: any) => void
  rounded?: boolean
  bottomText?: string | boolean
  height?: string
  autoCapitalize?: string
  colorType?: 'myProfile' | 'payment'
  [x: string]: any
}

export const Input: React.FC<IInputProps> = ({
  label,
  placeholder,
  value,
  type = 'text',
  onChange,
  isRequired = false,
  errors,
  error,
  isAllowedToDelete = false,
  deleteInputCallback,
  rounded = true,
  bottomText,
  height = '100%',
  autoCapitalize = 'off',
  colorType,
  ...props
}) => {
  const [visible, setVisible] = React.useState(false)

  const b = block('input')

  const theme = createTheme({
    components: {
      MuiInput: {
        styleOverrides: {
          root: {
            borderRadius: rounded ? 5 : 2,
            overflow: 'hidden',
            height,
            textTransform: 'none',
            fontVariant: 'inherit',
            fontStretch: 'inherit',
            fontFamily: 'inherit',
            fontSize: '13px',
            fontWeight: 500,
            lineHeight: 1.5,
            transition: 'all .3s ease',

            '&.Mui-focused': {
              input: {
                borderColor: 'transparent',
              },
            },
            '&.Mui-disabled': {
              input: {
                WebkitTextFillColor: 'inherit',
                opacity: 1,
              },
            },
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            cursor: 'pointer',
            svg: {
              width: '13px',
              polygon: {
                fill: 'white',
              },
            },
          },
        },
      },
    },
  })

  const errorLocale = useSelector(selectLocaleDict, shallowEqual).errorStatusMessage
  const isPasswordInput = type === 'password'
  const inputType = isPasswordInput ? (visible ? 'text' : 'password') : type

  const changeVisiblePassword = () => {
    setVisible(!visible)
  }

  const onChangeCallback = useCallback(
    e => {
      onChange(e)
    },
    [onChange],
  )

  const currErr = useMemo(() => {
    const { name } = props
    return errors?.listOfErrors.find((err: any) => err.field === name)
  }, [errors, props.name, errorLocale])

  return (
    <div className={b('wrapper')}>
      <ThemeProvider theme={theme}>
        <MInput
          fullWidth
          disableUnderline
          type={inputType}
          value={value}
          onChange={onChangeCallback}
          placeholder={placeholder}
          required={isRequired}
          className={b({ colorType }).toString()}
          endAdornment={
            (isPasswordInput && (
              <InputAdornment position="end" onClick={changeVisiblePassword}>
                {visible ? <EyeIcon /> : <EyeSlashIcon />}
              </InputAdornment>
            )) ||
            (isAllowedToDelete && (
              <InputAdornment position="end" onClick={deleteInputCallback}>
                <CrossSVG className={b('eye-icon')} />
              </InputAdornment>
            ))
          }
          inputProps={{
            autoCapitalize,
          }}
          {...props}
        />
        <div className={b('error')}>
          {(typeof error === 'string' && errorLocale[error]) || error || errorLocale[currErr?.code]}&nbsp;
        </div>
        <div className={b('bottom-text')}>{bottomText}</div>
      </ThemeProvider>
    </div>
  )
}
