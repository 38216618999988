import React, { SVGProps } from 'react'

import { ROUTE_URLS } from 'router/routes'

import { ISlotsGameType } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'

import { ReactComponent as CasinoSVG } from '../img/casino.svg'
import { ReactComponent as FilterSVG } from '../img/filter.svg'
import { ReactComponent as HeartSVG } from '../img/heart.svg'
import { ReactComponent as LiveCasinoSVG } from '../img/liveCasino.svg'
import { ReactComponent as MoreSVG } from '../img/more.svg'
import { ReactComponent as PromotionsSVG } from '../img/promotions.svg'
import { ReactComponent as TvGamesSVG } from '../img/tvGames.svg'
import { ReactComponent as VirtualSVG } from '../img/virtual.svg'

export interface INavigationItem {
  text: string
  img: React.FunctionComponent<SVGProps<SVGSVGElement>>
  route?: string
  first?: boolean
  selector?: boolean
}

export const getNavigationsItems = (gameType: ISlotsGameType): INavigationItem[] => [
  { text: 'casino', img: CasinoSVG, route: ROUTE_URLS.SLOTS_CASINO(), first: true },
  { text: 'virtualSport', img: VirtualSVG, route: ROUTE_URLS.SLOTS_GAMETYPE('virtual'), first: true },
  { text: 'tvGames', img: TvGamesSVG, route: ROUTE_URLS.SLOTS_GAMETYPE('tv-games'), first: true },
  { text: 'liveCasino', img: LiveCasinoSVG, route: ROUTE_URLS.LIVECASINO(), first: true },
  { text: 'more', img: MoreSVG, selector: true },
  { text: 'myGames', img: HeartSVG, route: ROUTE_URLS.SLOTS_MY_GAMES(gameType) },
  { text: 'promotions', img: PromotionsSVG, route: ROUTE_URLS.PROMOTIONS() },
  { text: 'filter', img: FilterSVG, route: ROUTE_URLS.SLOTS_FILTER(gameType) },
]
