import React, { useCallback, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import block from 'bem-cn'

import { ReactComponent as MinusSVG } from 'shared/img/minus.svg'
import { ReactComponent as PlusSVG } from 'shared/img/plus.svg'

import { logout } from 'features/auth/actions'
import { selectUserData } from 'features/auth/selectors'
import { selectLocaleDict } from 'features/locale/selectors'
import { selectSettings } from 'features/settings/selectors'

import { getAccordionLinks, IUserDataLinks, userDataLinks } from '../data/links/profilePanel'

import './ProfilePanel.scss'

const b = block('profile-panel')

export const ProfilePanel: React.FC<{ closePanel: () => void }> = ({ closePanel }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const locale = useSelector(selectLocaleDict, shallowEqual).profile
  const user = useSelector(selectUserData, shallowEqual)
  const isBonusTransferAvailable = useSelector(selectSettings, shallowEqual).settings.bonuses
    .transferBonusBalanceToBalanceOn

  const [selectedLinks, setSelectedLinks] = useState<number[]>([])

  const handleLogout = useCallback(() => {
    dispatch(logout())
    closePanel()
  }, [dispatch, closePanel])

  const handleAccrordeonElementClick = useCallback(
    (index: number) => {
      if (selectedLinks.find(link => link === index) !== undefined) {
        setSelectedLinks(selectedLinks.filter(link => link !== index))
      } else {
        setSelectedLinks([...selectedLinks, index])
      }
    },
    [selectedLinks],
  )

  const handleLinkClick = useCallback(
    (item: IUserDataLinks) => {
      if (item.link) {
        history.push(item.link!)
        closePanel()
      }
    },
    [closePanel, history],
  )

  const userLinks = useMemo(
    () => (
      <div className={b('links-block')}>
        {userDataLinks.map((item, index) => (
          <div className={b('link')} key={index} onClick={() => handleLinkClick(item)}>
            <span className={b('left-side')}>
              {locale[item.title] || item.title} {item.value && <>:</>}
            </span>
            {item.value && user && (
              <div className={b('right-side')}>
                {item.value.map((value, valueIndex) => (
                  <span key={valueIndex}>{user![value]} </span>
                ))}
                {item.title === 'balance' && <span>+ {user.bonusBalance} B</span>}
              </div>
            )}
          </div>
        ))}
      </div>
    ),
    [locale, handleLinkClick, user],
  )

  const accordeons = useMemo(
    () => (
      <div className={b('links-block')}>
        {getAccordionLinks(isBonusTransferAvailable!).map((item, index) => {
          const isActive = selectedLinks.find(link => index === link) !== undefined
          return (
            <div className={b('accordeon-container')} key={index}>
              <div
                className={b('link', { isAccordeon: true, isOpen: true })}
                onClick={() => handleAccrordeonElementClick(index)}>
                <span className={b('left-side')}>{locale[item.name] || item.name}</span>
                <div className={b('right-side')}>
                  <div className={b('open')}>
                    {!isActive && <PlusSVG className={b('img')} />}
                    {isActive && <MinusSVG className={b('img')} />}
                  </div>
                </div>
              </div>
              <div className={b('links', { isActive })}>
                {item.links
                  .filter(link => link.isDisplay !== false)
                  .map((link, linkIndex) => (
                    <div className={b('link')} key={linkIndex} onClick={() => handleLinkClick(link)}>
                      <span className={b('left-side')}>{locale[link.title] || link.title}</span>
                    </div>
                  ))}
              </div>
            </div>
          )
        })}
      </div>
    ),
    [handleAccrordeonElementClick, locale, handleLinkClick, selectedLinks, isBonusTransferAvailable],
  )

  return (
    <div className={b('wrapper')}>
      <div className={b()}>
        {userLinks}

        {accordeons}

        <div className={b('link')} onClick={() => handleLogout()}>
          <div className={b('left-side')}>{locale.logout}</div>
        </div>
      </div>
    </div>
  )
}
