/* eslint-disable max-len */
import { projectStyledName } from 'shared/constants'
export const auth = {
  successRegister: 'Registration was successful',
  youCanSignIn: 'You can log in now',
  onlyUsersCanUse: 'Only users can access the site',
  recover: 'Account',
  recoveryCode: 'Recovery code',
  newPassword: 'New password',
  repeat: 'Confirm new password',
  recovery: 'Password recovery',
  emailOrPhone: 'E-mail or phone',
  checking: 'Checking',
  sendCode: 'Send code',
  enterLogin: 'Enter your phone or email',
  confirmPass: 'Enter password again',
  passwordsDoNotMatch: 'Passwords do not match',
  newPass: 'Enter new password',
  phoneCode: 'Check your phone end enter code',
  inputPhone: 'Username',
  inputPassword: 'Input password',
  back: 'Return to authorization',
  restorePassword: 'Password recovery',
  restoreDescription: 'Enter phone or Email. You will be sent a password recovery code',
  password: 'Password',
  authorization: 'Sign In',
  registration: 'Registration',
  login: 'Login',
  socialAuthorization: 'Social authorization',
  byNumberPhone: 'By phone',
  byEmail: 'By E-mail',
  bySocial: 'By social',
  oneClick: 'One click',
  email: 'E-mail',
  currency: 'Currency',
  currencyBets: 'Currency bets',
  repeatPassword: 'Confirm password',
  iAssign: 'I confirm that I have read and fully agree with',
  numberPhone: 'Number phone',
  iHaveCode: 'I already have code',
  code: 'Code from sms',
  needAuth: 'Authorization require',
  successAuth: 'Sign In success',
  successReg: 'Sign Up success',
  succesRecovery: 'Password recovery success',
  forgotPassword: 'Forgot your password?',
  checkEmail: 'Please check your email',
  checkPhone: 'Please check your phone',
  dontForgetSave: "Don't forget to save your login and password",
  recoveryFailed: 'Password recovery failed',
  copiedSuccess: 'Authentication data successfully copied',
  recoverySuccess: 'Password recovered succesfully',
  tryAgain: ' SMS Message limit exceeded for today',
  pleaseCheckAuth: 'Please check your mail and confirm registration',
  signIn: 'Sign in',
  signUp: 'Sign up',
  signInOrUp: 'Sign in or register',
  forgot: 'Forgot?',
  dontHaveAccount: "Don't have an account yet?",
  register: 'Register',
  chooseMethod: 'Choose your preferred registration method',
  masterAccount: 'Master account',
  change: 'Change',
  chooseAccount: 'Choose an account',
  congratulations: 'Congratulations!',
  personal: 'Personal Information',
  account: 'Account Information',
  terms: 'Termas And Conditions',
  phone: 'Phone',
  accept: 'I accept the ',
  name: 'Name',
  username: 'Username',
  pass: 'Pass',
  confirm: 'Confirm',
  city: 'City',
  agrement:
    'By Clicking You Agree To Accept Our Terns And Conditions, That You Are Over 18 And You Are Aware Of Responsible Gaming Policy.',
  continue: 'Save and continue',
  loginText: 'Sign in or register',
  balance: 'Balance',
  promocode: 'Promocode',

  required: 'Required',
  passwDoesntMatch: 'Does not match with password',
  personalData:
    'The personal data that you supply when registering on this website may be shared with the 1xbet affiliate companies in different countries and third parties rendering services to 1xbet.',
  privacyPolicy: 'I have read, understood and accept the above Terms and Conditions and Privacy Policy',

  welcomeTo: 'Welcome to',
  signInDescription: 'Already with us?',
  registerDescription: 'First time with us?',
  acceptConditions: 'Terms and Conditions and the Privacy Policy',
  getBonuse: `I want to get bonuses and receive offers from ${projectStyledName}`,
  twoClicksRegister: 'Become a member of our Club in 2 simple clicks',
  signInLink: 'Please sign in',
  enterAndSignIn: 'Login to your account - by entering your email address and password below',
  bonusCode: 'Promotion Bonus Code',
  doYouHaveBonusCode: 'Do you have a bonus code?',

  minSymbols: 'Minimum number of characters',
  passwordsNotMatched: 'Password not matched',

  verifyEmailSuccess: 'Email verification successful. Please, login',
  forgotPasswordText: 'Enter your email address and I will send you a link to reset your password right away.',
  resetPasswordEmailSent: 'Password reset e-mail has been sent.',
  requiredToAccept: 'Required to accept',
  resendEmailRegistration: 'The letter has not arrived? Check spam folder or',
  resendEmail: 'Resend email',
  resendEmailSuccess: 'Email have been sent successfully',
  pleaseCheckSpam: 'The letter has not arrived? Please check your spam folder',
  send: 'Send',
  close: 'Close',

  bonusDescription: 'You will get +10% first deposit bonus and every day cashback',
  emailVerifiedSuccess: 'Email was successfully verified, please login',
  youShouldReceiveEmail: 'You should receive an email with a link',
  emailAddress: 'Email address',
  agreedTerms: 'Agree to the Terms & Privacy Policy',

}
