import React, { useCallback, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import block from 'bem-cn'

import { selectLocaleDict } from 'features/locale/selectors'
import { changePassword } from 'features/profile/actions'
import { selectProfileCommunication } from 'features/profile/selectors'

import Button from 'components/UIKit/Button/Button'
import { Input } from 'components/UIKit/Input/Input'

import './ChangePassword.scss'

const b = block('profile-change-password')

const ChangePassword: React.FC = () => {
  const dispatch = useDispatch()

  const [state, setState] = useState({
    oldPassword: '',
    newPassword: '',
    repeatNewPassword: '',
  })
  const changePasswordIsLoading = useSelector(selectProfileCommunication, shallowEqual).changePassword?.isLoading
  const locale = useSelector(selectLocaleDict, shallowEqual).profile

  const clearForm = useCallback(() => {
    setState({
      oldPassword: '',
      newPassword: '',
      repeatNewPassword: '',
    })
  }, [])

  const onChangeListener = useCallback(
    e => setState({ ...state, [e.currentTarget.name]: e.currentTarget.value as string }),
    [state],
  )

  const onSubmit = useCallback(
    e => {
      e.preventDefault()
      dispatch(changePassword({ data: state, callback: clearForm }))
    },
    [state, clearForm],
  )

  return (
    <section className={b()}>
      <div className={b('header')}>{locale.changePassword}</div>
      <form className={b('form')} onSubmit={onSubmit}>
        <div className={b('input-group')}>
          <span className={b('input-label')}>
            <span className={b('required')}>*</span> {locale.enterOldPassword}
          </span>
          <Input
            value={state.oldPassword}
            name="oldPassword"
            type="password"
            isRequired
            onChange={onChangeListener}
            height="35px"
          />
        </div>
        <div className={b('input-group')}>
          <span className={b('input-label')}>
            <span className={b('required')}>*</span> {locale.enterNewPassword}
          </span>
          <Input
            value={state.newPassword}
            isRequired
            name="newPassword"
            type="password"
            onChange={onChangeListener}
            height="35px"
          />
        </div>
        <div className={b('input-group')}>
          <span className={b('input-label')}>
            <span className={b('required')}>*</span> {locale.repeatNewPassword}
          </span>
          <Input
            value={state.repeatNewPassword}
            isRequired
            name="repeatNewPassword"
            type="password"
            onChange={onChangeListener}
            height="35px"
          />
        </div>
        <div className={b('button-container')}>
          <Button
            loading={changePasswordIsLoading}
            type="submit"
            text={locale.savePassword}
            colorType="primary"
            rounded
          />
        </div>
      </form>
    </section>
  )
}

export default ChangePassword
