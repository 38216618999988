// import BaseApi from 'shared/api/BaseApi'
import { BaseHttpService } from 'services/api/BaseHttpService'
import { QueryTypes } from 'services/api/data'

import { IServerFavorite, IServerResponse, ServerGameType } from 'modules/SlotsRoot/types/interfaces/Api'
import { ISlotViewItem } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'

import { slotProvidersConfiguration } from './configuration'
import FavoriteConverter from './providersData/Favorite/Favorite'
import { IFavoriteDTO } from './types/interfaces/Slots/Favorite'
import { IGetGameUrl, ProvidersType } from './types/slot'

export interface IGetGameList {
  id: string
  provider: string
}

export interface IGetExtraData {
  url: string
  converter?: (data: unknown) => unknown
}

export interface IGetSessionUrl {
  provider: ProvidersType
  gameId: string | number
  hasFreeSpins?: boolean
  params: object
  extraData: object
  gameList: ISlotViewItem[]
  demoStart?: string
}

export class SlotsApi extends BaseHttpService {
  private favoriteConverter

  private baseFrontendServiceUrl

  constructor(baseUrl: string) {
    super(baseUrl)
    this.baseUrl = baseUrl
    this.baseFrontendServiceUrl = `${baseUrl}/frontend-service`

    this.favoriteConverter = new FavoriteConverter()
  }

  async getGameList({ provider }: IGetGameList) {
    const { gameListUrl, converter } = slotProvidersConfiguration[provider]

    if (gameListUrl.includes('https://')) {
      return this.sendQuery<ServerGameType, ISlotViewItem[]>({
        type: QueryTypes.GET,
        url: `${gameListUrl}`,
        converterSuccess: data => converter.convertGameList({ data }),
      })
    }

    return this.sendQuery<ServerGameType, ISlotViewItem[]>({
      type: QueryTypes.GET,
      url: `${this.baseUrl}/${gameListUrl}`,
      converterSuccess: data => converter.convertGameList({ data }),
    })
  }

  async getFavoriteGameList() {
    return this.sendQuery<IFavoriteDTO[], string[]>({
      url: `${this.baseUrl}/api/users/favouritegames/`,
      type: QueryTypes.GET,
      converterSuccess: this.favoriteConverter.convertGameList,
    })
  }

  async getExtraData({ url, converter }: IGetExtraData): Promise<IServerResponse<unknown>> {
    return this.sendQuery({ type: QueryTypes.GET, url: `${this.baseUrl}/${url}`, converterSuccess: converter })
  }

  async getSessionUrl({
    provider,
    gameId,
    params = {},
    extraData = {},
    gameList,
    demoStart,
    hasFreeSpins,
  }: IGetSessionUrl): Promise<IServerResponse<string>> {
    const { sessionUrlData: { url, converter, getBody, getQuery, method, demoUrl } = {} } =
      slotProvidersConfiguration[provider]
    const body = getBody ? getBody({ gameId, ...params, gameList }) : undefined
    const urlQuery = getQuery ? getQuery({ gameId, ...params }) : ''
    const activeUrl = demoStart ? demoUrl : url
    if (activeUrl) {
      return this.sendQuery({
        type: method || QueryTypes.POST,
        url: `${this.baseUrl}/${activeUrl}${urlQuery}`,
        data: body,
        converterSuccess: (data: any) =>
          converter
            ? converter({ data, extraData, gameId, gameList, lang: SlotsApi.lang } as IGetGameUrl<unknown, unknown>)
            : data,
      })
    }
    return new Promise(resolve => {
      resolve({
        success: true,
        data: converter
          ? converter({ data: {}, extraData, gameId, gameList, lang: SlotsApi.lang } as IGetGameUrl<unknown, unknown>)
          : '',
        errorMessage: '',
        codeStatus: '',
      })
    })
  }

  async addSlot(provider: string, gameId: string) {
    return this.sendQuery<IServerFavorite, boolean>({
      type: QueryTypes.POST,
      url: `${this.baseUrl}/api/users/favouritegames/`,
      data: {
        provider,
        game_id: gameId,
      },
    })
  }

  async removeSlot(provider: string, gameId: string) {
    return this.sendQuery<IServerFavorite, boolean>({
      type: QueryTypes.DELETE,
      url: `${this.baseUrl}/api/users/favouritegames/`,
      data: {
        data: {
          provider,
          game_id: gameId,
        },
      },
    })
  }
}
