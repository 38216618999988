import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import block from 'bem-cn'

import { ReactComponent as StarSVG } from 'shared/img/slots/star.svg'

import { selectLocaleDict } from 'features/locale/selectors'

import './EmptyFavorites.scss'

const b = block('empty-favorites')

export const EmptyFavorites: React.FC = () => {
  const locale = useSelector(selectLocaleDict, shallowEqual).slots

  return (
    <div className={b()}>
      <h3 className={b('title')}>{locale.yourFavoritesList}</h3>
      <div className={b('description')}>
        <span>{locale.addFavoritesSlots}</span>
        <span className={b('icon-wrapper')}>
          <StarSVG />
        </span>
      </div>
    </div>
  )
}
