import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import block from 'bem-cn'
import { PanInfo } from 'framer-motion'

import { MODAL_ROUTE_URLS } from 'router/modalRoutes'
import { ROUTE_PATHS, ROUTE_URLS } from 'router/routes'

import { ReactComponent as CrossSVG } from 'shared/img/cross.svg'

import { useClickOutside } from 'shared/hooks/useClickOutside'
import { ProfileTabs } from 'shared/models/Profile'
import { mediaSize } from 'shared/style/var'

import { ISlotsGameType, SlotsGameType } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'

import { selectBonusPromotions } from 'features/bonusSystem/selectors'
import { selectLocaleDict } from 'features/locale/selectors'

import { ModalContext } from 'components/HOC/withModalQuery/ModalContext'

import AnimationPromotionHOC from './AnimationPromotionHOC/AnimationPromotionHOC'
import {
  activePromotionStatuses,
  bonusPromotionStatusWeights,
  takeYourBonusStatuses,
} from './BonusPromotionNotification.utils'
import freeSpinImg from './img/free-spin.png'
import takeBonusImg from './img/take-bonus.png'
import WagerCompletionScale from './WagerCompletionScale/WagerCompletionScale'

import './BonusPromotionNotification.scss'

const b = block('bonus-promotion-notification')
const availableNotificationRoutes: string[] = [ROUTE_PATHS.MAIN, ROUTE_PATHS.SLOTS_CASINO]
const BonusPromotionNotification = () => {
  const history = useHistory()
  const location = useLocation()
  const locale = useSelector(selectLocaleDict, shallowEqual).bonuses
  const promotions = useSelector(selectBonusPromotions, shallowEqual)
  const [isShowBonusNotification, setIsShowBonusNotification] = useState<boolean>(false)
  const { gameType }: { gameType: ISlotsGameType } = useParams()

  const isMobileOfTablet = useMediaQuery({ maxWidth: mediaSize.tablet })
  const { pushNewModal } = useContext(ModalContext)
  const bonusNotificationRef = useRef(null)
  useEffect(() => {
    if (availableNotificationRoutes.includes(location.pathname)) {
      let showHideTimer = setTimeout(() => {
        setIsShowBonusNotification(true)

        showHideTimer = setTimeout(() => {
          setIsShowBonusNotification(false)
        }, 5000)
      }, 1000)
      return () => {
        clearTimeout(showHideTimer)
      }
    }
    return () => {}
  }, [location.pathname])

  useClickOutside(bonusNotificationRef, () => setIsShowBonusNotification(false))
  const activePromotion = useMemo(
    () => promotions.find(promotion => activePromotionStatuses.indexOf(promotion.status) !== -1),
    [promotions],
  )

  const takeBonus = useMemo(
    () => promotions.find(promotion => takeYourBonusStatuses.indexOf(promotion.status) !== -1),
    [promotions],
  )

  const activePromotionWeight = useMemo(
    () => (activePromotion ? bonusPromotionStatusWeights[activePromotion.status] : 0),
    [activePromotion],
  )

  const takeBonusWight = useMemo(
    () => (takeBonus ? bonusPromotionStatusWeights[takeBonus.status] : 0),
    [activePromotion],
  )

  const handleCloseNotification = useCallback(
    (event: React.MouseEvent<SVGSVGElement | HTMLDivElement>) => {
      event.stopPropagation()
      setIsShowBonusNotification(false)
    },
    [setIsShowBonusNotification],
  )

  const handleFinishDrag = (info: PanInfo) => {
    if (info.offset.y < -(window.innerHeight * 0.05)) {
      setIsShowBonusNotification(false)
    }
  }

  const handleOpenPromo = useCallback(() => {
    if (isMobileOfTablet) {
      history.push(ROUTE_URLS.PROMOTIONS())
    } else {
      pushNewModal(MODAL_ROUTE_URLS.PROFILE_DESKTOP(ProfileTabs.BONUS_PROMOTIONS))()
    }
    setIsShowBonusNotification(false)
  }, [pushNewModal, isMobileOfTablet, history])

  if (activePromotion && takeBonusWight < activePromotionWeight) {
    return activePromotion.freeSpinsCount > 0 ? (
      <AnimationPromotionHOC isShowBonusNotification={isShowBonusNotification} handleFinishDrag={handleFinishDrag}>
        <div
          onClick={() => history.push(ROUTE_URLS.SLOTS_GAMETYPE(SlotsGameType.freeSpin))}
          className={b({ isFreeSpins: true })}>
          <img src={freeSpinImg} className={b('free-spin-image')} alt="free-spin" />
          <div className={b('blur')} />
          <CrossSVG className={b('cross')} onClick={handleCloseNotification} />
          <div className={b('text')}>{locale.clickForPlayFreeSpins}</div>
        </div>
      </AnimationPromotionHOC>
    ) : (
      <AnimationPromotionHOC isShowBonusNotification={isShowBonusNotification} handleFinishDrag={handleFinishDrag}>
        <div
          onClick={e => {
            history.push(ROUTE_URLS.SLOTS_GAMETYPE('wagering'))
            handleCloseNotification(e)
          }}
          className={b({ isWager: true })}>
          <CrossSVG className={b('cross')} onClick={handleCloseNotification} />

          <div className={b('text')}>
            <span className={b('highlighted-text')}>{locale.playFor}</span>
            <span>
              {Number(activePromotion.wager - activePromotion.wagerComplete).toFixed(2)} {activePromotion.wagerCurrency}
            </span>
            <span className={b('highlighted-text')}>{locale.toGet}</span>
            <span>
              {Number(activePromotion.promotionGrandAmount).toFixed(2)} {activePromotion.wagerCurrency}
            </span>
            <span className={b('highlighted-text')}>{locale.bonus.toLowerCase()}</span>
          </div>
          <WagerCompletionScale
            wager={Number(activePromotion.wager)}
            wagerComplete={Number(activePromotion.wagerComplete)}
          />
        </div>
      </AnimationPromotionHOC>
    )
  }

  if (takeBonus) {
    return (
      <AnimationPromotionHOC isShowBonusNotification={isShowBonusNotification} handleFinishDrag={handleFinishDrag}>
        {/* <div className={b({ isTakeBonus: true })} onClick={() => history.push(ROUTE_URLS.PROMOTIONS())}> */}
        <div ref={bonusNotificationRef} className={b({ isTakeBonus: true })} onClick={handleOpenPromo}>
          <CrossSVG className={b('cross')} onClick={handleCloseNotification} />
          <img src={takeBonusImg} className={b('take-bonus-image')} alt="take-bonus" />
          <img src={takeBonusImg} className={b('take-bonus-image', { isRight: true })} alt="take-bonus" />
          <div className={b('text')}>{locale.takeYourBonus}</div>
        </div>
      </AnimationPromotionHOC>
    )
  }

  return <></>
}

export default BonusPromotionNotification
