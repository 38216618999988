import { ProvidersType } from '../../slot'

export interface ISlotViewItem {
  id?: string
  gameId: string
  description: string
  format?: string
  gameName: string
  sectionId?: string
  tab: string[]
  tabLive: string[]
  provider: string
  imageUrl: string
  imageUrlWide?: string
  routeProvider: ProvidersType
  isDefault?: boolean
  technologyId?: string | number
  gameUrl?: string
  hasDemoMode?: boolean
  hasFreeSpins?: boolean
  isLiveGame?: boolean
  additionalData?: any
  isHide?: boolean
}

export interface ITabs {
  [key: string]: string[]
}

export type ISlotsGameType = 'casino' | 'live-casino' | 'virtual' | 'tv-games' | 'wagering' | 'free-spin'

export enum SlotsGameType {
  casino = 'casino',
  liveCasino = 'live-casino',
  starCasino = 'star-casino',
  virtual = 'virtual',
  freeSpin = 'free-spin',
  tvGames = 'tv-games',
}
