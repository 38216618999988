import { ISlotViewItem } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'
import { ISoftwissResponse, ISoftwissServerGame } from 'modules/SlotsRoot/types/interfaces/Slots/Softwiss'
import { Providers } from 'modules/SlotsRoot/types/slot'

import { ISlotConverter } from '../../types/converter'
import { excludeSoftwissList, getTab, getTabLive } from './SoftwissUtils'

class SoftwissConverter implements ISlotConverter {
  convertGameList = ({ data }: { data: ISoftwissServerGame[] }): ISlotViewItem[] =>
    data
      .map((item: ISoftwissServerGame) => ({
        id: item.identifier,
        gameId: item.identifier,
        description: item.title,
        gameName: item.title,
        tab: getTab(item.identifier, item.title),
        tabLive: getTabLive(item.identifier),
        provider: item.producer,
        hasDemoMode: true,
        imageUrl: `https://cdn.softswiss.net/i/s2/${item.provider}/${item.identifier.substring(
          item.identifier.lastIndexOf(':') + 1,
        )}.png`,
        imageUrlWide: `https://cdn.softswiss.net/i/s2/${item.provider}/${item.identifier.substring(
          item.identifier.lastIndexOf(':') + 1,
        )}.png`,
        routeProvider: Providers.softwiss,
        hasFreeSpins: false,
      }))
      .filter(item => excludeSoftwissList.indexOf(item.provider.toLowerCase()) === -1)

  convertGameUrl = ({ data }: { data: ISoftwissResponse }) => data.launch_options.game_url
}

export default SoftwissConverter
