import React from 'react'
import block from 'bem-cn'
import { AnimatePresence, motion } from 'framer-motion'

import './Loader.scss'

const b = block('loader')

interface ILoaderProps {
  className?: string
  containerClassName?: string
  colorType?: 'dark' | 'light'
  loading?: boolean
}

const Loader: React.FC<ILoaderProps> = ({
  className = '',
  containerClassName = '',
  colorType = 'light',
  loading = true,
}) => (
  <AnimatePresence exitBeforeEnter>
    {loading ? (
      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.25 }}
        className={b('container').mix(containerClassName)}>
        <div className={b('wrapper', { colorType }).mix(className)}>
          <div />
          <div />
          <div />
          <div />
        </div>
      </motion.div>
    ) : (
      ''
    )}
  </AnimatePresence>
)

export default Loader
