import { auth } from './auth'
import { betHistory } from './betHistory'
import { bonuses } from './bonuses'
import { common } from './common'
import { errorStatusMessage } from './errorStatusMessage'
import { payment } from './payment'
import { profile } from './profile'
import { pwa } from './pwa'
import { slots } from './slots'
import { wheelGame } from './wheelGame'

export const locale = {
  auth,
  common,
  profile,
  payment,
  betHistory,
  slots,
  errorStatusMessage,
  bonuses,
  wheelGame,
  pwa,
}

export type localeBonusesType = typeof bonuses
export type localeBonusesKeysType = keyof typeof bonuses
