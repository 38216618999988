import React, { memo, useEffect, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import block from 'bem-cn'
import { AnimatePresence, motion } from 'framer-motion'

import useInfiniteVerticalScroll from 'shared/hooks/useInfiniteVerticalScroll'

import { IFiltersSlots } from 'modules/SlotsRoot/types/filters'
import { ISlotViewItem } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'

import { selectLocaleDict } from 'features/locale/selectors'

import { EmptyFavorites } from '../EmptyFavorites/EmptyFavorites'
import { EmptySearchResult } from '../EmptySearchResult/EmptySearchResult'
import SlotsIcon from '../SlotsIcon/SlotsIcon'

import './AllSlotsList.scss'

const b = block('all-slots-list')

interface IAllSlotsListProps {
  slotsList: ISlotViewItem[]
  filters: IFiltersSlots
  modal?: boolean
  addFrame?: (slot: ISlotViewItem) => () => void
}

export const AllSlotsList = memo(({ slotsList, filters, modal, addFrame }: IAllSlotsListProps) => {
  const locale = useSelector(selectLocaleDict, shallowEqual).slots
  const slotsPerRender = modal ? 12 : 30

  const { selectedCategories, selectedProviders, searchString } = filters

  const { current, ref }: { current: ISlotViewItem[]; ref: any } = useInfiniteVerticalScroll({
    data: slotsList,
    loadingCount: slotsPerRender,
  })

  const allSlots = useMemo(() => {
    if (searchString.length && !current.length) return <EmptySearchResult />
    if (selectedCategories.indexOf('favorite') !== -1 && !slotsList.length) return <EmptyFavorites />
    return (
      current?.map((slot, id) => {
        const handleAddFrame = modal ? addFrame : undefined

        const wide = modal && (id % 9 === 0 || id % 14 === 0)

        return <SlotsIcon key={`${slot.gameId}_${slot.provider}`} slot={slot} addFrame={handleAddFrame} wide={wide} />
      }) ?? []
    )
  }, [searchString, current, selectedCategories, slotsList, modal, addFrame])

  const loading = useMemo(
    () => (
      <>
        {current.length !== slotsList.length && slotsList.length && slotsList.length > slotsPerRender && (
          <div ref={ref} className={b('loading')}>
            <span>{locale.loading}</span>
          </div>
        )}
      </>
    ),
    [current, locale, ref, slotsList],
  )

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        key={`${selectedCategories.join('-')}-${selectedProviders.join('-')}`}
        data-tip={`${selectedCategories.join('-') || ''}-${selectedProviders.join('-') || ''}`}
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.1 }}>
        <div className={b('slots')}>{allSlots}</div>
        {loading}
      </motion.div>
    </AnimatePresence>
  )
})
