import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Link, useParams } from 'react-router-dom'
import block from 'bem-cn'

import { usePromotionsCount } from 'shared/hooks/usePromotionsCount'

import { ISlotsGameType } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'

import { selectLocaleDict } from 'features/locale/selectors'

import PromotionsPolling from 'components/pollings/PromotionsPolling/PromotionsPolling'

import { getNavigationsItems } from './data'

import './SlotsNavigation.scss'

const b = block('slots-navigation')

const SlotsNavigation: React.FC = () => {
  const location = useLocation()
  const { gameType }: { gameType: ISlotsGameType } = useParams()

  const locale = useSelector(selectLocaleDict, shallowEqual).common

  const [showSelector, setShowSelector] = useState(false)

  const { count: promosCount } = usePromotionsCount()

  useEffect(() => {
    setShowSelector(false)
  }, [location])

  const items = useMemo(
    () =>
      getNavigationsItems(gameType).map((item, id) => {
        const isCurrentTab = location.pathname === item.route
        if (item.first && item.route?.includes(`slots/${gameType}`)) {
          return (
            <Link key={id} className={b('item')} to={item.route || ''}>
              <item.img className={b('item-svg', { active: isCurrentTab && !showSelector })} />
              <div className={b('item-text', { active: isCurrentTab && !showSelector })}>
                {locale[item.text] || item.text}
              </div>
            </Link>
          )
        }
        if (item.first) return null
        if (item.selector) {
          return (
            <div key={id} className={b('item')} onClick={() => setShowSelector(prev => !prev)}>
              <item.img className={b('item-svg', { active: showSelector })} />
              <div className={b('item-text', { active: showSelector })}>{locale[item.text] || item.text}</div>
            </div>
          )
        }
        return (
          <Link key={id} className={b('item')} to={item.route || ''}>
            {item.text === 'promotions' ? (
              <>
                <PromotionsPolling />
                {promosCount ? <span className={b('item-count')}>{promosCount}</span> : null}
              </>
            ) : null}

            <item.img className={b('item-svg', { active: isCurrentTab })} />
            <div className={b('item-text', { active: isCurrentTab })}>{locale[item.text] || item.text}</div>
          </Link>
        )
      }),
    [location, locale, showSelector, gameType, promosCount],
  )
  const selectorItems = useMemo(
    () =>
      getNavigationsItems(gameType)
        .filter(item => item.first && !item.route?.includes(`slots/${gameType}`))
        .map((item, id) => (
          <Link key={id} className={b('item', { number: id + 1 })} to={item.route || ''}>
            <item.img className={b('item-svg')} />
            <div className={b('item-text')}>{locale[item.text] || item.text}</div>
          </Link>
        )),
    [locale, gameType],
  )

  return (
    <div className={b()}>
      <div className={b('container')}>
        <div className={b('selector', { isOpen: showSelector })}>
          <div className={b('selector-content')}>{selectorItems}</div>
        </div>
        <div className={b('links')}>
          <div className={b('circle')} />
          {items}
        </div>
      </div>
    </div>
  )
}

export default SlotsNavigation
