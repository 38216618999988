import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { getCashbackList } from 'features/notifies/actions'
import { selectLanguage } from 'features/userSettings/selectors'

import { useAppDispatch } from '../../../../index'

const CashbacksPolling: React.FC = () => {
  const dispatch = useAppDispatch()
  const lang = useSelector(selectLanguage, shallowEqual)
  // eslint-disable-next-line no-undef
  const currentTimeoutId = useRef<NodeJS.Timeout>()
  // eslint-disable-next-line no-undef
  const previousTimeoutId = useRef<number>()

  const getTimeoutedFunc = useMemo(
    () => () => {
      currentTimeoutId.current = setTimeout(() => {
        previousTimeoutId.current = Number(currentTimeoutId.current)
        dispatch(getCashbackList()).then(() => {
          if (previousTimeoutId.current === currentTimeoutId.current) getTimeoutedFunc()
        })
      }, 15 * 1000)
    },
    [dispatch],
  )

  const getCashbacksFunc = useCallback(() => {
    getTimeoutedFunc()
    dispatch(getCashbackList())
  }, [getTimeoutedFunc])

  useEffect(() => {
    if (currentTimeoutId.current) {
      clearTimeout(currentTimeoutId.current)
      previousTimeoutId.current = 0
    }

    getCashbacksFunc()

    return () => {
      if (currentTimeoutId.current) {
        clearTimeout(currentTimeoutId.current)
        previousTimeoutId.current = 0
      }
    }
  }, [lang])

  return null
}

export default CashbacksPolling
