import { createReducer } from '@reduxjs/toolkit'

import * as actions from '../actions'
import { ISupportFormState } from '../types'

const initialState: ISupportFormState = {
  isOpen: false,
}

export default createReducer(initialState, builder => {
  builder.addCase(actions.changeOpenSupportForm, (state, action) => {
    state.isOpen = action.payload ? action.payload : !state.isOpen
  })
})
