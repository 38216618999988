import React, { useEffect, useState } from 'react'
import { shallowEqual,useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useHistory,useParams } from 'react-router-dom'

import { ROUTE_URLS } from 'router/routes'

import { mediaSize } from 'shared/style/var'

import withQueryParams from 'modules/SlotsRoot/components/SlotsLayout/withQueryParams'
import { useFilterSlots } from 'modules/SlotsRoot/hooks'
import { IFiltersSlots } from 'modules/SlotsRoot/types/filters'
import { ISlotsGameType } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'

import { selectGameList } from 'features/slots/selectors'

import SlotsNavigation from '../SlotsNavigation/SlotsNavigation'
import FilterParameters from './FilterParamters/FilterParameters'
import FilterResults from './FilterResults/FilterResults'

interface IMobileFilterProps {
  filters: IFiltersSlots
}

const MobileFilter: React.FC<IMobileFilterProps> = ({ filters }) => {
  const { gameType }: { gameType: ISlotsGameType } = useParams()
  const history = useHistory()

  const gameList = useSelector(selectGameList, shallowEqual)

  const { slots, providers, categories, tabByGameType } = useFilterSlots({
    filters,
    slots: gameList,
    gameType,
  })

  const isMobileOrTablet = useMediaQuery({ maxWidth: mediaSize.tablet })

  const [pageOpened, setPageOpened] = useState<'filterParameters' | 'filterResults'>('filterParameters')

  useEffect(() => {
    if (!isMobileOrTablet) {
      history.push(ROUTE_URLS.SLOTS_CASINO())
    }
  }, [history, isMobileOrTablet])

  return (
    <>
      {pageOpened === 'filterParameters' ? (
        <FilterParameters
          goToResults={() => setPageOpened('filterResults')}
          filters={filters}
          slots={slots}
          gameType={gameType}
          providers={providers}
          categories={categories}
        />
      ) : (
        <FilterResults
          returnToParametes={() => setPageOpened('filterParameters')}
          filters={filters}
          slots={slots}
          gameType={gameType}
          providers={providers}
          categories={categories}
          tabByGameType={tabByGameType}
        />
      )}
      <SlotsNavigation />
    </>
  )
}

export default withQueryParams(MobileFilter)
