import icon1 from "../../../shared/img/footer/icon-1.svg";
import icon2 from "../../../shared/img/footer/Vector-1.svg";
import icon3 from "../../../shared/img/footer/Vector-2.svg";
import icon4 from "../../../shared/img/footer/Layer.svg";
import icon5 from "../../../shared/img/footer/Vector-5.svg";
import icon6 from "../../../shared/img/footer/Vector-6.svg";
import icon7 from "../../../shared/img/footer/Vector-7.svg";
import icon8 from "../../../shared/img/footer/Vector-8.svg";
import icon9 from "../../../shared/img/footer/symbol_1_.svg";
import icon10 from "../../../shared/img/footer/Vector-10.svg";

const footerItems: {
  [key: string]: { imageUrl: string; backgroundColor?: string };
} = {
  item1: {
    imageUrl: icon1,
    backgroundColor: "#537FEF",
  },
  item2: {
    imageUrl: icon3,
    backgroundColor: "#50AF95",
  },
  item3: {
    imageUrl: icon2,
    backgroundColor: "#F7931A",
  },
  item4: {
    imageUrl: icon4,
    backgroundColor: "#1176A8",
  },
  item5: {
    imageUrl: icon5,
    backgroundColor: "#fff",
  },
  item6: {
    imageUrl: icon6,
    backgroundColor: "#FE0102",
  },

  item7: {
    imageUrl: icon7,
    backgroundColor: "#C2A633",
  },
  item8: {
    imageUrl: icon8,
    backgroundColor: "#0033AD",
  },
  item9: {
    imageUrl: icon9,
    backgroundColor: "#50AF95",
  },
  item10: {
    imageUrl: icon10,
    backgroundColor: "#2775CA",
  },
};

export { footerItems };
