import React from "react";
import primeNetwork from "../../../shared/img/achievements/prime.png";
import mega from "../../../shared/img/achievements/mega.png";
import mini from "../../../shared/img/achievements/mini.png";
import middle from "../../../shared/img/achievements/middle.png";
import jackpot from "../../../shared/img/achievements/jackpots.png";


export interface PrizeDataProps {
  text1: string;
  text2: string;
  icon?: string;
  svg?: string;
}

export const prizeData: PrizeDataProps[] = [
  // { text1: "Jackpots", text2: "Spineo.io", svg: jackpot },
  { text1: "Prize Pool", text2: "400 000.40 USDT" },
  { text1: "Prime Network", text2: "245 324.22 USDT", svg: primeNetwork },
  { text1: "Middle", text2: "4130.10 USDT", svg: middle },
  { text1: "Mini", text2: "646.87 USDT", svg: mini },
];

export const prizeDataMobile: PrizeDataProps[] = [
  { text1: "Prime Network", text2: "245 324.22 USDT", svg: primeNetwork },
  { text1: "Mega", text2: "42 356.40 USDT", svg: mega },
  { text1: "Middle", text2: "4130.10 USDT", svg: mini },
  { text1: "Mini", text2: "646.87 USDT", svg: middle },
];
