import React, { ReactElement } from 'react'
import { createTheme, ThemeProvider, Tooltip as MuiTooltip, TooltipProps } from '@mui/material'

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: '#fff',
          background: '#63b75d',
          fontSize: '1.2rem',
          fontFamily: 'inherit',
        },
      },
    },
  },
})

interface ITooltipProps {
  text: string
  children: ReactElement
  placement?: TooltipProps['placement']
}

export const Tooltip: React.FC<ITooltipProps> = ({ text, children, placement = 'top' }) => (
  <ThemeProvider theme={theme}>
    <MuiTooltip placement={placement} title={text}>
      {children}
    </MuiTooltip>
  </ThemeProvider>
)
