import React, { useCallback, useContext, useEffect, useMemo,useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import block from 'bem-cn'

import { MODAL_ROUTE_PATH,MODAL_ROUTE_URLS } from 'router/modalRoutes'

import { getRouteParamsByStrings } from 'shared/utils/getRouteParamsByStrings'

import { changeActiveUserPromotion } from 'features/bonusSystem/actions'
import { selectBonusPromotions } from 'features/bonusSystem/selectors'
import { selectLocaleDict } from 'features/locale/selectors'

import { ModalContext } from 'components/HOC/withModalQuery/ModalContext'

import Button from 'components/UIKit/Button/Button'

import './ConfirmActivateBonus.scss'

const b = block('confirm-activate-bonus')

interface IConfirmActivateProps {
  path: string
}

interface ParamsMatch {
  bonusId: string
}

export const ConfirmActivateBonus: React.FC<IConfirmActivateProps> = ({ path }) => {
  const dispatch = useDispatch()
  const params = getRouteParamsByStrings<ParamsMatch>(MODAL_ROUTE_PATH.CONFIRM_ACTIVATE_BONUS, path)
  const bonusPromotions = useSelector(selectBonusPromotions, shallowEqual)
  const locale = useSelector(selectLocaleDict, shallowEqual).bonuses
  const { closeModal } = useContext(ModalContext)

  const [bonusId, setBonusId] = useState(params?.bonusId || '')

  const findBonusId = useMemo(
    () => bonusPromotions.find(bonus => bonus.id === +(+bonusId))?.id || '',
    [bonusId, bonusPromotions],
  )

  const onConfirmActivateHandler = useCallback(() => {
    dispatch(
      changeActiveUserPromotion({
        id: bonusId,
        callback: closeModal(MODAL_ROUTE_URLS.CONFIRM_ACTIVATE_BONUS(bonusId)),
      }),
    )
  }, [bonusId, closeModal, dispatch])

  useEffect(() => {
    if (String(findBonusId)) {
      setBonusId(String(findBonusId))
    } else {
      closeModal(MODAL_ROUTE_URLS.CONFIRM_ACTIVATE_BONUS(bonusId))
    }
  }, [findBonusId])

  return (
    <>
      <div className={b()}>
        <div className={b('text')}>{locale.changeActiveBonusText}</div>
        <div className={b('button-wrapper')}>
          <div className={b('button')}>
            <Button text={locale.activate} color="success" callback={onConfirmActivateHandler} />
          </div>
          <div className={b('button')}>
            <Button
              text={locale.cancel}
              colorType="tertiary"
              callback={closeModal(MODAL_ROUTE_URLS.CONFIRM_ACTIVATE_BONUS(bonusId))}
            />
          </div>
        </div>
      </div>
    </>
  )
}
