import { createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

import { BaseHttpService } from 'services/api/BaseHttpService'

import { languagesType, languagesWithIcons, shortLanguages } from 'shared/locale'
import { ThunkAction } from 'shared/models/thunk'

import { actions as localeActions } from 'features/locale'

export const CHANGE_LANG = '@userSettings/CHANGE_LANG'

export const CHANGE_ODD_TYPE = '@userSettings/CHANGE_ODD_TYPE'

export const changeLang = createAsyncThunk<languagesType, languagesType, ThunkAction>(
  CHANGE_LANG,
  async (lang, { dispatch, extra: { api }, getState }) => {
    const { locale } = await import(`shared/locale/${lang}/index`)
    const html = document.querySelector('html')
    if (html) {
      html.lang = shortLanguages[lang]
    }
    dayjs.locale(shortLanguages[lang])
    dispatch(localeActions.changeLang(locale))
    BaseHttpService.setLang(shortLanguages[lang])
    return lang
  },
)
