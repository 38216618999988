import { ISlotsGameType } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'

export interface IThemeItems {
  gameType: ISlotsGameType
  themes: string[]
}

export const themeItems: IThemeItems[] = [
  {
    gameType: 'casino',
    themes: ['lotto', 'fruits', 'wild', 'books', 'egypt', 'asia', 'sport'],
  },
]
