/* eslint-disable no-mixed-operators */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import block from 'bem-cn'
import { motion } from 'framer-motion'

import { selectLocaleDict } from 'features/locale/selectors'
import { spinWheel } from 'features/wheelGame/actions'
import { selectSpinIsLoading, selectWheelPublicSettings } from 'features/wheelGame/selectors'

import LoadableImage from 'components/LoadableImage'
import Loader from 'components/Loader/Loader'

import { addNotify } from '../../Notify'
import { prizeAngles, PROCCESS_ROTATE_COUNT, PROCCESS_ROTATE_DURATION } from '../data'
import bannerImg from '../img/banner.png'
import btnTitleImg from '../img/btn-title.png'
import selectorImg from '../img/selector.png'
import SpinTimer from '../SpinTimer'
import { getDay, getNewTime } from '../SpinTimer/data'
import RouletteWheel from './RouletteWheel'

import './Roulette.scss'

const b = block('roulette-game')

const variantsSelector = {
  initial: { scale: 1 },
  end: {
    scale: [1, 1.1, 1],
  },
}

const Roulette = ({ prizes, spinState, isSpining, isSpinFinished, isAuth, currency }) => {
  const dispatch = useDispatch()

  const spinLoading = useSelector(selectSpinIsLoading, shallowEqual).isLoading
  const locale = useSelector(selectLocaleDict, shallowEqual).wheelGame
  const localeAuth = useSelector(selectLocaleDict, shallowEqual).auth

  const publicSettings = useSelector(selectWheelPublicSettings, shallowEqual)

  const [freeSpinEnabled, setFreeSpinEnabled] = useState(false)
  const [countDownTime, setCountDownTime] = useState(getDay())

  useEffect(() => {
    setCountDownTime(getDay(publicSettings?.freeSpinTime))
    setFreeSpinEnabled(publicSettings?.hasFreeSpin)
  }, [publicSettings])

  const variants = useMemo(
    () => ({
      stop: { rotate: 1, transition: { duration: 0, repeat: 0 } },
      rotate: {
        rotate: PROCCESS_ROTATE_COUNT * spinState.spinCount + prizeAngles[spinState.winNumber],
        transition: { duration: PROCCESS_ROTATE_DURATION, repeat: 0, repeatDelay: 0, ease: 'easeOut' },
      },
    }),
    [spinState],
  )

  const [isWheelLayoutReady, setWheelLayoutReady] = useState(false)

  const onSpin = isFree => {
    if (isSpining || spinLoading || !isWheelLayoutReady) return

    if (!isAuth) {
      addNotify(localeAuth.needAuth)
      return
    }

    dispatch(spinWheel({ is_free: isFree }))
  }

  return (
    <div className={b('container')}>
      <RouletteWheel
        variants={variants}
        setWheelLayoutReady={setWheelLayoutReady}
        isSpining={isSpining}
        data={prizes}
      />
      <motion.img
        variants={variantsSelector}
        animate={!isSpinFinished ? 'initial' : 'end'}
        src={selectorImg}
        className={b('selector-img', { hidden: !isWheelLayoutReady })}
        alt="selector"
      />
      <div className={b('spin-btn', { hidden: !isWheelLayoutReady })}>
        <div className={b('spin-btn_container')}>
          <motion.img
            src={btnTitleImg}
            className={b('spin-btn_text')}
            animate={{ scale: 1.14, rotate: 5 }}
            transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }}
          />
        </div>
      </div>
      <div className={b('bottom-heading')}>
        <LoadableImage hidden={!isWheelLayoutReady} src={bannerImg} className={b('bottom-heading_img')} />
      </div>
      {freeSpinEnabled || !isAuth ? (
        <button
          onClick={() => onSpin(true)}
          className={b('btn-spin', { hidden: !isWheelLayoutReady })}
          aria-label="spin">
          {spinLoading ? (
            <Loader className={b('loader-small')} colorType="light" />
          ) : (
            <div className={b('btn-spin_inner')}>{locale.freeSpin}</div>
          )}
        </button>
      ) : (
        <div className={b('btn-spin_wrapper', { hidden: !isWheelLayoutReady })}>
          <button onClick={() => onSpin(false)} className={b('btn-spin', { isCost: true })} aria-label="spin">
            <div className={b('btn-spin_inner')}>
              {locale.spinFor} {publicSettings?.paidSpinCost} {currency}
            </div>
          </button>
          <SpinTimer countDownDate={countDownTime} getNewTime={getNewTime} setCountDownTime={setCountDownTime} />
        </div>
      )}
    </div>
  )
}

export default Roulette
