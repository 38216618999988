import block from 'bem-cn'
import React from 'react'
import { SiVisa, SiSamsungpay } from 'react-icons/si'
import { FaGooglePay, FaCcMastercard } from 'react-icons/fa6'
import { FaApplePay } from 'react-icons/fa'
import Button from 'components/UIKit/Button/Button'
import GameSection, { GameSectionProps } from 'components/GameSection/GameSection'
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import { Search } from '@mui/icons-material'
import './GetCrypto.scss'
import { ReactComponent as Etherium } from '../../shared/img/get-crypto/ethereum.svg'

// Top Games
import { ReactComponent as TopIcon } from '../../shared/img/games-section/top-games/top-games-icon.svg'
import Tethrum from '../../shared/img/get-crypto/tethrum.png'
import Bitcoin from '../../shared/img/get-crypto/bitcoin.png'
import aviator from '../../shared/img/games-section/top-games/aviator.png'
import bigbass from '../../shared/img/games-section/top-games/bigbass.png'
import bigbasssplash from '../../shared/img/games-section/top-games/bigbasssplash.png'
import booksofancient from '../../shared/img/games-section/top-games/booksofancient.png'
import buffalosun from '../../shared/img/games-section/top-games/buffalosun.png'
import buffalotrail from '../../shared/img/games-section/top-games/buffalotrail.png'
import egyptsun from '../../shared/img/games-section/top-games/egyptsun.png'
import elvisfrog from '../../shared/img/games-section/top-games/elvisfrog.png'
import heartsdesire from '../../shared/img/games-section/top-games/heartsdesire.png'

// New Games
import { ReactComponent as NewIcon } from '../../shared/img/games-section/new-games/new-game-icon.svg'
import bigbass2 from '../../shared/img/games-section/new-games/bigbass.png'
import cheltenham from '../../shared/img/games-section/new-games/cheltenham.png'
import dawnofkings from '../../shared/img/games-section/new-games/dawnofkings.png'
import dragoncrash from '../../shared/img/games-section/new-games/dragoncrash.png'
import galactic from '../../shared/img/games-section/new-games/galactic.png'
import jumpforglory from '../../shared/img/games-section/new-games/jumpforglory.png'
import mighty from '../../shared/img/games-section/new-games/mighty.png'
import queenofshadows from '../../shared/img/games-section/new-games/queenofshadows.png'
import robocop from '../../shared/img/games-section/new-games/robocop.png'
import totem from '../../shared/img/games-section/new-games/totem.png'

// Live Casino
import { ReactComponent as LiveIcon } from '../../shared/img/games-section/live-casino/live-casino-icon.svg'
import blackjack from '../../shared/img/games-section/live-casino/blackjack.png'
import blackjack2 from '../../shared/img/games-section/live-casino/blackjack2.png'
import lighting from '../../shared/img/games-section/live-casino/lighting.png'
import queenofshadows2 from '../../shared/img/games-section/live-casino/queenofshadows.png'
import reddorr from '../../shared/img/games-section/live-casino/reddorr.png'
import roulette from '../../shared/img/games-section/live-casino/roulette.png'
import russian from '../../shared/img/games-section/live-casino/russian.png'
import speed from '../../shared/img/games-section/live-casino/speed.png'
import xtreme from '../../shared/img/games-section/live-casino/xtreme.png'

const tabsData = ['Bonus', 'Live Casino', 'Table Games', 'Jackpots', 'Bonus But Slots']

const liveGamesImages = [
  blackjack,
  blackjack2,
  lighting,
  queenofshadows2,
  reddorr,
  roulette,
  russian,
  speed,
  bigbass2,
  xtreme,
]

const newGamesImages = [
  bigbass2,
  cheltenham,
  dawnofkings,
  dragoncrash,
  galactic,
  jumpforglory,
  mighty,
  queenofshadows,
  robocop,
  totem,
]
const topGamesImages = [
  aviator,
  bigbass,
  bigbasssplash,
  booksofancient,
  buffalosun,
  buffalotrail,
  egyptsun,
  elvisfrog,
  heartsdesire,
  aviator,
]

const topGamesData = {
  Icon: TopIcon,
  headingText: 'Top Games',
  iconBg: 'top-games',
  btnText: 'View All',
  image: topGamesImages,
}

const newGamesData = {
  Icon: NewIcon,
  headingText: 'New Games',
  iconBg: 'new-games',
  btnText: 'View All',
  image: newGamesImages,
}

const liveGamesData = {
  Icon: LiveIcon,
  headingText: 'Live Casino',
  iconBg: 'live-games',
  btnText: 'View All',
  image: liveGamesImages,
}

const GetCrypto = () => {
  const b = block('get-crypto')
  
  return (
    <section className={b()}>
      <div className={b('container')}>
        <div className={b('img-container-eth')}>
          <div className={b('img-bottom')}>
            <Etherium />
          </div>
        </div>
        <h2 className={b('text')}>
          Get Crypto In
          <br /> Seconds
        </h2>
        <div className={b('img-container-btc')}>
          <img src={Bitcoin} alt="bitcoin" className={b('img-bottom')} />
        </div>
        <div className={b('img-container-tth')}>
          <img src={Tethrum} alt="bitcoin" className={b('img-top')} />
        </div>
        <div className={b('pay-icon-container')}>
          <div className={b('pay-icon')}>
            <FaGooglePay size={32} />
          </div>
          <div className={b('pay-icon')}>
            <FaCcMastercard size={32} style={{ background: '#150d1f' }} />
          </div>
          <div className={b('pay-icon')}>
            <FaApplePay size={32} />
          </div>
          <div className={b('pay-icon')}>
            <SiSamsungpay size={32} />
          </div>
          <div className={b('pay-icon')}>
            <SiVisa size={32} />
          </div>
        </div>
        <div className={b('buy-crypto')}>
          <Button text="Buy Crypto" colorType="primary" />
        </div>
      </div>
      <div className={b('tabs-section')}>
        {tabsData.map((item, index) => (
          <div className={b('tab')} key={index}>
            <span className={b('tab-item')} >{item}</span>
          </div>
        ))}
      </div>

      <div className={b('searchInput')}>
        <OutlinedInput
          id="outlined-adornment-password"
          type="text"
          placeholder="Search"
          // inputRef={inputRef}
          aria-label="Search"
          sx={{ backgroundColor: '#271937', color: 'white',width:"100%" }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" edge="end">
                <Search color="primary" sx={{ color: 'white', height: 20, width: 20 }} />
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
      <GameSection data={topGamesData} />
      <GameSection data={newGamesData} />
      <GameSection data={liveGamesData} />
    </section>
  )
}

export default GetCrypto
