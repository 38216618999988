import { generatePath } from 'react-router-dom'

import { ISlotsGameType, SlotsGameType } from 'modules/SlotsRoot/types/interfaces/Slots/Slots'

export enum ROUTE_PATHS {
  AUTH = '/auth',
  AUTH_SIGN_UP = '/auth/sign-up',
  AUTH_SIGN_UP_WITH_PROMOCODE = '/auth/sign-up/:promocode',
  MAIN = '/main',
  PROFILE = '/profile',
  PROFILE_INDEX = '/profile/index',
  PROFILE_USERINFO = '/profile/user-info',
  PROFILE_WITHDRAW = '/profile/withdraw',
  PROFILE_TRANSACTIONS = '/profile/transactions',
  PROFILE_PROMOCODE = '/profile/promocode',
  PROFILE_LIMITS = '/profile/limits',
  PROFILE_USERBONUS = '/profile/user-bonus',
  PROFILE_BONUS_PROMOTIONS = '/profile/bonus-promotions',
  PROFILE_SELFEXCLUSION = '/profile/self-exclusion',
  PROFILE_BETHISTORY = '/profile/bet-history',
  PROFILE_BONUSHISTORY = '/profile/bonus-history',
  PROFILE_CASHBACKHISTORY = '/profile/cashback-history',
  PROFILE_BETDETAIL = '/profile/bet-detail',
  PROFILE_REPLENISHMENT = '/profile/replenishment',
  PROFILE_CASHIER_WITHDRAW = '/profile/cashier',
  PROFILE_KYC_VERIFICATION = '/profile/kyc-verifiaction',
  PROFILE_EXCHANGE_BONUS_BALANCE = '/profile/exchange-bonus-balance',
  PROFILE_CHANGE_PASSWORD = '/profile/change-password',
  PROFILE_SPORT_FREE_SPINS = '/profile/sport-free-spins',
  PROFILE_DEPOSIT = '/profile/deposit',
  BALANCE = '/balance',
  HELP = '/help',
  HELP_FAQ = '/faq',
  HELP_IGROVOI_KONT = '/faq/igrovoi-kont',
  HELP_DEPOSITS_WITHDRAWALS = '/faq/depoziti-i-vyplati',
  VERIFY = '/verify',
  VERIFY_EMAIL = '/verify/:key',
  TERMSANDCONDITIONS = '/terms-and-conditions',
  TERMSANDCONDITIONS_POINT = '/terms-and-conditions/:pointId',
  TERMSANDCONDITIONS_GENERALTERMS = '/terms-and-conditions/generalTerms',
  TERMSANDCONDITIONS_PRIVACYPOLICY = '/terms-and-conditions/privacyPolicy',
  LIVECASINO = '/slots/live-casino',
  PROMOTIONS_DETAIL = '/promotions-detail/:type',
  SLOTS = '/slots',
  SLOTS_CASINO = '/slots/casino',
  SLOTS_ALL = '/slots/all',
  SLOTS_SECTION = '/slots/:sectionId/:id',
  SLOTS_PROVIDERS = '/slots/casino?categories=all&providers=:provider',
  SLOTS_PROVIDER = '/slots/:gameType/:provider/:gameId',
  SLOTS_PROVIDER_DEMO_START = '/slots/:gameType/:provider/:gameId/:demoStart',
  SLOTS_PROVIDER_FREE_SPIN = '/slots/:gameType/:provider/:gameId/:freeSpin/session',
  SLOTS_GAMETYPE = '/slots/:gameType',
  SLOTS_PROMOTIONS = '/slots/:gameType/promotions',
  SLOTS_FILTER = '/slots/:gameType/filter',
  SLOTS_MY_GAMES = '/slots/:gameType/myGames',
  FOOTER_GAMING_CURACAO = 'https://licensing.gaming-curacao.com/validator/?lh=686550c5369e6774e220eea348e077e6',
  NEWS = 'https://www.fifa.com/fifaplus/en/tournaments/mens/worldcup/qatar2022',
  PROMOTIONS = '/promotions',
  JACKPOT = '/jackpot',
  LIVESUPPORT = '/live-support',
  TOPGAMES = '/top-games',
  NEWGAMES = '/new-games',
  TABLEGAMES = '/table-games',
  JACKPOTGAMES = '/jackpot-games',
  FEATURE_BUY='/feature-buy',
  PROVIDERS = '/providers',
  LIVEGAME = '/live-games',
  OTHERGAMES = '/other-games',
  AFFILIATE_PROGRAM = '/affiliate-program',
  PRIVACY_POLICY = '/privacy-policy',
  SPORTSBOOK_POLICY = '/sportsbook-T&C',
  TERMSCONDITIONS_POLICY= '/terms-conditions',
  BONUS_TERMS_POLICY = '/bouns-terms',
  RESPONSIBLE_GAMBLING= '/responsible-gambing',
  UNDERAGE_GAMING_POLICY = '/underage-gaming-policy',
  ABOUTUS='/about-us',
  EMAIL_SUPPORT = '/email-support',
   FAQ = '/faq',
   MENU = '/menu',
   HOME = '/home',
  DEPOSIT = '/deposit',
   SUPPORT = '/support',

}

export const ROUTE_URLS = {
  AUTH_SIGN_UP: () => generatePath(ROUTE_PATHS.AUTH_SIGN_UP),
  AUTH_SIGN_UP_WITH_PROMOCODE: (promocode: string) =>
    generatePath(ROUTE_PATHS.AUTH_SIGN_UP_WITH_PROMOCODE, { promocode }),
  MAIN: () => generatePath(ROUTE_PATHS.MAIN),
  PROFILE: () => generatePath(ROUTE_PATHS.PROFILE),
  PROFILE_INDEX: () => generatePath(ROUTE_PATHS.PROFILE_INDEX),
  PROFILE_USERINFO: () => generatePath(ROUTE_PATHS.PROFILE_USERINFO),
  PROFILE_BONUS_PROMOTIONS: () => generatePath(ROUTE_PATHS.PROFILE_BONUS_PROMOTIONS),
  PROFILE_WITHDRAW: () => generatePath(ROUTE_PATHS.PROFILE_WITHDRAW),
  PROFILE_TRANSACTIONS: () => generatePath(ROUTE_PATHS.PROFILE_TRANSACTIONS),
  PROFILE_BONUSHISTORY: () => generatePath(ROUTE_PATHS.PROFILE_BONUSHISTORY),
  PROFILE_CASHBACKHISTORY: () => generatePath(ROUTE_PATHS.PROFILE_CASHBACKHISTORY),
  PROFILE_PROMOCODE: () => generatePath(ROUTE_PATHS.PROFILE_PROMOCODE),
  PROFILE_LIMITS: () => generatePath(ROUTE_PATHS.PROFILE_LIMITS),
  PROFILE_REPLENISHMENT: () => generatePath(ROUTE_PATHS.PROFILE_REPLENISHMENT),
  PROFILE_CASHIER_WITHDRAW: () => generatePath(ROUTE_PATHS.PROFILE_CASHIER_WITHDRAW),
  PROFILE_BETDETAIL: () => generatePath(ROUTE_PATHS.PROFILE_BETDETAIL),
  PROFILE_KYC_VERIFICATION: () => generatePath(ROUTE_PATHS.PROFILE_KYC_VERIFICATION),
  PROFILE_EXCHANGE_BONUS_BALANCE: () => generatePath(ROUTE_PATHS.PROFILE_EXCHANGE_BONUS_BALANCE),
  PROFILE_CHANGE_PASSWORD: () => generatePath(ROUTE_PATHS.PROFILE_CHANGE_PASSWORD),
  PROFILE_SPORT_FREE_SPINS: () => generatePath(ROUTE_PATHS.PROFILE_SPORT_FREE_SPINS),
  PROFILE_DEPOSIT: () => generatePath(ROUTE_PATHS.PROFILE_DEPOSIT),
  BALANCE: () => generatePath(ROUTE_PATHS.BALANCE),
  HELP: () => generatePath(ROUTE_PATHS.HELP),
  HELP_FAQ: () => generatePath(ROUTE_PATHS.HELP_FAQ),
  HELP_IGROVOI_KONT: () => generatePath(ROUTE_PATHS.HELP_IGROVOI_KONT),
  HELP_DEPOSITS_WITHDRAWALS: () => generatePath(ROUTE_PATHS.HELP_DEPOSITS_WITHDRAWALS),
  VERIFY: () => generatePath(ROUTE_PATHS.VERIFY),
  VERIFY_EMAIL: (key: string) => generatePath(ROUTE_PATHS.VERIFY_EMAIL, { key }),
  TERMSANDCONDITIONS: () => generatePath(ROUTE_PATHS.TERMSANDCONDITIONS),
  TERMSANDCONDITIONS_POINT: (pointId: string) => generatePath(ROUTE_PATHS.TERMSANDCONDITIONS_POINT, { pointId }),
  TERMSANDCONDITIONS_GENERALTERMS: () => generatePath(ROUTE_PATHS.TERMSANDCONDITIONS_GENERALTERMS),
  TERMSANDCONDITIONS_PRIVACYPOLICY: () => generatePath(ROUTE_PATHS.TERMSANDCONDITIONS_PRIVACYPOLICY),
  LIVECASINO: () => generatePath(ROUTE_PATHS.LIVECASINO),
  PROMOTIONS_DETAIL: (type = 'deposit') => generatePath(ROUTE_PATHS.PROMOTIONS_DETAIL, { type }),
  SLOTS: () => generatePath(ROUTE_PATHS.SLOTS),
  SLOTS_CASINO: () => generatePath(ROUTE_PATHS.SLOTS_CASINO),
  SLOTS_ALL: () => generatePath(ROUTE_PATHS.SLOTS_ALL),
  SLOTS_SECTION: (sectionId: string, id: string) => generatePath(ROUTE_PATHS.SLOTS_SECTION, { sectionId, id }),
  SLOTS_PROVIDERS: (provider: string) => generatePath(ROUTE_PATHS.SLOTS_PROVIDERS, { provider }),
  SLOTS_PROVIDER: (provider: string, gameId: string, gameType: ISlotsGameType, demoStart?: string) =>
    generatePath(ROUTE_PATHS.SLOTS_PROVIDER, {
      gameType,
      provider,
      gameId,
    }),
  SLOTS_PROVIDER_DEMO_START: (provider: string, gameId: string, gameType: ISlotsGameType, demoStart?: string) =>
    generatePath(ROUTE_PATHS.SLOTS_PROVIDER_DEMO_START, {
      gameType,
      provider,
      gameId,
      demoStart: demoStart || '',
    }),
  SLOTS_PROVIDER_FREE_SPIN: (
    provider: string,
    gameId: string,
    freeSpin: SlotsGameType.freeSpin,
    gameType: ISlotsGameType,
  ) =>
    generatePath(ROUTE_PATHS.SLOTS_PROVIDER_FREE_SPIN, {
      gameType,
      provider,
      gameId,
      freeSpin,
    }),
  SLOTS_GAMETYPE: (gameType: ISlotsGameType) => generatePath(ROUTE_PATHS.SLOTS_GAMETYPE, { gameType }),
  SLOTS_FILTER: (gameType: ISlotsGameType) => generatePath(ROUTE_PATHS.SLOTS_FILTER, { gameType }),
  SLOTS_PROMOTIONS: (gameType: ISlotsGameType) => generatePath(ROUTE_PATHS.SLOTS_PROMOTIONS, { gameType }),
  SLOTS_MY_GAMES: (gameType: ISlotsGameType) => generatePath(ROUTE_PATHS.SLOTS_MY_GAMES, { gameType }),
  SLOTS_GAMETYPE_ALL_CATEGORIES: (gameType: ISlotsGameType) =>
    `${generatePath(ROUTE_PATHS.SLOTS_GAMETYPE, { gameType })}?categories=all`,
  FOOTER_GAMING_CURACAO: () => generatePath(ROUTE_PATHS.FOOTER_GAMING_CURACAO),
  NEWS: () => generatePath(ROUTE_PATHS.NEWS),
  PROMOTIONS: () => generatePath(ROUTE_PATHS.PROMOTIONS),
  EMAIL_SUPPORT: () => generatePath(ROUTE_PATHS.VERIFY_EMAIL),
  JACKPOT: () => generatePath(ROUTE_PATHS.JACKPOT),
  LIVESUPPORT: () => generatePath(ROUTE_PATHS.LIVESUPPORT),
  TOPGAMES: () => generatePath(ROUTE_PATHS.TOPGAMES),
  NEWGAMES: () => generatePath(ROUTE_PATHS.NEWGAMES),
  TABLEGAMES: () => generatePath(ROUTE_PATHS.TABLEGAMES),
  JACKPOTGAMES: () => generatePath(ROUTE_PATHS.JACKPOTGAMES),
  PROVIDERS: () => generatePath(ROUTE_PATHS.PROVIDERS),
  LIVEGAME: () => generatePath(ROUTE_PATHS.LIVEGAME),
  OTHERGAMES: () => generatePath(ROUTE_PATHS.OTHERGAMES),
  AFFILIATE_PROGRAM: () => generatePath(ROUTE_PATHS.AFFILIATE_PROGRAM),
  PRIVACY_POLICY: () => generatePath(ROUTE_PATHS.PRIVACY_POLICY),
  SPORTSBOOK_POLICY: () => generatePath(ROUTE_PATHS.SPORTSBOOK_POLICY),
  TERMSCONDITIONS_POLICY: () => generatePath(ROUTE_PATHS.TERMSCONDITIONS_POLICY),
  BONUS_TERMS_POLICY: () => generatePath(ROUTE_PATHS.BONUS_TERMS_POLICY),
  RESPONSIBLE_GAMBLING: () => generatePath(ROUTE_PATHS.RESPONSIBLE_GAMBLING),
  FEATURE_BUY: () => generatePath(ROUTE_PATHS.FEATURE_BUY),
  ABOUTUS: () => generatePath(ROUTE_PATHS.ABOUTUS),
  UNDERAGE_GAMING_POLICY: () => generatePath(ROUTE_PATHS.UNDERAGE_GAMING_POLICY),
  MENU: () => generatePath(ROUTE_PATHS.MENU),
  HOME: () => generatePath(ROUTE_PATHS.HOME),
  DEPOSIT: () => generatePath(ROUTE_PATHS.DEPOSIT),
  SUPPORT: () => generatePath(ROUTE_PATHS.SUPPORT),



}
